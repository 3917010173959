<template>
  <div id="app">
    <v-tabs>
      <v-tab href="#search" v-if="$store.state.authP.accountType == 'admin'">
        {{$t('SellingNudes')}}
      </v-tab>
      <v-tab-item value="search">
        <SellingNudes />
      </v-tab-item>

      <v-tab href="#rare" v-if="$store.state.authP.accountType == 'admin'">
        SellingGifts
      </v-tab>
      <v-tab-item value="rare">
        <SellingGifts />
      </v-tab-item>

      <v-tab href="#profileUpdate" v-if="$store.state.authP.accountType == 'model'">
        {{$t('UpdatePhotos')}}
      </v-tab>
      <v-tab-item value="profileUpdate">
        <ProfileUpdateComponentVue />
      </v-tab-item>

      <v-tab href="#gallery">
        <span v-if="$store.state.authP.accountType == 'model'"> Model </span>
        <span v-else> {{('Community')}} </span> &nbsp; Gallery
      </v-tab>
      <v-tab-item value="gallery">
        <CommunityGallery />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SellingNudes from "../components/sellingNudes.vue";
import SellingGifts from "../components/sellingGifts.vue";
import CommunityGallery from "../components/CommunityGallery.vue";
import ProfileUpdateComponentVue from '../components/ProfileUpdateComponent.vue';

export default {
  components: {
    SellingNudes,
    SellingGifts,
    CommunityGallery,
    ProfileUpdateComponentVue
  },
};
</script>
