<template>
  <div id="app">
    <PageLoader v-if="loading" />
    <v-dialog  v-model="newModDialog" max-width="690">
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('Addnewmoderator')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <div class="mb-2">
              <v-col style="padding: 0px">
                <v-text-field
                  v-model="pin"
                  dense
                  outlined
                  placeholder="Enter Pin"
                  :rules="pinRules"
                >
                </v-text-field>
              </v-col>
              <v-col style="padding: 0px">
                <v-text-field
                  outlined
                  :rules="passRules"
                  v-model="mPpassword"
                  placeholder="Enter password"
                >
                </v-text-field>
              </v-col>
              <v-col style="padding: 0px">
                <v-select
                  outlined
                  :items="modTypes"
                  :rules="typeRules"
                  v-model="modType"
                >
                </v-select>
              </v-col>
            </div>
            <v-btn block @click="saveNewPin" class="mb-5">Save</v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-if="activeModerator" v-model="updateModDiaog" persistent max-width="690">
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            Update
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form>
          <v-col>
            <div class="mb-2">
              <v-col v-if="activeModerator.seletedOption == 'password'" style="padding: 0px">
                <v-text-field
                  outlined
                  :rules="passRules"
                  v-model="updatedPAssword"
                  placeholder="Enter password"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="activeModerator.seletedOption == 'role'" style="padding: 0px">
                <v-select
                  outlined
                  :items="modTypes"
                  :rules="typeRules"
                  v-model="updatedRole"
                >
                </v-select>
              </v-col>
            </div>
            <v-btn block @click="updateModData" class="mb-5">update</v-btn>
            <v-btn block @click="cancelUpdateMod" class="mb-5">cancel</v-btn>

          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row dense>
      <div class="mt-2">
        <v-row dense class="mt-5 mx-5">
          <v-avatar tile :size="60" class="mt-3 ml-2">
            <v-img :src="require('@/assets/chat.png')" />
          </v-avatar>
          <h2 class="px-3 pt-2 font-weight-bold">
            <span
              >{{subModType}}
              <h5>Manage {{subModType}} here</h5>
            </span>
          </h2>
          <v-spacer style="width: 10%"></v-spacer>
          <v-btn
            class="mx-10 my-2"
            color="blue darken-1"
            @click="newModDialog = true"
            dark
            >{{$t('AddModerator')}}</v-btn
          >
        </v-row>
      </div>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
      :loading="loadingData"
      :items="items"
      :items-per-page="10"
      :headers="headers"
      show-select
      fixed-header
      class="elevation-0 mt-3 mx-2"
      :footer-props="{
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
      }"
      v-model="allSelected"
    >
      <template v-slot:[`item.pin`]="{ item }">
        {{ item.mod.pin }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon
          v-text="
            item.mod.status == 'active'
              ? 'mdi-check-circle'
              : 'mdi-close-circle'
          "
          :size="30"
          class="font-weight-bold"
          :color="item.mod.status == 'active' ? 'green' : 'error'"
        >
        </v-icon>
        <!-- <v-chip>
          {{ item.mod.status }}
        </v-chip> -->
      </template>
      <template v-slot:[`item.outs`]="{ item }">
        <v-chip>
          {{ item.outs }}
        </v-chip>
      </template>
      <template v-slot:[`item.ins`]="{ item }">
        <v-chip>
          {{ item.ins }}
        </v-chip>
      </template>
      <template v-slot:[`item.editParent`]="{ item }">
        <EditParent
        :item="item"
        />
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <v-chip>
          {{ item.total }}
        </v-chip>
      </template>
      <template v-slot:[`item.earnings`]="{ item }">
        <v-chip>
          {{ item.earnings.toFixed(2) }}
        </v-chip>
      </template>
       <template v-slot:[`item.onlineStatus`]="{ item }">
          <v-tooltip left>
           <template v-slot:activator="{ on }">
             <v-icon
               v-on="on"
              v-text="'mdi-circle'"
              :size="20"
              class="font-weight-bold"
              :color="getModStatusColor(item)"
            >
            </v-icon>
           </template>
           <span>{{ getModStatus(item) }}</span>
         </v-tooltip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          :color="item.mod.accountType == 'agent' ? '' : 'blue'"
          :dark="item.mod.accountType != 'agent'"
        >
          {{ item.mod.accountType }}
        </v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div>
          <p>{{ parseDate(item.mod.createdAt) }}</p>
        </div>
      </template>
      <template v-slot:[`item.mod.parentMod`]="{ item }">
        <div>
          <p v-if="item.mod.parentMod"> {{item.mod.parentMod.pin}} ({{item.mod.parentMod.accountType}}) </p>
          <p v-else>-</p>
        </div>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          @change="updateModStat(item, item.mod.active||false)"
          v-model="item.mod.active"
          :value="item.mod.active"
        ></v-switch>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-select
          v-model="item.seletedOption"
          outlined
          dense
          item-text="label"
          item-value="value"
          @change="() => actionSelected(item)"
          class="mt-4 mx-3"
          placeholder="Action"
          :items="actionItems"
      ></v-select>
      </template>
      <template v-slot:[`item.langs`]="{ item }">
        <v-select
          v-model="item.languages"
          :items="languages"
          @change="() => updateLang(item)"
          hint="Pick your favorite states"
          label="Select"
          multiple
          persistent-hint
        ></v-select>
      </template>
    </v-data-table>
  </div>
</template>
<script>
/* eslint-disable */
import {
  AGENT_ANALYTICS,
  GEN_MOD,
  POP_MOD,
  UPDATE_STAT,
  UPDATE_MODERATOR,
  UPDATE_MOD_LANG
} from "../queries/core";
import PageLoader from "@/components/PageLoader.vue"; 
import EditParent from "@/components/EditParent.vue"
export default {
  components:{
    PageLoader,
    EditParent
  },
  created() {
    this.loadMods();
  },
  methods: {
    async updateLang(item) {
      try {
        this.loading = true
        console.log(item)
        let {data} = await this.$apollo
        .mutate({
          mutation: UPDATE_MOD_LANG,
          variables: {
            mod:item.mod.id,
            languages:item.languages.join(",")
          },
        })
        this.loading =false
      console.log(data)
      this.loadMods();
      }catch(e) {
        this.loading = false
        console.log(e)
      }
    },
    async actionSelected(item) {
      this.activeModerator = item
      if(item.seletedOption == 'delete') return this.popAdmin(item)
      this.updatedRole = item.mod.accountType
      this.updateModDiaog = true
    },
    cancelUpdateMod() {
      this.activeModerator.seletedOption = 'nothing'
       this.activeModerator = null
        this.updatedRole = null
        this.updatedPAssword = null
        this.updateModDiaog = false
    },
    async updateModData() {
      this.$apollo
        .mutate({
          mutation: UPDATE_MODERATOR,
          variables: {
            id: String(this.activeModerator.mod.id),
            role: this.updatedRole,
            password : this.updatedPAssword
          },
        })
        .then(() => {
          this.loadMods();
          this.showToaster("Status updated succesfully !!!", "success");
        }).catch(()=>{

        }).finally(()=>{
          this.activeModerator.seletedOption = 'nothing'
          this.activeModerator = null
          this.updatedRole = null
          this.updatedPAssword = null
        })
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    checkModOnline({mod}) {
      let online_mods =   this.$store.getters.getOnlineMods;
      let mod_online_data = online_mods.find(m => Number(m.id) == Number(mod.id))
      if(mod_online_data) return mod_online_data
      return false
    },
    getModStatus({mod}){
     let online_mods =   this.$store.getters.getOnlineMods;
      let mod_online_data = online_mods.find(m => Number(m.id) == Number(mod.id))
      let status = 'offline';
      if(mod_online_data){
        switch(mod_online_data.status) {
          case 'sb':
            status = 'Screen Break'
            break;
          case 'b':
            status = 'Break'
            break;
          case 'p':
            status = 'Production'
            break;
          case 'm':
            status = 'Meeting'
            break;
          case 'zu':
            status = '1zu1 Instruction'
            break;
          case 'w':
            status = 'Workshop'
            break;
          default:
            'offline'
        }
      }
      return status
    },
    getModStatusColor({mod}){
     let online_mods =   this.$store.getters.getOnlineMods;
      let mod_online_data = online_mods.find(m => Number(m.id) == Number(mod.id))
      let status = 'gray';
      if(mod_online_data){
        switch(mod_online_data.status) {
          case 'sb':
            status = 'orange'
            break;
          case 'b':
            status = 'red'
            break;
          case 'p':
            status = 'green'
          break;
            case 'm':
          status = '#A6C634'
          break;
            case 'zu':
          status = '#A82AEC'
          break;
            case 'w':
          status = '#0281FD'
            break;
          default:
            'gray'
        }
      }
      return status
    },
    updateModStat(admin, stat) {
      this.$apollo
        .mutate({
          mutation: UPDATE_STAT,
          variables: {
            mod: admin.mod.id,
            stat: stat,
          },
        })
        .then(() => {
          this.loadMods();
          this.showToaster("Status updated succesfully !!!", "success");
        });
    },
    saveNewPin() {
      if (this.$refs.form.validate()) {
        this.$apollo
          .mutate({
            mutation: GEN_MOD,
            variables: {
              input: {
                pin: this.pin,
                password: this.mPpassword,
                modType: this.modType,
              },
            },
          })
          .then(() => {
            this.newModDialog = false;
            this.loadMods()
            this.showToaster("Pin Added succesfully !!!", "success");
          })
          .catch(() => {
            this.showToaster("Error creating PIN", "error");
          });
      }
    },
    loadMods() {
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: AGENT_ANALYTICS,
          variables: {
            input: {
              dateFilter: this.dateFilter,
            },
          },
        })
        .then(({ data: { allAgentsAnalytics } }) => {
          allAgentsAnalytics = allAgentsAnalytics.map((r)=>{
          return { ... r ,seletedOption:"nothing",languages:r.mod.languages.split(",")}
        })
          this.items = allAgentsAnalytics;
        });
    },
    popAdmin(admin) {
      const cnfrm = confirm(
        "Are you sure you want to remove the pin " + admin.mod.pin
      );
      if (cnfrm) {
        this.$apollo.mutate({
          mutation: POP_MOD,
          variables: {
            input: admin.mod.id,
          },
        }).then(()=>{
          this.loadMods();
          this.showToaster("Moderator removed successfully", "success");
        }).catch(()=>{
          this.showToaster("There was an error", "error");
        })
      }else {
        this.activeModerator.seletedOption = 'nothing'
      }
      console.warn(admin);
    },
    parseDate(date) {
      const obj = new Date(parseInt(date));
      return `${obj.toLocaleDateString()} | ${obj.toLocaleTimeString()}`;
    },
  },
  computed: {
    subModType:function() {
      let accountType = this.$store.state.authP.accountType;
      if(accountType == 'admin') {
        return  'All Moderators'
      }
      if(accountType == 'sub_admin') {
        return 'Team Leaders'
      }
      if(accountType == 'tl') {
        return 'Agents'
      }
      return []
    },
    modTypes:function() {
      let accountType = this.$store.state.authP.accountType;
      if(accountType == 'admin') {
        return ["admin", "agent",'tl','sub_admin']
      }
      if(accountType == 'sub_admin') {
        return ['tl']
      }
      if(accountType == 'tl') {
        return ["agent"]
      }
      return []
    }
  },
  data: () => ({
    // languages:['english','Turkish',
    //   'french','german','Polish','Spanish','Italia',
    //   'Portuguese','Schwedisch','Danish','Finisch',
    //   'Serbisch','Russisch','Japanese','Koreanisch',
    //   'Thailändische','Ungarisch','Arabic','Iran'
    // ],
    languages:['english',
      'french','german','turkish'
    ],
    actionItems:[  
      { label: "Change Password", value: "password" },
      { label: "Change Role", value: "role" },
      { label: "Delete", value: "delete" },
      { label: "Select Action", value: "nothing" },
    ],
    activeModerator:null,
    updatedRole:null,
    updatedPAssword:null,
    updateModDiaog:false,
    searched: "",
    dateFilter: "thisMonth",
    loading: false,
    loadingData: false,
    newModDialog: false,
    items: [],
    headers: [
      {
        text: "Pin",
        align: "start",
        sortable: false,
        value: "pin",
      },
      
      {
        text: 'AccountType',
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        text: "Ins",
        align: "start",
        sortable: false,
        value: "ins",
      },
      {
        text: "Outs",
        align: "start",
        sortable: false,
        value: "outs",
      },
      {
        text: 'Total',
        align: "start",
        sortable: false,
        value: "total",
      },
      {
        text: 'Earnings',
        align: "start",
        sortable: false,
        value: "earnings",
      },
      {
        text: 'Parent',
        align: "start",
        sortable: false,
        value: "mod.parentMod",
      },
      {
        text: 'ActiveStatus',
        align: "start",
        sortable: false,
        value: "active",
      },
      {
        text: 'Edit Parent',
        align: "start",
        sortable: false,
        value: "editParent",
      },
      {
        text: 'OnlineStatus',
        align: "start",
        sortable: false,
        value: "onlineStatus",
      },
      {
        text: 'Actions',
        align: "start",
        sortable: false,
        value: "actions",
      },
      {
        text: 'Languages',
        align: "start",
        sortable: false,
        value: "langs",
      },
    ],
    allSelected: [],
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    // modTypes: ["admin", "agent",'fkm','tl','sub_admin'],
    pin: "",
    modType: "",
    mPpassword: "",
    pinRules: [
      (v) => !!v || "Pin is required",
      (v) => (v && v.length <= 25) || "pin must be less than 5 characters",
    ],
    typeRules: [(v) => !!v || "Type is required"],
    passRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Name must be atleast 8 characters",
    ],
    valid: false,
  }),
};
</script>
<style>
.v-data-footer__icons-before {
  margin-left: 20px;
}
.v-data-footer {
  margin-top: 20px;
}
.searchField {
  width: 50%;
}
</style>