<template>
  <div style="overflow: scroll">
    <v-text-field v-model="ModJobTranslations" :label="$t('Job')" style="width: 95%" outlined :placeholder="$t('Job')">
    </v-text-field>
    <v-btn @click="saveNotes('ModJobTranslations',ModJobTranslations)" color="green-darken-1" variant="text">
      {{$t('Save')}}
    </v-btn>
    <v-text-field
    v-model="ModWSTranslations"
      :label="$t('WorkingSchedule')"
      style="width: 95%"
      outlined
      :placeholder="$t('WorkingSchedule')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModWSTranslations',ModWSTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="ModRSTranslations"
      :label="$t('RelationshipStatus')"
      style="width: 95%"
      outlined
     :placeholder="$t('RelationshipStatus')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModRSTranslations',ModRSTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="ModSFTranslations"
       :label="$t('Searchingfor')"
      style="width: 95%"
      outlined
      :placeholder="$t('Searchingfor')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModSFTranslations',ModSFTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="ModPRTranslations"
       :label="$t('pastRelationships')"
      style="width: 95%"
      outlined
      :placeholder="$t('pastRelationships')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModPRTranslations',ModPRTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="ModIPTranslations"
       :label="$t('TraitsforIdealPartner')"
      style="width: 95%"
      outlined
      :placeholder="$t('TraitsforIdealPartner')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModIPTranslations',ModIPTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="ModFPPTranslations"
       :label="$t('FuturePlanswithPartner')"
      style="width: 95%"
      outlined
      :placeholder="$t('FuturePlanswithPartner')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('ModFPPTranslations',ModFPPTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
  </div>
</template>

<script>
const languageMapper = {
  en:"en",
  Deutsch:"ge",
  arabic:"ur",
  Turkish:"tur",
  france:"fr",
  polish:"po",
  Spanish:"sp",
  Italia:"it",
  portuguese:"pot",
  Schwedisch:"sch",
  Danish:"dan",
  finisch:"fini",
  Serbisch:"seb",
  russisch:"russ",
  japanese:"jap",
  koreanisch:"kor",
  Thailändische:"thai",
  ungarisch:"ung",
  irn:"inr"
}
import { UPDATE_IDRIS_NOTES } from "../queries/core";
export default {
  data() {
    return {
      ModFPPTranslations: null,
      ModIPTranslations: null,
      ModJobTranslations: null,
      ModPRTranslations: null,
      ModRSTranslations: null,
      ModSFTranslations: null,
      ModWSTranslations: null,
    };
  },
  created() {
     let currentLanguage = this.$store.state.lang
      let toTranslationLang = languageMapper[currentLanguage]
    let notes = this.$store.state.profileDescIdris;
    if(notes.ModFPPTranslations) {
      this.ModFPPTranslations = notes.ModFPPTranslations[toTranslationLang]
    }
    if(notes.ModIPTranslations) {
      this.ModIPTranslations = notes.ModIPTranslations[toTranslationLang]
    }
    if(notes.ModJobTranslations) {
      this.ModJobTranslations = notes.ModJobTranslations[toTranslationLang]
    }
    if(notes.ModPRTranslations) {
      this.ModPRTranslations = notes.ModPRTranslations[toTranslationLang]
    }
    if(notes.ModRSTranslations) {
      this.ModRSTranslations = notes.ModRSTranslations[toTranslationLang]
    }
    if(notes.ModSFTranslations) {
      this.ModSFTranslations = notes.ModSFTranslations[toTranslationLang]
    }
    if(notes.ModWSTranslations) {
      this.ModWSTranslations = notes.ModWSTranslations[toTranslationLang]
    }
  },
  methods: {
    async saveNotes(model,text) {
      console.log(model)
      console.log(text)
      // console.log(UPDATE_IDRIS_NOTES)
      this.$apollo.mutate({
        mutation: UPDATE_IDRIS_NOTES,
        variables: {
          input: {
            text: text,
            type: "real",
            convo: this.$store.state.curConv,
            fieldType: model,
          },
        },
      });
    },
  },
};
</script>
