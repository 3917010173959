<template>
  <v-container fluid>
      <v-app-bar flat :height="100">
        <!-- from date selector -->
        <v-avatar tile :size="60" class="mx-2">
          <v-img :src="require('@/assets/filter.png')"> </v-img>
        </v-avatar>
      </v-app-bar>
      <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
          <div class="mx-3">
            <v-card-title style="padding: 0px" class="pt-5 pb-2"
              >SendWarning</v-card-title
            >
            <v-textarea
            v-model="warningText"
             label="Enter your comment here"
              outlined
              filled
            >
            </v-textarea>
          </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mx-2"
            :ripple="false"
            @click="dialog = false"
            large
            text
            >cancel</v-btn
          >
          <v-btn
            class="mb-2"
            @click="sendWarning"
            large
            :ripple="false"
            color="rgb(255 116 79)"
            dark
            >SendReport</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-row>
        <v-col cols="12"
          dense fixed app>
          <template>
           <v-data-table
            :options.sync="options"
            :server-items-length="totalCount"
            :loading="loadingTableData"
            :items="reports"
            :items-per-page="10"
            :headers="headers"
            fixed-header
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
             <template v-slot:[`item.reportActionType`]="{ item }">
              <p style="color:orange" v-if="item.reportActionType == 'warn'">
                Warned
              </p>
              <p style="color:red" v-if="item.reportActionType == null">
                No action taken yet
              </p>
              <p style="color:green" v-if="item.reportActionType == 'block'">
                 {{ item.warnText }} {{ new Date(Number(item.user.unblockTimestamp)) > new Date() ? '' : '(unblocked)'}}
              </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
               <v-select
               v-if="!item.reportActionType"
               v-model="item.seletedOption"
                outlined
                dense
                item-text="label"
                item-value="value"
                @change="() => actionSelected(item)"
                class="mt-4 mx-3"
                placeholder="Action"
                :items="actionItems"
            ></v-select>
            <v-btn
            @click="unblockUser(item)"
            v-if="new Date(Number(item.user.unblockTimestamp)) > new Date()"
            >Unblock</v-btn>
            </template>
          </v-data-table>
          </template>
        </v-col>
      </v-row>
      </v-container> 
</template>

<script>
/* eslint-disable */
import {
 GET_ALL_MOD_REPORTS,
 ADD_REPORT_ACTION,
 UNBLOCK_USER,
 REMOVE_ACCOUNT
} from "../queries/core";
export default {
  apollo: {
    allReports: {
      query: GET_ALL_MOD_REPORTS,
      result({ data: { modReports } }) {
        modReports = modReports.map((r)=>{
          return { ... r ,seletedOption:"nothing"}
        })
        this.reports = modReports;
        this.loadingTableData = false
      },
    },
  },
  data() {
    return {
      loadingTableData:true,
      dialog:false,
      activeReport:null,
      warningText:"",
      actionItems:[  
        // { label: "Block for 24 hrs", value: "block_24h*24" },
        // { label: "Block for 3 Days", value: "block_3d*72" },
        // { label: "Block for 7 Days", value: "block_7d*168" },
        { label: "Block User", value: "block*88320" },
        { label: "Send Warning", value: "warn" },
        { label: "Delete User", value: "delete" },
        { label: "Select Action", value: "nothing" },

      ],
       headers: [
      { text: 'Moderator', value: 'moderator.pin' },
      { text: 'User', value: 'user.username' },
      { text: 'Reason', value: 'reason' },
      { text: 'Comment', value: 'comment' },
      { text: 'Previous Warnings', value: 'user.warnedByAdmin' },
      { text: 'Resolved Action', value: 'reportActionType' },
      { text: 'Actions', value: 'actions' },
    ],
    reports:[],
      images:[]
    }
  },
  methods:{
    baseUrl() {
      return ''
    },
    async sendWarning() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: ADD_REPORT_ACTION,
          variables: {
            reportId: String(this.activeReport.id),
            actionType: 'warn',
            warnText:this.warningText
          },
        });
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        alert(e)
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.$apollo.queries.allReports
        .refetch()
      }
    },
    async blockUser(item) {
      try {
        let blockHours = item.seletedOption.split('*')[1]
        const { data, errors } = await this.$apollo.mutate({
          mutation: ADD_REPORT_ACTION,
          variables: {
            reportId: String(item.id),
            actionType:"block",
            blockHours:blockHours,
            warnText: Number(blockHours) <= 24 ? `Blocked for ${blockHours} hrs ` : `Blocked for ${blockHours/24} days `
          },
        });
      }catch(e) {
        alert(e)
      }
    },
    async unblockUser(item) {
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: UNBLOCK_USER,
          variables: {
            reportId: String(item.id),
          },
        });
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        alert(e)
      }
    },
   async popUsr(item) {
      
      await this.$apollo
        .mutate({
          mutation: REMOVE_ACCOUNT,
          variables: {
            usr:String(item.id),
          },
        })
      
      console.warn(item, "item");
    },
    async actionSelected(item) {
      this.activeReport = item
      let text = "Are you sure you want to proceed with this action";
      if (!confirm(text)) {
         this.$apollo.queries.allReports
        .refetch()
        return
      } 
      console.log(item)
      if(item.seletedOption == 'warn') {
        this.dialog = true
      }
      if(item.seletedOption.includes('block')) {
        await this.blockUser(item)
        this.activeReport = null
        this.$apollo.queries.allReports
        .refetch()
      }
      if(item.seletedOption == 'delete') {
        await this.popUsr(item.user)
        this.activeReport = null
        this.$apollo.queries.allReports
        .refetch()
      }
    }
  }
}
</script>

<style>

</style>