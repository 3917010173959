<template>
  <v-col cols="12" lg="3" style="padding: 0px; max-width: 20%">
    <v-dialog
      v-model="profileDetail"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="primary" dark class="text-h6"
          >{{$t('Userbasicprofileinfo')}}</v-toolbar
        >
        <v-card-text class="mt-5">
          <h3 class="text-h5">
            <span style="font-weight: bold">FirstName:</span>
            {{ $store.state.targetData.base_profile.firstName }}
          </h3>
          <h3 class="text-h5">
            <span style="font-weight: bold">LastName:</span>
            {{ $store.state.targetData.base_profile.lastName }}
          </h3>
          <h3 class="text-h5" v-if="$store.state.targetData.looks">
            <span style="font-weight: bold">Height:</span>
            {{ $store.state.targetData.looks.height || "None" }}
          </h3>
          <h3 class="text-h5" v-if="$store.state.targetData.looks">
            <span style="font-weight: bold">Hair Color:</span>
            {{ $store.state.targetData.looks.hair_color }}
          </h3>
          <h3 class="text-h5" v-if="$store.state.targetData.looks">
            <span style="font-weight: bold">Gender:</span>
            {{ $store.state.targetData.looks.gender }}
          </h3>
          <h3 class="text-h5" v-if="$store.state.targetData.looks">
            <span style="font-weight: bold">Sexual Preference:</span>
            {{ $store.state.targetData.looks.preference }}
          </h3>
          <h3 class="text-h5" v-if="$store.state.targetData.looks">
            <span style="font-weight: bold">Location Detail:</span>
            {{ getLoc($store.state.targetData.tempLocationDetail) }}
          </h3>
          <div class="text-h2 pa-12"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="profileDetail = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <!-- </template> -->
    </v-dialog>
    <!-- <v-card flat style="background: #fff; padding: 0px; border-radius: 0px"> -->
    <v-tabs-items
      v-model="model"
      v-if="$store.state.loadedTargetProfileStatus == 'loaded'"
    >
      <v-tab-item :value="`profile`">
        <v-card flat>
          <v-row
            align="start"
            justify="start"
            dense
            v-if="!$store.state.noDialogs"
          >
            <!-- <v-col style="padding: 0px" class="mx-4 mx-3">
              <h5 class="pt-5 font-weight-bold">
                {{ $store.state.targetData.base_profile.firstName }}
                {{ $store.state.targetData.base_profile.lastName }}
              </h5>
              <div class="mt-5" v-if="targetAddress">
                <v-row>
                  <v-avatar tile>
                    <v-img alt="FLAG"></v-img>
                  </v-avatar>
                  <h4 class="pt-2 px-1 font-weight-bold">
                    {{ targetAddress.country }}
                  </h4>
                </v-row>
              </div>
            </v-col> -->
            <div class="img-cont">
              <!-- src="https://funnyflirts.net/media/upload/f_pics/51983581_1.jpg" -->
              <img
                 :src="getURL($store.state.targetData.profilePic)"
                 @error ="$event.target.src = getDummyImage()"
                style="max-height: 200px; object-fit: contain; width: 100%"
              />
              <a
                class="after"
                style="text-decoration: none"
                @click="showProfileBox"
              >
              
                <v-badge
                offset-y="-140"
                offset-x="-200"
                color="green"
                :content="$store.state.targetData.credits"
                >
                </v-badge>
              
                {{ $store.state.targetData.base_profile.firstName }}
                {{ $store.state.targetData.base_profile.lastName }}
              </a>
            </div>
          </v-row>
          <div  class="text-start">
            <!-- profile details table start -->
            <v-card flat>
              <v-col class="mx-1 my-2" style="padding: 0px">
                <ProflrMultilangNotes
                  v-if="$store.state.domain == 'xxxxx.us'"
                />
                <v-textarea
                v-else
                  dense
                  class="mx-1"
                  height="450"
                  :disabled="$store.state.noDialogs"
                  filled
                  v-model="profileDesc"
                  hide-details
                  outlined
                  clear-icon="mdi-close-circle"
                ></v-textarea>
                <v-col style="padding: 0px" class="mt-2">
                  <v-text-field
                    dense
                    :placeholder="$t('name')"
                    filled
                    v-model="rPack.r_name"
                    prepend-icon="mdi-account"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col style="padding: 0px">
                  <v-text-field
                    dense
                    :placeholder="$t('location')"
                    filled
                    :disabled="$store.state.noDialogs"
                    v-model="rPack.r_location"
                    prepend-icon="mdi-map-marker"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col style="padding: 0px">
                  <v-text-field
                    dense
                    :placeholder="$t('birthDay')"
                    filled
                    :disabled="$store.state.noDialogs"
                    prepend-icon="mdi-calendar"
                    v-model="rPack.r_birth"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <!-- profile details table ends -->
                <v-row class="mx-2 mt-1 mb-5">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-5 mt-2"
                    color="#5681d4"
                    block
                    :dark="!$store.state.noDialogs"
                    :disabled="$store.state.noDialogs"
                    :loading="loadingUpdate"
                    @click="saveChanges"
                    >{{$t('SaveChanges')}}</v-btn
                  >
                </v-row>
              </v-col>
            </v-card>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>
<script>
import axios from "axios";
import { UPDATE_NOTE } from "../queries/core";
import ProflrMultilangNotes from '../components/ProfileMultilangNotes.vue'
const PRODUCTION = true;
export default {
  components: {
    ProflrMultilangNotes
  },
  data: () => ({
    selectedPr: {},
    profileDetail: false,
    model: "profile",
    profileDetails: [],
    selectedItem: 1,
    matches: [
      { text: "Janice", url: 43 },
      { text: "Alice", url: 44 },
      { text: "Merry", url: 45 },
    ],
    targetAddress: null,
    profileData: {},
    profileDesc: "",
    authKey: "",
    loadingUpdate: false,
    rPack: {
      r_name: "",
      r_location: "",
      r_birth: "",
    },
    currentStamp: Math.floor(new Date().getTime() / 1000),
  }),
  created() {
    this.profileDesc = this.$store.state.profileDesc;
  },
  methods: {
    getDummyImage() {
      if(!this.$store.state.targetData.looks) {
        return require('@/assets/Male.jpg')
      }
      let gender = this.$store.state.targetData.looks.gender
      if(gender == 'male' || gender == 'trans'){
         return require('@/assets/Male.jpg')
      }else{
         return require('@/assets/Female.jpg')
      }
    },
    saveChanges() {
      this.updateConvoDesc();
    },
    matchSelected(item) {
      this.$store.commit("changeTarget", item.user.id);
      this.getMessages(item.id);
    },
    getMessages(id) {
      console.warn(id);
    },
    getSrc() {
      const randNo = Math.round(Math.random() * 300);
      const trId = "ascaxasdx324c";
      const value = 25;
      const desc = "Here is some description";
      return `https://ads.trafficjunky.net/tj_ads_pt?a=1000253971&member_id=1003156481&cb=${randNo}&cti=${trId}&ctv=${value}&ctd=${desc}`;
    },
    reverseTargetLocation() {
      const target = this.$store.state.targetData;
      const key = "pk.06a92f85d813a94b913602a3e92a50e2";
      const url = `https://us1.locationiq.com/v1/reverse.php?key=${key}&lat=${target.lat}&lon=${target.lng}&format=json`;
      axios
        .get(url)
        .then(({ data }) => {
          this.targetAddress = data.address;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    updateConvoDesc() {
      this.loadingUpdate = !true;
      // const url = `https://funnyflirts.net/aj/moderation/updateConvoDesc`;
      this.$apollo
        .mutate({
          mutation: UPDATE_NOTE,
          variables: {
            input: {
              detail: this.profileDesc,
              type: "real",
              convo: this.$store.state.curConv,
              pack: this.rPack,
            },
          },
        })
        .then(({ data }) => {
          this.showToaster("Detail updated succesfully !!", "success");
          console.warn(data);
        })
        .catch((e) => {
          this.showToaster("Error saving note please try again !", "error");
          console.error(e);
        })
        .finally(() => {
          this.loadingUpdate = false;
          this.$store.commit("setProfileDesc", this.profileDesc);
        });
    },

    fetchTargetProfile() {
      this.$store.commit("loadTargetProfile", "loading");

      // console.log(loadedStatus, "LOADED STATUS");
      const target = this.$store.state.profileData.id;
      axios
        .get(
          `https://funnyflirts.net/aj/moderation/fetch_target_profile?target=${target}`
        )
        .then(({ data }) => {
          const profileDetails = [];
          const validKeys = ["birthday", "colour"];
          validKeys.forEach((k) => {
            profileDetails.push({
              title: k == "colour" ? "color" : k,
              value: data.user[k],
            });
          });
          this.profileDetails = profileDetails;
          this.targetData = data.user;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("loadTargetProfile", "loaded");
        });
    },

    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":4000/media/" + url;
      return domain;
    },
    showProfileBox() {
      this.profileDetail = true;
    },
    getLoc(loc) {
      if (!loc) {
        return "Unknown";
      }
      const parsed = JSON.parse(loc);
      const str = `${parsed["city"]}, ${parsed["country_name"]}`;
      return str;
    },
  },
  computed: {
    profileDescChangedStatus() {
      return this.$store.getters.profileDescChangedStatus;
    },
    rPackChangedStatus() {
      return this.$store.getters.rPackChangedStatus;
    },
  },
  watch: {
    rPackChangedStatus(value) {
      this.rPack = value;
    },
    profileDescChangedStatus(value) {
      console.warn(value);
      this.profileDesc = this.$store.state.profileDesc;
    },
  },
};
</script>
<style>
.v-text-field__details {
  display: none;
}
.img-cont {
  align-items: flex-end;
  background: #8080805e;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.img-cont .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 25px;
  color: white;
  font-weight: bold;
  padding-top: 150px;
  letter-spacing: 4px;
  /* background: rgba(17, 14, 14, 0.301); */
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(64, 99, 138, 0.856)
  );
}
</style>