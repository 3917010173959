var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('PageLoader'):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('div',{staticClass:"mt-2"},[_c('v-row',{staticClass:"mt-5 mx-5",attrs:{"dense":""}},[_c('h2',{staticClass:"px-3 pt-2 font-weight-bold"},[_c('span',[_vm._v("Deactivated Accounts ")]),_vm._v(" "),_c('br')]),_c('v-btn',{staticClass:"ml-2",on:{"click":function($event){return _vm.fetchFakeAccounts()}}},[_vm._v("Refresh")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-0 mt-3 mx-2",attrs:{"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loadingData,"items":_vm.items,"items-per-page":10,"headers":_vm.headers,"fixed-header":"","footer-props":{
      prevIcon: 'mdi-arrow-collapse-left',
      nextIcon: 'mdi-arrow-collapse-right',
       'items-per-page-options': [10, 20, 30, 40, 50,100]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.profilepic",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{staticClass:"profile m-3",attrs:{"color":"grey","size":"100","tile":""}},[_c('v-img',{attrs:{"src":_vm.getURL(item.profilePic)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.username",fn:function(ref){
    var item = ref.item;
return [_vm._v(" @"+_vm._s(item.username)+" ")]}},{key:"item.lastName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.base_profile && item.base_profile.lastName)+" ")]}},{key:"item.firstName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.base_profile && item.base_profile.firstName)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.initInfoUpdate(item)}}},[_vm._v("upate details")])]}},{key:"item.view",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.getUserUrl(item),"outlined":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [(!item.active)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.changeUserStatus(item,true)}}},[_vm._v(" Activate ")]):_vm._e()]}}],null,true)}),_c('v-pagination',{attrs:{"length":Math.ceil(_vm.totalCount/_vm.options.itemsPerPage),"rounded":"circle","total-visible":10},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }