<template>
  <div>
    <v-btn>edit paremt</v-btn>
  </div>
</template>
<script>
export default {
  props:['item'],
  created() {
    console.log(this.item,"asdasdasdasdasd")
  }
}
</script>
