<template>
   <!-- <h1>sdf</h1 -->
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('UpdateProfil')}}e / Featured Images
          </v-card-title>
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form" v-if="selectedItem">
          <v-row class="mx-5">
            <v-col style="padding: 0px" class="m-2">
              <p>Update Profile Picture</p>
              <v-row class="my-5 mx-1">
                <img
                  :src="
                    selectedItem.profilePic
                      ? getURL(selectedItem.profilePic)
                      : require('../assets/placeholder.png')
                  "
                  style="width: 250px;display:inline-block"
                />
                
                <input
                  ref="profilepic"
                  class="d-none"
                  type="file"
                  @change="profileChanged"
                />
                
              </v-row>
              <v-btn
                  :loading="loadingupload"
                  outlined
                  class="mt-6 mx-2"
                  @click="$refs.profilepic.click()"
                  >Update Profile Pic</v-btn
                >
                <br><br>
              <p>Update Featured Images (Add 3)</p>

              <v-row class="mb-10 mt-10">
                <input
                  @change="uploadProfilePhotos"
                  type="file"
                  ref="photosupload"
                  class="d-none"
                />
                <div v-if="selectedItem.photos">
                  <v-row v-if="selectedItem.photos" class="mx-2">
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(pic, ix) in selectedItem.photos"
                      :key="ix"
                    >
                      <v-img
                        :src="getURL(pic.image)"
                        class="mx-2"
                        style="max-width: 250px"
                      >
                        <v-overlay absolute v-if="hover">
                          <v-btn color="transparent" @click="deletePhoto(pic)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-overlay>
                      </v-img>
                    </v-hover>
                  </v-row>
                  <p v-else class="px-3" style="font-size: 20px">
                    {{$t('Noprofilephotos')}}
                  </p>
                </div>
                <v-btn
                  icon
                  large
                  :loading="loadingPupload"
                  @click="$refs.photosupload.click()"
                  class="mb-2 mt-5"
                  outlined
                  text
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-row>
              <!-- <div class="mb-2">
                <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-text-field
                      v-model="selectedItem.base_profile.firstName"
                      label="First Name"
                      outlined
                      placeholder="First Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-text-field
                      v-model="selectedItem.base_profile.lastName"
                      outlined
                      label="Last Name"
                      placeholder="Last Name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Select Relationship"
                      label="Relationship Status"
                      :items="profileDetailSetupTypes.relationShipStatus"
                      v-model="selectedItem.user_basic.relationship"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Work Status"
                      label="Work Status"
                      :items="profileDetailSetupTypes.workStatus"
                      v-model="selectedItem.user_basic.workstatus"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Education Level"
                      label="Education Level"
                      :items="profileDetailSetupTypes.educationStatus"
                      v-model="selectedItem.user_basic.education_level"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col style="padding: 0px">
                  <v-textarea
                    v-model="selectedItem.user_basic.bio"
                    outlined
                    label="Bio"
                  >
                  </v-textarea>
                </v-col>
              </div> -->
            </v-col>
            <!-- <v-col style="padding: 0px" class="m-2">
              <p>Looks</p>
              <div class="mb-2">
                <v-row>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      v-model="selectedItem.looks.ethnicity"
                      :items="lTypes.ethnicity"
                      label="Ethnicity"
                      outlined
                      placeholder="Ethnicity"
                    >
                    </v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      v-model="selectedItem.looks.body_type"
                      :items="lTypes.bodyType"
                      label="Body Type"
                      outlined
                      placeholder="Body Type"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-col style="padding: 0px" class="mt-2">
                  <v-select
                    v-model="selectedItem.looks.hair_color"
                    :items="lTypes.hairColor"
                    label="Hair Color"
                    outlined
                    placeholder="Hair Color"
                  >
                  </v-select>
                </v-col>
              </div>

             
            </v-col> -->
          </v-row>
          <!-- <v-row class="mx-8" align="end" justify="end">
            <v-btn
              @click="saveChanges"
              :loading="loadingSave"
              large
              class="mb-5"
              >{{$t('SaveChanges')}}</v-btn
            >
            <v-btn
              class="mb-5 mx-3"
              @click="editDialog = false"
              large
              color="error"
              outlined
              >{{$t('Close')}}</v-btn
            >
          </v-row> -->
        </v-form>
      </v-card>
    
</template>

<script>
/* eslint-disable */
import {GET_MODEL_DATA ,
FAKE_ACCOUNTS,
  LIFESTYLE_TYPES,
  LOOKS_PERSONALITY_TYPES,
  POP_FAKE_PICS,
  PROFILE_DETAIL_SETUP_TYPES,
  SEARCH_USR,
  UPDATE_FAKE_ACCOUNT,
  UPDATE_FAKE_PROFILE_PHOTOS,
  UPDATE_FAKE_PROFILE_PIC,
} from '../queries/core'
const PRODUCTION = true;
export default {
  async created() {
    await this.fetchFakeAccounts();
    await this.fetchProfileDetailSetupTypes();
    await this.fetchlooksAndPersonalitySetupTypes();
    await this.fetchLifestyleDetailsSetupTypes();
  },
  data() {
    return {
     
    items: [],
    lTypes: [],
    lifestyleTypes:{},
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    profileDetailSetupTypes: [],
    allSelected: [],
    searched: "",
    totalCount: 0,
    editDialog: false,
    loadingSave: false,
    loadingPupload: false,
    loadingupload: false,
    loadingData: false,
    selectedItem: null,
    }
  },
  apollo: {
    getModelData: {
      query: GET_MODEL_DATA,
      result({ data: { getModelData } }) {
        this.selectedItem = getModelData
        console.log(this.selectedItem)
      },
    },
  },
  methods: {
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    async deletePhoto(pic) {
      const cnf = confirm("are you sure you want to remove this picture ?");
      if (cnf) {
        const { errors } = await this.$apollo.mutate({
          mutation: POP_FAKE_PICS,
          variables: {
            pic: JSON.stringify(pic.id),
          },
        });
        if (!errors) {
          this.showToaster("deleted photo successfully", "success");
          this.editDialog = false;
          this.$apollo.queries.getModelData.refetch()
        }
        console.log(pic);
      }
    },
    async profileChanged(e) {
      this.loadingupload = true;
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_FAKE_PROFILE_PIC,
        variables: {
          file: e.target.files[0],
          user: JSON.stringify(this.selectedItem.id),
        },
      });
      if (data) {
        this.loadingupload = false;
        this.editDialog = false;
        this.showToaster("changed profile photo successfully", "success");
        this.$apollo.queries.getModelData.refetch()
        // await this.fetchFakeAccounts();
      } else {
        this.loadingupload = false;
      }
    },
    async uploadProfilePhotos(e) {
      this.loadingPupload = true;
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_FAKE_PROFILE_PHOTOS,
        variables: {
          file: e.target.files[0],
          user: JSON.stringify(this.selectedItem.id),
        },
      });
      if (data) {
        this.loadingPupload = false;
        this.editDialog = false;
        this.showToaster("upload profile photo successfully", "success");
        this.$apollo.queries.getModelData.refetch()
        // await this.fetchFakeAccounts();
      } else {
        this.loadingPupload = false;
      }
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":5000/media/" + url;
      return domain;
    },
     async fetchlooksAndPersonalitySetupTypes() {
      const { data } = await this.$apollo.query({
        query: LOOKS_PERSONALITY_TYPES,
      });
      if (data) this.lTypes = data.looksAndPersonalitySetupTypes;
    },
    async fetchProfileDetailSetupTypes() {
      const { data } = await this.$apollo.query({
        query: PROFILE_DETAIL_SETUP_TYPES,
      });
      if (data) {
        this.profileDetailSetupTypes = data.profileDetailSetupTypes;
      }
    },
    async fetchLifestyleDetailsSetupTypes() {
      const { data } = await this.$apollo.query({
        query: LIFESTYLE_TYPES,
      });
      if (data) {
        this.lifestyleTypes = data.lifeStyleAndFavouritesSetupTypes;
      }
    },
  }
}
</script>