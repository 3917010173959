<template>
  <v-col cols="12" lg="3" style="padding: 0px; max-width: 20%">
    <!-- <v-card flat style="background: #eee; padding: 0px; border-radius: 0px"> -->
    <v-tabs-items
      v-model="model"
      v-if="$store.state.loadedTargetProfileStatus == 'loaded'"
    >
      <v-tab-item :value="`profile`">
        <v-card flat>
          <v-row
            align="center"
            justify="center"
            dense
            class="mx-3"
            v-if="!$store.state.noDialogs"
          >
            <div class="img-cont">
              <img
                :src="getURL($store.state.profileData.profilePic)"
                alt=""
                style="max-height: 200px; object-fit: contain; width: 100%"
              />
              <p class="after" style="">
                {{ $store.state.profileData.base_profile.firstName }}
                {{ $store.state.profileData.base_profile.lastName }}
              </p>
            </div>
          </v-row>
          <div class="text-start">
            <v-card class="mx-2" flat>
              <v-col class="mx-1 my-2" style="padding: 0px">
                <TargetMultilangNotes
                  v-if="$store.state.domain == 'xxxxx.us'"
                />
                <v-textarea
                  v-else
                  class="mx-1"
                  height="450"
                  filled
                  dense
                  hide-details
                  :disabled="$store.state.noDialogs"
                  outlined
                  v-model="targetDesc"
                  clear-icon="mdi-close-circle"
                ></v-textarea>
                <v-col style="padding: 0px" class="mt-2">
                  <v-text-field
                    dense
                    :placeholder="$t('name')"
                    filled
                    :disabled="$store.state.noDialogs"
                    v-model="fPack.r_name"
                    prepend-icon="mdi-account"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col style="padding: 0px">
                  <v-text-field
                    dense
                    :placeholder="$t('location')"
                    filled
                    v-model="fPack.r_location"
                    :disabled="$store.state.noDialogs"
                    prepend-icon="mdi-map-marker"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col style="padding: 0px">
                  <v-text-field
                    dense
                    :placeholder="$t('birthDay')"
                    :disabled="$store.state.noDialogs"
                    filled
                    v-model="fPack.r_birth"
                    prepend-icon="mdi-calendar"
                    class="mx-2"
                  >
                  </v-text-field>
                </v-col>
                <!-- profile details table ends -->
                <v-row class="mx-2 mb-5 mt-1">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-2 mt-2"
                    color="#5681d4"
                    block
                    :disabled="$store.state.noDialogs"
                    :dark="!$store.state.noDialogs"
                    :loading="loadingUpdate"
                    @click="saveChanges"
                    >{{$t('SaveChanges')}}</v-btn
                  >
                </v-row>
              </v-col>
            </v-card>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- </v-card> -->
    <v-container
      fill-height
      fluid
      v-else-if="$store.state.loadedTargetProfileStatus == 'loading'"
    >
      <v-row align="center" justify="center">
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
          ></v-progress-circular>
          <br />
          <h5 class="pt-3">Loading Profile</h5>
        </v-col>
      </v-row>
    </v-container>
    <v-container fill-height fluid v-else>
      <v-row align="center" justify="center">
        <v-col>
          <v-avatar :size="100">
            <v-icon :size="80">mdi-car</v-icon>
          </v-avatar>
          <br />
          <h5 class="pt-3">No matches selected yet</h5>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>
<script>
import axios from "axios";
import { UPDATE_NOTE } from "../queries/core";
import TargetMultilangNotes from '../components/TargetMultilangNotes.vue'

const PRODUCTION = true;
export default {
  components: {
    TargetMultilangNotes
  },
  name: "Home",
  data: () => ({
    model: "profile",
    profileDetails: [],
    selectedItem: 1,
    items: [
      { text: "Real Time", icon: "mdi-clock" },
      { text: 'Audience', icon: "mdi-account" },
      { text: 'Conversions', icon: "mdi-flag" },
    ],
    targetData: {},
    targetDesc: "",
    authKey: "",
    loadingUpdate: false,
    fPack: {
      r_name: "",
      r_location: "",
      r_birth: "",
    },
  }),
  created() {
    this.targetDesc = this.$store.state.targetDesc;
    this.fPack = this.$store.state.fPack;
  },
  methods: {
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":4000/media/" + url;
      // const domain = "https://testfunnyflirts.net/media/" + url;
      return domain;
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    saveChanges() {
      this.updateConvoDesc();
    },
    updateConvoDesc() {
      this.loadingUpdate = true;
      console.clear();
      console.log(this.fPack, "What");
      this.$apollo
        .mutate({
          mutation: UPDATE_NOTE,
          variables: {
            input: {
              detail: this.targetDesc,
              type: "moded",
              convo: this.$store.state.curConv,
              pack: this.fPack,
            },
          },
        })
        .then(({ data }) => {
          console.warn(data);
          this.showToaster("Detail updated succesfully !!", "success");
        })
        .catch((e) => {
          this.showToaster("Detail updated succesfully !!", "success");
          console.error(e);
        })
        .finally(() => {
          this.loadingUpdate = false;
          this.$store.commit("setTargetDesc", this.targetDesc);
        });
    },
    fetchTargetProfile() {
      this.$store.commit("loadTargetProfile", "loading");

      // console.log(loadedStatus, "LOADED STATUS");
      const target = this.$store.state.targetData.id;
      axios
        .get(
          `https://funnyflirts.net/aj/moderation/fetch_target_profile?target=${target}`
        )
        .then(({ data }) => {
          const profileDetails = [];
          const validKeys = ["about", "birthday", "colour"];
          validKeys.forEach((k) => {
            profileDetails.push({
              title: k == "colour" ? "color" : k,
              value: data.user[k],
            });
          });
          this.profileDetails = profileDetails;
          this.targetData = data.user;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("loadTargetProfile", "loaded");
        });
    },
  },
  computed: {
    // targetStatus() {
    //   return this.$store.getters.targetStatus;
    // },
    targetDescChangedStatus() {
      return this.$store.getters.targetDescChangedStatus;
    },
    fPackChangedStatus() {
      return this.$store.getters.fPackChangedStatus;
    },
  },
  watch: {
    fPackChangedStatus(value) {
      this.fPack = value;
    },
    // targetStatus(value) {
    //   console.log(value);
    //   this.fetchTargetProfile();
    // },
    targetDescChangedStatus(value) {
      console.warn(value);
      this.targetDesc = this.$store.state.targetDesc;
    },
  },
};
</script>
