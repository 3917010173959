<template>
  <v-app
    :style="{ background: $vuetify.theme.themes.light.background }"
    class="mx-2"
  >
    <!-- start of udpate dialog -->
    <v-dialog v-model="updateDialog" max-width="690" persistent>
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('Updatepackage')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterpackageinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <v-text-field
              v-model="packageName"
              outlined
              label="Package Name"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="price"
              type="number"
              outlined
              label="Package Price"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="euroPrice"
              type="number"
              outlined
              label="Package Price ( euro)"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="poundPrice"
              type="number"
              outlined
              label="Package Price ( Sterling Pound)"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tryPrice"
              type="number"
              outlined
              label="Package Price ( Turkish lira )"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-row dense class="mx-2" v-if="isRanged">
            <v-col>
              <v-text-field
                v-model="creditValue"
                type="number"
                outlined
                :rules="creditRules"
                :label="packageType === 'timed' ? 'Time Value' : 'Credit Value'"
              >
                <!-- label="Credit Value" -->
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="packageTypes"
                v-model="packageType"
                outlined
                label="Package Type"
                :rules="pinRules"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-col>
            <label style="font-size: 20px; font-weight: bold" for=""
              >{{$t('packagecover')}}:
            </label>
            <input
              type="file"
              @change="onFileChange"
              accept=".jpg, .jpeg, .png"
              class="px-2"
            />
          </v-col>
          <v-col style="padding: 0px" class="px-3">
            <v-textarea
              v-model="packageDesc"
              dense
              outlined
              label="Package Description"
              :rules="descRules"
            >
            </v-textarea>
          </v-col>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="error"
            @click="updateDialog = false"
            outlined
            class="mb-12"
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            :loading="loadingUpdate"
            large
            color="primary"
            class="mb-12"
            @click="updateP()"
            >{{$t('SaveChanges')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- start of create dialog -->
    <v-dialog v-model="newModDialog" max-width="690" persistent>
      <v-card style="overflow-x: hidden">
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t("Addnewpackage")}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterpackageinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <v-text-field
              v-model="packageName"
              outlined
              label="Package Name"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="price"
              type="number"
              outlined
              label="Package Price"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="euroPrice"
              type="number"
              outlined
              label="Package Price (Euro)"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="poundPrice"
              type="number"
              outlined
              label="Package Price (Pound Sterling)"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tryPrice"
              type="number"
              outlined
              label="Package Price (Turkish lira)"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
          <v-row denseclass="mx-2">
            <v-col v-if="!isRanged && packageType != 'porntool_subscription'" class="mx-3">
              <v-text-field
                v-model="creditValue"
                type="number"
                outlined
                :label="packageType === 'timed' ? 'Time Value' : 'Credit Value'"
                :rules="creditRules"
              >
              </v-text-field>
            </v-col>
            
            <v-col v-if="!isRanged">
              <v-select
                :items="packageTypes"
                v-model="packageType"
                outlined
                label="Package Type"
                :rules="pinRules"
              >
              </v-select>
            </v-col>
            <v-col class="mx-3">
              <v-select
                :items="packageLvlTypes"
                v-model="packageLvlType"
                outlined
                label="Package Level"
                :rules="pinRules"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row denseclass="mx-2">
            <v-col v-if="!isRanged && packageType == 'porntool_subscription'" class="mx-3">
              <v-text-field
                v-model="subscriptionDuration"
                type="number"
                outlined
                label="Subscription Duration (in months)"
                :rules="creditRules"
              >
              </v-text-field>
            </v-col>
           </v-row>
          <v-checkbox v-model="isRanged" class="mx-4">
            <template v-slot:label>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <h5 class="pt-4" @click.stop v-on="on">
                      {{$t('Thepackagehasatimerange')}}.
                    </h5>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <v-row class="mx-1 mb-3" v-if="isRanged">
            <v-col cols="12" lg="6">
              <v-menu
                ref="menuSt"
                v-model="menuStart"
                :close-on-content-click="false"
                :return-value.sync="dateStart"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="dateStart"
                    readonly
                    prepend-icon="mdi-calendar"
                    label="Start Date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateStart" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStart = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuSt.save(dateStart)"
                  >
                    {{$t('OK')}}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- package end stamp  -->
            <!-- end of range picker -->
            <v-col cols="12" lg="6">
              <v-menu
                ref="menuEn"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="dateEnd"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="dateEnd"
                    readonly
                    prepend-icon="mdi-calendar"
                    label="End Date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateEnd" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEnd = false">
                    {{$t('Cancel')}}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuEn.save(dateEnd)"
                  >
                    {{$t('OK')}}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div class="mx-1 mb-2">
            <label
              style="font-size: 20px; font-weight: bold"
              for=""
              class="px-2"
              >{{$t('packagecover')}}:
            </label>
            <input
              type="file"
              @change="onFileChange"
              accept=".jpg, .jpeg, .png"
              class="px-2"
            />
          </div>
          <v-col style="padding: 0px" class="px-3">
            <v-textarea
              v-model="packageDesc"
              dense
              outlined
              label="Package Description"
              :rules="descRules"
            >
            </v-textarea>
          </v-col>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="error"
            outlined
            class="mb-12"
            @click="newModDialog = false"
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            large
            :loading="loadingAdd"
            color="primary"
            class="mb-12"
            @click="addPackage"
            >{{$t('SaveChanges')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mx-3 mt-10">
      <h2>{{$t('PackageManagement')}}</h2>
      <p>{{$t('Thiswherewemanageandupdateflirtsorpackages')}}</p>
    </div>
    <v-container fluid class="mb-10">
      <v-btn class="primary my-4" dark @click="newModDialog = true"
        >{{$t('AddNew')}}</v-btn
      >
      <v-data-table
        :headers="headers"
        :items="all_packagesMod"
        :search="search"
        :loading="loadingContent"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <p class="pt-2">
            {{ parseDate(item.createdAt) }}
          </p>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-btn icon color="blue mx-2 my-2 darken-3" @click="update(item)"
              ><v-icon>mdi-update</v-icon>
            </v-btn>
            <div class="mx-2"></div>
            <v-btn icon color="red mx-2 my-2 darken-3" @click="deleteP(item)"
              ><v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:[`item.packageName`]="{ item }">
          <p class="pt-2">
            {{ item.packageName }}
          </p>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <p class="pt-2">
            {{ item.user.base_profile.firstName }}

            {{ item.user.base_profile.lastName }}
            <br />
            <a :href="'https://funnyflirts.net/@' + item.user.username">
              @{{ item.user.username }}
            </a>
          </p>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
import {
  ADD_PACKAGE,
  ALL_PACKAGES,
  REMOVE_PACKAGE,
  UPDATE_PACKAGE,
} from "../queries/core";
export default {
  apollo: {
    all_packagesMod: {
      query: ALL_PACKAGES,
      result() {
        this.loadingContent = false;
      },
    },
  },
  created() {
    this.reloadContent();
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.packageCover = files[0];
    },
    update(item) {
      this.selected = item.id;
      this.updateDialog = true;
      (this.packageName = item.packageName),
        (this.packageDesc = item.packageDesc),
        (this.packageType = item.packageType),
        (this.price = item.price);
        (this.euroPrice = item.euroPrice);
        (this.poundPrice = item.poundPrice);
        (this.tryPrice = item.tryPrice);
      this.creditValue = item.creditValue;
    },
    updateP() {
      this.loadingUpdate = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_PACKAGE,
          variables: {
            file: this.packageCover,
            input: {
              package: parseInt(this.selected),
              packageName: this.packageName,
              packageDesc: this.packageDesc,
              creditValue: parseInt(this.creditValue),
              packageType: this.packageType,
              price: parseInt(this.price),
              euroPrice :parseInt(this.euroPrice),
              poundPrice:parseInt(this.poundPrice),
              tryPrice:parseInt(this.tryPrice)
              
            },
          },
        })
        .then(() => {
          this.loadingUpdate = false;
          this.updateDialog = false;
          this.reloadContent();
        })
        .catch((e) => {
          this.loadingUpdate = false;
          this.updateDialog = false;
          console.error(e);
          this.reloadContent();
        });
    },
    deleteP(item) {
      const runD = confirm("Are you sure you want to remove this package ?");
      if (runD)
        this.$apollo
          .mutate({
            mutation: REMOVE_PACKAGE,
            variables: {
              input: JSON.stringify(item.id),
            },
          })
          .then(() => {
            this.reloadContent();
          })
          .catch((e) => {
            console.error(e);
            this.reloadContent();
          });
    },
    reloadContent() {
      this.loadingContent = true;
      this.$apollo.queries.all_packagesMod
        .refetch()
        .then(() => {
          this.loadingContent = false;
        })
        .catch(() => {
          this.loadingContent = false;
        });
    },
    parseDate(date) {
      return new Date(parseInt(date)).toDateString();
    },
    addPackage() {
      if (this.$refs.form.validate()) {
        this.loadingAdd = true;
        this.$apollo
          .mutate({
            mutation: ADD_PACKAGE,
            variables: {
              file: this.packageCover,
              input: {
                packageName: this.packageName,
                packageDesc: this.packageDesc,
                creditValue: parseInt(this.creditValue),
                subscriptionDuration:Number(this.subscriptionDuration),
                timeValue:
                  this.packageType == "timed"
                    ? parseInt(this.creditValue)
                    : null,
                packageStart: this.dateStart,
                packageEnd: this.dateEnd,
                packageType: this.packageType,
                packageLvlType: this.packageLvlType,
                price: parseInt(this.price),
                euroPrice:parseInt(this.euroPrice),
                poundPrice:parseInt(this.poundPrice),
                tryPrice: parseInt(this.tryPrice)
              },
            },
          })
          .then(() => {
            this.loadingAdd = false;
            this.newModDialog = false;
            this.reloadContent();
          })
          .catch((e) => {
            console.error(e);
            this.loadingAdd = false;
            this.reloadContent();
          });
      }
    },
  },
  data() {
    return {
      search: "",
      price: null,
      euroPrice:null,
      poundPrice:null,
      tryPrice:null,
      selected: null,
      subscriptionDuration:null,
      menuEnd: false,
      isRanged: false,
      packageName: "",
      packageDesc: "",
      all_packages: [],
      menuStart: false,
      packageCover: null,
      creditValue: null,
      newModDialog: false,
      updateDialog: false,
      packageType: "otime",
      packageLvlType: "",
      loadingContent: false,
      loadingAdd: false,
      loadingUpdate: false,
      packageTypes: ["otime", "timed", "infny","porntool_subscription"],
      packageLvlTypes: [
        "daily",
        "silver",
        "ruby",
        "gold",
        "platinum",
        "diamond",
        "OTHER",
      ],
      dateStart: new Date().toISOString().substr(0, 10),
      dateEnd: new Date().toISOString().substr(0, 10),
      headers: [
        {
          text: this.$t('PackageName'),
          align: "start",
          filterable: false,
          value: "packageName",
        },
        {
          text: this.$t('PackagesDescription'),
          name: "packageDesc",
          value: "packageDesc",
        },
        { text: this.$t('FlirtsAmount'), value: "creditValue" },
        { text: this.$t('TimeDuration'), value: "timeValue" },
        { text: this.$t('PackageType'), name: "packageType", value: "packageType" },
        { text: this.$t('Price'), name: "price", value: "price" },
        { text: this.$t('CreatedDate'), name: "createdAt", value: "createdAt" },
        { text: this.$t('Actions'), name: "actions", value: "actions" },
      ],
      nameRules: [
        (v) => !!v || "Package name is required",
        (v) =>
          (v && v.length <= 25) ||
          "Package name must be less than 5 characters",
      ],
      pinRules: [(v) => !!v || "package type is required"],
      creditRules: [(v) => !!v || "Credit is required"],
      descRules: [(v) => !!v || "Description is required"],
      priceRules: [(v) => !!v || "Price is required"],
    };
  },
};
</script>