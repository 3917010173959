<template>
  <v-col lg="7" cols="12" style="padding: 0px" data-app>
     <v-dialog v-model="reportMessageDialog" scrollable max-width="500px">
      <v-card>
          <div class="mx-3">
            <v-card-title style="padding: 0px" class="pt-5 pb-2"
              >SendWarning</v-card-title
            >
            <v-textarea
           v-model="reportText"
             label="Enter your comment here"
              outlined
              filled
            >
            </v-textarea>
          </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mx-2"
            :ripple="false"
            @click="reportMessageDialog = false"
            large
            text
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            class="mb-2"
           @click="reportMessage()"
            large
            :ripple="false"
            color="rgb(255 116 79)"
            dark
            >SendReport</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportDialog" scrollable max-width="500px">
      <v-card>
        <v-form ref="form" v-model="reportFormValid" lazy-validation>
          <div class="mx-3">
            <v-card-title style="padding: 0px" class="pt-5 pb-2"
              >{{$t('Reportauser')}}</v-card-title
            >
            <v-select
              outlined
              class="mt-2"
              label="Reason for your report"
              v-model="reportData.reason"
              :items="reportReasons"
              :rules="[(v) => !!v || 'Reason is required']"
              filled
            >
            </v-select>
            <v-textarea
              v-model="reportData.comment"
              :rules="[(v) => !!v || 'Comment is required']"
              label="Enter your comment here"
              outlined
              filled
            >
            </v-textarea>
          </div>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mx-2"
            :ripple="false"
            @click="reportDialog = false"
            large
            text
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            class="mb-2"
            @click="validate"
            large
            :ripple="false"
            color="rgb(255 116 79)"
            dark
            >SendReport</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" scrollable max-width="300px" persistent>
      <v-card :loading="loadingAgents">
        <v-card-title>Select an agent</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="dialogm1" column>
            <v-radio
              :disabled="loadingAgents"
              v-for="(ag, idx) in allOnlineAgents"
              :key="idx"
              :value="ag.id"
            >
              <template v-slot:label>
                <strong class="px-2 pt-1">{{ ag.pin }}</strong>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            class="mx-2"
          >
            {{$t('Close')}}
          </v-btn>
          <v-btn color="rgb(23 23 255)" text @click="processTransfer">
            Finish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="container">
      <div class="chat-head">
        <v-toolbar :height="$vuetify.breakpoint.smAndUp ? 77 : 60" flat>
          <v-btn
            @click="$router.push('/')"
            icon
            v-if="$vuetify.breakpoint.mobile"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn icon large>
            <!-- <v-icon> mdi-heart-box </v-icon> -->
            <NudesSelect
             @sendNudeImage="sendNudeImage"
             :bus="bus"
            />
          </v-btn>
           <v-btn icon large>
              <GiftsSelect
               @sendGift="sendGift"
             :bus="bus"
              />
           </v-btn>
          <!-- $store.state.domain -->
          <div v-if="$store.state.domain != 'funnyflirts.net'">
          <v-hover v-slot:default="{ hover }" v-if="$store.state.noDialogs">
            <v-btn
              :loading="$store.state.loadedTargetProfileStatus == 'loading'"
              large
              :outlined="hover"
              rounded
              elevation="5"
              class="font-weight-bold"
              color="rgb(193 185 18)"
              @click="fetchConvQueue"
            >
              Refresh <v-icon class="px-2">mdi-reload</v-icon>
            </v-btn>
          </v-hover>
          <v-btn
            v-else
            class="font-weight-bold"
            color="rgb(193 185 18)"
            :ripple="false"
            rounded
            depressed
            :loading="$store.state.loadedTargetProfileStatus == 'loading'"
            @click="getNextDialog"
            style="text-decoration: none"
            :disabled="$store.state.noDialogs"
            :dark="!$store.state.noDialogs"
          >
            {{$t('SKIP')}}
            <v-icon class="px-1">mdi-skip-next</v-icon>
          </v-btn>
        </div>
          <v-btn
            class="font-weight-bold ml-3"
            color="#5681d4"
            :ripple="false"
            rounded
            depressed
            @click="runTransfer"
            style="text-decoration: none"
            :disabled="$store.state.noDialogs"
            :dark="!$store.state.noDialogs"
          >
            Transfer
            <v-icon class="px-1">mdi-shuffle-variant</v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bold ml-3"
            color="rgb(255 116 79)"
            :ripple="false"
            rounded
            depressed
            @click="reportDialog = true"
            :dark="!$store.state.noDialogs"
            :disabled="$store.state.noDialogs"
            style="text-decoration: none"
          >
            {{$t('report')}}
            <v-icon class="px-2">mdi-sign-caution</v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bold ml-3"
            color=""
            :dark="!$store.state.noDialogs"
            style="text-decoration: none"
          >
            {{$t('TimeElapsed')}}
             {{ timeElapsed.min }}:{{ timeElapsed.sec >= 10 ? timeElapsed.sec : '0' + timeElapsed.sec  }}
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </div>
      <!-- <div class="col chat-body"> -->
      <v-sheet
        flat
        style="border-radius: 0px"
        id="chatBody"
        class="col chat-body"
        :color="$vuetify.theme.dark ? '#131212' : ''"
        v-on:scroll.native="handleScroll"
      >
        <!-- new start -->
        <div
          v-if="
            $store.state.loadedTargetProfileStatus == 'loaded' &&
            !$store.state.noDialogs
          "
        >
          <div class="mt-1 mx-2 mb-5" v-for="(val, idx) in messages" :key="idx">
            <v-col v-if="val.sender.id == $store.state.profileData.id">
              <div class="message pt-3 mb-2">
                <div class="messageFrom">
                  [{{ val.mod ? val.mod.pin : "NO MOD" }}]<br />
                  {{ parseDate(val.createdAt).stmp }} |
                  {{ parseDate(val.createdAt).time }} | 
                  <a v-if="!val.modReported" @click.prevent="openReportMessageDialog(val)" style="color:white" href="#"> <b>Reportmessage</b></a>  
                  <span v-else style="color:black" >  <b>   {{$t('MessageReported')}} </b></span>  

                </div>
                <div v-if="val.attachment_url && val.message_type =='d'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
                <p v-else-if="val.message_type =='d' && val.attachment_url == null">
                  <br />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </p>
                <div v-else-if="val.message_type =='sn'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
                <div v-else-if="val.message_type =='g'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
              </div>
            </v-col>
            <v-col v-else>
              <v-spacer class="my-2"></v-spacer>
              <div class="message-rec mx-2 mb-2">
                <div class="messageFr">
                  {{ parseDate(val.createdAt).stmp }} |
                  {{ parseDate(val.createdAt).time }}
                  <v-icon size="6">mdi-pen</v-icon>
                </div>
                <br />
                <div v-if="val.attachment_url && val.message_type =='d'">
                  <img
                    style="max-width: 100%"
                    :src="getURL(val.attachment_url)"
                  />
                </div>
                <p v-else-if="val.message_type =='d' && val.attachment_url == null">
                  <span> </span>
                  <v-col style="padding: 0px" class="text-left">
                    {{ val.body }}
                  </v-col>
                </p>
                <div v-else-if="val.message_type =='g'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
              </div>
            </v-col>
          </div>
        </div>
      </v-sheet>
      <!-- <div class="w-100 d-none d-md-block"></div> -->
      <v-card
        flat
        class="col chat-foot"
        :color="$vuetify.theme.dark ? '' : 'rgb(249 249 249)'"
      >
        <v-row
          :class="{
            'mt-0': $vuetify.breakpoint.smAndUp,
          }"
          dense
        >
          <v-text-field
            filled
            dense
            :disabled="!($store.state.loadedTargetProfileStatus == 'loaded')"
            :class="{ 'mx-1': $vuetify.breakpoint.mobile }"
            rounded
            @keydown.enter="sendMessage"
            :append-icon="$vuetify.breakpoint.name == 'xs' ? 'mdi-send' : ''"
            color="amber darken-2"
            class="mx-0 mt-1"
            placeholder="Say something..."
            v-model="chatMess"
          ></v-text-field>
          <input
            type="file"
            ref="photoupload"
            accept="image/*"
            class="d-none"
            @change="uploaded"
          />
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            outlined
            :disabled="!($store.state.loadedTargetProfileStatus == 'loaded')"
            text
            color="amber accent-4"
            class="mx-1"
            large
            :loading="loadingSend"
            @click="$refs.photoupload.click()"
            :ripple="false"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>

          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            outlined
            :disabled="!($store.state.loadedTargetProfileStatus == 'loaded')"
            text
            color="amber accent-4"
            class="mx-2"
            large
            :loading="loadingSend"
            @click="sendMessage"
            :ripple="false"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>

          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="
                  !($store.state.loadedTargetProfileStatus == 'loaded')
                "
                v-if="$vuetify.breakpoint.smAndUp"
                icon
                large
                outlined
                text
                color="amber accent-4"
                class="mx-"
                :ripple="false"
              >
                <v-icon>mdi-emoticon</v-icon>
              </v-btn>
            </template>
            <v-list>
              <VEmojiPicker
                v-show="showDialog"
                :style="{ width: '440px', height: '200' }"
                labelSearch="Search"
                lang="pt-BR"
                @select="onSelectEmoji"
              />
            </v-list>
          </v-menu>
          <!-- <v-btn
            :disabled="!($store.state.loadedTargetProfileStatus == 'loaded')"
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            large
            outlined
            text
            color="amber accent-4"
            class="mx-1"
            :ripple="false"
          >
            <v-icon>mdi-microphone</v-icon>
          </v-btn> -->
        </v-row>
      </v-card>
    </div>
  </v-col>
</template>

<script>
/* eslint-disable */
//  en:"English",
//   ge:"German",
//   tur:"Turkish",
//   // hi:"Hindi",
//   // ur:"Urdu",
//   fr:"French",
//   po:"Polish",
//   sp:"Spanish",
//   it:"Italian",
//   pot:"Portuguese",
//   sch:"Schwedisch",
//   dan:"Danish",
//   fini:"finisch",
//   seb:"Serbisch",
//   russ:"Russian",
//   jap:"Japanese",
//   kor:"korean",
//   thai:"Thailändische",
//   ung:"Ungarisch",
//   inr:"Iranian"
const languageMapper = {
  en:"en",
  Deutsch:"ge",
  arabic:"ur",
  Turkish:"tur",
  france:"fr",
  polish:"po",
  Spanish:"sp",
  Italia:"it",
  portuguese:"pot",
  Schwedisch:"sch",
  Danish:"dan",
  finisch:"fini",
  Serbisch:"seb",
  russisch:"russ",
  japanese:"jap",
  koreanisch:"kor",
  Thailändische:"thai",
  ungarisch:"ung",
  irn:"inr"
}
import { VEmojiPicker } from "v-emoji-picker";
import axios from "axios";
import {
  ADD_REPORT,
  FETCH_ALL_MESSAGES,
  FETCH_CONVO_QUEUE,
  MOD_MESASGE_ADDED,
  ONLINE_MODS,
  SEND_MODED_MESSAGE,
  TRANSFER_DIALOG,
  REPORT_MOD_MESSAGE,
  GET_POOL_COUNT
} from "../queries/core";
import NudesSelect from '../components/nudesSelect.vue'
import GiftsSelect from '../components/GiftsSelect.vue'

import Vue from 'vue'

const PRODUCTION = true;
let time =  {
  min:6,
  sec:60
}
export default {
  components: {
    VEmojiPicker,NudesSelect,GiftsSelect
  },
  data: () => ({
    reportText:"",
    activeMessage:null,
    reportMessageDialog:false,
    fupload: null,
    dialogm1: "",
    dialog: false,
    adminMessage: "",
    reportFormValid: false,
    showDialog: true,
    fab: !false,
    chatMess: "",
    avatarColor: "",
    loadingSend: false,
    loadMessages: false,
    roomId: "",
    messages: [],
    //   rooms: ["newroom", "newrooms", "music"],
    changedRoom: "",
    curUsr: "amen",
    toastNots: {
      title: "Message for admins",
      body: "Hey admins you can all fuck yourself",
      type: "success",
      defaultTime: true,
      url: "https://google.com",
    },
    loadingMessages: false,
    loadingAgents: false,
    last_message: 0,
    randomState: {},
    authKey: "",
    allOnlineAgents: [
      {
        value: "amen",
        label: "DMAmen",
      },
      {
        value: "james",
        label: "DMJames",
      },
      {
        value: "jack",
        label: "DMJack",
      },
    ],
    reportDialog: false,
    reportData: {
      comment: "",
      reason: "",
    },
    reportReasons: [
      "Taboo or vulgar   language",
      "Pornographic content in profile",
      "Signs of child abuse",
      "Extremity and violent",
    ],
    bus: new Vue(),
    message_type:'d',
    attachment:'',
    timeElapsed:{
      min:time.min,
      sec:time.sec
    },
    messageTimerInterval:null,
  }),
  apollo: {
    onlineMods: {
      query: ONLINE_MODS,
      result({ data: { onlineMods } }) {
        this.allOnlineAgents = onlineMods;
      },
    },
    allMessagesMod: {
      query: FETCH_ALL_MESSAGES,
      variables() {
        return {
          conv: this.$store.state.curConv,
        };
      },
      result({ data }) {
        this.$store.commit("loadTargetProfile", "loaded");
        // console.log(data.allMessagesMod.messages, "wait");
        console.log(this.$store.state.lang,"this.$store.state")
        let currentLanguage = this.$store.state.lang
        let toTranslationLang = languageMapper[currentLanguage]
        let allMessages = [ ...data.allMessagesMod.messages ]
        
        allMessages.forEach(message => {
            if(message.translations) {
              if(message.translations[toTranslationLang]) {
                  message.body = message.translations[toTranslationLang]
               }
            }
            return message
        });
        this.messages = allMessages;
        console.log("wait");
        // this.$store.commit("loadTargetProfile", "loaded");
        setTimeout(() => {
          let chatBody = this.$el.querySelector("#chatBody");
          chatBody.scrollTo({
            top: chatBody.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
          this.resetTimer()
          // this.scrollDown(false);
        }, 100);
      },
    },
    fetchConvQueue: {
      query: FETCH_CONVO_QUEUE,
      variables() {
        return {
          cnv: "random",
          pool: this.$store.state.queryFromPools,
        };
      },
      result({ data }) {
       try{ 
        if(!data){
          this.$store.commit("poolcount", 0)
          this.$store.commit("vipCount", 0)
          this.$store.commit("netPoolCount", 0)
          this.showToaster("Pools/Vips might be locked by other moderators")
          return
        }
        let fetchConvQueue = data.fetchConvQueue

        const sender = fetchConvQueue.conversation.m_one;
        const reciever = fetchConvQueue.conversation.m_two;
        this.getPoolCountInfo()
        if(reciever.usrType != "model" && sender.usrType != "model" ) {
          if (reciever.usrType != "nt") { // fake user has send first message to real user
            this.$store.commit("setTargetData", reciever); // real user profile (profileSidebar.vue)  ON LEFT
          } else {
            this.$store.commit("setProfileData", reciever); // FAKE user profile (targetUsrsidebat.vue)  ON RIGHT
          }
          if (sender.usrType != "nt") { // real user has send first message to fake user
            this.$store.commit("setTargetData", sender);
          } else {
            this.$store.commit("setProfileData", sender);
          }
        }else {
          if (reciever.usrType != "model") { 
            this.$store.commit("setTargetData", reciever); 
          } else {
            this.$store.commit("setProfileData", reciever); 
          }
          if (sender.usrType != "model") { 
            this.$store.commit("setTargetData", sender);
          } else {
            this.$store.commit("setProfileData", sender);
          }
        }
        
        this.$store.commit("setCurConv", fetchConvQueue.conversation.id);
        // set empty dialog status and refetch messages
        this.$store.commit("setNoDialogs", false);
        // update main target notes
        let currentLanguage = this.$store.state.lang
        let toTranslationLang = languageMapper[currentLanguage]
        
        this.$store.commit(
            "setTargetDesc",
            fetchConvQueue.conversation.target_notes
          );
          this.$store.commit(
            "setTargetDescIdris",
            {
              TargetFPPTranslations:fetchConvQueue.conversation.TargetFPPTranslations,
              TargetIPTranslations:fetchConvQueue.conversation.TargetIPTranslations,
              TargetJobTranslations:fetchConvQueue.conversation.TargetJobTranslations,
              TargetPRTranslations:fetchConvQueue.conversation.TargetPRTranslations,
              TargetRSTranslations:fetchConvQueue.conversation.TargetRSTranslations,
              TargetSFTranslations:fetchConvQueue.conversation.TargetSFTranslations,
              TargetWSTranslations:fetchConvQueue.conversation.TargetWSTranslations
            }
          );
         this.$store.commit(
            "setProfileDesc",
            fetchConvQueue.conversation.mod_notes
          );
           this.$store.commit(
            "setProfileDescIdris",
            {
              ModFPPTranslations:fetchConvQueue.conversation.ModFPPTranslations,
              ModIPTranslations:fetchConvQueue.conversation.ModIPTranslations,
              ModJobTranslations:fetchConvQueue.conversation.ModJobTranslations,
              ModPRTranslations:fetchConvQueue.conversation.ModPRTranslations,
              ModRSTranslations:fetchConvQueue.conversation.ModRSTranslations,
              ModSFTranslations:fetchConvQueue.conversation.ModSFTranslations,
              ModWSTranslations:fetchConvQueue.conversation.ModWSTranslations
            }
          );
        // update main profile notes
        
        console.log(fetchConvQueue.conversation.id, "Bish");
        this.$store.commit("loadTargetProfile", "loaded");
        this.fetchMessaes();
       }catch(e)  {
         console.log(e)
        //  alert("hereeeeeeee")
           
       }
      },
    },
    // $subscribe: {
    //   // listen when a new dialog comes to this moderator and update status
    //   // TODO: play notificaiton sound at this moment
    //   modMessageAdded: {
    //     query: MOD_MESASGE_ADDED,
    //     result({ data: { modMessageAdded } }) {
    //       this.getPoolCountInfo()
    //       this.playSound();
    //       this.showToaster("Updating Pools/Vips")
    //       return
    //       const sender = modMessageAdded.m_one;
    //       const reciever = modMessageAdded.m_two;
    //       if (reciever.usrType != "nt") {
    //         this.$store.commit("setTargetData", reciever);
    //       } else {
    //         this.$store.commit("setProfileData", reciever);
    //       }
    //       if (sender.usrType != "nt") {
    //         this.$store.commit("setTargetData", sender);
    //       } else {
    //         this.$store.commit("setProfileData", sender);
    //       }
    //       this.$store.commit("setCurConv", modMessageAdded.id);
    //       // set empty dialog status and refetch messages
    //       this.$store.commit("setNoDialogs", false);
    //       // update main target notes
    //       this.$store.commit("setTargetDesc", modMessageAdded.target_notes);
    //       // update main profile notes
    //       this.$store.commit("setProfileDesc", modMessageAdded.mod_notes);
    //       console.log(modMessageAdded.id, "Bish");
    //       this.fetchMessaes();
    //       this.playSound();
    //       this.$store.commit(
    //         "saveMessageSent",
    //         Math.round(Math.random() * 200)
    //       );
    //       // change notification state
    //       // console.log(data, "The message that's added")
    //       // this.$store.commit('addNotification', data.notificationAdded)
    //     },
    //     error: (e) => {
    //       console.error(e, "what an error");
    //     },
    //   },
    // },
  },
  mounted() {
    this.$apollo.queries.fetchConvQueue.refetch();
    this.startTimer()
  },
  computed: {
    getQueryFromPools() {
      return this.$store.getters.getQueryFromPools;
    },
    targetStatus() {
      return this.$store.getters.targetStatus;
    },
    requestedSetStatus() {
      return this.$store.getters.requestedSetStatus;
    },
  },
  watch: {
    getQueryFromPools() {
      this.$apollo.queries.fetchConvQueue.refetch();
    },
    targetStatus(value) {
      // alert("test")
      console.log(value, this.$store.state.noDialogs, "CHECK HERE");
      if (!this.$store.state.noDialogs) {
        this.fetchMessaes();
      }
    },
    requestedSetStatus(value) {
      console.warn(value);
      this.$apollo.queries.fetchConvQueue.refetch();
    },
  },
  destroyed() {
    clearInterval(this.messageTimerInterval)
  },
  methods: {
    sendNudeImage(messageData) {
      this.message_type = 'sn'
      this.chatMess = messageData.message
      this.attachment = messageData.url.split('media/')[1]
      this.pushMessage(()=>{
        this.bus.$emit('messageSent',true);
      })
    },
    sendGift(messageData) {
      // console.log(messageData,"imageDataimageData")
      // console.log(messageData.asset.split('media/')[1])
      this.message_type = 'g'
      this.chatMess = messageData.message
      this.attachment = messageData.asset
      this.pushMessage(()=>{
        this.bus.$emit('messageSent',true);
      })
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    async getPoolCountInfo() {
      try {
        let {data} = await this.$apollo
        .query({
          query: GET_POOL_COUNT,
          fetchPolicy: 'no-cache'
        })
        if(data.getPoolInfo){
          let pools = data.getPoolInfo.filter(p => p.pool_type == 'pool')
          let vips = data.getPoolInfo.filter(p => p.pool_type != 'pool')
          this.$store.commit("poolcount", pools.length)
          this.$store.commit("vipCount", vips.length)
          this.$store.commit("netPoolCount", data.getPoolInfo.length)
        }
      }catch(e) {
        console.log(e)
      }
    },
    openReportMessageDialog(messageData) {
      this.activeMessage = messageData
      this.reportMessageDialog = true
    },
    async reportMessage() {
      if(!this.activeMessage) return alert("err")
      try{ 
        let {data} = await this.$apollo
        .mutate({
          mutation: REPORT_MOD_MESSAGE,
          variables: {
            comment:this.reportText,
            message:String(this.activeMessage.id),
            reportedAgainst:String(this.activeMessage.mod.id)
          },
        })
        if(data.reportModMessage) {
          this.messages = this.messages.map((m)=>{
            if(m.id == this.activeMessage.id) {
              m.modReported = true
            }
            return m
          })
          this.reportText = ''
          this.showToaster(`Message Reported Successfully`, "success");
        }
        if(!data.reportModMessage) {
          this.showToaster(`There was some error`, "error");
        }
        this.activeMessage = null
        this.reportMessageDialog = false
        console.log(data,"datadatadata")
      }catch(e) {
        this.activeMessage = null
        this.reportMessageDialog = false
        console.log(e)
        this.showToaster(`There was some error`, "error");
      }
    },
    resetTimer() {
      clearInterval(this.messageTimerInterval)
      this.timeElapsed.min = time.min
      this.timeElapsed.sec = time.sec
      this.startTimer()
    },
    messageTimeout() {
      clearInterval(this.messageTimerInterval)
      this.logout()
      // alert("logged outt...")
    },
    startTimer() {
      this.messageTimerInterval = setInterval(() => {
        if(this.timeElapsed.sec == 0){ 
          this.timeElapsed.min--
          if(this.timeElapsed.min < 0) {
            this.messageTimeout()
          }
          this.timeElapsed.sec = time.sec
        }else {
          this.timeElapsed.sec--
        }
      },1000)
    },
    uploaded(e) {
      this.fupload = e.target.files[0];
    },
    logout() {
        let userType = this.$store.state.authP.accountType
        window.localStorage.clear();
        this.$store.commit("setFlirtTok", "");
        this.$store.commit("setCurrentStatus", "sb");
        this.$store.commit("resetState");
        this.$store.commit("setCurrentAgent", {});
        if(userType == 'model') {
          this.$router.push("/modellogin");
        }else {
          this.$router.push("/");
        }
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":8080/media/" + url;
      return domain;
    },

    parseDate(date) {
      const dObj = new Date(parseInt(date));
      return {
        time: dObj.toLocaleTimeString(),
        stmp: dObj.toLocaleDateString(),
      };
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.reportUser();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    playSound() {
      var data = {
        soundurl:
          "https://assets.mixkit.co/sfx/preview/mixkit-cartoon-door-melodic-bell-110.mp3",
      };
      var audio = new Audio(data.soundurl);
      audio.play();
    },
    showNewMessageNotification(message) {
      this.playSound();
      this.showToaster(message, "success");
      setTimeout(() => {
        this.$apollo.queries.fetchConvQueue.refetch();
      }, 2000);
    },
    pollModeratorNotifications() {
      const url = "https://funnyflirts.net/aj/moderation/moderatorNotification";
      axios
        .get(url, {
          headers: {
            Authorization: this.authKey,
          },
        })
        .then(({ data }) => {
          // const currentNots = this.$store.state.totalAssignedNotifications;
          // const currentNotsPoll =
          //   this.$store.state.totalAssignedPoolNotifications;
          // console.warn(
          //   data.pool_count,
          //   this.$store.state.totalAssignedPoolNotifications,
          //   "WATCH POLL"
          // );
          // console.warn(
          //   data.count,
          //   this.$store.state.totalAssignedNotifications,
          //   "WATCH COUNT"
          // );
          if (
            data.pool_count > this.$store.state.totalAssignedPoolNotifications
          ) {
            this.showNewMessageNotification("You have a new message");
            // if user is not in pool then switch them up
            this.$store.commit("setRequestedSet", "vip");
          } else if (
            data.count > this.$store.state.totalAssignedNotifications
          ) {
            this.showNewMessageNotification("You have a new message");
            // alert("new message");
          }
          this.$store.commit("setTotalAssignedNotifications", data.count);
          this.$store.commit(
            "setTotalAssignedPoolNotifications",
            data.pool_count
          );

          // console.log(data.count);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    replaceAllBackSlash(targetStr) {
      var index = targetStr.indexOf("\\");
      while (index >= 0) {
        targetStr = targetStr.replace("\\", "");
        index = targetStr.indexOf("\\");
      }
      return targetStr;
    },
    // process client transfer
    processTransfer() {
      this.$apollo
        .mutate({
          mutation: TRANSFER_DIALOG,
          variables: {
            input: {
              agent: this.dialogm1,
              convo: this.$store.state.curConv,
            },
          },
        })
        .then(() => {
          this.dialog = false;
          this.showToaster("client transferred succesfully", "success");
          this.$apollo.queries.fetchConvQueue.refetch();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getTime(stamp) {
      const date = new Date(stamp);
      return date.toLocaleTimeString().replace(/:\d+ /, " ");
    },
    getDate(stamp) {
      const date = new Date(stamp);
      return date.toLocaleDateString();
    },
    // create a new moderator account
    newModerator() {
      const url = "https://funnyflirts.net/aj/moderation/generate_moderator_pin";
      axios
        .post(url, {
          username: "NewAdmminAmenabe",
          password: "#Hallelujah",
          c_password: "#Hallelujah",
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // test moderator login
    testLogin() {
      axios
        .post("https://funnyflirts.net/aj/moderation/moderator_login", {
          username: "JACKIEamenabe",
          password: "#Hallelujah",
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // test func
    testJwt() {
      const url = `https://funnyflirts.net/aj/moderation/jwt_test`;
      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: this.authKey,
            },
          }
        )
        .then(({ data }) => {
          this.$store.commit("setCurrentAgent", data.user);
        })
        .catch((e) => {
          console.error("error getting data", e);
        });
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    triggerFunc() {
      console.log("HEY NIGGGGAA");
    },
    getOnlineAgents() {
      this.loadingAgents = true;
      this.$apollo.queries.onlineMods
        .refetch()
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loadingAgents = false;
        });
    },
    getNewChatMessage() {
      console.warn(this.messages.length, this.messages, "LOOK ON THIS SIDE RN");
      this.last_message = this.messages[this.messages.length - 1].id;
      const fuid = this.$store.state.profileData.id;
      const selectedTarget = this.$store.state.targetData.id;
      axios
        .get(
          `https://funnyflirts.net/aj/moderation/get_chat_messages?uid=${fuid}&target=${selectedTarget}&last=${this.last_message}`
        )
        .then(({ data }) => {
          if (data.message == "") {
            console.warn("no new messages");
          } else {
            this.messages.push(data.message);
            this.last_message = this.messages[this.messages.length - 1].id;
            setTimeout(() => {
              let chatBody = this.$el.querySelector("#chatBody");
              chatBody.scrollTo({
                top: chatBody.scrollHeight,
                left: 0,
                behavior: "smooth",
              });

              // this.scrollDown(false);
            }, 100);
          }
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    fetchMessaes() {
      console.log("dawg");
      console.warn(
        this.$store.state.targetData,
        this.$store.state.targetData,
        "TARGET"
      );
      console.warn(
        this.$store.state.profileData,
        this.$store.state.profileData,
        "PROFILE"
      );
      // this.loadingMessages = true;
      // const fuId = this.$store.state.profileData.id;
      console.warn("check there1222222222222222222222");
      console.warn(this.$store.state.profileData, "PROFILE DATA");
      const selectedTarget = this.$store.state.targetData.id;
      console.log(selectedTarget, "sheesh");
      this.$store.commit("loadTargetProfile", "loading");
      this.$apollo.queries.allMessagesMod.refetch();
    },
    sendMessage() {
      // return this.pushMessage();
      let requiredLengh;
      if(this.messages.length >= 1) {
        requiredLengh = 70
      }else {
        requiredLengh = 35
      }
      if (this.chatMess.length < requiredLengh) {
        this.showToaster(`you need to write atleast ${requiredLengh} characters`, "error");
      } else {
        this.pushMessage();
      }
    },
    randomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    reportUser() {
      const targetId = this.$store.state.targetData.id;
      // const url = "https://funnyflirts.net/aj/moderation/report_user";
      this.reportData["user"] = targetId;
      console.log(this.reportData, "Report Data");
      this.$apollo
        .mutate({
          mutation: ADD_REPORT,
          variables: {
            input: {
              user: targetId,
              reason: this.reportData.reason,
              comment: this.reportData.comment,
            },
          },
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.reportDialog = false;
          this.$apollo.queries.fetchConvQueue.refetch();
        });
    },
     
     pushMessage(callback = '') {
      const fuId = this.$store.state.profileData.id;
      const selectedTarget = this.$store.state.targetData.id;
      console.warn(selectedTarget, "Selected Target", fuId);
      this.loadingSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_MODED_MESSAGE,
          variables: {
            input: {
              body: this.chatMess,
              user: fuId,
              target: selectedTarget,
              attachment: this.attachment,
              message_type:this.message_type
            },
            file: this.fupload,
          },
        })
        .then(({ data: { sendModMessage } }) => {
          console.log(sendModMessage, "Message has been sent");
          this.messages.push(sendModMessage);
          this.chatMess = "";
          this.message_type = 'd'
          this.attachment = "";
          this.fupload = null
          this.loadingSend = false;
          if(callback && typeof callback == 'function') callback()
          setTimeout(() => {
            let chatBody = this.$el.querySelector("#chatBody");
            chatBody.scrollTo({
              top: chatBody.scrollHeight,
              left: 0,
              behavior: "smooth",
            });
            this.resetTimer()
            // reload once the message is sents
          }, 100);
          
        })
        .catch((e) => {
          console.error(e, "Error sending message");
          this.loadingSend = false;
        });
      setTimeout(() => {
        this.$apollo.queries.fetchConvQueue.refetch();
        this.$store.commit("saveMessageSent", Math.round(Math.random() * 200));
      }, 500);
    },
    getNextDialog() {
      this.$store.commit("loadTargetProfile", "loading");
      this.$apollo.queries.fetchConvQueue.refetch();
    },
    backToOldPos() {
      let oldPos = localStorage.getItem("lastPos");
      let chatBody = this.$el.querySelector("#chatBody");
      chatBody.scrollTo({
        top: parseInt(oldPos),
        left: 0,
        behavior: "smooth",
      });
    },
    handleScroll(e) {
      // console.warn(e.target.scrollTop);
      localStorage.setItem("lastPos", e.target.scrollTop);
      // console.warn(e)
    },
    scrollDown(fast) {
      let chatBody = this.$el.querySelector("#chatBody");
      chatBody.scrollTo({
        top: chatBody.scrollHeight,
        left: 0,
        behavior: fast ? "smooth" : "auto",
      });
      // chatBody.scrollTop = chatBody.scrollHeight;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    runTransfer() {
      this.getOnlineAgents();
      this.dialog = true;
    },
    toogleDialogEmoji() {
      console.log("Toogle!");
      this.showDialog = !this.showDialog;
    },
    onSelectEmoji(emoji) {
      this.chatMess += " " + emoji.data;
      // Optional
      // this.toogleDialogEmoji();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/chat.scss";
.messageFr {
  float: left;
  background-color: transparent;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  // padding: 6px 12px;
  font-size: 10px;
  border-radius: 15px;
  line-height: 1.4;
  // max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.messageFrom {
  float: right;
  background-color: transparent;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  padding: 0 0 5px 0px;
  // padding: 6px 12px;
  text-align: start;
  font-size: 10px;
  border-radius: 15px;
  line-height: 1.4;
  // max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.message {
  float: right;
  background-color: #a33596;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 15px;
  line-height: 1.4;
  max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.message-rec {
  float: left;
  background-color: #4a4545;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 1.5px;
  clear: both;
  padding-top: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 15px;
  line-height: 1.4;
  max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
</style>
