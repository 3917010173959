<template>
  <div>
    <!-- update event dialog start -->
    <v-dialog v-model="openUpdateEvDialog" max-width="800" persistent>
      <v-card>
        <v-row class="mx-2 pt-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            <v-icon size="30" class="pr-2" color="blue darken-2"
              >mdi-video</v-icon
            >{{("Updateeventdetailsfor")}}
            <b class="px-2">{{ selectedEvent.name }}</b>
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-5"
            >{{$t('AddnewFlirtTooleventhere')}}</v-card-text
          >
          <!-- <v-btn class="align-end justify-end">Close</v-btn> -->
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="xform">
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-pen"
                v-model="selectedEvent.name"
                filled
                label="Event Name"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-link"
                v-model="selectedEvent.access_link"
                filled
                label="Access Link"
                :rules="[(v) => !!v || 'Phone is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                type="number"
                append-icon="mdi-cash"
                v-model="selectedEvent.price"
                filled
                label="Event Price"
              >
              </v-text-field>
            </v-col>
            <v-col>
							<v-menu
								ref="menuSt"
								v-model="menuStartx"
								:close-on-content-click="false"
								:return-value.sync="eventDate"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										filled
										v-model="dateStart"
										readonly
										append-icon="mdi-calendar"
										label="Event Date"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker v-model="dateStart" no-title scrollable>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menuStartx = false">
										{{$t('Cancel')}}
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.menuSt.save(dateStart)"
									>
										{{$t(OK)}}
									</v-btn>
								</v-date-picker>
							</v-menu>


              <!-- <v-text-field
                append-icon="mdi-clock"
                v-model="selectedEvent.eventDate"
                filled
                label="Event Date"
                :rules="[(v) => !!v || 'Phone is required']"
              >
              </v-text-field> -->
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-textarea
                append-icon="mdi-pen"
                v-model="selectedEvent.detail"
                filled
                label="Event detail"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-btn
            class="m-3 primary"
            :loading="loadingEventSave"
            @click="saveEventUpdate"
            >{{$t('SaveEvent')}}</v-btn
          >
          <v-btn color="error" outlined text @click="openUpdateEvDialog = false"
            >{{$t('Cancel')}}</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <!-- update event dialog end -->
    <!-- start of new event dialog -->
    <v-dialog v-model="openEvDialog" max-width="800" persistent>
      <v-card>
        <v-row class="mx-2 pt-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            <v-icon size="30" class="pr-2" color="blue darken-2"
              >mdi-video</v-icon
            >{{$t('AddanewEvent')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-5"
            >{{$t('Addnewchateventhere')}}</v-card-text
          >
          <!-- <v-btn class="align-end justify-end">Close</v-btn> -->
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form">
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-pen"
                v-model="eventName"
                filled
                label="Event Name"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-link"
                v-model="accessLink"
                filled
                label="Access Link"
                :rules="[(v) => !!v || 'Phone is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                type="number"
                append-icon="mdi-cash"
                v-model="eventPrice"
                filled
                label="Event Price"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>

							<v-menu
								ref="menuSt"
								v-model="menuStart"
								:close-on-content-click="false"
								:return-value.sync="eventDate"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										filled
										v-model="dateStart"
										readonly
										append-icon="mdi-calendar"
										label="Event Date"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker v-model="dateStart" no-title scrollable>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menuStart = false">
										{{$t('Cancel')}}
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.menuSt.save(dateStart)"
									>
										{{('OK')}}
									</v-btn>
								</v-date-picker>
							</v-menu>


            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <label style="font-size: 20px; font-weight: bold" for=""
                >{{$t('eventcover')}}:
              </label>
              <input
                type="file"
                @change="onFileChange"
                accept=".jpg, .jpeg, .png"
                class="px-2"
              />
            </v-col>
          </v-row>

          <v-row dense class="mx-2">
            <v-col>
              <v-textarea
                append-icon="mdi-pen"
                v-model="eventDetail"
                filled
                label="Event detail"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-btn
            class="m-3 primary"
            :loading="loadingEventSave"
            @click="saveEvent"
            >{{$t('SaveEvent')}}</v-btn
          >
          <v-btn color="error" outlined text @click="openEvDialog = false"
            >{{$t('Cancel')}}</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>
    <!-- end of new event dialog -->
    <v-col cols="12" md="12" style="padding: 0px" class="px-4 pb-6 pt-10">
      <h1 class="headline grey--text">{{$t('AddEvents')}}</h1>
      <v-row dense class="mx-2">
        <v-btn class="primary my-4" dark @click="openEvDialog = true"
          >{{$t('AddEvents')}}</v-btn
        >
        <v-btn outlined @click="fetchEvents()" class="mt-4 mx-2">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-row>
    </v-col>

    <v-layout row class="mx-4">
      <v-card width="100%">
        <v-card-title class="d-none">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          show-select
          selectable-key="id"
          :headers="headers"
          :items="events"
          :search="search"
          :loading="loadingEvents"
        >
          <template v-slot:[`item.cover`]="{ item }">
            <img style="width: 100px" :src="getUrl(item.cover)" />
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <p class="pt-2">
              {{ parseDate(item.createdAt) }}
            </p>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <p class="pt-2">
              {{ item.name }}
            </p>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <p class="pt-2">
              {{ item.price }}
            </p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row align="center">
              <v-btn icon color="primary" @click="updateEvent(item)"
                ><v-icon>mdi-update</v-icon></v-btn
              >
              <v-btn icon color="error" @click="deleteEvent(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </div>
</template>
<script>
import {
  CREATE_EVENT,
  GET_EVENTS,
  POP_EVENT,
  UPDATE_EVENT,
} from "../queries/core";
const PRODUCTION = true;

export default {
  data: () => ({
    search: "",
    events: [],
    selectedEvent: {},
    accessLink: "",
    // eventDate: "",
    eventDate: new Date().toISOString().substr(0, 10),
    eventPrice: 0,
    eventDetail: "",
    eventName: "",
    eventCover: null,
    menuStart:false,
    menuStartx: false,
    loadingEventSave: false,
    loadingEvents: false,
    openEvDialog: false,
    openUpdateEvDialog: false,
    headers: [
      { text: this.$t('cover'), name: "Cover", value: "cover" },
      { text: this.$t('EventName'), name: "name", value: "name" },
      { text: this.$t('EventDetail'), value: "detail", name: "detail" },
      { text: this.$t('EventPrice'), value: "price", name: "price" },
      { text: this.$t('EventDate'), name: "eventDate", value: "eventDate" },
      { text: this.$t('PaymentDate'), name: "createdAt", value: "createdAt" },
      { text: this.$t('Actions'), value: "actions" },
    ],
  }),
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.eventCover = files[0];
    },
    fetchEvents() {
      this.loadingEvents = true;
      this.$apollo
        .query({
          query: GET_EVENTS,
          fetchPolicy: "network-only",
        })
        .then(({ data: { flirttoolEvents } }) => {
          this.events = flirttoolEvents;
          this.loadingEvents = false;
        })
        .catch((e) => {
          console.error(e);
          this.loadingEvents = false;
        });
    },
    parseDate(date) {
      return new Date(parseInt(date)).toDateString();
    },
    updateEvent(event) {
      this.selectedEvent = event;
      this.openUpdateEvDialog = true;
    },
    popEvent(event) {
      this.$apollo
        .mutate({
          mutation: POP_EVENT,
          variables: {
            event,
          },
        })
        .then(() => {
          this.fetchEvents();
          alert("event deleted");
        })
        .catch(() => {
          alert("error removing event");
        });
    },
    saveEvent() {
      if (this.$refs.form.validate()) {
        this.loadingEventSave = true;
        this.$apollo
          .mutate({
            mutation: CREATE_EVENT,
            variables: {
              file: this.eventCover,
              input: {
                name: this.eventName,
                access_link: this.accessLink,
                detail: this.eventDetail,
                price: this.eventPrice ? parseFloat(this.eventPrice) : 0,
                eventDate: this.eventDate,
              },
            },
          })
          .then(() => {
            this.openEvDialog = false;
            setTimeout(() => {
              alert("Event Added Successfully");
            }, 1000);
            this.fetchEvents();
            this.loadingEventSave = false;
          })
          .catch((e) => {
            this.loadingEventSave = false;
            alert("Error saving event");
            console.error(e);
          });
      }
      return;
    },
    getUrl(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":4000/media/" + url;
      return domain;
    },
    saveEventUpdate() {
      if (this.$refs.xform.validate()) {
        const payload = {
          access_link: this.selectedEvent.access_link,
          detail: this.selectedEvent.detail,
          eventDate: this.selectedEvent.eventDate,
          name: this.selectedEvent.name,
          price: this.selectedEvent.price
            ? parseFloat(this.selectedEvent.price)
            : 0,
        };
        this.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              event: this.selectedEvent.id,
              input: payload,
            },
          })
          .then(() => {
            this.openUpdateEvDialog = false;
            alert("event data updated");
          })
          .catch(() => {
            this.openUpdateEvDialog = false;
            alert("error updating event");
          });
      }
    },
    deleteEvent(event) {
      const deleteEvent = confirm(
        `Are you sure you want to delete this event: ${event.name} ?`
      );
      if (deleteEvent) {
        this.popEvent(event.id);
      }
    },
  },
  created() {
    this.fetchEvents();
  },
};
</script>