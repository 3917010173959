var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"flat":"","height":100}},[_c('v-avatar',{staticClass:"mx-2",attrs:{"tile":"","size":60}},[_c('v-img',{attrs:{"src":require('@/assets/filter.png')}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{ref:"menuSt",attrs:{"close-on-content-click":false,"return-value":_vm.dateStart,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateStart=$event},"update:return-value":function($event){_vm.dateStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-7",attrs:{"outlined":"","prepend-icon":"mdi-calendar","readonly":"","label":"From"},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuStart = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuSt.save(_vm.dateStart)}}},[_vm._v(" "+_vm._s(_vm.$t('OK'))+" ")])],1)],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{ref:"menuEn",attrs:{"close-on-content-click":false,"return-value":_vm.dateEnd,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateEnd=$event},"update:return-value":function($event){_vm.dateEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-7",attrs:{"outlined":"","prepend-icon":"mdi-calendar","readonly":"","label":"To"},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuEnd = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuEn.save(_vm.dateEnd)}}},[_vm._v(" "+_vm._s(_vm.$t('OK'))+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"my-2",staticStyle:{"padding":"0px"},attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"active-class":"activeFilterBtn","color":"blue darken-4","mandatory":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},_vm._l((_vm.filters),function(fil,idx){return _c('v-btn',{key:idx,attrs:{"ripple":false,"value":fil.value,"large":""},domProps:{"textContent":_vm._s(fil.text)},on:{"click":function($event){return _vm.filterChanged(fil)}}})}),1)],1),_c('v-spacer'),[_c('v-data-table',{staticClass:"dataCard",attrs:{"headers":_vm.headers,"items":_vm.analysis_data},scopedSlots:_vm._u([{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+" ")])]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }