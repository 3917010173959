import '@babel/polyfill'
import store from './store'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { BootstrapVue } from 'bootstrap-vue'
import { createProvider } from './v-apollo'
import VueApollo from "vue-apollo";
import VueI18n from 'vue-i18n'
import translations from './translations'
import AsyncComputed from 'vue-async-computed'
Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(VueApollo);
Vue.use(VueI18n)
Vue.use(AsyncComputed)

export const i18n = new VueI18n(translations);
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
Vue.use(BootstrapVue);

new Vue({
    i18n,
    vuetify,
    router,
    store,
    render: h => h(App),
    apolloProvider: createProvider(),
}).$mount('#app')