<template>
  <div id="app">
    <v-tabs>
      <v-tab href="#pool">Fake Accounts</v-tab>
      <v-tab href="#search"> Deactivated Accounts </v-tab>
      <v-tab-item value="pool">
        <FakeAccounts/>
      </v-tab-item>
      <v-tab-item value="search">
        <DeactivatedAccounts/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
/* eslint-disable */
// import PageLoader from '@/components/PageLoader.vue'
import FakeAccounts from '@/components/FakeAccounts.vue';
import DeactivatedAccounts from '@/components/DeactivatedAccounts.vue'
export default {
  components: {
    FakeAccounts,
    DeactivatedAccounts
  },
};
</script>