<template>
	<v-app
		:style="{ background: $vuetify.theme.themes.light.background }"
		class="mx-2"
	>
		<v-dialog v-model="dialog2" max-width="500px" persistent>
			<v-card>
				<v-card-title> Generate Coupons </v-card-title>
				<v-card-text>
					<v-text-field
						type="number"
						v-model="generateCount"
						label="Voucher Count"
						filled
					></v-text-field>
					<v-text-field
						v-model="prefix"
						label="Voucher prefix"
						filled
					></v-text-field>
					<v-btn large color="primary" dark @click="generateCoupon">
						{{$t('GenerateVoucher')}}
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="newCouponDialog" max-width="690" persistent>
			<v-card>
				<v-row class="mx-2 pt-2">
					<v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
						<v-icon size="30" class="pr-2" color="blue darken-2"
							>mdi-ticket</v-icon
						>
						{{$t('AddCoupons')}}
					</v-card-title>
					<v-card-text style="padding: 0px" class="px-3"
						>{{$t('createnewcouponshere')}}</v-card-text
					>
				</v-row>
				<v-divider></v-divider>
				<v-col v-if="vouchers">
					<v-row class="mb-2">
						<v-chip
							class="mx-2 mb-2"
							dark
							color="blue darken-2"
							:key="idx"
							v-for="(vc, idx) in vouchers.slice(0, 12)"
							v-text="vc"
						></v-chip>
					</v-row>
				</v-col>
				<v-form v-model="valid" ref="form">
					<v-col>
						<v-row class="mx-1 mb-2">
							<v-text-field
								v-if="!generated"
								v-model="coupon"
								outlined
								label="Coupon"
								:rules="nameRules"
							>
							</v-text-field>
							<v-spacer />
							<v-btn
								text
								class="ml-3 mt-2"
								elevation="8"
								large
								color="green"
								v-if="vouchers"
								@click="clearVoucehrs"
							>
								<h6 class="pt-2" v-if="vouchers">
									({{ vouchers.length }})<span class="px-2">clear</span>
								</h6>
								<v-icon v-else>mdi-close</v-icon>
							</v-btn>
							<v-btn
								icon
								class="ml-3 mt-2"
								elevation="8"
								large
								color="green"
								@click="dialog2 = true"
							>
								<v-icon>mdi-fire</v-icon>
							</v-btn>
						</v-row>
					</v-col>
					<v-row class="mx-1">
						<v-col>
							<v-select
								:items="couponTypes"
								v-model="couponType"
								outlined
								label="Coupon Type"
								:rules="pinRules"
							>
							</v-select>
						</v-col>
						<v-col>
							<v-select
								:items="all_packagesMod"
								v-model="selectedPkg"
								item-value="id"
								item-text="packageName"
								outlined
								label="Packages"
								:rules="pinRules"
							>
							</v-select>
						</v-col>
					</v-row>
					<v-col>
						<v-text-field
							:rules="discountRules"
							v-model="discount"
							label="Discount amount in percent"
							outlined
							type="number"
						></v-text-field>
					</v-col>

					<v-col style="padding: 0px" class="px-3">
						<v-textarea
							v-model="packageDesc"
							dense
							outlined
							label="Package Description"
							:rules="descRules"
						>
						</v-textarea>
					</v-col>
				</v-form>
				<v-card-actions>
					<v-spacer />
					<v-btn large outlined color="error" @click="newCouponDialog = false"
						>{{$t('Cancel')}}</v-btn
					>
					<v-btn :loading="loadingSave" large @click="saveChanges">
						{{$t('SaveChanges')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<div class="mx-3 mt-10">
			<h2>{{$t('CouponsManagement')}}</h2>
			<p>{{$t('createnewcouponshere')}}</p>
		</div>
		<v-container fluid class="mb-10">
			<v-row dense class="mx-1">
				<v-btn class="primary my-4" dark @click="newCouponDialog = true"
					>{{$t('AddCoupons')}}</v-btn
				>
				<v-btn outlined @click="$apollo.queries.coupons.refetch()" class="mt-4 mx-2">
					<v-icon>mdi-reload</v-icon>
				</v-btn>
				<v-btn
					v-if="selectedCpns.length > 0"
					dark
					color="error"
					class="mx-2 mt-4"
					@click="removeSelected"
				>
					{{$t('Remove')}}
				</v-btn>
				<v-col
					cols="2"
					class="mt-4"
					style="padding: 0px"
					v-if="selectedCpns.length > 0"
				>
					<v-select
						v-if="selectedCpns.length > 0"
						:items="[
							{ label: 'Activate', val: true },
							{ label: 'Disable', val: false },
						]"
						item-text="label"
						item-value="val"
						v-model="flag"
						@change="updateSelected"
						dense
						label="update"
						outlined
					></v-select>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="coupons"
				v-model="selectedCpns"
				show-select
			>
				<template v-slot:[`item.coupon`]="{ item }">
					<v-chip
						style="border-radius: 0px"
						color="yellow lighten-4"
						large
						class="my-2"
					>
						<h5 class="pt-2" style="color: #606060">
							{{ item.coupon }}
						</h5>
					</v-chip>
				</template>

				<template v-slot:[`item.active`]="{ item }">
					<v-switch
						@change="statusChanged(item)"
						v-model="item.active"
						:value="item.active"
					></v-switch>
				</template>
				<template v-slot:[`item.discount`]="{ item }">
					<v-chip>
						{{ item.discount }}
					</v-chip>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-row>
						<!-- <v-btn icon color="blue mx-2 my-2 d-none darken-3" @click="update(item)"
							><v-icon>mdi-update</v-icon>
						</v-btn> -->
						<div class="mx-2"></div>
						<v-btn icon color="red mx-2 my-2 darken-3" @click="deleteP(item)"
							><v-icon>mdi-delete</v-icon>
						</v-btn>
					</v-row>
				</template>
				<template v-slot:[`item.used`]="{ item }">
					<v-icon
						v-text="
							item.used 
								? 'mdi-check-circle'
								: 'mdi-close-circle'
						"
						:size="30"
						class="font-weight-bold"
						:color="item.used ? 'green' : 'error'"
					>
					</v-icon>
				</template>
				<template v-slot:[`item.package`]="{ item }">
					{{ item.package.packageName }}
				</template>
				<template v-slot:[`item.couponType`]="{ item }">
					<h6 class="pt-2" v-if="item.couponType === 'otime'">{{$t('Onetime')}}</h6>
					<h6 class="pt-2" v-else>Reuseable</h6>
				</template>
				<template v-slot:[`item.description`]="{ item }">
					<h6 class="pt-2" v-text="item.description.substr(0, 50) + '...'"></h6>
				</template>
				<template v-slot:[`item.createdAt`]="{ item }">
					<h6 class="pt-2">
						{{ parseDate(item.createdAt) }}
					</h6>
				</template>
				<template v-slot:[`item.moderator`]="{ item }">
					<h6 class="pt-2">
						{{ item.moderator.pin }}
					</h6>
				</template>
			</v-data-table>
		</v-container>
	</v-app>
</template>
<script>
import vgen from 'voucher-code-generator'
import { ADD_COUPON, ALL_PACKAGES, COUPONS, POP_CPN, POP_MASS_CPN, UPDATE_CPN_STAT, UPDATE_MASS_CPN } from '../queries/core'
export default {
	data: () => ({
		coupons: [],
		coupon: "",
		valid: false,
		loadingSave: false,
		newCouponDialog: false,
		discount: 0,
		generateCount: null,
		couponTypes: [
			"otime", "reuseable"
		],
		flag: null,
		dialog2: false,
		generated: false,
		all_packagesMod: null,
		selectedPkg: null,
		packageDesc: "",
		// selectedPkg:null,
		vouchers: null,
		selectedCpns: [],
		prefix: "promo",
		couponType: "reuseable",
		headers: [
			{ text: 'Coupon Code', name: 'coupon', value: 'coupon' },
			{ text: 'Package', name: 'package', value: 'package' },
			{ text: 'Coupon Type', name: 'type', value: 'couponType' },
			{ text: 'Moderator', name: 'moderator', value: 'moderator' },
			{ text: "Discount Percent", name: "discount", value: "discount" },
			{ text: 'Description', name: 'description', value: 'description' },
			{ text: 'Active', name: 'active', value: 'active' },
			{ text: 'Used', name: 'used', value: 'used' },
			{ text: 'Created At', name: 'createdAt', value: 'createdAt' },
			{ text: 'Actions', name: 'actions', value: 'actions' },
		],
		discountRules: [
			(v) => !!v || "discount amount is required",
		],
		descRules: [
			(v) => !!v || "Description is required",
		],
		nameRules: [
			(v) => !!v || "Coupon is required",
		],
		pinRules: [(v) => !!v || "Coupon type is required",
		]
	}),
	methods: {
		deleteP(item) {
			const confirmed = confirm("Are you sure you want to remove this coupon ?")
			if (confirmed) {
				this.$apollo.mutate({
					mutation: POP_CPN,
					variables: {
						input: JSON.stringify(item.id)
					}
				}).then((data) => {
					console.warn(data)
					this.$apollo.queries.coupons.refetch()
				})
			}
		},
		showToaster(message, type = "info") {
			const data = {
				position: "top-center",
				timeout: 2000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: "button",
				icon: true,
				rtl: false,
			};
			switch (type) {
				case "info":
					this.$toast.info(message, data);
					break;
				case "success":
					this.$toast.success(message, data);
					break;
				case "warning":
					this.$toast.warning(message, data);
					break;
				case "error":
					this.$toast.error(message, data);
					break;

				default:
					this.$toast.info(message, data);
					break;
			}
		},
		statusChanged(item) {
			const couponStatus = item.active ?? false
			this.$apollo.mutate({
				mutation: UPDATE_CPN_STAT,
				variables: {
					stat: couponStatus,
					input: JSON.stringify(item.id)
				}
			}).then(() => {
				this.showToaster("status updated Sccesfully", "success")
			})
			console.warn(couponStatus)
		},
		saveChanges() {
			if (this.$refs.form.validate()) {
				this.saveCoupon()
			}
		},
		saveCoupon() {
			this.loadingSave = true
			this.$apollo.mutate({
				mutation: ADD_COUPON,
				variables: {
					input: {
						coupons: this.vouchers,
						coupon: this.coupon,
						type: this.couponType,
						discount: parseInt(this.discount),
						description: this.packageDesc,
						pkg: this.selectedPkg
					}
				}
			}).then(() => {
				this.loadingSave = false
				this.newCouponDialog = false
				this.$apollo.queries.coupons.refetch()
				this.showToaster("Coupon Added Sccesfully", "success")
			}).catch((e) => {
				console.error(e)
				this.loadingSave = false
				this.newCouponDialog = false
			})
		},
		updateSelected() {

			const selected = this.selectedCpns.map(e => JSON.stringify(e.id))
			this.$apollo.mutate({
				mutation: UPDATE_MASS_CPN,
				variables: {
					input: { coupons: selected, status: this.flag }
				}
			}).then(() => {
				this.$apollo.queries.coupons.refetch()
				this.showToaster("updated all selected coupons", "success")
			})

		},
		removeSelected() {
			const pop = confirm("Are you sure you want to remove these coupons")
			if (pop) {
				const selected = this.selectedCpns.map(e => JSON.stringify(e.id))
				this.$apollo.mutate({
					mutation: POP_MASS_CPN,
					variables: {
						input: { coupons: selected }
					}
				}).then(() => {
					this.$apollo.queries.coupons.refetch()
				this.showToaster("deleted all selected coupons", "success")
				})
			}
		},
		clearVoucehrs() {
			this.vouchers = null
			this.generated = false
		},
		generateCoupon() {
			this.vouchers = vgen.generate({
				length: 10,
				prefix: this.prefix + "-",
				count: this.generateCount
			})
			this.generated = true
			this.dialog2 = false
		},
		parseDate(date) {
			return new Date(parseInt(date)).toDateString()
		},
	},
	apollo: {
		all_packagesMod: {
			query: ALL_PACKAGES
		},
		coupons: {
			query: COUPONS,
		}
	},
	created() {
		this.$apollo.queries.coupons.refetch()
		this.$apollo.queries.all_packagesMod.refetch()
	}
}
</script>