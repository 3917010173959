/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ModelLogin from '../views/ModelLogin.vue'

import Blocked from '../views/Blocked.vue'
import Admin from '../views/Admin.vue'
import PrManagement from '../views/PrManagement.vue'
import ChatAnalysis from '../views/ChatAnalysis.vue'
import AgentChatAnalysis from '../views/AgentChatAnalysis.vue'
import ChatPage from '../views/ChatContent.vue'
import Settings from '../views/Settings.vue'
import ReachOut from '../views/ReachOut.vue'
import Coupons from '../views/Coupons.vue'
import Finance from '../views/Finance.vue'
import Packages from '../views/Packages.vue'
import Statistics from '../views/Statistics.vue'
import AgentOverview from '../views/AgentOverview.vue'
import FakeAccounts from '../views/FakeAccounts.vue'
import Moderators from '../views/Moderators.vue'
import LiveMonitoring from '../views/Livemonitoring.vue'
import DialogExplorer from '../views/DialogExplorer.vue'
import MessageStatistics from '../views/MessageStatistics.vue'
import Affilate from '../views/Affilates.vue'
import store from '../store'
import Events from '../views/Events.vue'
import SellingImages from '../views/SellingImages.vue'
import ModeratorReports from '../views/ModeratorReports.vue'
import PanicRoom from '../views/PanicRoom.vue'
import PanelSettings from '../views/PanelSettings.vue'
import Dashboard from '../views/Dashboard.vue'



Vue.use(VueRouter)

const routes = [{
    path: '/panel/:convo',
    name: 'Home',
    component: Home,
    children: [
        {
            path: "/settings",
            name: "Settings",
            component: PanelSettings
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard
        },
        {
            path: "/selling-images",
            name: "Selling Images",
            component: SellingImages
        },
        {
            path: "/mod-reports",
            name: "ModReports",
            component: ModeratorReports
        },
        {
            path: "/panicroom",
            name: "PanicRoom",
            component: PanicRoom
        },
        {
            path: "/events",
            name: "Events",
            component: Events
        },
        {
            path: "/dialog-explorer/:conv",
            name: "DialogExplorer",
            component: DialogExplorer
        },
        {
            path: "/affilates",
            name: "Affilates",
            component: Affilate
        },
        {
            path: "/coupons",
            name: "Coupons",
            component: Coupons
        },
        {
            path: "/chat-page",
            name: "ChatPage",
            component: ChatPage
        },
        {
            path: "/agent-chat-analysis",
            name: "AgentChatAnalysis",
            component: AgentChatAnalysis
        },
        {
            path: "/agentoverview",
            name: "Agent Overview",
            component: AgentOverview
        },
        {
            path: "/livemonitoring",
            name: "Live Monitoring",
            component: LiveMonitoring
        },
        {
            path: "/message-statistics",
            name: "Message Statistics",
            component: MessageStatistics
        },
        {
            path: "/finance",
            name: "Finance",
            component: Finance
        },
        {
            path: "/packages",
            name: "Packages",
            component: Packages
        },
        {
            path: "/statistics",
            name: "Statistics",
            component: Statistics
        },
        {
            path: "/chat-analysis/",
            name: "ChatAnalysis",
            component: ChatAnalysis
        },
        {
            path: "/settings/",
            name: "Settings",
            component: Settings
        },
        {
            path: "/moderators/",
            name: "Moderators",
            component: Moderators
        },
        {
            path: "/fake-accounts/",
            name: "FakeAccounts",
            component: FakeAccounts
        },
        {
            path: "/reach-out/",
            name: "ReachOut",
            component: ReachOut
        }
    ],
    beforeEnter: (to, from, next) => {
        if (store.state.flirtok) {
            next()
        } else {
            next(from)
        }
        // console.warn(to, from, next)
        // ...ad
    },
},
{
    path: '/',
    name: "Login",
    component: Login
},
{
    path: '/modellogin',
    name: "ModelLogin",
    component: ModelLogin
},
{
    path: "/admin",
    name: "Admin",
    component: Admin,
},
{
    path: "/project_management",
    name: "ProjectManagment",
    component: PrManagement
},
{
    path: "/blocked",
    name: "Blocked",
    component: Blocked
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router