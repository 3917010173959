<template>
  <div>
    <PageLoader
    v-if="loading"
    />
     <v-dialog v-model="addFakeDialog" max-width="690" >
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('AddFakeAccount')}}
          </v-card-title>
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form">
           <v-row class="mx-8" align="end" justify="end">
            <v-text-field
            v-model="newFake.firstName"
              label="First Name"
              outlined
              placeholder="First Name"
            >
            </v-text-field>
          </v-row>
           <v-row class="mx-8" align="end" justify="end">
            <v-text-field
            v-model="newFake.lastName"
              label="Last Name"
              outlined
              placeholder="Last Name"
            >
            </v-text-field>
          </v-row>
           <v-row class="mx-8" align="end" justify="end">
            <v-text-field
            v-model="newFake.username"
              label="Username"
              outlined
              placeholder="Username"
            >
            </v-text-field>
          </v-row>
           <v-row class="mx-8" align="end" justify="end">
            <v-text-field
            v-model="newFake.email"
              label="Email"
              outlined
              placeholder="Email"
            >
            </v-text-field>
          </v-row>
            <v-row class="mx-8" align="end" justify="end">
            <v-text-field
            v-model="newFake.password"
              label="Password"
              outlined
              placeholder="Password"
            >
            </v-text-field>
          </v-row>
          <v-row class="mx-8" align="end" justify="end">
           
            <v-btn
              @click="addFakeUser"
              :loading="loadingSave"
              large
              class="mb-5"
              >{{$t('SaveChanges')}}</v-btn
            >
            <v-btn
              class="mb-5 mx-3"
              @click="addFakeDialog = false"
              large
              color="error"
              outlined
              >{{$t('Close')}}</v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="690" fullscreen>
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            Update Moderator Info
          </v-card-title>
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form" v-if="selectedItem">
          <v-row class="mx-5">
            <v-col style="padding: 0px" class="m-2">
              <p>BasicInformation</p>
              <v-row class="my-5 mx-1">
                <img
                  :src="
                    selectedItem.profilePic
                      ? getURL(selectedItem.profilePic)
                      : require('../assets/placeholder.png')
                  "
                  style="width: 70px"
                />
                <input
                  ref="profilepic"
                  class="d-none"
                  type="file"
                  @change="profileChanged"
                />
                <v-btn
                  :loading="loadingupload"
                  outlined
                  class="mt-6 mx-2"
                  @click="$refs.profilepic.click()"
                  >{{$t('UpdateProfilePic')}}</v-btn
                >
              </v-row>
              <v-row class="mb-10 mt-10">
                <input
                  @change="uploadProfilePhotos"
                  type="file"
                  ref="photosupload"
                  class="d-none"
                />
                <div v-if="selectedItem.photos">
                  <v-row v-if="selectedItem.photos" class="mx-2">
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(pic, ix) in selectedItem.photos"
                      :key="ix"
                    >
                      <v-img
                        :src="getURL(pic.image)"
                        class="mx-2"
                        style="max-width: 120px"
                      >
                        <v-overlay absolute v-if="hover">
                          <v-btn color="transparent" @click="deletePhoto(pic)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-overlay>
                      </v-img>
                    </v-hover>
                  </v-row>
                  <p v-else class="px-3" style="font-size: 20px">
                    {{$t('Noprofilephotos')}}
                  </p>
                </div>
                <v-btn
                  icon
                  large
                  :loading="loadingPupload"
                  @click="$refs.photosupload.click()"
                  class="mb-2 mt-5"
                  outlined
                  text
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-row>
              <div class="mb-2">
                <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-text-field
                      v-model="selectedItem.base_profile.firstName"
                      label="First Name"
                      outlined
                      placeholder="First Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-text-field
                      v-model="selectedItem.base_profile.lastName"
                      outlined
                      label="Last Name"
                      placeholder="Last Name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Select Relationship"
                      label="Relationship Status"
                      :items="profileDetailSetupTypes.relationShipStatus"
                      v-model="selectedItem.user_basic.relationship"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Work Status"
                      label="Work Status"
                      :items="profileDetailSetupTypes.workStatus"
                      v-model="selectedItem.user_basic.workstatus"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Education Level"
                      label="Education Level"
                      :items="profileDetailSetupTypes.educationStatus"
                      v-model="selectedItem.user_basic.education_level"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col style="padding: 0px">
                  <v-textarea
                    v-model="selectedItem.user_basic.bio"
                    outlined
                    label="Bio"
                  >
                  </v-textarea>
                </v-col>
              </div>
            </v-col>
            <v-col style="padding: 0px" class="m-2">
              <p>{{$t('Looks')}}</p>
              <div class="mb-2">
                <v-row>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      v-model="selectedItem.looks.ethnicity"
                      :items="lTypes.ethnicity"
                      label="Ethnicity"
                      outlined
                      placeholder="Ethnicity"
                    >
                    </v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      v-model="selectedItem.looks.body_type"
                      :items="lTypes.bodyType"
                      label="Body Type"
                      outlined
                      placeholder="Body Type"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                <v-col style="padding: 0px" class="mt-2">
                  <v-select
                    v-model="selectedItem.looks.hair_color"
                    :items="lTypes.hairColor"
                    label="Hair Color"
                    outlined
                    placeholder="Hair Color"
                  >
                  </v-select>
                </v-col>
                <v-col style="padding: 0px" class="mt-2 ml-2">
                  <v-select
                    v-model="selectedItem.looks.gender"
                    :items="lTypes.gender"
                    label="Gender"
                    outlined
                    placeholder="Gender"
                  >
                  </v-select>
                </v-col>
                </v-row>
              </div>

              <div class="mb-2 mt-10">
                <p>{{$t('LifeStyle')}}</p>
                 <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Select Lives With"
                      label="Lives With"
                      :items="lifestyleTypes.livingWith"
                      v-model="selectedItem.lifeStyle.live_with"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Car"
                      label="Car"
                      :items="lifestyleTypes.car"
                      v-model="selectedItem.lifeStyle.car"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Religion"
                      label="Religion"
                      :items="lifestyleTypes.religion"
                      v-model="selectedItem.lifeStyle.religion"
                    ></v-select>
                  </v-col>
                </v-row>
                 <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Smoke"
                      label="Smoke"
                      :items="lifestyleTypes.smoke"
                      v-model="selectedItem.lifeStyle.smoke"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Drinking"
                      label="Drinking"
                      :items="lifestyleTypes.drink"
                      v-model="selectedItem.lifeStyle.drinking"
                    ></v-select>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                    <v-select
                      outlined
                      placeholder="Travel"
                      label="Travel"
                      :items="lifestyleTypes.travel"
                      v-model="selectedItem.lifeStyle.travel"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="mb-2 mt-10">
                <p>{{$t('EmailUsername')}}</p>
                 <v-row dense>
                  <v-col style="padding: 0px" class="m-2">
                     <v-text-field
                      v-model="selectedItem.email"
                      label="Email"
                      outlined
                      placeholder="Email"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col style="padding: 0px" class="m-2">
                     <v-text-field
                      v-model="selectedItem.username"
                      label="Username"
                      outlined
                      placeholder="Username"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-8" align="end" justify="end">
            <v-btn
              @click="saveChanges"
              :loading="loadingSave"
              large
              class="mb-5"
              >{{$t('SaveChanges')}}</v-btn
            >
            <v-btn
              class="mb-5 mx-3"
              @click="editDialog = false"
              large
              color="error"
              outlined
              >{{$t('Close')}}</v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <v-row dense>
      <div class="mt-2">
        <v-row dense class="mt-5 mx-5">
          <h2 class="px-3 pt-2 font-weight-bold">
            <span>{{$t('FakeAccounts')}} </span> <br/>
            <v-btn @click="addFakeDialog = true">{{$t('AddFakeAccount')}}</v-btn>
            <v-btn class="ml-2" @click="fetchFakeAccounts()">Refresh</v-btn>

          </h2>
          <v-spacer style="width: 10%"></v-spacer>
          <v-btn class="mx-2 my-2 d-none" color="blue darken-1" dark
            >{{$t('AddFakeAccount')}}</v-btn
          >
        </v-row>
      </div>
    </v-row>
    <v-row dense class="mx-3 mt-2">
      <v-icon class="mx-2 mt-5" :size="30">mdi-magnify</v-icon>
      <b-form-input
        v-model="searched"
        style="max-width: 40%"
        class="searchField mt-7"
        placeholder="Search for any username, email, ..."
        @keypress.enter="searchUsers"
      ></b-form-input>
      <v-select
        class="mt-3 ml-2"
        v-model="order"
        item-text="label"
        @change="fetchFakeAccounts"
        item-value="value"
        label="Order"
        :items="[{
          label:'Show Updated',
          value:'DESC'
        },{
          label:'Show Latest',
          value:'ASC'
        }]"
        ></v-select>
    </v-row>

    <v-divider></v-divider>
    <v-data-table
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loadingData"
      :items="items"
      :items-per-page="10"
      :headers="headers"
      show-select
      fixed-header
      class="elevation-0 mt-3 mx-2"
      :footer-props="{
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
         'items-per-page-options': [10, 20, 30, 40, 50,100]
      }"
      v-model="allSelected"
    >
      <template v-slot:[`item.profilepic`]="{ item }">
        <v-avatar class="profile m-3" color="grey" size="100" tile>
          <v-img :src="getURL(item.profilePic)">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        @{{ item.username }}
      </template>
      <template v-slot:[`item.lastName`]="{ item }">
        {{ item.base_profile && item.base_profile.lastName }}
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        {{ item.base_profile && item.base_profile.firstName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="initInfoUpdate(item)" outlined>upate details</v-btn>
      </template>
      <template v-slot:[`item.view`]="{ item }">
        <a target="_blank" :href="getUserUrl(item)" outlined>
          <v-icon>mdi-eye</v-icon>
        </a>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-btn @click="changeUserStatus(item,false)" v-if="item.active" outlined>
          Deactivate
        </v-btn>
        <!-- <v-btn @click="changeUserStatus(item,true)" v-else outlined>
          Activate
        </v-btn> -->
      </template>
    </v-data-table>
    <v-pagination  :length="Math.ceil(totalCount/options.itemsPerPage)"  rounded="circle" :total-visible="10" v-model="options.page">
    </v-pagination>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from '@/components/PageLoader.vue'
import {
  FAKE_ACCOUNTS,
  LIFESTYLE_TYPES,
  LOOKS_PERSONALITY_TYPES,
  POP_FAKE_PICS,
  PROFILE_DETAIL_SETUP_TYPES,
  SEARCH_USR,
  UPDATE_FAKE_ACCOUNT,
  UPDATE_FAKE_PROFILE_PHOTOS,
  UPDATE_FAKE_PROFILE_PIC,
  ADD_FAKE_ACCOUNT,
  SET_USER_STATUS
} from "../queries/core";
const PRODUCTION = true;

export default {
  components: {
    PageLoader
  },
  data: () => ({
    newFake: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password : ""
    },
    addFakeDialog:false,
    headers: [
      {
        text: 'ProfilePic',
        align: "start",
        sortable: false,
        value: "profilepic",
      },
      {
        text: 'Username',
        align: "start",
        sortable: false,
        value: "username",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: 'FirstName',
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: 'LastName',
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: 'Active',
        align: "start",
        sortable: false,
        value: "active",
      },
      {
        text: 'Actions',
        align: "start",
        sortable: false,
        value: "actions",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "view",
      },
    ],
    items: [],
    lTypes: [],
    lifestyleTypes:{},
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    profileDetailSetupTypes: [],
    allSelected: [],
    searched: "",
    totalCount: 0,
    editDialog: false,
    loadingSave: false,
    loadingPupload: false,
    loadingupload: false,
    loadingData: false,
    selectedItem: null,
    loading:false,
    order:'ASC'
  }),
  async created() {
    await this.fetchFakeAccounts();
    await this.fetchProfileDetailSetupTypes();
    await this.fetchlooksAndPersonalitySetupTypes();
    await this.fetchLifestyleDetailsSetupTypes();
  },
  watch: {
    "options.itemsPerPage": {
      async handler() {
        await this.fetchFakeAccounts();
      },
    },
    "options.page": {
      async handler() {
        // console.log("pagedd")
        await this.fetchFakeAccounts();
      },
    },
  },
  methods: {
    async changeUserStatus(item,active) {
      try{ 
        this.loadingSave = true;
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: SET_USER_STATUS,
          variables: {
            id:String(item.id),
            active:active
          },
        });
        this.loadingSave = false;
        this.loading = false;
        this.items = this.items.map((i)=>{
          if(i.id == item.id) {
              i.active = active
          }
          return i
        })
      }catch(e){
        alert(e.message)
        this.loadingSave = false;
        this.loading = false;
      }
    },
    async addFakeUser() {
      if(this.newFake.firstName.length < 3) return alert('Firstname should be grater than 3 letters')
      if(this.newFake.lastName.length < 3) return alert('lastName should be grater than 3 letters') 
      if(this.newFake.email.length < 5) return alert('email should be grater than 5 letters') 
      if(this.newFake.username.length < 3) return alert('username should be grater than 3 letters') 
      if(this.newFake.password.length < 3) return alert('password should be grater than 3 letters')
       try{ 
        this.loadingSave = true;
        const { data } = await this.$apollo.mutate({
          mutation: ADD_FAKE_ACCOUNT,
          variables: {
            firstName:this.newFake.firstName,
            lastName:this.newFake.lastName,
            email:this.newFake.email,
            username:this.newFake.username,
            password:this.newFake.password,
          },
        });
        if (data) {
          this.loadingSave = false;
          this.editDialog = false;
          this.addFakeDialog = false
          this.order = 'DESC'
          this.newFake.firstName = ''
          this.newFake.lastName = ''
          this.newFake.email = ''
          this.newFake.username = ''
          this.newFake.password = ''
          this.showToaster("Added fake account", "success");
          await this.fetchFakeAccounts();
        } else {
          this.loadingSave = false;
          this.editDialog = false;
        }
      }catch(e) {
        alert(e.message)
        this.loadingSave = false;
        if(e.message.includes('unique constraint')) {
          this.showToaster("Username or email already exists", "error");
          setTimeout(()=>{
            this.showToaster("The property being edited can-not be set to this one.", "error");
          },1000)
          this.items = []
          await this.fetchFakeAccounts();
        }
      }


      console.log(this.newFake)
    },
    searchUsers() {
      // return true
      console.log("searcheddd");
      this.loading = true;
      this.$apollo
        .query({
          query: SEARCH_USR,
          variables: {
            input: this.searched,
          },
        })
        .then(({ data: { searchAllUser } }) => {
          this.loading = false;
          this.items = searchAllUser;
          // this.items = data.users;
          // console.warn(this.items);
          //   console.warn(data.users);
        })
        .catch((e) => {
          this.showToaster("Error searching for user.", "error");
          console.error(e);
          this.loading = false;
        })
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },

    async deletePhoto(pic) {
      const cnf = confirm("are you sure you want to remove this picture ?");
      if (cnf) {
        const { errors } = await this.$apollo.mutate({
          mutation: POP_FAKE_PICS,
          variables: {
            pic: JSON.stringify(pic.id),
          },
        });
        if (!errors) {
          this.showToaster("deleted photo successfully", "success");
          this.editDialog = false;
          await this.fetchFakeAccounts();
        }
        console.log(pic);
      }
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":3000/media/" + url;
      return domain;
    },
    async fetchlooksAndPersonalitySetupTypes() {
      const { data } = await this.$apollo.query({
        query: LOOKS_PERSONALITY_TYPES,
      });
      if (data) this.lTypes = data.looksAndPersonalitySetupTypes;
    },
    async fetchProfileDetailSetupTypes() {
      const { data } = await this.$apollo.query({
        query: PROFILE_DETAIL_SETUP_TYPES,
      });
      if (data) {
        this.profileDetailSetupTypes = data.profileDetailSetupTypes;
      }
    },
    async fetchLifestyleDetailsSetupTypes() {
      const { data } = await this.$apollo.query({
        query: LIFESTYLE_TYPES,
      });
      if (data) {
        this.lifestyleTypes = data.lifeStyleAndFavouritesSetupTypes;
      }
    },
    createLifeStyleFields(item) {
      item.lifeStyle = { 
        car: null,
        drinking: null,
        id: null,
        live_with:null, 
        religion:null,
        smoke: null,
        travel: null
      }
    },
    initInfoUpdate(item) {
      if(item.lifeStyle === null) this.createLifeStyleFields(item)
      console.log(item)
      this.selectedItem = item;
      this.editDialog = true;
    },
    async fetchFakeAccounts() {
      try {
        this.loadingData = true;
        this.loading = true;
        const { data, errors } = await this.$apollo.query({
          query: FAKE_ACCOUNTS,
          fetchPolicy: "no-cache",
          variables: {
            input: {
              order:this.order,
              page: this.options.page,
              pageSize: this.options.itemsPerPage,
            },
          },
        });
        this.loading = false;
        if (!errors) {
          this.items = data.fakeAccounts.data;
          this.totalCount = data.fakeAccounts.total;
          this.loadingData = false;
          this.loading = false;
        }
      }catch(e){ 
         this.loadingData = false;
        this.loading = false;
      }
    },
    getUserUrl(item) {
      return `https://${this.$store.state.domain}/p/${item.username}`;
    },
    async uploadProfilePhotos(e) {
      this.loadingPupload = true;
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_FAKE_PROFILE_PHOTOS,
        variables: {
          file: e.target.files[0],
          user: JSON.stringify(this.selectedItem.id),
        },
      });
      if (data) {
        this.loadingPupload = false;
        this.editDialog = false;
        this.showToaster("upload profile photo successfully", "success");
        await this.fetchFakeAccounts();
      } else {
        this.loadingPupload = false;
      }
    },
    async profileChanged(e) {
      this.loadingupload = true;
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_FAKE_PROFILE_PIC,
        variables: {
          file: e.target.files[0],
          user: JSON.stringify(this.selectedItem.id),
        },
      });
      if (data) {
        this.loadingupload = false;
        this.editDialog = false;
        this.showToaster("changed profile photo successfully", "success");
        await this.fetchFakeAccounts();
      } else {
        this.loadingupload = false;
      }
    },
    async saveChanges() {
      try{ 
        this.loadingSave = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_FAKE_ACCOUNT,
          variables: {
            user: JSON.stringify(this.selectedItem.id),
            input: {
              username:this.selectedItem.username,
              email:this.selectedItem.email,
              basic: {
                firstName: this.selectedItem.base_profile.firstName,
                lastName: this.selectedItem.base_profile.lastName,
              },
              profile: {
                bio: this.selectedItem.user_basic.bio,
                relationship: this.selectedItem.user_basic.relationship,
                workStatus: this.selectedItem.user_basic.workstatus,
                educationLevel: this.selectedItem.user_basic.education_level,
              },
              looks: {
                ethnicity: this.selectedItem.looks.ethnicity,
                bodyType: this.selectedItem.looks.body_type,
                hairColor: this.selectedItem.looks.hair_color,
                gender:this.selectedItem.looks.gender
              },
              lifestyle: {
                livingWith: this.selectedItem.lifeStyle.live_with,
                carType: this.selectedItem.lifeStyle.car,
                religion: this.selectedItem.lifeStyle.religion,
                smoke: this.selectedItem.lifeStyle.smoke,
                drink: this.selectedItem.lifeStyle.drinking,
                travel: this.selectedItem.lifeStyle.travel,
              },
            },
          },
        });
        if (data) {
          this.loadingSave = false;
          this.editDialog = false;
          this.showToaster("Updated fake account", "success");
          await this.fetchFakeAccounts();
        } else {
          this.loadingSave = false;
          this.editDialog = false;
        }
      }catch(e) {
        this.loadingSave = false;
        if(e.message.includes('unique constraint')) {
          this.showToaster("Username or email already exists", "error");
          setTimeout(()=>{
            this.showToaster("The property being edited can-not be set to this one.", "error");
          },1000)
          this.items = []
          await this.fetchFakeAccounts();
        }
      }
    },
  },
};
</script>