<template>
	<v-app
		:style="{ background: $vuetify.theme.themes.light.background }"
		class="mx-2"
	>
		<v-container fluid class="mt-10" v-if="transactionData">
			<v-col cols="12" md="12" style="padding: 0px" class="px-4 pb-5"
				><h1 class="headline grey--text">{{$t('Overview')}}</h1></v-col
			>
			<v-row class="mx-1 mb-2">
				<v-col cols="12" lg="4" xl="4" sm="6">
					<v-card color="blue darken-2" dark>
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline"> Today </v-card-title>

								<v-card-subtitle class="headline"
									>${{ transactionData.today }}</v-card-subtitle
								>
							</div>

							<v-avatar class="ma-3" size="100" tile>
								<v-img src="gold.jpg"></v-img>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" lg="4" xl="4" sm="6">
					<v-card color="blue darken-2" dark>
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline"> {{$t('Thisweek')}} </v-card-title>

								<v-card-subtitle class="headline"
									>${{ transactionData.thisWeek }}</v-card-subtitle
								>
							</div>

							<v-avatar class="ma-3" size="100" tile>
								<v-img src="gold.jpg"></v-img>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" lg="4" xl="4" sm="6">
					<v-card color="blue darken-2" dark>
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline"> {{$t('TotalEarnings')}} </v-card-title>

								<v-card-subtitle class="headline"
									>${{ transactionData.total }}</v-card-subtitle
								>
							</div>
							<v-avatar class="ma-3" size="100" tile>
								<v-img src="gold.jpg"></v-img>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
			</v-row>

			<v-col cols="12" md="12" style="padding: 0px" class="px-4 pb-6 pt-10"
				><h1 class="headline grey--text">{{$t('Earnings')}}</h1></v-col
			>
			<v-layout row class="mx-4">
				<v-card width="100%">
					<v-card-title class="d-none">
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							hide-details
						></v-text-field>
					</v-card-title>
					<v-data-table
						:headers="headers"
						:items="transactionData.transactions"
						:search="search"
					>
						<template v-slot:[`item.createdAt`]="{ item }">
							<p class="pt-2">
								{{ parseDate(item.createdAt) }}
							</p>
						</template>
						<template v-slot:[`item.paymentSource`]="{ item }">
							<p class="pt-2">
								{{ item.paymentSource }}
							</p>
						</template>
						<template v-slot:[`item.user`]="{ item }">
							<p class="pt-2">
								{{ item.user.base_profile.firstName }}

								{{ item.user.base_profile.lastName }}
								<br />
								<a :href="'https://funnyflirts.net/@'+item.user.username"> @{{ item.user.username }} </a>
							</p>
						</template>
					</v-data-table>
				</v-card>
			</v-layout>
			<v-row dense class="mx-4 mb-12 d-none">
				<v-col cols="12" sm="12"
					><h1 class="headline grey--text pt-12 pb-3">
						{{$t('MontlyStatistics')}}
					</h1></v-col
				>
				<v-col cols="12" md="6" sm="12">
					<v-card>
						<v-list>>
							<v-list-item>
								<v-list-item-avatar color="blue">
									<v-icon dark>fas fa-car</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>JAN</v-list-item-title>
									<v-list-item-subtitle>$700 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$1,200</v-list-item-title
									>
									<v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="red">
									<v-icon dark>fas fa-utensils</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>FEB</v-list-item-title>
									<v-list-item-subtitle>$2,100 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$3,700</v-list-item-title
									>
									<v-list-item-subtitle>$1,600 of 3,700</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="orange">
									<v-icon dark>fas fa-dumbbell</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>MARCH</v-list-item-title>
									<v-list-item-subtitle>$350 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$950</v-list-item-title
									>
									<v-list-item-subtitle>$600 of 950</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="green">
									<v-icon dark>fas fa-gamepad</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>THIS MONTH</v-list-item-title>
									<v-list-item-subtitle>$150 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$350</v-list-item-title
									>
									<v-list-item-subtitle>$200 of 150</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-card>
						<v-list>
							<v-list-item>
								<v-list-item-avatar color="blue">
									<v-icon dark>fas fa-car</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>JAN</v-list-item-title>
									<v-list-item-subtitle>$700 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$1,200</v-list-item-title
									>
									<v-list-item-subtitle>$500 of 1,200</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="red">
									<v-icon dark>fas fa-utensils</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>FEB</v-list-item-title>
									<v-list-item-subtitle>$2,100 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$3,700</v-list-item-title
									>
									<v-list-item-subtitle>$1,600 of 3,700</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="orange">
									<v-icon dark>fas fa-dumbbell</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>MARCH</v-list-item-title>
									<v-list-item-subtitle>$350 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$950</v-list-item-title
									>
									<v-list-item-subtitle>$600 of 950</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider inset></v-divider>
							<v-list-item>
								<v-list-item-avatar color="green">
									<v-icon dark>fas fa-gamepad</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>THIS MONTH</v-list-item-title>
									<v-list-item-subtitle>$150 Turn Over</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content align="right">
									<v-list-item-title class="indigo--text"
										>$350</v-list-item-title
									>
									<v-list-item-subtitle>$200 of 150</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>


<script>
import { TRANSACTIONS_STAT } from '../queries/core'
export default {
	apollo: {
		transactionData: {
			query: TRANSACTIONS_STAT
		}
	},
	methods: {
		parseDate(date){
			return new Date(parseInt(date)).toDateString()
		}
	},
	created() {
		this.$apollo.queries.transactionData.refetch()
	},
	data() {
		return {
			transactionData: null,
			search: '',
			headers: [
				{
					text: 'User',
					align: 'start',
					filterable: false,
					value: 'user',
				},
				{ text: 'Payment Source', name: 'paymentSource', value:'paymentSource' },
				{ text: 'Amount (USD)', value: 'amount' },
				{ text: 'Package Name', value: 'transactionName' },
				{ text: 'Payment Date', name: 'createdAt',value:'createdAt' },

			],
		}
	},
}
</script>