<template>
  <div id="app">
    <v-tabs>
      <v-tab href="#pool"> Pool info </v-tab>
      <v-tab href="#search"> Chat Live monitoring </v-tab>
      <v-tab-item value="pool">
        <PoolInfo />
      </v-tab-item>
      <v-tab-item value="search">
        <LiveMonitoring />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import LiveMonitoring from "../components/LiveMonitoring.vue";
import PoolInfo from "../components/PoolInfo.vue";
// import LiveMonitoringVue
export default {
  components: {
    LiveMonitoring,
    PoolInfo,
  },
};
</script>
