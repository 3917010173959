/* eslint-disable */
export default {
  locale: "be",
  messages: {
    en: {
      SellingImages: "Selling Images",
      Images: "Images",
      AgentID: "Agnet ID",
      Actions: "Actions",
      Rank: "Rank",
      EventPrice: "EventPrice",
      Answers: "Answers",
      Operator: "Operator",
      Settings: "Settings",
      HoursSpent: "HoursSpent",
      SendMessage: "SendMessage",
      Messages: "Messages",
      EventDetail: "EventDetail",
      Audience: "Audience",
      EventDate: "EventDate",
      Actions: "Actions",
      ActiveStatus: "ActiveStatus",
      CreatedAt: "Created At",
      Location: "Location",
      PackageType: "PackageType",
      FlirtsAmount: "FlirtsAmount",
      CreatedDate: "CreatedDate",
      Price: "Price",
      Total: "Total",
      PackageName: "PackageName",
      PackagesDescription: "PackagesDescription",
      AccountType: "Account Type",
      OnlineStatus: "OnlineStatus",
      PaymentDate: "PaymentDate",
      SelectFake: "SelectFake",
      Moderators: "Moderator",
      Conversions: "Conversions",
      FullName: "Full Name",
      Category: "Category",
      LastWeek: "LastWeek",
      RealTime: "Real Time",
      AnswerRate: "AnswerRate",
      ModeratorTabs: "Agent Tabs",
      Messagesrecieved: "Messages recieved (INs)",
      Lengthoffirstmesssage: "Length of first messsage",
      MessagesSent: "Messages Sent (OUTs)",
      ShowOverlay: "Show Overlay",
      Gallery: "Gallery",
      Statistics: "Statistics",
      Community: "Community",
      Coupons: "Coupons",
      Packages: "Packages",
      Transfer: "Transfer",
      SKIP: "SKIP",
      Today: "Today",
      LasWeek: "LasWeek",
      ThisWeek: "ThisWeek",
      ThisMonth: "This Month",
      LastMonth: "Last Month",
      MessageReported: "Message Reported",
      report: "Report",
      TimeElapsed: "Time Elapsed",
      Affilates: "Affilates",
      Finance: "Finance",
      Events: "Events",

      Status: "Status",
      FakeAccounts: "Fake Accounts",
      AgentOverview: "Agent Overview",
      LiveMonitoring: "Live Monitoring",
      MessageStatistics: "Message Statistics",
      ActivityAnalysis: "Activity Analysis",
      AgentActivity: "Agent Activity",
      ChatModeration: "Chat Moderation",
      Reportauser: "Report a user",
      startChatting: "Start Chatting",
      UserProfile: "User Profile",
      Change: "Change",
      Owner: "Owner",
      SaveChanges: "Save Changes",
      UpdateAffilateData: "Update Affilate Data",
      AddAffilate: "Add Affilate",
      addnewaffilite: "add new affilates/creators/influencers here",
      AffilatesManagement: "AffilatesManagement",
      Cancel: "Cancel",
      OK: "OK",
      Youhavebeenblocked: "You have been blocked",
      NoPoolsFoundPleaseRefreshinaWhile:
        "No Pools Found Please Refresh in a While",
      GenerateVoucher: "Generate Voucher",
      AddCoupons: "Add Coupons",
      createnewcouponshere: "create new coupons here",
      Remove: "Remove",
      Onetime: "One time",
      Welcome: "Welcome",
      DialogExplorer: "DialogExplorer",
      Updateeventdetailsfor: "Update event details for",
      AddnewFlirtTooleventhere: "Add new FlirtTool eventhere",
      SaveEvent: "Save Event",
      AddanewEvent: "Add a new Event",
      Addnewchateventhere: "Add new chat event here",
      eventcover: "event cover",
      cover: "cover",
      Active: "Active",
      EventName: "Event Name",
      FirstName: "FirstName",
      LastName: "LastName",
      AddEvents: "AddEvents",
      AddFakeAccount: "Add Fake Account",
      Close: "Close",
      Profile: "Profile",
      ProfilePic: "ProfilePic",
      Gender: "Gender",
      UpdateProfilePic: "Update Profile Pic",
      Noprofilephotos: "No profile photos",
      Looks: "Looks",
      LifeStyle: "LifeStyle",
      EmailUsername: "Email 0r Username",
      Username: "Username",
      FakeAccounts: "Fake Accounts",
      Overview: "Overview",
      Thisweek: "This week",
      Earnings: "Earnings",
      MontlyStatistics: "Montly Statistics",
      Login: "Login",
      Addnewmoderator: "Add new moderator",
      Enterinformationhere: "Enter information here",
      Moderators: "Moderators",
      AddModerator: "Add Moderator",
      Updatepackage: "Update package",
      Enterpackageinformationhere: "Enter package information here",
      packagecover: "package cover",
      Addnewpackage: "Add new package",
      Enterpackageinformationhere: "Enter package information here",
      Thepackagehasatimerange: "The package has a time range",
      PackageManagement: "Package Management",
      Thiswherewemanageandupdateflirtsorpackages:
        "This where we manage and update flirts or packages",
      AddNew: "AddNew",
      RoleSettings: "Role Settings",
      AddnewModeratedAccount: "Add new Moderated Account",
      Enterinformationhere: "Enter information here",
      Save: "Save",
      SendMassPoke: "Send Mass Poke",
      YourPOKESwillgoouttotheseusers: "Your POKES will go out to these users",
      SendMassLikes: "SendMassLikes",
      YourLIKESwillgoouttotheseusers: "Your LIKES will go out to these users",
      SendMassSpam: "Send Mass Spam",
      Yourmessagewillgoouttotheseusers:
        "Your message will go out to these users",
      SendSpam: "Send Spam",
      SendMassEmail: "Send Mass Email",
      Emailmessageswillgoouttotheseusers:
        "Email messages will go out to these users",
      SendEmail: "Send Email",
      Promotion: "Users",
      Sendmessageandreachouttousershere:
        "Send message and reach out to users here",
      Search: "Search",
      Unknown: "Unknown",
      NOTSET: "NOT SET",
      Youruseraccounts: "Youruseraccounts",
      SellingNudes: "Selling Nudes",
      SellingGifts: "Selling Gifts",
      UpdatePhotos: "Update Photos",
      Community: "Community",
      Agree: "Agree",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "You have been warned by the admin , PLease agree that this will not happen again",
      Senddirectchatmessage: "Send direct chat message",
      Dashboard: "Dashboard",
      name: "Name",
      location: "Location",
      birthDay: "BirthDay",
      Job: "Job",
      WorkingSchedule: "Working Schedule",
      RelationshipStatus: "Relationship Status",
      Searchingfor: "Searching for",
      pastRelationships: "Past Relationships",
      TraitsforIdealPartner: "Traits for Ideal Partner",
      FuturePlanswithPartner: "Future Plans with Partner"
    },
    Deutsch: {
      Job: "Beruf",
      WorkingSchedule: "Arbeitszeitplan",
      RelationshipStatus: "Beziehungsstatus",
      Searchingfor: "Sucht nach",
      pastRelationships: "Vergangene Beziehungen",
      TraitsforIdealPartner: "Eigenschaften des idealen Partners",
      FuturePlanswithPartner: "Zukunftspläne mit dem Partner",

      name: "Name",
      location: "Ort",
      birthDay: "Geburtstag",
      SellingImages: "Bilder verkaufen",
      Images: "Bilder",
      AgentID: "Agenten-ID",
      Actions: "Aktionen",
      Rank: "Rang",
      EventPrice: "Veranstaltungspreis",
      Answers: "Antworten",
      Operator: "Operator",
      Settings: "Einstellungen",
      HoursSpent: "Stunden verbracht",
      SendMessage: "Nachricht senden",
      Messages: "Nachrichten",
      EventDetail: "Veranstaltungsdetails",
      Audience: "Zielgruppe",
      EventDate: "Veranstaltungsdatum",
      ActiveStatus: "Aktiver Status",
      CreatedAt: "Erstellt am",
      Location: "Ort",
      PackageType: "Pakettyp",
      FlirtsAmount: "Anzahl der Flirts",
      CreatedDate: "Erstellungsdatum",
      Price: "Preis",
      Total: "Gesamt",
      PackageName: "Paketname",
      PackagesDescription: "Paketbeschreibung",
      AccountType: "Kontotyp",
      OnlineStatus: "Online-Status",
      PaymentDate: "Zahlungsdatum",
      SelectFake: "Falsche auswählen",
      Moderators: "Moderatoren",
      Conversions: "Konversionen",
      FullName: "Vollständiger Name",
      Category: "Kategorie",
      LastWeek: "Letzte Woche",
      RealTime: "Echtzeit",
      AnswerRate: "Antwortrate",
      ModeratorTabs: "Moderatorregisterkarten",
      Messagesrecieved: "Empfangene Nachrichten (Eingänge)",
      Lengthoffirstmesssage: "Länge der ersten Nachricht",
      MessagesSent: "Gesendete Nachrichten (Ausgänge)",
      ShowOverlay: "Overlay anzeigen",
      Gallery: "Galerie",
      Statistics: "Statistiken",
      Community: "Gemeinschaft",
      Coupons: "Gutscheine",
      Packages: "Pakete",
      Transfer: "Übertragung",
      SKIP: "Überspringen",
      Today: "Heute",
      LasWeek: "Letzte Woche",
      ThisWeek: "Diese Woche",
      ThisMonth: "Dieser Monat",
      LastMonth: "Letzter Monat",
      MessageReported: "Nachricht gemeldet",
      report: "Bericht",
      TimeElapsed: "Verstrichene Zeit",
      Affilates: "Partner",
      Finance: "Finanzen",
      Events: "Veranstaltungen",
      Promotion: "Benutzer",
      Status: "Status",
      FakeAccounts: "Fake-Konten",
      AgentOverview: "Agentenübersicht",
      LiveMonitoring: "Live-Überwachung",
      MessageStatistics: "Nachrichtenstatistiken",
      ActivityAnalysis: "Aktivitätsanalyse",
      AgentActivity: "Agentenaktivität",
      ChatModeration: "Chat-Moderation",
      Reportauser: "Benutzer melden",
      startChatting: "Chatten starten",
      UserProfile: "Benutzerprofil",
      Change: "Ändern",
      Owner: "Besitzer",
      SaveChanges: "Änderungen speichern",
      UpdateAffilateData: "Partnerdaten aktualisieren",
      AddAffilate: "Partner hinzufügen",
      addnewaffilite: "Neue Partner/Ersteller/Influencer hier hinzufügen",
      AffilatesManagement: "Partnermanagement",
      Cancel: "Abbrechen",
      OK: "OK",
      Youhavebeenblocked: "Du wurdest blockiert",
      NoPoolsFoundPleaseRefreshinaWhile: "Keine Pools gefunden. Bitte aktualisieren Sie in Kürze",
      GenerateVoucher: "Gutschein generieren",
      AddCoupons: "Gutscheine hinzufügen",
      createnewcouponshere: "Erstellen Sie hier neue Gutscheine",
      Remove: "Entfernen",
      Onetime: "Einmalig",
      Welcome: "Willkommen",
      DialogExplorer: "Dialog-Explorer",
      Updateeventdetailsfor: "Veranstaltungsdetails aktualisieren für",
      AddnewFlirtTooleventhere: "Hier neues FlirtTool-Ereignis hinzufügen",
      SaveEvent: "Veranstaltung speichern",
      AddanewEvent: "Neue Veranstaltung hinzufügen",
      Addnewchateventhere: "Hier neues Chat-Ereignis hinzufügen",
      eventcover: "Veranstaltungscover",
      cover: "Cover",
      Active: "Aktiv",
      EventName: "Veranstaltungsname",
      FirstName: "Vorname",
      LastName: "Nachname",
      AddEvents: "Veranstaltungen hinzufügen",
      AddFakeAccount: "Falsches Konto hinzufügen",
      Close: "Schließen",
      Profile: "Profil",
      ProfilePic: "Profilbild",
      Gender: "Geschlecht",
      UpdateProfilePic: "Profilbild aktualisieren",
      Noprofilephotos: "Keine Profilfotos",
      Looks: "Aussehen",
      LifeStyle: "Lebensstil",
      EmailUsername: "E-Mail oder Benutzername",
      Username: "Benutzername",
      Overview: "Übersicht",
      Thisweek: "Diese Woche",
      Earnings: "Einnahmen",
      MontlyStatistics: "Monatliche Statistiken",
      Login: "Anmelden",
      Addnewmoderator: "Neuen Moderator hinzufügen",
      Enterinformationhere: "Geben Sie hier die Informationen ein",
      AddModerator: "Moderator hinzufügen",
      Updatepackage: "Paket aktualisieren",
      Enterpackageinformationhere: "Geben Sie hier die Paketinformationen ein",
      packagecover: "Paketcover",
      Addnewpackage: "Neues Paket hinzufügen",
      Thepackagehasatimerange: "Das Paket hat einen Zeitbereich",
      PackageManagement: "Paketmanagement",
      Thiswherewemanageandupdateflirtsorpackages:
        "Hier verwalten und aktualisieren wir Flirts oder Pakete",
      AddNew: "Neu hinzufügen",
      RoleSettings: "Rollen Einstellungen",
      AddnewModeratedAccount: "Neues moderiertes Konto hinzufügen",
      Enterinformationhere: "Geben Sie hier die Informationen ein",
      Save: "Speichern",
      SendMassPoke: "Massen-Poke senden",
      YourPOKESwillgoouttotheseusers: "Deine POKES werden an diese Benutzer gesendet",
      SendMassLikes: "Massen-Likes senden",
      YourLIKESwillgoouttotheseusers: "Deine LIKES werden an diese Benutzer gesendet",
      SendMassSpam: "Massen-Spam senden",
      Yourmessagewillgoouttotheseusers:
        "Deine Nachricht wird an diese Benutzer gesendet",
      SendSpam: "Spam senden",
      SendMassEmail: "Massen-E-Mail senden",
      Emailmessageswillgoouttotheseusers:
        "E-Mail-Nachrichten werden an diese Benutzer gesendet",
      SendEmail: "E-Mail senden",

      Sendmessageandreachouttousershere:
        "Senden Sie Nachrichten und erreichen Sie Benutzer hier",
      Search: "Suche",
      Unknown: "Unbekannt",
      NOTSET: "NICHT FESTGELEGT",
      Youruseraccounts: "Ihre Benutzerkonten",
      SellingNudes: "Verkauf von Nacktbildern",
      SellingGifts: "Verkauf von Geschenken",
      UpdatePhotos: "Fotos aktualisieren",
      Agree: "Zustimmen",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Sie wurden vom Administrator gewarnt. Bitte stimmen Sie zu, dass dies nicht wieder passieren wird",
      Senddirectchatmessage: "Direkte Chatnachricht senden",
      Dashboard: "Dashboard",
    },

    Turkish: {
      Job: "İş",
      WorkingSchedule: "Çalışma Programı",
      RelationshipStatus: "İlişki Durumu",
      Searchingfor: "Arıyor",
      pastRelationships: "Geçmiş İlişkiler",
      TraitsforIdealPartner: "İdeal Partner Özellikleri",
      FuturePlanswithPartner: "Partnerle Gelecek Planları",
      name: "İsim",
      location: "Konum",
      birthDay: "Doğum Günü",
      SellingImages: "Resimleri Satma",
      Images: "Resimler",
      AgentID: "Ajan Kimliği",
      Actions: "Eylemler",
      Rank: "Sıra",
      EventPrice: "Etkinlik Fiyatı",
      Answers: "Cevaplar",
      Operator: "Operatör",
      Settings: "Ayarlar",
      HoursSpent: "Harcanan Saatler",
      SendMessage: "Mesaj Gönder",
      Messages: "Mesajlar",
      EventDetail: "Etkinlik Detayı",
      Audience: "Hedef Kitle",
      EventDate: "Etkinlik Tarihi",
      ActiveStatus: "Aktif Durum",
      CreatedAt: "Oluşturulma Tarihi",
      Location: "Konum",
      PackageType: "Paket Türü",
      FlirtsAmount: "Flört Miktarı",
      CreatedDate: "Oluşturma Tarihi",
      Price: "Fiyat",
      Total: "Toplam",
      PackageName: "Paket Adı",
      PackagesDescription: "Paket Açıklaması",
      AccountType: "Hesap Türü",
      OnlineStatus: "Çevrimiçi Durum",
      PaymentDate: "Ödeme Tarihi",
      SelectFake: "Sahte Seç",
      Moderators: "Moderatörler",
      Conversions: "Dönüşümler",
      FullName: "Tam Adı",
      Category: "Kategori",
      LastWeek: "Geçen Hafta",
      RealTime: "Gerçek Zamanlı",
      AnswerRate: "Cevap Oranı",
      ModeratorTabs: "Moderatör Sekmeleri",
      Messagesrecieved: "Alınan Mesajlar (GİRİŞ)",
      Lengthoffirstmesssage: "İlk Mesaj Uzunluğu",
      MessagesSent: "Gönderilen Mesajlar (ÇIKIŞ)",
      ShowOverlay: "Örtüyü Göster",
      Gallery: "Galeri",
      Statistics: "İstatistikler",
      Community: "Topluluk",
      Coupons: "Kuponlar",
      Packages: "Paketler",
      Transfer: "Transfer",
      SKIP: "GEÇ",
      Today: "Bugün",
      LasWeek: "Geçen Hafta",
      ThisWeek: "Bu Hafta",
      ThisMonth: "Bu Ay",
      LastMonth: "Geçen Ay",
      MessageReported: "Mesaj Bildirildi",
      report: "Rapor",
      TimeElapsed: "Geçen Zaman",
      Affilates: "Ortaklar",
      Finance: "Finans",
      Events: "Etkinlikler",
      Promotion: "Kullanıcılar",
      Status: "Durum",
      FakeAccounts: "Sahte Hesaplar",
      AgentOverview: "Ajan Genel Bakış",
      LiveMonitoring: "Canlı İzleme",
      MessageStatistics: "Mesaj İstatistikleri",
      ActivityAnalysis: "Aktivite Analizi",
      AgentActivity: "Ajan Aktivitesi",
      ChatModeration: "Sohbet Moderasyonu",
      Reportauser: "Kullanıcıyı Bildir",
      startChatting: "Sohbete Başla",
      UserProfile: "Kullanıcı Profili",
      Change: "Değiştir",
      Owner: "Sahip",
      SaveChanges: "Değişiklikleri Kaydet",
      UpdateAffilateData: "Ortak Verilerini Güncelle",
      AddAffilate: "Ortak Ekle",
      addnewaffilite: "Yeni ortak/oluşturucu/etkileyici buraya ekle",
      AffilatesManagement: "Ortak Yönetimi",
      Cancel: "İptal",
      OK: "Tamam",
      Youhavebeenblocked: "Engellendiniz",
      NoPoolsFoundPleaseRefreshinaWhile: "Havuz Bulunamadı. Lütfen bir süre sonra yenileyin",
      GenerateVoucher: "Kupon Oluştur",
      AddCoupons: "Kupon Ekle",
      createnewcouponshere: "Yeni kuponlar burada oluşturulur",
      Remove: "Kaldır",
      Onetime: "Bir kez",
      Welcome: "Hoş Geldiniz",
      DialogExplorer: "Diyalog Gezgini",
      Updateeventdetailsfor: "Şu etkinlik için etkinlik detaylarını güncelle",
      AddnewFlirtTooleventhere: "Burada yeni FlirtTool etkinliği ekle",
      SaveEvent: "Etkinliği Kaydet",
      AddanewEvent: "Yeni Bir Etkinlik Ekleyin",
      Addnewchateventhere: "Burada yeni sohbet etkinliği ekle",
      eventcover: "etkinlik kapağı",
      cover: "kapağın",
      Active: "Aktif",
      EventName: "Etkinlik Adı",
      FirstName: "Ad",
      LastName: "Soyad",
      AddEvents: "Etkinlikler Ekle",
      AddFakeAccount: "Sahte Hesap Ekle",
      Close: "Kapat",
      Profile: "Profil",
      ProfilePic: "Profil Resmi",
      Gender: "Cinsiyet",
      UpdateProfilePic: "Profil Resmini Güncelle",
      Noprofilephotos: "Profil Fotoğrafı Yok",
      Looks: "Görünüm",
      LifeStyle: "Yaşam Tarzı",
      EmailUsername: "E-Posta veya Kullanıcı Adı",
      Username: "Kullanıcı Adı",
      Overview: "Genel Bakış",
      Thisweek: "Bu hafta",
      Earnings: "Kazançlar",
      MontlyStatistics: "Aylık İstatistikler",
      Login: "Giriş",
      Addnewmoderator: "Yeni Moderatör Ekle",
      Enterinformationhere: "Buraya bilgileri girin",
      AddModerator: "Moderatör Ekle",
      Updatepackage: "Paket Güncelle",
      Enterpackageinformationhere: "Paket bilgilerini buraya girin",
      packagecover: "Paket Kapak",
      Addnewpackage: "Yeni Paket Ekle",
      Thepackagehasatimerange: "Paket bir zaman aralığına sahip",
      PackageManagement: "Paket Yönetimi",
      Thiswherewemanageandupdateflirtsorpackages:
        "Burada flörtleri veya paketleri yönetir ve güncelleriz",
      AddNew: "Yeni Ekle",
      RoleSettings: "Rol Ayarları",
      AddnewModeratedAccount: "Yeni Moderatörlü Hesap Ekle",
      Enterinformationhere: "Buraya bilgileri girin",
      Save: "Kaydet",
      SendMassPoke: "Toplu Poke Gönder",
      YourPOKESwillgoouttotheseusers: "POKELERİNİZ bu kullanıcılara gönderilecek",
      SendMassLikes: "Toplu Beğeni Gönder",
      YourLIKESwillgoouttotheseusers: "BEĞENİLERİNİZ bu kullanıcılara gönderilecek",
      SendMassSpam: "Toplu Spam Gönder",
      Yourmessagewillgoouttotheseusers:
        "MESAJINIZ bu kullanıcılara gönderilecek",
      SendSpam: "Spam Gönder",
      SendMassEmail: "Toplu E-Posta Gönder",
      Emailmessageswillgoouttotheseusers:
        "E-Posta mesajları bu kullanıcılara gönderilecek",
      SendEmail: "E-Posta Gönder",

      Sendmessageandreachouttousershere:
        "Mesaj gönder ve kullanıcılara buradan ulaş",
      Search: "Arama",
      Unknown: "Bilinmiyor",
      NOTSET: "AYARLANMADI",
      Youruseraccounts: "Kullanıcı hesaplarınız",
      SellingNudes: "Çıplak Fotoğraflar Satma",
      SellingGifts: "Hediyeler Satma",
      UpdatePhotos: "Fotoğrafları Güncelle",
      Agree: "Kabul et",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Yönetici tarafından uyarıldınız. Lütfen bunun tekrar olmayacağına dair anlaşmayı kabul edin",
      Senddirectchatmessage: "Doğrudan sohbet mesajı gönder",
      Dashboard: "Gösterge Paneli",
    },
    france: {
      Job: "Emploi",
WorkingSchedule: "Horaires de Travail",
RelationshipStatus: "Statut de la Relation",
Searchingfor: "Recherche",
pastRelationships: "Relations Passées",
TraitsforIdealPartner: "Traits du Partenaire Idéal",
FuturePlanswithPartner: "Plans Futurs avec le Partenaire",
      name: "Nom",
      location: "Emplacement",
      birthDay: "Date de Naissance",
      SellingImages: "Vente d'images",
      Images: "Images",
      AgentID: "Identifiant de l'agent",
      Actions: "Actions",
      Rank: "Classement",
      EventPrice: "Prix de l'événement",
      Answers: "Réponses",
      Operator: "Opérateur",
      Settings: "Paramètres",
      HoursSpent: "Heures passées",
      SendMessage: "Envoyer un message",
      Messages: "Messages",
      EventDetail: "Détail de l'événement",
      Audience: "Public",
      EventDate: "Date de l'événement",
      ActiveStatus: "Statut actif",
      CreatedAt: "Créé à",
      Location: "Emplacement",
      PackageType: "Type de package",
      FlirtsAmount: "Nombre de flirts",
      CreatedDate: "Date de création",
      Price: "Prix",
      Total: "Total",
      PackageName: "Nom du package",
      PackagesDescription: "Description des packages",
      AccountType: "Type de compte",
      OnlineStatus: "Statut en ligne",
      PaymentDate: "Date de paiement",
      SelectFake: "Sélectionner Faux",
      Moderators: "Modérateurs",
      Conversions: "Conversions",
      FullName: "Nom complet",
      Category: "Catégorie",
      LastWeek: "Semaine dernière",
      RealTime: "Temps réel",
      AnswerRate: "Taux de réponse",
      ModeratorTabs: "Onglets du modérateur",
      Messagesrecieved: "Messages reçus (entrants)",
      Lengthoffirstmesssage: "Longueur du premier message",
      MessagesSent: "Messages envoyés (sortants)",
      ShowOverlay: "Afficher le superposition",
      Gallery: "Galerie",
      Statistics: "Statistiques",
      Community: "Communauté",
      Coupons: "Coupons",
      Packages: "Forfaits",
      Transfer: "Transfert",
      SKIP: "SAUTER",
      Today: "Aujourd'hui",
      LasWeek: "Semaine dernière",
      ThisWeek: "Cette semaine",
      ThisMonth: "Ce mois-ci",
      LastMonth: "Mois dernier",
      MessageReported: "Message signalé",
      report: "Rapport",
      TimeElapsed: "Temps écoulé",
      Affilates: "Affiliés",
      Finance: "Finance",
      Events: "Événements",
      Promotion: "Utilisateurs",
      Status: "Statut",
      FakeAccounts: "Comptes Fake",
      AgentOverview: "Aperçu de l'agent",
      LiveMonitoring: "Surveillance en direct",
      MessageStatistics: "Statistiques des messages",
      ActivityAnalysis: "Analyse d'activité",
      AgentActivity: "Activité de l'agent",
      ChatModeration: "Modération du chat",
      Reportauser: "Signaler un utilisateur",
      startChatting: "Commencer à discuter",
      UserProfile: "Profil de l'utilisateur",
      Change: "Changer",
      Owner: "Propriétaire",
      SaveChanges: "Sauvegarder les modifications",
      UpdateAffilateData: "Mettre à jour les données d'affilié",
      AddAffilate: "Ajouter un affilié",
      addnewaffilite: "Ajouter de nouveaux affiliés/créateurs/influenceurs ici",
      AffilatesManagement: "Gestion des affiliés",
      Cancel: "Annuler",
      OK: "OK",
      Youhavebeenblocked: "Vous avez été bloqué",
      NoPoolsFoundPleaseRefreshinaWhile: "Aucune piscine trouvée. Veuillez rafraîchir dans un moment",
      GenerateVoucher: "Générer un bon",
      AddCoupons: "Ajouter des coupons",
      createnewcouponshere: "Créer de nouveaux coupons ici",
      Remove: "Supprimer",
      Onetime: "Une fois",
      Welcome: "Bienvenue",
      DialogExplorer: "Explorateur de dialogues",
      Updateeventdetailsfor: "Mettre à jour les détails de l'événement pour",
      AddnewFlirtTooleventhere: "Ajouter un nouvel événement FlirtTool ici",
      SaveEvent: "Enregistrer l'événement",
      AddanewEvent: "Ajouter un nouvel événement",
      Addnewchateventhere: "Ajouter un nouvel événement de chat ici",
      eventcover: "couverture de l'événement",
      cover: "couverture",
      Active: "Actif",
      EventName: "Nom de l'événement",
      FirstName: "Prénom",
      LastName: "Nom de famille",
      AddEvents: "Ajouter des événements",
      AddFakeAccount: "Ajouter un faux compte",
      Close: "Fermer",
      Profile: "Profil",
      ProfilePic: "Photo de profil",
      Gender: "Genre",
      UpdateProfilePic: "Mettre à jour la photo de profil",
      Noprofilephotos: "Pas de photos de profil",
      Looks: "Apparence",
      LifeStyle: "Style de vie",
      EmailUsername: "E-mail ou Nom d'utilisateur",
      Username: "Nom d'utilisateur",
      Overview: "Vue d'ensemble",
      Thisweek: "Cette semaine",
      Earnings: "Gains",
      MontlyStatistics: "Statistiques mensuelles",
      Login: "Connexion",
      Addnewmoderator: "Ajouter un nouveau modérateur",
      Enterinformationhere: "Entrez les informations ici",
      AddModerator: "Ajouter un modérateur",
      Updatepackage: "Mettre à jour le package",
      Enterpackageinformationhere: "Entrez les informations sur le package ici",
      packagecover: "couverture du package",
      Addnewpackage: "Ajouter un nouveau package",
      Thepackagehasatimerange: "Le package a une plage horaire",
      PackageManagement: "Gestion des packages",
      Thiswherewemanageandupdateflirtsorpackages:
        "C'est ici que nous gérons et mettons à jour les flirts ou les packages",
      AddNew: "Ajouter un nouveau",
      RoleSettings: "Paramètres de rôle",
      AddnewModeratedAccount: "Ajouter un nouveau compte modéré",
      Enterinformationhere: "Entrez les informations ici",
      Save: "Enregistrer",
      SendMassPoke: "Envoyer un poke de masse",
      YourPOKESwillgoouttotheseusers: "Vos POKES seront envoyés à ces utilisateurs",
      SendMassLikes: "Envoyer des likes en masse",
      YourLIKESwillgoouttotheseusers: "Vos LIKES seront envoyés à ces utilisateurs",
      SendMassSpam: "Envoyer du spam en masse",
      Yourmessagewillgoouttotheseusers:
        "Votre message sera envoyé à ces utilisateurs",
      SendSpam: "Envoyer du spam",
      SendMassEmail: "Envoyer un e-mail en masse",
      Emailmessageswillgoouttotheseusers:
        "Les messages électroniques seront envoyés à ces utilisateurs",
      SendEmail: "Envoyer un e-mail",

      Sendmessageandreachouttousershere:
        "Envoyez un message et contactez les utilisateurs ici",
      Search: "Recherche",
      Unknown: "Inconnu",
      NOTSET: "NON DÉFINI",
      Youruseraccounts: "Vos comptes d'utilisateur",
      SellingNudes: "Vente de nus",
      SellingGifts: "Vente de cadeaux",
      UpdatePhotos: "Mettre à jour les photos",
      Agree: "D'accord",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Vous avez été averti par l'administrateur. Veuillez accepter que cela ne se reproduira pas",
      Senddirectchatmessage: "Envoyer un message de chat direct",
      Dashboard: "Tableau de bord",
    },
    spanisch: {
      name: "Nombre",
      location: "Ubicación",
      birthDay: "Cumpleaños",
      SellingImages: "Venta de Imágenes",
      Images: "Imágenes",
      AgentID: "ID de Agente",
      Actions: "Acciones",
      Rank: "Rango",
      EventPrice: "Precio del Evento",
      Answers: "Respuestas",
      Operator: "Operador",
      Settings: "Ajustes",
      HoursSpent: "Horas Gastadas",
      SendMessage: "Enviar Mensaje",
      Messages: "Mensajes",
      EventDetail: "Detalle del Evento",
      Audience: "Audiencia",
      EventDate: "Fecha del Evento",
      ActiveStatus: "Estado Activo",
      CreatedAt: "Creado En",
      Location: "Ubicación",
      PackageType: "Tipo de Paquete",
      FlirtsAmount: "Cantidad de Coqueteos",
      CreatedDate: "Fecha de Creación",
      Price: "Precio",
      Total: "Total",
      PackageName: "Nombre del Paquete",
      PackagesDescription: "Descripción de los Paquetes",
      AccountType: "Tipo de Cuenta",
      OnlineStatus: "Estado en Línea",
      PaymentDate: "Fecha de Pago",
      SelectFake: "Seleccionar Falso",
      Moderators: "Moderadores",
      Conversions: "Conversiones",
      FullName: "Nombre Completo",
      Category: "Categoría",
      LastWeek: "Semana Pasada",
      RealTime: "Tiempo Real",
      AnswerRate: "Tasa de Respuesta",
      ModeratorTabs: "Pestañas del Moderador",
      Messagesrecieved: "Mensajes Recibidos (ENTRADAS)",
      Lengthoffirstmesssage: "Longitud del Primer Mensaje",
      MessagesSent: "Mensajes Enviados (SALIDAS)",
      ShowOverlay: "Mostrar Superposición",
      Gallery: "Galería",
      Statistics: "Estadísticas",
      Community: "Comunidad",
      Coupons: "Cupones",
      Packages: "Paquetes",
      Transfer: "Transferencia",
      SKIP: "OMITIR",
      Today: "Hoy",
      LasWeek: "Semana Pasada",
      ThisWeek: "Esta Semana",
      ThisMonth: "Este Mes",
      LastMonth: "Mes Pasado",
      MessageReported: "Mensaje Reportado",
      report: "Reportar",
      TimeElapsed: "Tiempo Transcurrido",
      Affilates: "Afiliados",
      Finance: "Finanzas",
      Events: "Eventos",
      Promotion: "Usuarios",
      Status: "Estado",
      FakeAccounts: "Cuentas Falsas",
      AgentOverview: "Resumen del Agente",
      LiveMonitoring: "Monitoreo en Vivo",
      MessageStatistics: "Estadísticas de Mensajes",
      ActivityAnalysis: "Análisis de Actividad",
      AgentActivity: "Actividad del Agente",
      ChatModeration: "Moderación del Chat",
      Reportauser: "Reportar un Usuario",
      startChatting: "Empezar a Chatear",
      UserProfile: "Perfil de Usuario",
      Change: "Cambiar",
      Owner: "Propietario",
      SaveChanges: "Guardar Cambios",
      UpdateAffilateData: "Actualizar Datos de Afiliado",
      AddAffilate: "Agregar Afiliado",
      addnewaffilite: "Agregar nuevos afiliados/creadores/influenciadores aquí",
      AffilatesManagement: "Gestión de Afiliados",
      Cancel: "Cancelar",
      OK: "Aceptar",
      Youhavebeenblocked: "Has sido Bloqueado",
      NoPoolsFoundPleaseRefreshinaWhile: "No se Encontraron Piscinas. Por favor, Refresca en un Momento",
      GenerateVoucher: "Generar Vale",
      AddCoupons: "Agregar Cupones",
      createnewcouponshere: "Crea nuevos cupones aquí",
      Remove: "Eliminar",
      Onetime: "Una Vez",
      Welcome: "Bienvenido",
      DialogExplorer: "Explorador de Diálogos",
      Updateeventdetailsfor: "Actualizar detalles del evento para",
      AddnewFlirtTooleventhere: "Agregar nuevo evento de FlirtTool aquí",
      SaveEvent: "Guardar Evento",
      AddanewEvent: "Agregar un Nuevo Evento",
      Addnewchateventhere: "Agregar nuevo evento de chat aquí",
      eventcover: "portada del evento",
      cover: "cubierta",
      Active: "Activo",
      EventName: "Nombre del Evento",
      FirstName: "Nombre",
      LastName: "Apellido",
      AddEvents: "Agregar Eventos",
      AddFakeAccount: "Agregar Cuenta Falsa",
      Close: "Cerrar",
      Profile: "Perfil",
      ProfilePic: "Foto de Perfil",
      Gender: "Género",
      UpdateProfilePic: "Actualizar Foto de Perfil",
      Noprofilephotos: "Sin Fotos de Perfil",
      Looks: "Apariencia",
      LifeStyle: "Estilo de Vida",
      EmailUsername: "Correo Electrónico o Nombre de Usuario",
      Username: "Nombre de Usuario",
      Overview: "Resumen",
      Thisweek: "Esta semana",
      Earnings: "Ganancias",
      MontlyStatistics: "Estadísticas Mensuales",
      Login: "Iniciar Sesión",
      Addnewmoderator: "Agregar Nuevo Moderador",
      Enterinformationhere: "Ingrese la información aquí",
      AddModerator: "Agregar Moderador",
      Updatepackage: "Actualizar Paquete",
      Enterpackageinformationhere: "Ingrese la información del paquete aquí",
      packagecover: "portada del paquete",
      Addnewpackage: "Agregar Nuevo Paquete",
      Thepackagehasatimerange: "El paquete tiene un rango de tiempo",
      PackageManagement: "Gestión de Paquetes",
      Thiswherewemanageandupdateflirtsorpackages:
        "Aquí es donde gestionamos y actualizamos coqueteos o paquetes",
      AddNew: "Agregar Nuevo",
      RoleSettings: "Configuración de Rol",
      AddnewModeratedAccount: "Agregar Nueva Cuenta Moderada",
      Enterinformationhere: "Ingrese la información aquí",
      Save: "Guardar",
      SendMassPoke: "Enviar Poke Masivo",
      YourPOKESwillgoouttotheseusers: "Tus POKES se enviarán a estos usuarios",
      SendMassLikes: "Enviar Me Gusta Masivos",
      YourLIKESwillgoouttotheseusers: "Tus ME GUSTA se enviarán a estos usuarios",
      SendMassSpam: "Enviar Spam Masivo",
      Yourmessagewillgoouttotheseusers:
        "Tu mensaje se enviará a estos usuarios",
      SendSpam: "Enviar Spam",
      SendMassEmail: "Enviar Correo Electrónico Masivo",
      Emailmessageswillgoouttotheseusers:
        "Los mensajes de correo electrónico se enviarán a estos usuarios",
      SendEmail: "Enviar Correo Electrónico",

      Sendmessageandreachouttousershere:
        "Enviar mensaje y comunicarse con los usuarios aquí",
      Search: "Buscar",
      Unknown: "Desconocido",
      NOTSET: "NO ESTABLECIDO",
      Youruseraccounts: "Tus Cuentas de Usuario",
      SellingNudes: "Venta de Desnudos",
      SellingGifts: "Venta de Regalos",
      UpdatePhotos: "Actualizar Fotos",
      Agree: "Aceptar",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Has sido advertido por el administrador. Por favor, acepta que esto no volverá a suceder",
      Senddirectchatmessage: "Enviar Mensaje de Chat Directo",
      Dashboard: "Tablero",
    },

    // 	Mandarin-Chinesisch
    Italia: {
      name: "Nome",
      location: "Posizione",
      birthDay: "Data di Nascita",
      SellingImages: "Vendita di Immagini",
      Images: "Immagini",
      AgentID: "ID Agente",
      Actions: "Azioni",
      Rank: "Grado",
      EventPrice: "Prezzo dell'Evento",
      Answers: "Risposte",
      Operator: "Operatore",
      Settings: "Impostazioni",
      HoursSpent: "Ore Trascorse",
      SendMessage: "Invia Messaggio",
      Messages: "Messaggi",
      EventDetail: "Dettaglio dell'Evento",
      Audience: "Pubblico",
      EventDate: "Data dell'Evento",
      ActiveStatus: "Stato Attivo",
      CreatedAt: "Creato il",
      Location: "Posizione",
      PackageType: "Tipo di Pacchetto",
      FlirtsAmount: "Quantità di Flirt",
      CreatedDate: "Data di Creazione",
      Price: "Prezzo",
      Total: "Totale",
      PackageName: "Nome del Pacchetto",
      PackagesDescription: "Descrizione dei Pacchetti",
      AccountType: "Tipo di Account",
      OnlineStatus: "Stato Online",
      PaymentDate: "Data di Pagamento",
      SelectFake: "Seleziona Falso",
      Moderators: "Moderatori",
      Conversions: "Conversioni",
      FullName: "Nome Completo",
      Category: "Categoria",
      LastWeek: "Settimana Scorsa",
      RealTime: "Tempo Reale",
      AnswerRate: "Tasso di Risposta",
      ModeratorTabs: "Tab Moderatore",
      Messagesrecieved: "Messaggi Ricevuti (ENTRATA)",
      Lengthoffirstmesssage: "Lunghezza del Primo Messaggio",
      MessagesSent: "Messaggi Inviati (USCITA)",
      ShowOverlay: "Mostra Sovrapposizione",
      Gallery: "Galleria",
      Statistics: "Statistiche",
      Community: "Comunità",
      Coupons: "Coupon",
      Packages: "Pacchetti",
      Transfer: "Trasferimento",
      SKIP: "SALTA",
      Today: "Oggi",
      LasWeek: "Settimana Scorsa",
      ThisWeek: "Questa Settimana",
      ThisMonth: "Questo Mese",
      LastMonth: "Mese Scorso",
      MessageReported: "Messaggio Segnalato",
      report: "Segnala",
      TimeElapsed: "Tempo Trascorso",
      Affilates: "Affiliati",
      Finance: "Finanza",
      Events: "Eventi",
      Promotion: "Utenti",
      Status: "Stato",
      FakeAccounts: "Account Falsi",
      AgentOverview: "Panoramica Agente",
      LiveMonitoring: "Monitoraggio Live",
      MessageStatistics: "Statistiche dei Messaggi",
      ActivityAnalysis: "Analisi dell'Attività",
      AgentActivity: "Attività Agente",
      ChatModeration: "Moderazione della Chat",
      Reportauser: "Segnala un Utente",
      startChatting: "Inizia a Chattare",
      UserProfile: "Profilo Utente",
      Change: "Cambia",
      Owner: "Proprietario",
      SaveChanges: "Salva Modifiche",
      UpdateAffilateData: "Aggiorna Dati Affiliato",
      AddAffilate: "Aggiungi Affiliato",
      addnewaffilite: "Aggiungi nuovi affiliati/creatori/influencer qui",
      AffilatesManagement: "Gestione Affiliati",
      Cancel: "Annulla",
      OK: "OK",
      Youhavebeenblocked: "Sei stato Bloccato",
      NoPoolsFoundPleaseRefreshinaWhile: "Nessuna Piscina Trovata. Si prega di Aggiornare tra un Momento",
      GenerateVoucher: "Genera Voucher",
      AddCoupons: "Aggiungi Coupon",
      createnewcouponshere: "Crea nuovi coupon qui",
      Remove: "Rimuovi",
      Onetime: "Una Volta",
      Welcome: "Benvenuto",
      DialogExplorer: "Esploratore di Dialoghi",
      Updateeventdetailsfor: "Aggiorna dettagli evento per",
      AddnewFlirtTooleventhere: "Aggiungi nuovo evento FlirtTool qui",
      SaveEvent: "Salva Evento",
      AddanewEvent: "Aggiungi un Nuovo Evento",
      Addnewchateventhere: "Aggiungi nuovo evento chat qui",
      eventcover: "copertina dell'evento",
      cover: "copertina",
      Active: "Attivo",
      EventName: "Nome dell'Evento",
      FirstName: "Nome",
      LastName: "Cognome",
      AddEvents: "Aggiungi Eventi",
      AddFakeAccount: "Aggiungi Account Falso",
      Close: "Chiudi",
      Profile: "Profilo",
      ProfilePic: "Foto Profilo",
      Gender: "Genere",
      UpdateProfilePic: "Aggiorna Foto Profilo",
      Noprofilephotos: "Nessuna Foto Profilo",
      Looks: "Aspetto",
      LifeStyle: "Stile di Vita",
      EmailUsername: "Email o Nome Utente",
      Username: "Nome Utente",
      Overview: "Panoramica",
      Thisweek: "Questa settimana",
      Earnings: "Guadagni",
      MontlyStatistics: "Statistiche Mensili",
      Login: "Accesso",
      Addnewmoderator: "Aggiungi Nuovo Moderatore",
      Enterinformationhere: "Inserisci informazioni qui",
      AddModerator: "Aggiungi Moderatore",
      Updatepackage: "Aggiorna Pacchetto",
      Enterpackageinformationhere: "Inserisci informazioni pacchetto qui",
      packagecover: "copertina pacchetto",
      Addnewpackage: "Aggiungi Nuovo Pacchetto",
      Thepackagehasatimerange: "Il pacchetto ha un intervallo di tempo",
      PackageManagement: "Gestione Pacchetti",
      Thiswherewemanageandupdateflirtsorpackages:
        "Qui è dove gestiamo e aggiorniamo flirt o pacchetti",
      AddNew: "Aggiungi Nuovo",
      RoleSettings: "Impostazioni Ruolo",
      AddnewModeratedAccount: "Aggiungi Nuovo Account Moderato",
      Enterinformationhere: "Inserisci informazioni qui",
      Save: "Salva",
      SendMassPoke: "Invia Poke di Massa",
      YourPOKESwillgoouttotheseusers: "I tuoi POKES saranno inviati a questi utenti",
      SendMassLikes: "Invia Like di Massa",
      YourLIKESwillgoouttotheseusers: "I tuoi LIKE saranno inviati a questi utenti",
      SendMassSpam: "Invia Spam di Massa",
      Yourmessagewillgoouttotheseusers:
        "Il tuo messaggio sarà inviato a questi utenti",
      SendSpam: "Invia Spam",
      SendMassEmail: "Invia Email di Massa",
      Emailmessageswillgoouttotheseusers:
        "I messaggi di posta elettronica saranno inviati a questi utenti",
      SendEmail: "Invia Email",

      Sendmessageandreachouttousershere:
        "Invia messaggio e contatta gli utenti qui",
      Search: "Cerca",
      Unknown: "Sconosciuto",
      NOTSET: "NON IMPOSTATO",
      Youruseraccounts: "I tuoi account utente",
      SellingNudes: "Vendita di Nudi",
      SellingGifts: "Vendita di Regali",
      UpdatePhotos: "Aggiorna Foto",
      Agree: "Accetta",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Sei stato avvisato dall'amministratore. Ti preghiamo di accettare che ciò non accadrà più",
      Senddirectchatmessage: "Invia Messaggio di Chat Diretta",
      Dashboard: "Dashboard",
    },

    // 	Mandarin-Chinesisch

    // Hindi
    portuguese: {
      Job: "Trabalho",
WorkingSchedule: "Horário de Trabalho",
RelationshipStatus: "Status de Relacionamento",
Searchingfor: "Procurando",
pastRelationships: "Relacionamentos Passados",
TraitsforIdealPartner: "Características do Parceiro Ideal",
FuturePlanswithPartner: "Planos Futuros com o Parceiro",
      name: "Nome",
      location: "Localização",
      birthDay: "Data de Nascimento",
      SellingImages: "Venda de Imagens",
      Images: "Imagens",
      AgentID: "ID do Agente",
      Actions: "Ações",
      Rank: "Classificação",
      EventPrice: "Preço do Evento",
      Answers: "Respostas",
      Operator: "Operador",
      Settings: "Configurações",
      HoursSpent: "Horas Gastas",
      SendMessage: "Enviar Mensagem",
      Messages: "Mensagens",
      EventDetail: "Detalhe do Evento",
      Audience: "Público",
      EventDate: "Data do Evento",
      ActiveStatus: "Status Ativo",
      CreatedAt: "Criado Em",
      Location: "Localização",
      PackageType: "Tipo de Pacote",
      FlirtsAmount: "Quantidade de Flertes",
      CreatedDate: "Data de Criação",
      Price: "Preço",
      Total: "Total",
      PackageName: "Nome do Pacote",
      PackagesDescription: "Descrição dos Pacotes",
      AccountType: "Tipo de Conta",
      OnlineStatus: "Status Online",
      PaymentDate: "Data de Pagamento",
      SelectFake: "Selecionar Falso",
      Moderators: "Moderadores",
      Conversions: "Conversões",
      FullName: "Nome Completo",
      Category: "Categoria",
      LastWeek: "Semana Passada",
      RealTime: "Tempo Real",
      AnswerRate: "Taxa de Resposta",
      ModeratorTabs: "Abas do Moderador",
      Messagesrecieved: "Mensagens Recebidas (ENTRADAS)",
      Lengthoffirstmesssage: "Comprimento da Primeira Mensagem",
      MessagesSent: "Mensagens Enviadas (SAÍDAS)",
      ShowOverlay: "Mostrar Sobreposição",
      Gallery: "Galeria",
      Statistics: "Estatísticas",
      Community: "Comunidade",
      Coupons: "Cupons",
      Packages: "Pacotes",
      Transfer: "Transferência",
      SKIP: "PULAR",
      Today: "Hoje",
      LasWeek: "Semana Passada",
      ThisWeek: "Esta Semana",
      ThisMonth: "Este Mês",
      LastMonth: "Mês Passado",
      MessageReported: "Mensagem Reportada",
      report: "Reportar",
      TimeElapsed: "Tempo Decorrido",
      Affilates: "Afiliados",
      Finance: "Finanças",
      Events: "Eventos",
      Promotion: "Usuários",
      Status: "Status",
      FakeAccounts: "Contas Falsas",
      AgentOverview: "Visão Geral do Agente",
      LiveMonitoring: "Monitoramento ao Vivo",
      MessageStatistics: "Estatísticas de Mensagens",
      ActivityAnalysis: "Análise de Atividade",
      AgentActivity: "Atividade do Agente",
      ChatModeration: "Moderação de Chat",
      Reportauser: "Reportar um Usuário",
      startChatting: "Iniciar Conversa",
      UserProfile: "Perfil do Usuário",
      Change: "Alterar",
      Owner: "Proprietário",
      SaveChanges: "Salvar Alterações",
      UpdateAffilateData: "Atualizar Dados de Afiliado",
      AddAffilate: "Adicionar Afiliado",
      addnewaffilite: "Adicionar novos afiliados/criadores/influenciadores aqui",
      AffilatesManagement: "Gerenciamento de Afiliados",
      Cancel: "Cancelar",
      OK: "OK",
      Youhavebeenblocked: "Você foi Bloqueado",
      NoPoolsFoundPleaseRefreshinaWhile: "Nenhuma Piscina Encontrada. Por favor, Atualize em Breve",
      GenerateVoucher: "Gerar Cupom",
      AddCoupons: "Adicionar Cupons",
      createnewcouponshere: "Crie novos cupons aqui",
      Remove: "Remover",
      Onetime: "Única Vez",
      Welcome: "Bem-vindo",
      DialogExplorer: "Explorador de Diálogo",
      Updateeventdetailsfor: "Atualizar detalhes do evento para",
      AddnewFlirtTooleventhere: "Adicionar novo evento FlirtTool aqui",
      SaveEvent: "Salvar Evento",
      AddanewEvent: "Adicionar um Novo Evento",
      Addnewchateventhere: "Adicionar novo evento de chat aqui",
      eventcover: "capa do evento",
      cover: "capa",
      Active: "Ativo",
      EventName: "Nome do Evento",
      FirstName: "Nome",
      LastName: "Sobrenome",
      AddEvents: "Adicionar Eventos",
      AddFakeAccount: "Adicionar Conta Falsa",
      Close: "Fechar",
      Profile: "Perfil",
      ProfilePic: "Foto de Perfil",
      Gender: "Gênero",
      UpdateProfilePic: "Atualizar Foto de Perfil",
      Noprofilephotos: "Sem Fotos de Perfil",
      Looks: "Aparência",
      LifeStyle: "Estilo de Vida",
      EmailUsername: "E-mail ou Nome de Usuário",
      Username: "Nome de Usuário",
      Overview: "Visão Geral",
      Thisweek: "Esta Semana",
      Earnings: "Ganhos",
      MontlyStatistics: "Estatísticas Mensais",
      Login: "Entrar",
      Addnewmoderator: "Adicionar Novo Moderador",
      Enterinformationhere: "Digite as informações aqui",
      AddModerator: "Adicionar Moderador",
      Updatepackage: "Atualizar Pacote",
      Enterpackageinformationhere: "Digite as informações do pacote aqui",
      packagecover: "capa do pacote",
      Addnewpackage: "Adicionar Novo Pacote",
      Thepackagehasatimerange: "O pacote tem um intervalo de tempo",
      PackageManagement: "Gerenciamento de Pacotes",
      Thiswherewemanageandupdateflirtsorpackages:
        "Aqui é onde gerenciamos e atualizamos flertes ou pacotes",
      AddNew: "Adicionar Novo",
      RoleSettings: "Configurações de Papel",
      AddnewModeratedAccount: "Adicionar Nova Conta Moderada",
      Enterinformationhere: "Digite as informações aqui",
      Save: "Salvar",
      SendMassPoke: "Enviar Poke em Massa",
      YourPOKESwillgoouttotheseusers: "Seus POKES serão enviados para esses usuários",
      SendMassLikes: "Enviar Curtidas em Massa",
      YourLIKESwillgoouttotheseusers: "Suas CURTIDAS serão enviadas para esses usuários",
      SendMassSpam: "Enviar Spam em Massa",
      Yourmessagewillgoouttotheseusers:
        "Sua mensagem será enviada para esses usuários",
      SendSpam: "Enviar Spam",
      SendMassEmail: "Enviar E-mail em Massa",
      Emailmessageswillgoouttotheseusers:
        "As mensagens de e-mail serão enviadas para esses usuários",
      SendEmail: "Enviar E-mail",
      Promotion: "Usuários",
      Sendmessageandreachouttousershere:
        "Enviar mensagem e alcançar usuários aqui",
      Search: "Pesquisar",
      Unknown: "Desconhecido",
      NOTSET: "NÃO DEFINIDO",
      Youruseraccounts: "Suas Contas de Usuário",
      SellingNudes: "Venda de Nudes",
      SellingGifts: "Venda de Presentes",
      UpdatePhotos: "Atualizar Fotos",
      Agree: "Concordar",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Você foi avisado pelo administrador. Por favor, concorde que isso não acontecerá novamente",
      Senddirectchatmessage: "Enviar Mensagem de Chat Direto",
      Dashboard: "Painel",
    },
    // Hindi

    // Portugiesisch
    Schwedisch: {
      Job: "Jobb",
WorkingSchedule: "Arbetsschema",
RelationshipStatus: "Relationsstatus",
Searchingfor: "Söker",
pastRelationships: "Tidigare Relationer",
TraitsforIdealPartner: "Egenskaper för Idealisk Partner",
FuturePlanswithPartner: "Framtidsplaner med Partnern",
      name: "Namn",
      location: "Plats",
      birthDay: "Födelsedag",
      SellingImages: "Försäljning av bilder",
      Images: "Bilder",
      AgentID: "Agent ID",
      Actions: "Åtgärder",
      Rank: "Rankning",
      EventPrice: "Evenemangs pris",
      Answers: "Svar",
      Operator: "Operatör",
      Settings: "Inställningar",
      HoursSpent: "Timmar spenderade",
      SendMessage: "Skicka meddelande",
      Messages: "Meddelanden",
      EventDetail: "Evenemangsdetaljer",
      Audience: "Publik",
      EventDate: "Evenemangsdatum",
      ActiveStatus: "Aktiv status",
      CreatedAt: "Skapat den",
      Location: "Plats",
      PackageType: "Pakettyp",
      FlirtsAmount: "Flörtar antal",
      CreatedDate: "Skapat datum",
      Price: "Pris",
      Total: "Totalt",
      PackageName: "Paketets namn",
      PackagesDescription: "Paketbeskrivning",
      AccountType: "Kontotyp",
      OnlineStatus: "Online status",
      PaymentDate: "Betalningsdatum",
      SelectFake: "Välj falskt",
      Moderators: "Moderatorer",
      Conversions: "Konverteringar",
      FullName: "Fullständigt namn",
      Category: "Kategori",
      LastWeek: "Förra veckan",
      RealTime: "Real Tid",
      AnswerRate: "Svarsfrekvens",
      ModeratorTabs: "Moderatortabs",
      Messagesrecieved: "Meddelanden mottagna (INs)",
      Lengthoffirstmesssage: "Längd på första meddelandet",
      MessagesSent: "Meddelanden skickade (OUTs)",
      ShowOverlay: "Visa överlagring",
      Gallery: "Galleri",
      Statistics: "Statistik",
      Community: "Samhälle",
      Coupons: "Kuponger",
      Packages: "Paket",
      Transfer: "Överföring",
      SKIP: "HOPPA ÖVER",
      Today: "Idag",
      LasWeek: "Förra veckan",
      ThisWeek: "Denna vecka",
      ThisMonth: "Denna månad",
      LastMonth: "Förra månaden",
      MessageReported: "Meddelande rapporterat",
      report: "Rapportera",
      TimeElapsed: "Tid förfluten",
      Affilates: "Affiliates",
      Finance: "Finans",
      Events: "Händelser",

      Status: "Status",
      FakeAccounts: "Falska konton",
      AgentOverview: "Agentöversikt",
      LiveMonitoring: "Liveövervakning",
      MessageStatistics: "Meddelandestatistik",
      ActivityAnalysis: "Aktivitetsanalys",
      AgentActivity: "Agentaktivitet",
      ChatModeration: "Chattmoderation",
      Reportauser: "Rapportera en användare",
      startChatting: "Börja chatta",
      UserProfile: "Användarprofil",
      Change: "Ändra",
      Owner: "Ägare",
      SaveChanges: "Spara ändringar",
      UpdateAffilateData: "Uppdatera affilatdata",
      AddAffilate: "Lägg till affilat",
      addnewaffilite: "Lägg till nya affilat/skapare/influenser här",
      AffilatesManagement: "Affilathantering",
      Cancel: "Avbryt",
      OK: "OK",
      Youhavebeenblocked: "Du har blivit blockerad",
      NoPoolsFoundPleaseRefreshinaWhile: "Inga pooler hittades. Vänligen uppdatera om en stund",
      GenerateVoucher: "Generera kupong",
      AddCoupons: "Lägg till kuponger",
      createnewcouponshere: "Skapa nya kuponger här",
      Remove: "Ta bort",
      Onetime: "En gång",
      Welcome: "Välkommen",
      DialogExplorer: "Dialogutforskare",
      Updateeventdetailsfor: "Uppdatera händelsedetaljer för",
      AddnewFlirtTooleventhere: "Lägg till ny flirtverktygshändelse här",
      SaveEvent: "Spara händelse",
      AddanewEvent: "Lägg till en ny händelse",
      Addnewchateventhere: "Lägg till ny chathändelse här",
      eventcover: "evenemangsförpackning",
      cover: "omslag",
      Active: "Aktiv",
      EventName: "Händelsenamn",
      FirstName: "Förnamn",
      LastName: "Efternamn",
      AddEvents: "Lägg till händelser",
      AddFakeAccount: "Lägg till falskt konto",
      Close: "Stäng",
      Profile: "Profil",
      ProfilePic: "Profilbild",
      Gender: "Kön",
      UpdateProfilePic: "Uppdatera profilbild",
      Noprofilephotos: "Inga profilfoton",
      Looks: "Utseende",
      LifeStyle: "Livsstil",
      EmailUsername: "E-post eller användarnamn",
      Username: "Användarnamn",
      Overview: "Översikt",
      Thisweek: "Denna vecka",
      Earnings: "Intäkter",
      MontlyStatistics: "Månadsstatistik",
      Login: "Logga in",
      Addnewmoderator: "Lägg till ny moderator",
      Enterinformationhere: "Ange information här",
      AddModerator: "Lägg till moderator",
      Updatepackage: "Uppdatera paket",
      Enterpackageinformationhere: "Ange paketinformation här",
      packagecover: "paketomslag",
      Addnewpackage: "Lägg till nytt paket",
      Thepackagehasatimerange: "Paketet har ett tidsintervall",
      PackageManagement: "Paketledning",
      Thiswherewemanageandupdateflirtsorpackages:
        "Här hanterar och uppdaterar vi flörtar eller paket",
      AddNew: "Lägg till ny",
      RoleSettings: "Rollinställningar",
      AddnewModeratedAccount: "Lägg till ny modererat konto",
      Enterinformationhere: "Ange information här",
      Save: "Spara",
      SendMassPoke: "Skicka massiv knuff",
      YourPOKESwillgoouttotheseusers: "Dina KNUFFAR kommer att skickas till dessa användare",
      SendMassLikes: "Skicka massiva gilla",
      YourLIKESwillgoouttotheseusers: "Dina GILLANDE kommer att skickas till dessa användare",
      SendMassSpam: "Skicka massivt spam",
      Yourmessagewillgoouttotheseusers:
        "Ditt meddelande kommer att skickas till dessa användare",
      SendSpam: "Skicka spam",
      SendMassEmail: "Skicka massiva e-postmeddelanden",
      Emailmessageswillgoouttotheseusers:
        "E-postmeddelanden kommer att skickas till dessa användare",
      SendEmail: "Skicka e-post",
      Promotion: "Användare",
      Sendmessageandreachouttousershere:
        "Skicka meddelande och nå användare här",
      Search: "Sök",
      Unknown: "Okänd",
      NOTSET: "EJ INSTÄLLD",
      Youruseraccounts: "Dina användarkonton",
      SellingNudes: "Säljer Nudes",
      SellingGifts: "Säljer Presenter",
      UpdatePhotos: "Uppdatera foton",
      Agree: "Hålla med",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Du har varnats av administratören. Vänligen godkänn att detta inte kommer att hända igen",
      Senddirectchatmessage: "Skicka direkt chattmeddelande",
      Dashboard: "Instrumentpanel",
    },
    // Portugiesisch

    // Russisch
    Dänisch: {
      name: "Navn",
      location: "Placering",
      birthDay: "Fødselsdag",
      SellingImages: "Sælger billeder",
      Images: "Billeder",
      AgentID: "Agent ID",
      Actions: "Handlinger",
      Rank: "Rang",
      EventPrice: "Pris på begivenhed",
      Answers: "Svar",
      Operator: "Operatør",
      Settings: "Indstillinger",
      HoursSpent: "Timer brugt",
      SendMessage: "Send besked",
      Messages: "Beskeder",
      EventDetail: "Begivenhedsdetaljer",
      Audience: "Publikum",
      EventDate: "Begivenhedsdato",
      ActiveStatus: "Aktiv status",
      CreatedAt: "Oprettet den",
      Location: "Beliggenhed",
      PackageType: "Pakketype",
      FlirtsAmount: "Antal flirts",
      CreatedDate: "Oprettelsesdato",
      Price: "Pris",
      Total: "Total",
      PackageName: "Pakkenavn",
      PackagesDescription: "Pakkebeskrivelse",
      AccountType: "Kontotype",
      OnlineStatus: "Online-status",
      PaymentDate: "Betalingsdato",
      SelectFake: "Vælg falsk",
      Moderators: "Moderatorer",
      Conversions: "Konverteringer",
      FullName: "Fulde navn",
      Category: "Kategori",
      LastWeek: "Sidste uge",
      RealTime: "Real tid",
      AnswerRate: "Svarprocent",
      ModeratorTabs: "Moderatorfaner",
      Messagesrecieved: "Beskeder modtaget (IND)",
      Lengthoffirstmesssage: "Længde af første besked",
      MessagesSent: "Beskeder sendt (UD)",
      ShowOverlay: "Vis overlay",
      Gallery: "Galleri",
      Statistics: "Statistikker",
      Community: "Fællesskab",
      Coupons: "Kuponer",
      Packages: "Pakker",
      Transfer: "Overførsel",
      SKIP: "SPRING OVER",
      Today: "I dag",
      LasWeek: "Sidste uge",
      ThisWeek: "Denne uge",
      ThisMonth: "Denne måned",
      LastMonth: "Sidste måned",
      MessageReported: "Besked rapporteret",
      report: "Rapportere",
      TimeElapsed: "Tid forløbet",
      Affilates: "Affiliater",
      Finance: "Finanser",
      Events: "Begivenheder",

      Status: "Status",
      FakeAccounts: "Falske konti",
      AgentOverview: "Agentoversigt",
      LiveMonitoring: "Live overvågning",
      MessageStatistics: "Beskedstatistik",
      ActivityAnalysis: "Aktivitetsanalyse",
      AgentActivity: "Agentaktivitet",
      ChatModeration: "Chatmoderation",
      Reportauser: "Rapportere en bruger",
      startChatting: "Start chatten",
      UserProfile: "Brugerprofil",
      Change: "Skift",
      Owner: "Ejer",
      SaveChanges: "Gem ændringer",
      UpdateAffilateData: "Opdater affiliatdata",
      AddAffilate: "Tilføj affiliat",
      addnewaffilite: "Tilføj nye affilierede/kreatorer/influencere her",
      AffilatesManagement: "Affiliatehåndtering",
      Cancel: "Annuller",
      OK: "OK",
      Youhavebeenblocked: "Du er blevet blokeret",
      NoPoolsFoundPleaseRefreshinaWhile: "Ingen puljer fundet. Opdater venligst om et øjeblik",
      GenerateVoucher: "Generer kupon",
      AddCoupons: "Tilføj kuponer",
      createnewcouponshere: "Opret nye kuponer her",
      Remove: "Fjern",
      Onetime: "Én gang",
      Welcome: "Velkommen",
      DialogExplorer: "Dialogopdagelse",
      Updateeventdetailsfor: "Opdater begivenhedsoplysninger for",
      AddnewFlirtTooleventhere: "Tilføj ny flirtværktøjshændelse her",
      SaveEvent: "Gem begivenhed",
      AddanewEvent: "Tilføj en ny begivenhed",
      Addnewchateventhere: "Tilføj ny chathændelse her",
      eventcover: "begivenhedsomslag",
      cover: "omslag",
      Active: "Aktiv",
      EventName: "Begivenhedsnavn",
      FirstName: "Fornavn",
      LastName: "Efternavn",
      AddEvents: "Tilføj begivenheder",
      AddFakeAccount: "Tilføj falsk konto",
      Close: "Luk",
      Profile: "Profil",
      ProfilePic: "Profilbillede",
      Gender: "Køn",
      UpdateProfilePic: "Opdater profilbillede",
      Noprofilephotos: "Ingen profilbilleder",
      Looks: "Udseende",
      LifeStyle: "Livsstil",
      EmailUsername: "E-mail eller brugernavn",
      Username: "Brugernavn",
      Overview: "Oversigt",
      Thisweek: "Denne uge",
      Earnings: "Indtjening",
      MontlyStatistics: "Månedlig statistik",
      Login: "Log ind",
      Addnewmoderator: "Tilføj ny moderator",
      Enterinformationhere: "Indtast oplysninger her",
      AddModerator: "Tilføj moderator",
      Updatepackage: "Opdater pakke",
      Enterpackageinformationhere: "Indtast pakkeoplysninger her",
      packagecover: "pakkeomslag",
      Addnewpackage: "Tilføj ny pakke",
      Thepackagehasatimerange: "Pakken har et tidsinterval",
      PackageManagement: "Pakkehåndtering",
      Thiswherewemanageandupdateflirtsorpackages:
        "Her administrerer og opdaterer vi flirts eller pakker",
      AddNew: "Tilføj ny",
      RoleSettings: "Rolleindstillinger",
      AddnewModeratedAccount: "Tilføj nyt modereret konto",
      Enterinformationhere: "Indtast oplysninger her",
      Save: "Gem",
      SendMassPoke: "Send masse Poke",
      YourPOKESwillgoouttotheseusers: "Dine POKEs vil blive sendt til disse brugere",
      SendMassLikes: "Send masse Likes",
      YourLIKESwillgoouttotheseusers: "Dine LIKES vil blive sendt til disse brugere",
      SendMassSpam: "Send masse Spam",
      Yourmessagewillgoouttotheseusers:
        "Din besked vil blive sendt til disse brugere",
      SendSpam: "Send spam",
      SendMassEmail: "Send masse-e-mail",
      Emailmessageswillgoouttotheseusers:
        "E-mails vil blive sendt til disse brugere",
      SendEmail: "Send e-mail",
      Promotion: "Brugere",
      Sendmessageandreachouttousershere:
        "Send besked og nå ud til brugere her",
      Search: "Søg",
      Unknown: "Ukendt",
      NOTSET: "IKKE INDSTILLET",
      Youruseraccounts: "Dine brugerkonti",
      SellingNudes: "Sælger nøgenbilleder",
      SellingGifts: "Sælger gaver",
      UpdatePhotos: "Opdater fotos",
      Agree: "Enig",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Du er blevet advaret af admin. Vær venlig at acceptere, at dette ikke vil ske igen",
      Senddirectchatmessage: "Send direkte chatbesked",
      Dashboard: "Instrumentbræt",
    },

    // Russisch

    // Urdu

    finisch: {
      name: "Nimi",
      location: "Sijainti",
      birthDay: "Syntymäpäivä",
      SellingImages: "Kuvien myynti",
      Images: "Kuvat",
      AgentID: "Asiamies ID",
      Actions: "Toimet",
      Rank: "Sijoitus",
      EventPrice: "Tapahtuman hinta",
      Answers: "Vastaukset",
      Operator: "Operaattori",
      Settings: "Asetukset",
      HoursSpent: "Käytetyt tunnit",
      SendMessage: "Lähetä viesti",
      Messages: "Viestit",
      EventDetail: "Tapahtuman tiedot",
      Audience: "Yleisö",
      EventDate: "Tapahtuman päivämäärä",
      ActiveStatus: "Aktiivinen tila",
      CreatedAt: "Luotu",
      Location: "Sijainti",
      PackageType: "Paketin tyyppi",
      FlirtsAmount: "Flirttailumäärä",
      CreatedDate: "Luomispäivämäärä",
      Price: "Hinta",
      Total: "Yhteensä",
      PackageName: "Paketin nimi",
      PackagesDescription: "Pakettien kuvaus",
      AccountType: "Tilin tyyppi",
      OnlineStatus: "Online-tila",
      PaymentDate: "Maksupäivä",
      SelectFake: "Valitse väärä",
      Moderators: "Moderaattorit",
      Conversions: "Muunnokset",
      FullName: "Koko nimi",
      Category: "Luokka",
      LastWeek: "Viime viikko",
      RealTime: "Reaaliaikainen",
      AnswerRate: "Vastausprosentti",
      ModeratorTabs: "Moderaattorivälilehdet",
      Messagesrecieved: "Vastaanotetut viestit (SIS)",
      Lengthoffirstmesssage: "Ensimmäisen viestin pituus",
      MessagesSent: "Lähetetyt viestit (ULOS)",
      ShowOverlay: "Näytä peitto",
      Gallery: "Galleria",
      Statistics: "Tilastot",
      Community: "Yhteisö",
      Coupons: "Kupongit",
      Packages: "Paketit",
      Transfer: "Siirto",
      SKIP: "OHITA",
      Today: "Tänään",
      LasWeek: "Viime viikko",
      ThisWeek: "Tämä viikko",
      ThisMonth: "Tämä kuukausi",
      LastMonth: "Viime kuussa",
      MessageReported: "Ilmoitettu viesti",
      report: "Ilmoita",
      TimeElapsed: "Kulunut aika",
      Affilates: "Yhteistyökumppanit",
      Finance: "Rahoitus",
      Events: "Tapahtumat",
      Promotion: "Käyttäjät",
      Status: "Tila",
      FakeAccounts: "Väärennetyt tilit",
      AgentOverview: "Asiamiehen yleiskatsaus",
      LiveMonitoring: "Live-seuranta",
      MessageStatistics: "Viestitilastot",
      ActivityAnalysis: "Toiminta-analyysi",
      AgentActivity: "Asiamiehen toiminta",
      ChatModeration: "Chat-moderointi",
      Reportauser: "Ilmoita käyttäjä",
      startChatting: "Aloita keskustelu",
      UserProfile: "Käyttäjäprofiili",
      Change: "Vaihda",
      Owner: "Omistaja",
      SaveChanges: "Tallenna muutokset",
      UpdateAffilateData: "Päivitä kumppanitiedot",
      AddAffilate: "Lisää kumppani",
      addnewaffilite: "Lisää uudet kumppanit / luojat / vaikuttajat tänne",
      AffilatesManagement: "Kumppanien hallinta",
      Cancel: "Peruuta",
      OK: "OK",
      Youhavebeenblocked: "Olet estetty",
      NoPoolsFoundPleaseRefreshinaWhile: "Ei allasta löytynyt. Päivitä hetken kuluttua",
      GenerateVoucher: "Luo kuponki",
      AddCoupons: "Lisää kuponkeja",
      createnewcouponshere: "Luo uusia kuponkeja täällä",
      Remove: "Poista",
      Onetime: "Kerran",
      Welcome: "Tervetuloa",
      DialogExplorer: "Dialogin tutkija",
      Updateeventdetailsfor: "Päivitä tapahtuman tiedot",
      AddnewFlirtTooleventhere: "Lisää uusi FlirtTool-tapahtuma tänne",
      SaveEvent: "Tallenna tapahtuma",
      AddanewEvent: "Lisää uusi tapahtuma",
      Addnewchateventhere: "Lisää uusi keskustelutapahtuma tänne",
      eventcover: "tapahtumakansi",
      cover: "kansi",
      Active: "Aktiivinen",
      EventName: "Tapahtuman nimi",
      FirstName: "Etunimi",
      LastName: "Sukunimi",
      AddEvents: "Lisää tapahtumia",
      AddFakeAccount: "Lisää väärennetty tili",
      Close: "Sulje",
      Profile: "Profiili",
      ProfilePic: "Profiilikuva",
      Gender: "Sukupuoli",
      UpdateProfilePic: "Päivitä profiilikuva",
      Noprofilephotos: "Ei profiilikuvia",
      Looks: "Ulkonäkö",
      LifeStyle: "Elämäntyyli",
      EmailUsername: "Sähköposti tai käyttäjätunnus",
      Username: "Käyttäjänimi",
      Overview: "Yleiskatsaus",
      Thisweek: "Tämä viikko",
      Earnings: "Ansaitseminen",
      MontlyStatistics: "Kuukausittaiset tilastot",
      Login: "Kirjaudu sisään",
      Addnewmoderator: "Lisää uusi moderaattori",
      Enterinformationhere: "Syötä tiedot tähän",
      AddModerator: "Lisää moderaattori",
      Updatepackage: "Päivitä paketti",
      Enterpackageinformationhere: "Syötä pakettitiedot tähän",
      packagecover: "pakettikansi",
      Addnewpackage: "Lisää uusi paketti",
      Thepackagehasatimerange: "Paketissa on aikaväli",
      PackageManagement: "Paketinhallinta",
      Thiswherewemanageandupdateflirtsorpackages:
        "Täällä hallitsemme ja päivitämme flirttejä tai paketteja",
      AddNew: "Lisää uusi",
      RoleSettings: "Rooliasetukset",
      AddnewModeratedAccount: "Lisää uusi moderoidut tili",
      Enterinformationhere: "Syötä tiedot tähän",
      Save: "Tallenna",
      SendMassPoke: "Lähetä massapoke",
      YourPOKESwillgoouttotheseusers: "POKESisi lähetetään näille käyttäjille",
      SendMassLikes: "Lähetä massatykkäykset",
      YourLIKESwillgoouttotheseusers: "TYKKÄYKSESI lähetetään näille käyttäjille",
      SendMassSpam: "Lähetä massaspamia",
      Yourmessagewillgoouttotheseusers:
        "Viestisi lähetetään näille käyttäjille",
      SendSpam: "Lähetä roskaposti",
      SendMassEmail: "Lähetä massasähköposti",
      Emailmessageswillgoouttotheseusers:
        "Sähköpostiviestisi lähetetään näille käyttäjille",
      SendEmail: "Lähetä sähköpostia",
      Promotion: "Käyttäjät",
      Sendmessageandreachouttousershere:
        "Lähetä viesti ja tavoita käyttäjät täällä",
      Search: "Hae",
      Unknown: "Tuntematon",
      NOTSET: "EI ASETETTU",
      Youruseraccounts: "Käyttäjätilisi",
      SellingNudes: "Alastonkuvien myynti",
      SellingGifts: "Lahjojen myynti",
      UpdatePhotos: "Päivitä kuvat",
      Agree: "Hyväksy",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Olet saanut varoituksen ylläpidolta. Ole hyvä ja hyväksy, ettei tämä toistu",
      Senddirectchatmessage: "Lähetä suora chat-viesti",
      Dashboard: "Hallintapaneeli",
    },

    // Urdu

    // Indonesisch
    Serbisch: {
      name: "Ime",
      location: "Lokacija",
      birthDay: "Datum Rođenja",
      SellingImages: "Prodaja slika",
      Images: "Slike",
      AgentID: "ID agenta",
      Actions: "Radnje",
      Rank: "Rang",
      EventPrice: "Cena događaja",
      Answers: "Odgovori",
      Operator: "Operator",
      Settings: "Podešavanja",
      HoursSpent: "Provedeni sati",
      SendMessage: "Pošalji poruku",
      Messages: "Poruke",
      EventDetail: "Detalji događaja",
      Audience: "Publika",
      EventDate: "Datum događaja",
      ActiveStatus: "Aktivan status",
      CreatedAt: "Kreirano",
      Location: "Lokacija",
      PackageType: "Tip paketa",
      FlirtsAmount: "Količina flertova",
      CreatedDate: "Datum kreiranja",
      Price: "Cena",
      Total: "Ukupno",
      PackageName: "Naziv paketa",
      PackagesDescription: "Opis paketa",
      AccountType: "Tip naloga",
      OnlineStatus: "Status na mreži",
      PaymentDate: "Datum plaćanja",
      SelectFake: "Izaberi lažno",
      Moderators: "Moderatori",
      Conversions: "Konverzije",
      FullName: "Puno ime",
      Category: "Kategorija",
      LastWeek: "Prošla nedelja",
      RealTime: "Realno vreme",
      AnswerRate: "Stopa odgovora",
      ModeratorTabs: "Tabovi moderatora",
      Messagesrecieved: "Primljene poruke (ULAZI)",
      Lengthoffirstmesssage: "Dužina prvog poruke",
      MessagesSent: "Poslate poruke (IZLAZI)",
      ShowOverlay: "Prikaži preklapanje",
      Gallery: "Galerija",
      Statistics: "Statistika",
      Community: "Zajednica",
      Coupons: "Kuponi",
      Packages: "Paketi",
      Transfer: "Transfer",
      SKIP: "PRESKOČI",
      Today: "Danas",
      LasWeek: "Prošle nedelje",
      ThisWeek: "Ove nedelje",
      ThisMonth: "Ovog meseca",
      LastMonth: "Prošlog meseca",
      MessageReported: "Prijavljena poruka",
      report: "Prijavi",
      TimeElapsed: "Proteklo vreme",
      Affilates: "Afiliati",
      Finance: "Finansije",
      Events: "Događaji",
      Promotion: "Корисници",
      Status: "Status",
      FakeAccounts: "Lažni računi",
      AgentOverview: "Pregled agenta",
      LiveMonitoring: "Uživo praćenje",
      MessageStatistics: "Statistika poruka",
      ActivityAnalysis: "Analiza aktivnosti",
      AgentActivity: "Aktivnost agenta",
      ChatModeration: "Moderacija četa",
      Reportauser: "Prijavi korisnika",
      startChatting: "Započni čet",
      UserProfile: "Korisnički profil",
      Change: "Promeni",
      Owner: "Vlasnik",
      SaveChanges: "Sačuvaj promene",
      UpdateAffilateData: "Ažuriraj podatke o saradnicima",
      AddAffilate: "Dodaj saradnika",
      addnewaffilite: "Dodaj nove saradnike / kreatore / influensere ovde",
      AffilatesManagement: "Upravljanje saradnicima",
      Cancel: "Otkaži",
      OK: "U redu",
      Youhavebeenblocked: "Zabrana pristupa",
      NoPoolsFoundPleaseRefreshinaWhile: "Nema pronađenih bazena. Osveži za trenutak",
      GenerateVoucher: "Generiši kupon",
      AddCoupons: "Dodaj kupone",
      createnewcouponshere: "Kreiraj nove kupone ovde",
      Remove: "Ukloni",
      Onetime: "Jednokratno",
      Welcome: "Dobrodošli",
      DialogExplorer: "Istraživač dijaloga",
      Updateeventdetailsfor: "Ažuriraj detalje događaja za",
      AddnewFlirtTooleventhere: "Dodaj novi događaj za FlirtTool ovde",
      SaveEvent: "Sačuvaj događaj",
      AddanewEvent: "Dodaj novi događaj",
      Addnewchateventhere: "Dodaj novi čet događaj ovde",
      eventcover: "Pokrivač događaja",
      cover: "Pokrivač",
      Active: "Aktivan",
      EventName: "Naziv događaja",
      FirstName: "Ime",
      LastName: "Prezime",
      AddEvents: "Dodaj događaje",
      AddFakeAccount: "Dodaj lažni račun",
      Close: "Zatvori",
      Profile: "Profil",
      ProfilePic: "Profilna slika",
      Gender: "Pol",
      UpdateProfilePic: "Ažuriraj profilnu sliku",
      Noprofilephotos: "Nema profilnih slika",
      Looks: "Izgled",
      LifeStyle: "Stil života",
      EmailUsername: "E-adresa ili korisničko ime",
      Username: "Korisničko ime",
      Overview: "Pregled",
      Thisweek: "Ove nedelje",
      Earnings: "Zarada",
      MontlyStatistics: "Mesečna statistika",
      Login: "Prijavi se",
      Addnewmoderator: "Dodaj novog moderatora",
      Enterinformationhere: "Unesite informacije ovde",
      AddModerator: "Dodaj moderatora",
      Updatepackage: "Ažuriraj paket",
      Enterpackageinformationhere: "Unesite informacije o paketu ovde",
      packagecover: "Pokrivač paketa",
      Addnewpackage: "Dodaj novi paket",
      Thepackagehasatimerange: "Paket ima vremenski opseg",
      PackageManagement: "Upravljanje paketima",
      Thiswherewemanageandupdateflirtsorpackages:
        "Ovde upravljamo i ažuriramo flertove ili pakete",
      AddNew: "Dodaj novo",
      RoleSettings: "Podešavanja uloge",
      AddnewModeratedAccount: "Dodaj novi moderirani nalog",
      Enterinformationhere: "Unesite informacije ovde",
      Save: "Sačuvaj",
      SendMassPoke: "Pošalji masovno povlačenje",
      YourPOKESwillgoouttotheseusers: "Vaša POVLAČENJA će biti poslata ovim korisnicima",
      SendMassLikes: "Pošalji masovno lajkove",
      YourLIKESwillgoouttotheseusers: "Vaši LAJKOVI će biti poslati ovim korisnicima",
      SendMassSpam: "Pošalji masovni spam",
      Yourmessagewillgoouttotheseusers:
        "Vaša poruka će biti poslata ovim korisnicima",
      SendSpam: "Pošalji spam",
      SendMassEmail: "Pošalji masovni e-mail",
      Emailmessageswillgoouttotheseusers:
        "E-mail poruke će biti poslate ovim korisnicima",
      SendEmail: "Pošalji e-mail",
      Promotion: "Корисници",
      Sendmessageandreachouttousershere:
        "Pošaljite poruku i obratite se korisnicima ovde",
      Search: "Pretraži",
      Unknown: "Nepoznato",
      NOTSET: "NIJE POSTAVLJENO",
      Youruseraccounts: "Vaši korisnički računi",
      SellingNudes: "Prodaja golih slika",
      SellingGifts: "Prodaja poklona",
      UpdatePhotos: "Ažuriraj fotografije",
      Agree: "Slažem se",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Upozoren/a si od strane administratora. Molimo, složi se da se ovo neće ponoviti",
      Senddirectchatmessage: "Pošalji direktnu poruku za čet",
      Dashboard: "Kontrolna tabla",
    },

    // Indonesisch

    // Japanisch
    russisch: {
      Job: "Работа",
WorkingSchedule: "Рабочий График",
RelationshipStatus: "Семейное Положение",
Searchingfor: "Ищет",
pastRelationships: "Прошлые Отношения",
TraitsforIdealPartner: "Черты Идеального Партнера",
FuturePlanswithPartner: "Планы на Будущее с Партнером",
      name: "Имя",
      location: "Местоположение",
      birthDay: "День Рождения",
      SellingImages: "Продажа изображений",
      Images: "Изображения",
      AgentID: "ID агента",
      Actions: "Действия",
      Rank: "Ранг",
      EventPrice: "Цена события",
      Answers: "Ответы",
      Operator: "Оператор",
      Settings: "Настройки",
      HoursSpent: "Часы, проведенные",
      SendMessage: "Отправить сообщение",
      Messages: "Сообщения",
      EventDetail: "Детали события",
      Audience: "Аудитория",
      EventDate: "Дата события",
      ActiveStatus: "Активный статус",
      CreatedAt: "Создано",
      Location: "Местоположение",
      PackageType: "Тип пакета",
      FlirtsAmount: "Количество флиртов",
      CreatedDate: "Дата создания",
      Price: "Цена",
      Total: "Всего",
      PackageName: "Название пакета",
      PackagesDescription: "Описание пакетов",
      AccountType: "Тип аккаунта",
      OnlineStatus: "Онлайн-статус",
      PaymentDate: "Дата платежа",
      SelectFake: "Выбрать фейк",
      Moderators: "Модераторы",
      Conversions: "Конверсии",
      FullName: "Полное имя",
      Category: "Категория",
      LastWeek: "На прошлой неделе",
      RealTime: "Реальное время",
      AnswerRate: "Скорость ответа",
      ModeratorTabs: "Вкладки модератора",
      Messagesrecieved: "Полученные сообщения (ВХОДЯЩИЕ)",
      Lengthoffirstmesssage: "Длина первого сообщения",
      MessagesSent: "Отправленные сообщения (ИСХОДЯЩИЕ)",
      ShowOverlay: "Показать наложение",
      Gallery: "Галерея",
      Statistics: "Статистика",
      Community: "Сообщество",
      Coupons: "Купоны",
      Packages: "Пакеты",
      Transfer: "Перевод",
      SKIP: "ПРОПУСТИТЬ",
      Today: "Сегодня",
      LasWeek: "На прошлой неделе",
      ThisWeek: "На этой неделе",
      ThisMonth: "В этом месяце",
      LastMonth: "В прошлом месяце",
      MessageReported: "Сообщение было пожаловано",
      report: "Пожаловаться",
      TimeElapsed: "Прошедшее время",
      Affilates: "Партнеры",
      Finance: "Финансы",
      Events: "События",
      Promotion: "Пользователи",
      Status: "Статус",
      FakeAccounts: "Фейковые аккаунты",
      AgentOverview: "Обзор агента",
      LiveMonitoring: "Живое мониторинг",
      MessageStatistics: "Статистика сообщений",
      ActivityAnalysis: "Анализ активности",
      AgentActivity: "Активность агента",
      ChatModeration: "Модерация чата",
      Reportauser: "Пожаловаться на пользователя",
      startChatting: "Начать чат",
      UserProfile: "Профиль пользователя",
      Change: "Изменить",
      Owner: "Владелец",
      SaveChanges: "Сохранить изменения",
      UpdateAffilateData: "Обновить данные партнера",
      AddAffilate: "Добавить партнера",
      addnewaffilite: "Добавить новых партнеров / создателей / влиятельных личностей здесь",
      AffilatesManagement: "Управление партнерами",
      Cancel: "Отмена",
      OK: "OK",
      Youhavebeenblocked: "Вы были заблокированы",
      NoPoolsFoundPleaseRefreshinaWhile: "Пулы не найдены. Пожалуйста, обновите через некоторое время",
      GenerateVoucher: "Создать ваучер",
      AddCoupons: "Добавить купоны",
      createnewcouponshere: "Создайте новые купоны здесь",
      Remove: "Удалить",
      Onetime: "Одноразово",
      Welcome: "Добро пожаловать",
      DialogExplorer: "Исследователь диалогов",
      Updateeventdetailsfor: "Обновить детали события для",
      AddnewFlirtTooleventhere: "Добавить новое событие для FlirtTool здесь",
      SaveEvent: "Сохранить событие",
      AddanewEvent: "Добавить новое событие",
      Addnewchateventhere: "Добавить новое чат-событие здесь",
      eventcover: "Обложка события",
      cover: "Обложка",
      Active: "Активный",
      EventName: "Название события",
      FirstName: "Имя",
      LastName: "Фамилия",
      AddEvents: "Добавить события",
      AddFakeAccount: "Добавить фейковый аккаунт",
      Close: "Закрыть",
      Profile: "Профиль",
      ProfilePic: "Фото профиля",
      Gender: "Пол",
      UpdateProfilePic: "Обновить фото профиля",
      Noprofilephotos: "Нет фото профиля",
      Looks: "Внешность",
      LifeStyle: "Образ жизни",
      EmailUsername: "Эл. адрес или имя пользователя",
      Username: "Имя пользователя",
      Overview: "Обзор",
      Thisweek: "На этой неделе",
      Earnings: "Доходы",
      MontlyStatistics: "Месячная статистика",
      Login: "Вход",
      Addnewmoderator: "Добавить нового модератора",
      Enterinformationhere: "Введите информацию здесь",
      AddModerator: "Добавить модератора",
      Updatepackage: "Обновить пакет",
      Enterpackageinformationhere: "Введите информацию о пакете здесь",
      packagecover: "Обложка пакета",
      Addnewpackage: "Добавить новый пакет",
      Thepackagehasatimerange: "Пакет имеет временной диапазон",
      PackageManagement: "Управление пакетами",
      Thiswherewemanageandupdateflirtsorpackages:
        "Здесь мы управляем и обновляем флирты или пакеты",
      AddNew: "Добавить новый",
      RoleSettings: "Настройки роли",
      AddnewModeratedAccount: "Добавить новый модерируемый аккаунт",
      Enterinformationhere: "Введите информацию здесь",
      Save: "Сохранить",
      SendMassPoke: "Отправить массовый тычок",
      YourPOKESwillgoouttotheseusers: "Ваши ПОКИ отправятся этим пользователям",
      SendMassLikes: "Отправить массовые лайки",
      YourLIKESwillgoouttotheseusers: "Ваши ЛАЙКИ отправятся этим пользователям",
      SendMassSpam: "Отправить массовый спам",
      Yourmessagewillgoouttotheseusers:
        "Ваше сообщение будет отправлено этим пользователям",
      SendSpam: "Отправить спам",
      SendMassEmail: "Отправить массовое электронное письмо",
      Emailmessageswillgoouttotheseusers:
        "Электронные сообщения будут отправлены этим пользователям",
      SendEmail: "Отправить электронное письмо",
      Promotion: "Пользователи",
      Sendmessageandreachouttousershere:
        "Отправьте сообщение и свяжитесь с пользователями здесь",
      Search: "Поиск",
      Unknown: "Неизвестно",
      NOTSET: "НЕ УСТАНОВЛЕНО",
      Youruseraccounts: "Ваши учетные записи пользователя",
      SellingNudes: "Продажа голых фотографий",
      SellingGifts: "Продажа подарков",
      UpdatePhotos: "Обновить фотографии",
      Agree: "Согласен",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Вас предупредил администратор. Пожалуйста, согласитесь, что это не повторится",
      Senddirectchatmessage: "Отправить прямое чат-сообщение",
      Dashboard: "Информационная панель",
    },

    // Japanisch

    // Marathi'

    japanese: {
      Job: "仕事",
WorkingSchedule: "勤務時間",
RelationshipStatus: "関係ステータス",
Searchingfor: "探している",
pastRelationships: "過去の関係",
TraitsforIdealPartner: "理想のパートナーの特徴",
FuturePlanswithPartner: "パートナーとの将来の計画",
      name: "名前",
      location: "場所",
      birthDay: "誕生日",
      SellingImages: "画像の販売",
      Images: "画像",
      AgentID: "エージェントID",
      Actions: "アクション",
      Rank: "ランク",
      EventPrice: "イベント価格",
      Answers: "回答",
      Operator: "オペレーター",
      Settings: "設定",
      HoursSpent: "費やした時間",
      SendMessage: "メッセージを送信",
      Messages: "メッセージ",
      EventDetail: "イベントの詳細",
      Audience: "観客",
      EventDate: "イベント日",
      ActiveStatus: "アクティブステータス",
      CreatedAt: "作成日",
      Location: "場所",
      PackageType: "パッケージタイプ",
      FlirtsAmount: "フリートの量",
      CreatedDate: "作成日",
      Price: "価格",
      Total: "合計",
      PackageName: "パッケージ名",
      PackagesDescription: "パッケージの説明",
      AccountType: "アカウントタイプ",
      OnlineStatus: "オンラインステータス",
      PaymentDate: "支払い日",
      SelectFake: "フェイクを選択",
      Moderators: "モデレーター",
      Conversions: "変換",
      FullName: "フルネーム",
      Category: "カテゴリー",
      LastWeek: "先週",
      RealTime: "リアルタイム",
      AnswerRate: "回答率",
      ModeratorTabs: "モデレータータブ",
      Messagesrecieved: "受信したメッセージ（IN）",
      Lengthoffirstmesssage: "最初のメッセージの長さ",
      MessagesSent: "送信されたメッセージ（OUT）",
      ShowOverlay: "オーバーレイを表示",
      Gallery: "ギャラリー",
      Statistics: "統計",
      Community: "コミュニティ",
      Coupons: "クーポン",
      Packages: "パッケージ",
      Transfer: "転送",
      SKIP: "スキップ",
      Today: "今日",
      LasWeek: "先週",
      ThisWeek: "今週",
      ThisMonth: "今月",
      LastMonth: "先月",
      MessageReported: "メッセージが報告されました",
      report: "報告",
      TimeElapsed: "経過時間",
      Affilates: "アフィリエイト",
      Finance: "ファイナンス",
      Events: "イベント",
      Promotion: "ユーザー",
      Status: "ステータス",
      FakeAccounts: "偽アカウント",
      AgentOverview: "エージェントの概要",
      LiveMonitoring: "ライブモニタリング",
      MessageStatistics: "メッセージ統計",
      ActivityAnalysis: "アクティビティ分析",
      AgentActivity: "エージェントのアクティビティ",
      ChatModeration: "チャットのモデレーション",
      Reportauser: "ユーザーを報告する",
      startChatting: "チャットを開始",
      UserProfile: "ユーザープロファイル",
      Change: "変更",
      Owner: "所有者",
      SaveChanges: "変更を保存",
      UpdateAffilateData: "アフィリエイトデータを更新",
      AddAffilate: "アフィリエイトを追加",
      addnewaffilite: "ここに新しいアフィリエイト/クリエーター/インフルエンサーを追加",
      AffilatesManagement: "アフィリエイト管理",
      Cancel: "キャンセル",
      OK: "OK",
      Youhavebeenblocked: "ブロックされました",
      NoPoolsFoundPleaseRefreshinaWhile: "プールが見つかりません。しばらくしてから更新してください",
      GenerateVoucher: "バウチャーを生成",
      AddCoupons: "クーポンを追加",
      createnewcouponshere: "ここで新しいクーポンを作成",
      Remove: "削除",
      Onetime: "一度",
      Welcome: "ようこそ",
      DialogExplorer: "ダイアログエクスプローラー",
      Updateeventdetailsfor: "イベントの詳細を更新",
      AddnewFlirtTooleventhere: "ここに新しいフリットツールイベントを追加",
      SaveEvent: "イベントを保存",
      AddanewEvent: "新しいイベントを追加",
      Addnewchateventhere: "ここに新しいチャットイベントを追加",
      eventcover: "イベントカバー",
      cover: "カバー",
      Active: "アクティブ",
      EventName: "イベント名",
      FirstName: "名",
      LastName: "姓",
      AddEvents: "イベントを追加",
      AddFakeAccount: "偽アカウントを追加",
      Close: "閉じる",
      Profile: "プロフィール",
      ProfilePic: "プロフィール写真",
      Gender: "性別",
      UpdateProfilePic: "プロフィール写真を更新",
      Noprofilephotos: "プロフィール写真はありません",
      Looks: "外見",
      LifeStyle: "ライフスタイル",
      EmailUsername: "電子メールまたはユーザー名",
      Username: "ユーザー名",
      Overview: "概要",
      Thisweek: "今週",
      Earnings: "収益",
      MontlyStatistics: "月次統計",
      Login: "ログイン",
      Addnewmoderator: "新しいモデレーターを追加",
      Enterinformationhere: "ここに情報を入力",
      AddModerator: "モデレーターを追加",
      Updatepackage: "パッケージを更新",
      Enterpackageinformationhere: "ここにパッケージ情報を入力",
      packagecover: "パッケージカバー",
      Addnewpackage: "新しいパッケージを追加",
      Thepackagehasatimerange: "パッケージにはタイマー範囲があります",
      PackageManagement: "パッケージ管理",
      Thiswherewemanageandupdateflirtsorpackages:
        "ここでフリルトやパッケージを管理および更新します",
      AddNew: "新規追加",
      RoleSettings: "ロール設定",
      AddnewModeratedAccount: "新しいモデレートされたアカウントを追加",
      Enterinformationhere: "ここに情報を入力",
      Save: "保存",
      SendMassPoke: "マスポークを送信",
      YourPOKESwillgoouttotheseusers: "これらのユーザーにあなたのPOKESが送信されます",
      SendMassLikes: "マスライクを送信",
      YourLIKESwillgoouttotheseusers: "これらのユーザーにあなたのLIKESが送信されます",
      SendMassSpam: "マススパムを送信",
      Yourmessagewillgoouttotheseusers:
        "あなたのメッセージがこれらのユーザーに送信されます",
      SendSpam: "スパムを送信",
      SendMassEmail: "マスメールを送信",
      Emailmessageswillgoouttotheseusers:
        "これらのユーザーにメールが送信されます",
      SendEmail: "メールを送信",
      Promotion: "ユーザー",
      Sendmessageandreachouttousershere:
        "ここでメッセージを送信してユーザーにアプローチする",
      Search: "検索",
      Unknown: "不明",
      NOTSET: "未設定",
      Youruseraccounts: "あなたのユーザーアカウント",
      SellingNudes: "ヌード販売",
      SellingGifts: "ギフト販売",
      UpdatePhotos: "写真を更新",
      Agree: "同意",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "管理者から警告されました。これが再び起こらないことに同意してください",
      Senddirectchatmessage: "直接チャットメッセージを送信",
      Dashboard: "ダッシュボード",
    },

    // Marathi
    // Vietnamesisch
    koreanisch: {
      Job: "직업",
WorkingSchedule: "근무 일정",
RelationshipStatus: "관계 상태",
Searchingfor: "찾고 있는",
pastRelationships: "과거의 관계",
TraitsforIdealPartner: "이상적인 파트너의 특성",
FuturePlanswithPartner: "파트너와의 미래 계획",
      name: "이름",
      location: "위치",
      birthDay: "생일",
      SellingImages: "이미지 판매",
      Images: "이미지",
      AgentID: "에이전트 ID",
      Actions: "작업",
      Rank: "순위",
      EventPrice: "이벤트 가격",
      Answers: "답변",
      Operator: "운영자",
      Settings: "설정",
      HoursSpent: "소요 시간",
      SendMessage: "메시지 보내기",
      Messages: "메시지",
      EventDetail: "이벤트 상세",
      Audience: "관객",
      EventDate: "이벤트 날짜",
      ActiveStatus: "활성 상태",
      CreatedAt: "작성 날짜",
      Location: "위치",
      PackageType: "패키지 유형",
      FlirtsAmount: "유혹 횟수",
      CreatedDate: "작성 날짜",
      Price: "가격",
      Total: "총계",
      PackageName: "패키지 이름",
      PackagesDescription: "패키지 설명",
      AccountType: "계정 유형",
      OnlineStatus: "온라인 상태",
      PaymentDate: "지불 날짜",
      SelectFake: "가짜 선택",
      Moderators: "모더레이터",
      Conversions: "변환",
      FullName: "전체 이름",
      Category: "카테고리",
      LastWeek: "지난 주",
      RealTime: "실시간",
      AnswerRate: "답변 비율",
      ModeratorTabs: "모더레이터 탭",
      Messagesrecieved: "받은 메시지 (INs)",
      Lengthoffirstmesssage: "첫 번째 메시지의 길이",
      MessagesSent: "보낸 메시지 (OUTs)",
      ShowOverlay: "오버레이 표시",
      Gallery: "갤러리",
      Statistics: "통계",
      Community: "커뮤니티",
      Coupons: "쿠폰",
      Packages: "패키지",
      Transfer: "전송",
      SKIP: "건너뛰기",
      Today: "오늘",
      LasWeek: "지난 주",
      ThisWeek: "이번 주",
      ThisMonth: "이번 달",
      LastMonth: "지난 달",
      MessageReported: "메시지 신고됨",
      report: "신고",
      TimeElapsed: "경과 시간",
      Affilates: "제휴사",
      Finance: "금융",
      Events: "이벤트",
      Promotion: "사용자",
      Status: "상태",
      FakeAccounts: "가짜 계정",
      AgentOverview: "에이전트 개요",
      LiveMonitoring: "실시간 모니터링",
      MessageStatistics: "메시지 통계",
      ActivityAnalysis: "활동 분석",
      AgentActivity: "에이전트 활동",
      ChatModeration: "채팅 조절",
      Reportauser: "사용자 신고",
      startChatting: "채팅 시작",
      UserProfile: "사용자 프로필",
      Change: "변경",
      Owner: "소유자",
      SaveChanges: "변경 사항 저장",
      UpdateAffilateData: "제휴사 데이터 업데이트",
      AddAffilate: "제휴사 추가",
      addnewaffilite: "여기에 새로운 제휴사/크리에이터/인플루언서 추가",
      AffilatesManagement: "제휴사 관리",
      Cancel: "취소",
      OK: "확인",
      Youhavebeenblocked: "차단되었습니다",
      NoPoolsFoundPleaseRefreshinaWhile: "풀을 찾을 수 없습니다. 잠시 후에 새로 고칩니다",
      GenerateVoucher: "바우처 생성",
      AddCoupons: "쿠폰 추가",
      createnewcouponshere: "여기에 새로운 쿠폰을 생성합니다",
      Remove: "삭제",
      Onetime: "일회용",
      Welcome: "환영합니다",
      DialogExplorer: "대화 탐색기",
      Updateeventdetailsfor: "이벤트 세부 정보 업데이트",
      AddnewFlirtTooleventhere: "여기에 새로운 FlirtTool 이벤트 추가",
      SaveEvent: "이벤트 저장",
      AddanewEvent: "새 이벤트 추가",
      Addnewchateventhere: "여기에 새로운 채팅 이벤트 추가",
      eventcover: "이벤트 커버",
      cover: "커버",
      Active: "활성화",
      EventName: "이벤트 이름",
      FirstName: "이름",
      LastName: "성",
      AddEvents: "이벤트 추가",
      AddFakeAccount: "가짜 계정 추가",
      Close: "닫기",
      Profile: "프로필",
      ProfilePic: "프로필 사진",
      Gender: "성별",
      UpdateProfilePic: "프로필 사진 업데이트",
      Noprofilephotos: "프로필 사진 없음",
      Looks: "외모",
      LifeStyle: "라이프 스타일",
      EmailUsername: "이메일 또는 사용자 이름",
      Username: "사용자 이름",
      Overview: "개요",
      Thisweek: "이번 주",
      Earnings: "수익",
      MontlyStatistics: "월간 통계",
      Login: "로그인",
      Addnewmoderator: "새로운 모더레이터 추가",
      Enterinformationhere: "여기에 정보 입력",
      AddModerator: "모더레이터 추가",
      Updatepackage: "패키지 업데이트",
      Enterpackageinformationhere: "여기에 패키지 정보 입력",
      packagecover: "패키지 커버",
      Addnewpackage: "새로운 패키지 추가",
      Thepackagehasatimerange: "패키지에 타이머 범위가 있습니다",
      PackageManagement: "패키지 관리",
      Thiswherewemanageandupdateflirtsorpackages:
        "여기에서 유혹이나 패키지를 관리하고 업데이트합니다",
      AddNew: "새로 추가",
      RoleSettings: "역할 설정",
      AddnewModeratedAccount: "새로운 모더레이트된 계정 추가",
      Enterinformationhere: "여기에 정보 입력",
      Save: "저장",
      SendMassPoke: "대량 포케 보내기",
      YourPOKESwillgoouttotheseusers: "이 사용자들에게 POKES가 전송됩니다",
      SendMassLikes: "대량 좋아요 보내기",
      YourLIKESwillgoouttotheseusers: "이 사용자들에게 LIKES가 전송됩니다",
      SendMassSpam: "대량 스팸 보내기",
      Yourmessagewillgoouttotheseusers:
        "이 사용자들에게 메시지가 전송됩니다",
      SendSpam: "스팸 보내기",
      SendMassEmail: "대량 이메일 보내기",
      Emailmessageswillgoouttotheseusers:
        "이 사용자들에게 이메일 메시지가 전송됩니다",
      SendEmail: "이메일 보내기",
      Promotion: "사용자",
      Sendmessageandreachouttousershere:
        "여기에서 메시지를 보내 사용자에게 연락하십시오",
      Search: "검색",
      Unknown: "알 수 없음",
      NOTSET: "설정되지 않음",
      Youruseraccounts: "귀하의 사용자 계정",
      SellingNudes: "누드 판매",
      SellingGifts: "선물 판매",
      UpdatePhotos: "사진 업데이트",
      Agree: "동의",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "관리자에게 경고 받았습니다. 다시 발생하지 않도록 동의하십시오",
      Senddirectchatmessage: "직접 채팅 메시지 보내기",
      Dashboard: "대시 보드",
    },

    // Vietnamesisch

    // Tagalog

    Persian: {
      name: "نام",
      location: "مکان",
      birthDay: "تاریخ تولد",
      SellingImages: "فروش تصاویر",
      Images: "تصاویر",
      AgentID: "شناسه نماینده",
      Actions: "اقدامات",
      Rank: "رتبه",
      EventPrice: "قیمت رویداد",
      Answers: "پاسخ‌ها",
      Operator: "اپراتور",
      Settings: "تنظیمات",
      HoursSpent: "ساعت‌های صرف شده",
      SendMessage: "ارسال پیام",
      Messages: "پیام‌ها",
      EventDetail: "جزئیات رویداد",
      Audience: "مخاطبین",
      EventDate: "تاریخ رویداد",
      ActiveStatus: "وضعیت فعال",
      CreatedAt: "ساخته شده در",
      Location: "مکان",
      PackageType: "نوع بسته",
      FlirtsAmount: "مقدار فلرت",
      CreatedDate: "تاریخ ایجاد",
      Price: "قیمت",
      Total: "مجموع",
      PackageName: "نام بسته",
      PackagesDescription: "توضیحات بسته‌ها",
      AccountType: "نوع حساب",
      OnlineStatus: "وضعیت آنلاین",
      PaymentDate: "تاریخ پرداخت",
      SelectFake: "انتخاب جعلی",
      Moderators: "مدیران",
      Conversions: "تبدیل‌ها",
      FullName: "نام کامل",
      Category: "دسته بندی",
      LastWeek: "هفته گذشته",
      RealTime: "زمان واقعی",
      AnswerRate: "نرخ پاسخ",
      ModeratorTabs: "تب‌های مدیر",
      Messagesrecieved: "پیام‌های دریافتی (ورودی)",
      Lengthoffirstmesssage: "طول اولین پیام",
      MessagesSent: "پیام‌های ارسالی (خروجی)",
      ShowOverlay: "نمایش ابرپوش",
      Gallery: "گالری",
      Statistics: "آمار",
      Community: "جامعه",
      Coupons: "کوپن‌ها",
      Packages: "بسته‌ها",
      Transfer: "انتقال",
      SKIP: "پرش",
      Today: "امروز",
      LasWeek: "هفته گذشته",
      ThisWeek: "این هفته",
      ThisMonth: "این ماه",
      LastMonth: "ماه گذشته",
      MessageReported: "پیام گزارش شده",
      report: "گزارش",
      TimeElapsed: "زمان گذشته",
      Affilates: "همکاران",
      Finance: "مالی",
      Events: "رویدادها",
      Promotion: "کاربران",
      Status: "وضعیت",
      FakeAccounts: "حساب‌های جعلی",
      AgentOverview: "بررسی نماینده",
      LiveMonitoring: "مانیتورینگ زنده",
      MessageStatistics: "آمار پیام",
      ActivityAnalysis: "تجزیه و تحلیل فعالیت",
      AgentActivity: "فعالیت نماینده",
      ChatModeration: "مدیریت گفتگو",
      Reportauser: "گزارش کاربر",
      startChatting: "شروع گفتگو",
      UserProfile: "پروفایل کاربری",
      Change: "تغییر",
      Owner: "مالک",
      SaveChanges: "ذخیره تغییرات",
      UpdateAffilateData: "به‌روزرسانی داده‌های همکار",
      AddAffilate: "افزودن همکار",
      addnewaffilite: "همکاران/خالقان/تأثیرگذاران جدید را اینجا اضافه کنید",
      AffilatesManagement: "مدیریت همکاران",
      Cancel: "لغو",
      OK: "باشه",
      Youhavebeenblocked: "شما مسدود شده‌اید",
      NoPoolsFoundPleaseRefreshinaWhile: "استخری یافت نشد. لطفاً بعداً دوباره تلاش کنید",
      GenerateVoucher: "تولید کد تخفیف",
      AddCoupons: "افزودن کوپن",
      createnewcouponshere: "ایجاد کوپن جدید در اینجا",
      Remove: "حذف",
      Onetime: "یک بار",
      Welcome: "خوش آمدید",
      DialogExplorer: "اکتشافگر دیالوگ",
      Updateeventdetailsfor: "به‌روزرسانی جزئیات رویداد برای",
      AddnewFlirtTooleventhere: "افزودن رویداد جدید FlirtTool در اینجا",
      SaveEvent: "ذخیره رویداد",
      AddanewEvent: "افزودن رویداد جدید",
      Addnewchateventhere: "افزودن رویداد گفتگو جدید در اینجا",
      eventcover: "کاور رویداد",
      cover: "کاور",
      Active: "فعال",
      EventName: "نام رویداد",
      FirstName: "نام",
      LastName: "نام خانوادگی",
      AddEvents: "افزودن رویدادها",
      AddFakeAccount: "افزودن حساب جعلی",
      Close: "بستن",
      Profile: "پروفایل",
      ProfilePic: "تصویر پروفایل",
      Gender: "جنسیت",
      UpdateProfilePic: "به‌روزرسانی تصویر پروفایل",
      Noprofilephotos: "عکس پروفایل موجود نیست",
      Looks: "ظاهر",
      LifeStyle: "سبک زندگی",
      EmailUsername: "ایمیل یا نام کاربری",
      Username: "نام کاربری",
      Overview: "مرور",
      Thisweek: "این هفته",
      Earnings: "درآمد",
      MontlyStatistics: "آمار ماهانه",
      Login: "ورود",
      Addnewmoderator: "افزودن مدیر جدید",
      Enterinformationhere: "اطلاعات را در اینجا وارد کنید",
      AddModerator: "افزودن مدیر",
      Updatepackage: "به‌روزرسانی بسته",
      Enterpackageinformationhere: "اطلاعات بسته را در اینجا وارد کنید",
      packagecover: "کاور بسته",
      Addnewpackage: "افزودن بسته جدید",
      Thepackagehasatimerange: "بسته دارای محدوده زمانی است",
      PackageManagement: "مدیریت بسته",
      Thiswherewemanageandupdateflirtsorpackages:
        "اینجا جایی است که ما دستکاری و به‌روزرسانی فلرت یا بسته‌ها را مدیریت می‌کنیم",
      AddNew: "افزودن جدید",
      RoleSettings: "تنظیمات نقش",
      AddnewModeratedAccount: "افزودن حساب کاربری تنظیم شده جدید",
      Enterinformationhere: "اطلاعات را در اینجا وارد کنید",
      Save: "ذخیره",
      SendMassPoke: "ارسال پوک به صورت دسته جمعی",
      YourPOKESwillgoouttotheseusers: "پوک‌های شما به این کاربران ارسال خواهد شد",
      SendMassLikes: "ارسال پسند به صورت دسته جمعی",
      YourLIKESwillgoouttotheseusers: "پسند‌های شما به این کاربران ارسال خواهد شد",
      SendMassSpam: "ارسال اسپم به صورت دسته جمعی",
      Yourmessagewillgoouttotheseusers:
        "پیام شما به این کاربران ارسال خواهد شد",
      SendSpam: "ارسال اسپم",
      SendMassEmail: "ارسال ایمیل به صورت دسته جمعی",
      Emailmessageswillgoouttotheseusers:
        "پیام‌های ایمیل شما به این کاربران ارسال خواهد شد",
      SendEmail: "ارسال ایمیل",
      Promotion: "کاربران",
      Sendmessageandreachouttousershere:
        "پیام ارسال کرده و با کاربران در اینجا ارتباط برقرار کنید",
      Search: "جستجو",
      Unknown: "ناشناخته",
      NOTSET: "تنظیم نشده",
      Youruseraccounts: "حساب‌های کاربری شما",
      SellingNudes: "فروش عکس‌های عاریت",
      SellingGifts: "فروش هدایا",
      UpdatePhotos: "به‌روزرسانی تصاویر",
      Agree: "موافقم",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "شما توسط مدیر هشدار داده شده‌اید. لطفاً موافقت کنید که این موضوع دوباره رخ ندهد",
      Senddirectchatmessage: "ارسال پیام گفت‌وگو مستقیم",
      Dashboard: "داشبورد",
    },

    // Tagalog

    // Koreanisch

    arabic: {
      Job: "الوظيفة",
WorkingSchedule: "جدول العمل",
RelationshipStatus: "الحالة الاجتماعية",
Searchingfor: "يبحث عن",
pastRelationships: "العلاقات السابقة",
TraitsforIdealPartner: "صفات الشريك المثالي",
FuturePlanswithPartner: "خطط مستقبلية مع الشريك",
      name: "الاسم",
      location: "الموقع",
      birthDay: "تاريخ الميلاد",
      SellingImages: "بيع الصور",
      Images: "الصور",
      AgentID: "هوية الوكيل",
      Actions: "الإجراءات",
      Rank: "الرتبة",
      EventPrice: "سعر الحدث",
      Answers: "الإجابات",
      Operator: "المشغل",
      Settings: "الإعدادات",
      HoursSpent: "الساعات المستغرقة",
      SendMessage: "إرسال رسالة",
      Messages: "الرسائل",
      EventDetail: "تفاصيل الحدث",
      Audience: "الجمهور",
      EventDate: "تاريخ الحدث",
      ActiveStatus: "الحالة النشطة",
      CreatedAt: "تم الإنشاء في",
      Location: "الموقع",
      PackageType: "نوع الحزمة",
      FlirtsAmount: "كمية الغازل",
      CreatedDate: "تاريخ الإنشاء",
      Price: "السعر",
      Total: "الإجمالي",
      PackageName: "اسم الحزمة",
      PackagesDescription: "وصف الحزم",
      AccountType: "نوع الحساب",
      OnlineStatus: "الحالة عبر الإنترنت",
      PaymentDate: "تاريخ الدفع",
      SelectFake: "تحديد مزيف",
      Moderators: "المشرفين",
      Conversions: "التحويلات",
      FullName: "الاسم الكامل",
      Category: "الفئة",
      LastWeek: "الأسبوع الماضي",
      RealTime: "الوقت الحقيقي",
      AnswerRate: "معدل الرد",
      ModeratorTabs: "علامات المشرف",
      Messagesrecieved: "الرسائل المستلمة (الواردة)",
      Lengthoffirstmesssage: "طول الرسالة الأولى",
      MessagesSent: "الرسائل المرسلة (الصادرة)",
      ShowOverlay: "إظهار التراكب",
      Gallery: "المعرض",
      Statistics: "الإحصائيات",
      Community: "المجتمع",
      Coupons: "القسائم",
      Packages: "الحزم",
      Transfer: "التحويل",
      SKIP: "تخطي",
      Today: "اليوم",
      LasWeek: "الأسبوع الماضي",
      ThisWeek: "هذا الأسبوع",
      ThisMonth: "هذا الشهر",
      LastMonth: "الشهر الماضي",
      MessageReported: "الرسالة المبلغ عنها",
      report: "تقرير",
      TimeElapsed: "الوقت المنقضي",
      Affilates: "الشركاء",
      Finance: "المالية",
      Events: "الأحداث",
      Promotion: "المستخدمين",
      Status: "الحالة",
      FakeAccounts: "الحسابات الوهمية",
      AgentOverview: "نظرة عامة على الوكيل",
      LiveMonitoring: "مراقبة مباشرة",
      MessageStatistics: "إحصائيات الرسائل",
      ActivityAnalysis: "تحليل النشاط",
      AgentActivity: "نشاط الوكيل",
      ChatModeration: "إدارة الدردشة",
      Reportauser: "الإبلاغ عن مستخدم",
      startChatting: "بدء المحادثة",
      UserProfile: "ملف تعريف المستخدم",
      Change: "تغيير",
      Owner: "المالك",
      SaveChanges: "حفظ التغييرات",
      UpdateAffilateData: "تحديث بيانات الشريك",
      AddAffilate: "إضافة شريك",
      addnewaffilite: "إضافة شركاء / منشئين / مؤثرين جدد هنا",
      AffilatesManagement: "إدارة الشركاء",
      Cancel: "إلغاء",
      OK: "حسنًا",
      Youhavebeenblocked: "تم حظرك",
      NoPoolsFoundPleaseRefreshinaWhile: "لم يتم العثور على أحواض. يرجى تحديث في وقت لاحق",
      GenerateVoucher: "إنشاء قسيمة",
      AddCoupons: "إضافة قسائم",
      createnewcouponshere: "إنشاء قسائم جديدة هنا",
      Remove: "إزالة",
      Onetime: "مرة واحدة",
      Welcome: "مرحبًا",
      DialogExplorer: "مستكشف الحوار",
      Updateeventdetailsfor: "تحديث تفاصيل الحدث لـ",
      AddnewFlirtTooleventhere: "إضافة حدث FlirtTool جديد هنا",
      SaveEvent: "حفظ الحدث",
      AddanewEvent: "إضافة حدث جديد",
      Addnewchateventhere: "إضافة حدث دردشة جديد هنا",
      eventcover: "غلاف الحدث",
      cover: "الغلاف",
      Active: "نشط",
      EventName: "اسم الحدث",
      FirstName: "الاسم الأول",
      LastName: "الاسم الأخير",
      AddEvents: "إضافة الأحداث",
      AddFakeAccount: "إضافة حساب مزيف",
      Close: "إغلاق",
      Profile: "الملف الشخصي",
      ProfilePic: "صورة الملف الشخصي",
      Gender: "الجنس",
      UpdateProfilePic: "تحديث صورة الملف الشخصي",
      Noprofilephotos: "لا توجد صور ملف شخصي",
      Looks: "المظهر",
      LifeStyle: "نمط الحياة",
      EmailUsername: "البريد الإلكتروني أو اسم المستخدم",
      Username: "اسم المستخدم",
      Overview: "نظرة عامة",
      Thisweek: "هذا الأسبوع",
      Earnings: "الأرباح",
      MontlyStatistics: "إحصائيات شهرية",
      Login: "تسجيل الدخول",
      Addnewmoderator: "إضافة مشرف جديد",
      Enterinformationhere: "أدخل المعلومات هنا",
      AddModerator: "إضافة مشرف",
      Updatepackage: "تحديث الحزمة",
      Enterpackageinformationhere: "أدخل معلومات الحزمة هنا",
      packagecover: "غلاف الحزمة",
      Addnewpackage: "إضافة حزمة جديدة",
      Thepackagehasatimerange: "الحزمة تحتوي على نطاق زمني",
      PackageManagement: "إدارة الحزم",
      Thiswherewemanageandupdateflirtsorpackages:
        "هنا حيث ندير ونحدث الغازل أو الحزم",
      AddNew: "إضافة جديد",
      RoleSettings: "إعدادات الدور",
      AddnewModeratedAccount: "إضافة حساب معتدل جديد",
      Enterinformationhere: "أدخل المعلومات هنا",
      Save: "حفظ",
      SendMassPoke: "إرسال تعليق جماعي",
      YourPOKESwillgoouttotheseusers: "سيتم إرسال تعليقاتك إلى هؤلاء المستخدمين",
      SendMassLikes: "إرسال إعجاب جماعي",
      YourLIKESwillgoouttotheseusers: "سيتم إرسال إعجاباتك إلى هؤلاء المستخدمين",
      SendMassSpam: "إرسال رسائل عشوائية جماعية",
      Yourmessagewillgoouttotheseusers:
        "سيتم إرسال رسالتك إلى هؤلاء المستخدمين",
      SendSpam: "إرسال رسائل عشوائية",
      SendMassEmail: "إرسال بريد إلكتروني جماعي",
      Emailmessageswillgoouttotheseusers:
        "سيتم إرسال رسائل بريدك الإلكتروني إلى هؤلاء المستخدمين",
      SendEmail: "إرسال بريد إلكتروني",

      Sendmessageandreachouttousershere:
        "إرسال رسالة والتواصل مع المستخدمين هنا",
      Search: "بحث",
      Unknown: "غير معروف",
      NOTSET: "غير مضبوط",
      Youruseraccounts: "حسابات المستخدم الخاصة بك",
      SellingNudes: "بيع الصور العارية",
      SellingGifts: "بيع الهدايا",
      UpdatePhotos: "تحديث الصور",
      Agree: "موافق",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "لقد حذرك المسؤول. يرجى الموافقة على أن هذا لن يحدث مرة أخرى",
      Senddirectchatmessage: "إرسال رسالة دردشة مباشرة",
      Dashboard: "لوحة القيادة",
    },

    // Koreanisch

    // Iranisches Persisch
    Thailändische: {
      Job: "งาน",
WorkingSchedule: "ตารางการทำงาน",
RelationshipStatus: "สถานะความสัมพันธ์",
Searchingfor: "กำลังมองหา",
pastRelationships: "ความสัมพันธ์ในอดีต",
TraitsforIdealPartner: "ลักษณะของคู่ในอุดมคติ",
FuturePlanswithPartner: "แผนอนาคตร่วมกับคู่รัก",
      name: "ชื่อ",
      location: "สถานที่",
      birthDay: "วันเกิด",
      SellingImages: "ขายรูปภาพ",
      Images: "รูปภาพ",
      AgentID: "รหัสตัวแทน",
      Actions: "การดำเนินการ",
      Rank: "อันดับ",
      EventPrice: "ราคาเหตุการณ์",
      Answers: "คำตอบ",
      Operator: "ผู้ดำเนินการ",
      Settings: "การตั้งค่า",
      HoursSpent: "ชั่วโมงที่ใช้",
      SendMessage: "ส่งข้อความ",
      Messages: "ข้อความ",
      EventDetail: "รายละเอียดเหตุการณ์",
      Audience: "กลุ่มเป้าหมาย",
      EventDate: "วันที่เหตุการณ์",
      ActiveStatus: "สถานะที่ใช้งานอยู่",
      CreatedAt: "สร้างเมื่อ",
      Location: "สถานที่ตั้ง",
      PackageType: "ประเภทแพ็คเกจ",
      FlirtsAmount: "จำนวนคำพูดโสม",
      CreatedDate: "วันที่สร้าง",
      Price: "ราคา",
      Total: "รวม",
      PackageName: "ชื่อแพ็คเกจ",
      PackagesDescription: "คำอธิบายแพ็คเกจ",
      AccountType: "ประเภทบัญชี",
      OnlineStatus: "สถานะออนไลน์",
      PaymentDate: "วันที่ชำระเงิน",
      SelectFake: "เลือกเท็จ",
      Moderators: "ผู้ดูแลระบบ",
      Conversions: "การแปลง",
      FullName: "ชื่อเต็ม",
      Category: "หมวดหมู่",
      LastWeek: "สัปดาห์ที่แล้ว",
      RealTime: "เวลาจริง",
      AnswerRate: "อัตราการตอบกลับ",
      ModeratorTabs: "แท็บผู้ดูแลระบบ",
      Messagesrecieved: "ข้อความที่ได้รับ (เข้า)",
      Lengthoffirstmesssage: "ความยาวของข้อความแรก",
      MessagesSent: "ข้อความที่ส่ง (ออก)",
      ShowOverlay: "แสดงโอเวอร์เลย์",
      Gallery: "แกลเลอรี",
      Statistics: "สถิติ",
      Community: "ชุมชน",
      Coupons: "คูปอง",
      Packages: "แพ็คเกจ",
      Transfer: "โอน",
      SKIP: "ข้าม",
      Today: "วันนี้",
      LasWeek: "สัปดาห์ที่แล้ว",
      ThisWeek: "สัปดาห์นี้",
      ThisMonth: "เดือนนี้",
      LastMonth: "เดือนที่แล้ว",
      MessageReported: "รายงานข้อความ",
      report: "รายงาน",
      TimeElapsed: "เวลาผ่านไป",
      Affilates: "พันธมิตร",
      Finance: "การเงิน",
      Events: "เหตุการณ์",
      Promotion: "ผู้ใช้",
      Status: "สถานะ",
      FakeAccounts: "บัญชีปลอม",
      AgentOverview: "ภาพรวมของตัวแทน",
      LiveMonitoring: "การตรวจสอบสด",
      MessageStatistics: "สถิติข้อความ",
      ActivityAnalysis: "การวิเคราะห์กิจกรรม",
      AgentActivity: "กิจกรรมของตัวแทน",
      ChatModeration: "การดูแลแชท",
      Reportauser: "รายงานผู้ใช้",
      startChatting: "เริ่มการสนทนา",
      UserProfile: "โปรไฟล์ผู้ใช้",
      Change: "เปลี่ยนแปลง",
      Owner: "เจ้าของ",
      SaveChanges: "บันทึกการเปลี่ยนแปลง",
      UpdateAffilateData: "อัปเดตข้อมูลพันธมิตร",
      AddAffilate: "เพิ่มพันธมิตร",
      addnewaffilite: "เพิ่มพันธมิตร / ผู้สร้าง / ผู้มีอิทธิพลใหม่ที่นี่",
      AffilatesManagement: "การจัดการพันธมิตร",
      Cancel: "ยกเลิก",
      OK: "ตกลง",
      Youhavebeenblocked: "คุณถูกบล็อกแล้ว",
      NoPoolsFoundPleaseRefreshinaWhile: "ไม่พบบริเวณ โปรดรีเฟรชในภายหลัง",
      GenerateVoucher: "สร้างบัตรกำนัล",
      AddCoupons: "เพิ่มคูปอง",
      createnewcouponshere: "สร้างคูปองใหม่ที่นี่",
      Remove: "ลบ",
      Onetime: "ครั้งหนึ่ง",
      Welcome: "ยินดีต้อนรับ",
      DialogExplorer: "เอ็กซ์พลอเรอร์ของข้อความ",
      Updateeventdetailsfor: "อัปเดตรายละเอียดเหตุการณ์สำหรับ",
      AddnewFlirtTooleventhere: "เพิ่มเหตุการณ์ FlirtTool ใหม่ที่นี่",
      SaveEvent: "บันทึกเหตุการณ์",
      AddanewEvent: "เพิ่มเหตุการณ์ใหม่",
      Addnewchateventhere: "เพิ่มเหตุการณ์แชทใหม่ที่นี่",
      eventcover: "ปกเหตุการณ์",
      cover: "ปก",
      Active: "ใช้งาน",
      EventName: "ชื่อเหตุการณ์",
      FirstName: "ชื่อ",
      LastName: "นามสกุล",
      AddEvents: "เพิ่มเหตุการณ์",
      AddFakeAccount: "เพิ่มบัญชีปลอม",
      Close: "ปิด",
      Profile: "โปรไฟล์",
      ProfilePic: "รูปโปรไฟล์",
      Gender: "เพศ",
      UpdateProfilePic: "อัปเดตรูปโปรไฟล์",
      Noprofilephotos: "ไม่มีรูปโปรไฟล์",
      Looks: "รูปลักษณ์",
      LifeStyle: "ไลฟ์สไตล์",
      EmailUsername: "อีเมลหรือชื่อผู้ใช้",
      Username: "ชื่อผู้ใช้",
      Overview: "ภาพรวม",
      Thisweek: "สัปดาห์นี้",
      Earnings: "รายได้",
      MontlyStatistics: "สถิติรายเดือน",
      Login: "เข้าสู่ระบบ",
      Addnewmoderator: "เพิ่มผู้ดูแลระบบใหม่",
      Enterinformationhere: "ป้อนข้อมูลที่นี่",
      AddModerator: "เพิ่มผู้ดูแลระบบ",
      Updatepackage: "อัปเดตแพ็คเกจ",
      Enterpackageinformationhere: "ป้อนข้อมูลแพ็คเกจที่นี่",
      packagecover: "ปกแพ็คเกจ",
      Addnewpackage: "เพิ่มแพ็คเกจใหม่",
      Thepackagehasatimerange: "แพ็คเกจมีช่วงเวลา",
      PackageManagement: "การจัดการแพ็คเกจ",
      Thiswherewemanageandupdateflirtsorpackages:
        "ที่นี่คือที่ที่เราจัดการและอัปเดตการละเลยหรือแพ็คเกจ",
      AddNew: "เพิ่มใหม่",
      RoleSettings: "การตั้งค่าบทบาท",
      AddnewModeratedAccount: "เพิ่มบัญชีที่ควบคุมได้ใหม่",
      Enterinformationhere: "ป้อนข้อมูลที่นี่",
      Save: "บันทึก",
      SendMassPoke: "ส่งโพคจำนวนมาก",
      YourPOKESwillgoouttotheseusers: "โพคของคุณจะถูกส่งไปยังผู้ใช้เหล่านี้",
      SendMassLikes: "ส่งปัจจุบันเป็นจำนวนมาก",
      YourLIKESwillgoouttotheseusers: "ปัจจุบันของคุณจะถูกส่งไปยังผู้ใช้เหล่านี้",
      SendMassSpam: "ส่งสแปมจำนวนมาก",
      Yourmessagewillgoouttotheseusers:
        "ข้อความของคุณจะถูกส่งไปยังผู้ใช้เหล่านี้",
      SendSpam: "ส่งสแปม",
      SendMassEmail: "ส่งอีเมลจำนวนมาก",
      Emailmessageswillgoouttotheseusers:
        "ข้อความอีเมลของคุณจะถูกส่งไปยังผู้ใช้เหล่านี้",
      SendEmail: "ส่งอีเมล",

      Sendmessageandreachouttousershere:
        "ส่งข้อความและติดต่อผู้ใช้ที่นี่",
      Search: "ค้นหา",
      Unknown: "ไม่รู้จัก",
      NOTSET: "ไม่ได้ตั้งค่า",
      Youruseraccounts: "บัญชีผู้ใช้ของคุณ",
      SellingNudes: "ขายรูปภาพเปลือย",
      SellingGifts: "ขายของขวัญ",
      UpdatePhotos: "อัปเดตรูปภาพ",
      Agree: "ยอมรับ",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "คุณได้รับการเตือนโดยผู้ดูแลแล้ว โปรดยอมรับว่านี่จะไม่เกิดขึ้นอีกครั้ง",
      Senddirectchatmessage: "ส่งข้อความแชทโดยตรง",
      Dashboard: "แดชบอร์ด",
    },

    // Iranisches Persisch
    // Hausa
    ungarisch: {
      name: "Név",
      location: "Helyszín",
      birthDay: "Születésnap",
      SellingImages: "Képek eladása",
      Images: "Képek",
      AgentID: "Ügynökazonosító",
      Actions: "Műveletek",
      Rank: "Rang",
      EventPrice: "Eseményár",
      Answers: "Válaszok",
      Operator: "Operátor",
      Settings: "Beállítások",
      HoursSpent: "Eltöltött órák",
      SendMessage: "Üzenet küldése",
      Messages: "Üzenetek",
      EventDetail: "Esemény részletei",
      Audience: "Közönség",
      EventDate: "Esemény dátuma",
      ActiveStatus: "Aktív állapot",
      CreatedAt: "Létrehozva",
      Location: "Helyszín",
      PackageType: "Csomag típusa",
      FlirtsAmount: "Flörtök száma",
      CreatedDate: "Létrehozás dátuma",
      Price: "Ár",
      Total: "Összesen",
      PackageName: "Csomagnév",
      PackagesDescription: "Csomagok leírása",
      AccountType: "Fióktípus",
      OnlineStatus: "Online állapot",
      PaymentDate: "Fizetés dátuma",
      SelectFake: "Hamis kiválasztása",
      Moderators: "Moderátorok",
      Conversions: "Átalakítások",
      FullName: "Teljes név",
      Category: "Kategória",
      LastWeek: "Elmúlt hét",
      RealTime: "Valós idő",
      AnswerRate: "Válaszarány",
      ModeratorTabs: "Moderátor lapok",
      Messagesrecieved: "Beérkező üzenetek (BE)",
      Lengthoffirstmesssage: "Az első üzenet hossza",
      MessagesSent: "Küldött üzenetek (KI)",
      ShowOverlay: "Fedő réteg megjelenítése",
      Gallery: "Galéria",
      Statistics: "Statisztikák",
      Community: "Közösség",
      Coupons: "Kuponok",
      Packages: "Csomagok",
      Transfer: "Átvitel",
      SKIP: "KIHAGYÁS",
      Today: "Ma",
      LasWeek: "Elmúlt hét",
      ThisWeek: "Ezen a héten",
      ThisMonth: "Ebben a hónapban",
      LastMonth: "Előző hónap",
      MessageReported: "Üzenet jelentve",
      report: "Jelentés",
      TimeElapsed: "Eltelt idő",
      Affilates: "Partnerek",
      Finance: "Pénzügyek",
      Events: "Események",
      Promotion: "Felhasználók",
      Status: "Állapot",
      FakeAccounts: "Hamis fiókok",
      AgentOverview: "Ügynök áttekintése",
      LiveMonitoring: "Élő ellenőrzés",
      MessageStatistics: "Üzenet statisztikák",
      ActivityAnalysis: "Tevékenység elemzés",
      AgentActivity: "Ügynök tevékenysége",
      ChatModeration: "Chat moderáció",
      Reportauser: "Felhasználó jelentése",
      startChatting: "Beszélgetés indítása",
      UserProfile: "Felhasználói profil",
      Change: "Változtatás",
      Owner: "Tulajdonos",
      SaveChanges: "Változtatások mentése",
      UpdateAffilateData: "Partner adatok frissítése",
      AddAffilate: "Partner hozzáadása",
      addnewaffilite: "Új partnerek / alkotók / befolyásolók hozzáadása itt",
      AffilatesManagement: "Partnerek kezelése",
      Cancel: "Mégse",
      OK: "OK",
      Youhavebeenblocked: "Letiltva lettél",
      NoPoolsFoundPleaseRefreshinaWhile: "Nincs találat. Kérlek frissíts egy kicsit",
      GenerateVoucher: "Kupon generálása",
      AddCoupons: "Kuponok hozzáadása",
      createnewcouponshere: "Új kuponok létrehozása itt",
      Remove: "Eltávolítás",
      Onetime: "Egyszer",
      Welcome: "Üdvözlet",
      DialogExplorer: "Párbeszéd felfedező",
      Updateeventdetailsfor: "Esemény részleteinek frissítése a számára",
      AddnewFlirtTooleventhere: "Új FlirtTool esemény hozzáadása itt",
      SaveEvent: "Esemény mentése",
      AddanewEvent: "Új esemény hozzáadása",
      Addnewchateventhere: "Új beszélgetési esemény hozzáadása itt",
      eventcover: "Esemény borító",
      cover: "Borító",
      Active: "Aktív",
      EventName: "Esemény neve",
      FirstName: "Keresztnév",
      LastName: "Vezetéknév",
      AddEvents: "Események hozzáadása",
      AddFakeAccount: "Hamis fiók hozzáadása",
      Close: "Bezárás",
      Profile: "Profil",
      ProfilePic: "Profilkép",
      Gender: "Nem",
      UpdateProfilePic: "Profilkép frissítése",
      Noprofilephotos: "Nincsenek profilfotók",
      Looks: "Megjelenés",
      LifeStyle: "Életmód",
      EmailUsername: "E-mail vagy felhasználónév",
      Username: "Felhasználónév",
      Overview: "Áttekintés",
      Thisweek: "Ezen a héten",
      Earnings: "Jövedelem",
      MontlyStatistics: "Havi statisztikák",
      Login: "Bejelentkezés",
      Addnewmoderator: "Új moderátor hozzáadása",
      Enterinformationhere: "Adja meg az információkat itt",
      AddModerator: "Moderátor hozzáadása",
      Updatepackage: "Csomag frissítése",
      Enterpackageinformationhere: "Adja meg a csomag információit itt",
      packagecover: "Csomag borító",
      Addnewpackage: "Új csomag hozzáadása",
      Thepackagehasatimerange: "A csomagnak időtartománya van",
      PackageManagement: "Csomag kezelése",
      Thiswherewemanageandupdateflirtsorpackages:
        "Itt kezeljük és frissítjük a flörtöket vagy a csomagokat",
      AddNew: "Új hozzáadása",
      RoleSettings: "Szerepkör beállítások",
      AddnewModeratedAccount: "Új moderált fiók hozzáadása",
      Enterinformationhere: "Adja meg az információkat itt",
      Save: "Mentés",
      SendMassPoke: "Tömeges pöccintés küldése",
      YourPOKESwillgoouttotheseusers: "A PÖKKENTÉSEK el fognak jutni ezekhez a felhasználókhoz",
      SendMassLikes: "Tömeges kedvelések küldése",
      YourLIKESwillgoouttotheseusers: "A KEDVELÉSEID el fognak jutni ezekhez a felhasználókhoz",
      SendMassSpam: "Tömeges spam küldése",
      Yourmessagewillgoouttotheseusers:
        "Az üzeneted el fog jutni ezekhez a felhasználókhoz",
      SendSpam: "Spam küldése",
      SendMassEmail: "Tömeges e-mail küldése",
      Emailmessageswillgoouttotheseusers:
        "Az e-mail üzenetek el fognak jutni ezekhez a felhasználókhoz",
      SendEmail: "E-mail küldése",

      Sendmessageandreachouttousershere:
        "Üzenet küldése és kapcsolatfelvétel a felhasználókkal itt",
      Search: "Keresés",
      Unknown: "Ismeretlen",
      NOTSET: "Nincs beállítva",
      Youruseraccounts: "Felhasználói fiókjaid",
      SellingNudes: "Meztelenek eladása",
      SellingGifts: "Ajándékok eladása",
      UpdatePhotos: "Fotók frissítése",
      Agree: "Egyetértek",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Figyelmeztetés adminisztrátor által. Kérlek egyezz bele, hogy ez többé nem fordul elő",
      Senddirectchatmessage: "Közvetlen chat üzenet küldése",
      Dashboard: "Irányítópult",
    },

    // Hausa

    // Ägyptisch-Arabisch

    arz: {
      SellingImages: "بيع الصور",
      Images: "الصور",
      AgentID: "هوية الوكيل",
      Actions: "الإجراءات",
      Rank: "الرتبة",
      EventPrice: "سعر الحدث",
      Answers: "الإجابات",
      Operator: "المشغل",
      Settings: "الإعدادات",
      HoursSpent: "ساعات مستغرقة",
      SendMessage: "إرسال رسالة",
      Messages: "الرسائل",
      EventDetail: "تفاصيل الحدث",
      Audience: "الجمهور",
      EventDate: "تاريخ الحدث",
      ActiveStatus: "الحالة النشطة",
      CreatedAt: "تم الإنشاء في",
      Location: "الموقع",
      PackageType: "نوع الحزمة",
      FlirtsAmount: "عدد الغمزات",
      CreatedDate: "تاريخ الإنشاء",
      Price: "السعر",
      Total: "الإجمالي",
      PackageName: "اسم الحزمة",
      PackagesDescription: "وصف الحزم",
      AccountType: "نوع الحساب",
      OnlineStatus: "الحالة الإلكترونية",
      PaymentDate: "تاريخ الدفع",
      SelectFake: "تحديد مزيف",
      Moderators: "المشرفون",
      Conversions: "التحويلات",
      FullName: "الاسم الكامل",
      Category: "الفئة",
      LastWeek: "الأسبوع الماضي",
      RealTime: "في الوقت الحقيقي",
      AnswerRate: "معدل الرد",
      ModeratorTabs: "ألسنة المشرف",
      Messagesrecieved: "الرسائل المستلمة (دخول)",
      Lengthoffirstmesssage: "طول أول رسالة",
      MessagesSent: "الرسائل المرسلة (خروج)",
      ShowOverlay: "عرض التراكب",
      Gallery: "المعرض",
      Statistics: "الإحصائيات",
      Community: "المجتمع",
      Coupons: "القسائم",
      Packages: "الحزم",
      Transfer: "التحويل",
      SKIP: "تخطى",
      Today: "اليوم",
      LasWeek: "الأسبوع الماضي",
      ThisWeek: "هذا الأسبوع",
      ThisMonth: "هذا الشهر",
      LastMonth: "الشهر الماضي",
      MessageReported: "تم الإبلاغ عن الرسالة",
      report: "الإبلاغ",
      TimeElapsed: "الوقت المنقضي",
      Affilates: "الشركاء",
      Finance: "المالية",
      Events: "الأحداث",

      Status: "الحالة",
      FakeAccounts: "الحسابات المزيفة",
      AgentOverview: "نظرة عامة على الوكيل",
      LiveMonitoring: "المراقبة الحية",
      MessageStatistics: "إحصائيات الرسالة",
      ActivityAnalysis: "تحليل النشاط",
      AgentActivity: "نشاط الوكيل",
      ChatModeration: "مراقبة الدردشة",
      Reportauser: "الإبلاغ عن مستخدم",
      startChatting: "بدء الدردشة",
      UserProfile: "ملف المستخدم",
      Change: "تغيير",
      Owner: "المالك",
      SaveChanges: "حفظ التغييرات",
      UpdateAffilateData: "تحديث بيانات الشريك",
      AddAffilate: "إضافة شريك",
      addnewaffilite: "إضافة شركاء / مبدعين / مؤثرين جدد هنا",
      AffilatesManagement: "إدارة الشركاء",
      Cancel: "إلغاء",
      OK: "موافق",
      Youhavebeenblocked: "لقد تم حظرك",
      NoPoolsFoundPleaseRefreshinaWhile: "لم يتم العثور على حمامات. يرجى تحديث بعد لحظة",
      GenerateVoucher: "إنشاء قسيمة",
      AddCoupons: "إضافة قسائم",
      createnewcouponshere: "إنشاء قسائم جديدة هنا",
      Remove: "إزالة",
      Onetime: "مرة واحدة",
      Welcome: "مرحبًا",
      DialogExplorer: "استكشاف الحوار",
      Updateeventdetailsfor: "تحديث تفاصيل الحدث لـ",
      AddnewFlirtTooleventhere: "إضافة حدث جديد لـ FlirtTool هنا",
      SaveEvent: "حفظ الحدث",
      AddanewEvent: "إضافة حدث جديد",
      Addnewchateventhere: "إضافة حدث دردشة جديد هنا",
      eventcover: "غطاء الحدث",
      cover: "الغلاف",
      Active: "نشط",
      EventName: "اسم الحدث",
      FirstName: "الاسم الأول",
      LastName: "الاسم الأخير",
      AddEvents: "إضافة الأحداث",
      AddFakeAccount: "إضافة حساب مزيف",
      Close: "إغلاق",
      Profile: "الملف الشخصي",
      ProfilePic: "الصورة الشخصية",
      Gender: "الجنس",
      UpdateProfilePic: "تحديث الصورة الشخصية",
      Noprofilephotos: "لا توجد صور ملف شخصي",
      Looks: "المظهر",
      LifeStyle: "نمط الحياة",
      EmailUsername: "البريد الإلكتروني أو اسم المستخدم",
      Username: "اسم المستخدم",
      Overview: "نظرة عامة",
      Thisweek: "هذا الأسبوع",
      Earnings: "الأرباح",
      MontlyStatistics: "الإحصائيات الشهرية",
      Login: "تسجيل الدخول",
      Addnewmoderator: "إضافة مشرف جديد",
      Enterinformationhere: "أدخل المعلومات هنا",
      AddModerator: "إضافة مشرف",
      Updatepackage: "تحديث الحزمة",
      Enterpackageinformationhere: "أدخل معلومات الحزمة هنا",
      packagecover: "غطاء الحزمة",
      Addnewpackage: "إضافة حزمة جديدة",
      Thepackagehasatimerange: "الحزمة لديها نطاق زمني",
      PackageManagement: "إدارة الحزم",
      Thiswherewemanageandupdateflirtsorpackages:
        "هنا ندير ونحدث التقاعد أو الحزم",
      AddNew: "إضافة جديد",
      RoleSettings: "إعدادات الدور",
      AddnewModeratedAccount: "إضافة حساب معتدل جديد",
      Enterinformationhere: "أدخل المعلومات هنا",
      Save: "حفظ",
      SendMassPoke: "إرسال Pokes الجماعية",
      YourPOKESwillgoouttotheseusers: "سوف تذهب POKES الخاصة بك إلى هؤلاء المستخدمين",
      SendMassLikes: "إرسال Likes جماعية",
      YourLIKESwillgoouttotheseusers: "سوف يذهب LIKES الخاص بك إلى هؤلاء المستخدمين",
      SendMassSpam: "إرسال رسائل مزعجة جماعية",
      Yourmessagewillgoouttotheseusers:
        "سوف يذهب رسالتك إلى هؤلاء المستخدمين",
      SendSpam: "إرسال رسالة مزعجة",
      SendMassEmail: "إرسال بريد إلكتروني جماعي",
      Emailmessageswillgoouttotheseusers:
        "سوف تذهب رسائل البريد الإلكتروني إلى هؤلاء المستخدمين",
      SendEmail: "إرسال بريد إلكتروني",
      Promotion: "المستخدمين",
      Sendmessageandreachouttousershere:
        "إرسال رسالة والتواصل مع المستخدمين هنا",
      Search: "بحث",
      Unknown: "غير معروف",
      NOTSET: "غير مضبوط",
      Youruseraccounts: "حسابات المستخدم الخاصة بك",
      SellingNudes: "بيع العارية",
      SellingGifts: "بيع الهدايا",
      UpdatePhotos: "تحديث الصور",
      Agree: "موافق",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "لقد تم تحذيرك من قبل المسؤول. يرجى الموافقة على أن هذا لن يحدث مرة أخرى",
      Senddirectchatmessage: "إرسال رسالة دردشة مباشرة",
      Dashboard: "لوحة القيادة",
    },

    // Ägyptisch-Arabisch
    // Swahili
    Swahili: {
      SellingImages: "Kuuza Picha",
      Images: "Picha",
      AgentID: "Kitambulisho cha Wakala",
      Actions: "Vitendo",
      Rank: "Nafasi",
      EventPrice: "Bei ya Tukio",
      Answers: "Majibu",
      Operator: "Msimamizi",
      Settings: "Mipangilio",
      HoursSpent: "Masaa Yaliyotumika",
      SendMessage: "Tuma Ujumbe",
      Messages: "Ujumbe",
      EventDetail: "Maelezo ya Tukio",
      Audience: "Wasikilizaji",
      EventDate: "Tarehe ya Tukio",
      ActiveStatus: "Hali ya Aktiviti",
      CreatedAt: "Imetengenezwa",
      Location: "Eneo",
      PackageType: "Aina ya Pakiti",
      FlirtsAmount: "Idadi ya Flirts",
      CreatedDate: "Tarehe ya Kuanzishwa",
      Price: "Bei",
      Total: "Jumla",
      PackageName: "Jina la Pakiti",
      PackagesDescription: "Maelezo ya Pakiti",
      AccountType: "Aina ya Akaunti",
      OnlineStatus: "Hali ya Mtandaoni",
      PaymentDate: "Tarehe ya Malipo",
      SelectFake: "Chagua Bandia",
      Moderators: "Wamodilisha",
      Conversions: "Mabadiliko",
      FullName: "Jina Kamili",
      Category: "Jamii",
      LastWeek: "Wiki Iliyopita",
      RealTime: "Wakati Halisi",
      AnswerRate: "Kiwango cha Majibu",
      ModeratorTabs: "Vijisehemu vya Wamodilisha",
      Messagesrecieved: "Ujumbe Uliopokelewa (Kuingia)",
      Lengthoffirstmesssage: "Urefu wa Ujumbe wa Kwanza",
      MessagesSent: "Ujumbe Uliotumwa (Kutoka)",
      ShowOverlay: "Onyesha Kufunika",
      Gallery: "Maktaba",
      Statistics: "Takwimu",
      Community: "Jumuiya",
      Coupons: "Kuponi",
      Packages: "Pakiti",
      Transfer: "Kuhamisha",
      SKIP: "RUKIA",
      Today: "Leo",
      LasWeek: "Wiki Iliyopita",
      ThisWeek: "Wiki Hii",
      ThisMonth: "Mwezi Huu",
      LastMonth: "Mwezi Uliopita",
      MessageReported: "Ujumbe Umeripotiwa",
      report: "Ripoti",
      TimeElapsed: "Muda Umeisha",
      Affilates: "Washirika",
      Finance: "Fedha",
      Events: "Matukio",
      Promotion: "Watumiaji",
      Status: "Hali",
      FakeAccounts: "Akaunti Bandia",
      AgentOverview: "Muhtasari wa Wakala",
      LiveMonitoring: "Ufuatiliaji Moja kwa Moja",
      MessageStatistics: "Takwimu za Ujumbe",
      ActivityAnalysis: "Uchambuzi wa Shughuli",
      AgentActivity: "Shughuli za Wakala",
      ChatModeration: "Kudhibiti Mazungumzo",
      Reportauser: "Ripoti Mtumiaji",
      startChatting: "Anza Mazungumzo",
      UserProfile: "Wasifu wa Mtumiaji",
      Change: "Badilisha",
      Owner: "Mmiliki",
      SaveChanges: "Hifadhi Mabadiliko",
      UpdateAffilateData: "Sasisha Data ya Washirika",
      AddAffilate: "Ongeza Mshirika",
      addnewaffilite: "Ongeza washirika/wabunifu/wahamasishaji hapa",
      AffilatesManagement: "Usimamizi wa Washirika",
      Cancel: "Ghairi",
      OK: "Sawa",
      Youhavebeenblocked: "Umefungwa",
      NoPoolsFoundPleaseRefreshinaWhile: "Hakuna Maji Yalipatikana. Tafadhali Rudia Baadaye",
      GenerateVoucher: "Zalisha Kibali",
      AddCoupons: "Ongeza Kuponi",
      createnewcouponshere: "Unda kuponi mpya hapa",
      Remove: "Ondoa",
      Onetime: "Kwa Mara moja",
      Welcome: "Karibu",
      DialogExplorer: "Mtafuta Maelezo",
      Updateeventdetailsfor: "Sasisha Maelezo ya Tukio kwa",
      AddnewFlirtTooleventhere: "Ongeza Tukio Jipya la FlirtTool hapa",
      SaveEvent: "Hifadhi Tukio",
      AddanewEvent: "Ongeza Tukio Jipya",
      Addnewchateventhere: "Ongeza Tukio la Mazungumzo Hapa",
      eventcover: "Kifuniko cha Tukio",
      cover: "Kifuniko",
      Active: "Aktiviti",
      EventName: "Jina la Tukio",
      FirstName: "Jina la Kwanza",
      LastName: "Jina la Mwisho",
      AddEvents: "Ongeza Matukio",
      AddFakeAccount: "Ongeza Akaunti Bandia",
      Close: "Funga",
      Profile: "Wasifu",
      ProfilePic: "Picha ya Wasifu",
      Gender: "Jinsia",
      UpdateProfilePic: "Sasisha Picha ya Wasifu",
      Noprofilephotos: "Hakuna Picha za Wasifu",
      Looks: "Muonekano",
      LifeStyle: "Mtindo wa Maisha",
      EmailUsername: "Barua pepe au Jina la Mtumiaji",
      Username: "Jina la Mtumiaji",
      Overview: "Muhtasari",
      Thisweek: "Wiki Hii",
      Earnings: "Mapato",
      MontlyStatistics: "Takwimu za Kila Mwezi",
      Login: "Ingia",
      Addnewmoderator: "Ongeza Msimamizi Mpya",
      Enterinformationhere: "Ingiza Taarifa Hapa",
      AddModerator: "Ongeza Msimamizi",
      Updatepackage: "Sasisha Pakiti",
      Enterpackageinformationhere: "Ingiza Taarifa za Pakiti Hapa",
      packagecover: "Kifuniko cha Pakiti",
      Addnewpackage: "Ongeza Pakiti Mpya",
      Thepackagehasatimerange: "Pakiti ina Wigo wa Wakati",
      PackageManagement: "Usimamizi wa Pakiti",
      Thiswherewemanageandupdateflirtsorpackages:
        "Hapa ndipo tunaposimamia na kusasisha mizaha au pakiti",
      AddNew: "Ongeza Mpya",
      RoleSettings: "Mipangilio ya Nafasi",
      AddnewModeratedAccount: "Ongeza Akaunti iliyodhibitiwa Mpya",
      Enterinformationhere: "Ingiza Taarifa Hapa",
      Save: "Hifadhi",
      SendMassPoke: "Tuma Pokes kwa Kundi",
      YourPOKESwillgoouttotheseusers: "POKES zako zitaenda kwa watumiaji hawa",
      SendMassLikes: "Tuma Likes kwa Kundi",
      YourLIKESwillgoouttotheseusers: "LIKES zako zitaenda kwa watumiaji hawa",
      SendMassSpam: "Tuma Ujumbe wa Barua Pepe kwa Kundi",
      Yourmessagewillgoouttotheseusers:
        "Ujumbe wako utaenda kwa watumiaji hawa",
      SendSpam: "Tuma Barua Pepe ya Barua Pepe",
      SendMassEmail: "Tuma Barua pepe kwa Kundi",
      Emailmessageswillgoouttotheseusers:
        "Ujumbe wa Barua pepe utaenda kwa watumiaji hawa",
      SendEmail: "Tuma Barua pepe",

      Sendmessageandreachouttousershere:
        "Tuma ujumbe na wasiliana na watumiaji hapa",
      Search: "Tafuta",
      Unknown: "Haijulikani",
      NOTSET: "HAUJAWEKWA",
      Youruseraccounts: "Akaunti zako za Mtumiaji",
      SellingNudes: "Kuuza Nguo",
      SellingGifts: "Kuuza Zawadi",
      UpdatePhotos: "Sasisha Picha",
      Agree: "Kubaliana",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Umeonywa na msimamizi. Tafadhali kubaliana kwamba hii haitatokea tena",
      Senddirectchatmessage: "Tuma Ujumbe wa Mazungumzo Moja kwa Moja",
      Dashboard: "Dashibodi",
    },

    // Swahili

    // Javanisch
    Javanisch: {
      SellingImages: "Mènèhi Gambar",
      Images: "Gambar",
      AgentID: "ID Agen",
      Actions: "Aksi",
      Rank: "Peringkat",
      EventPrice: "Harga Acara",
      Answers: "Jawaban",
      Operator: "Operator",
      Settings: "Pangaturan",
      HoursSpent: "Waktu Disedhiyaké",
      SendMessage: "Kirim Pesan",
      Messages: "Pesan",
      EventDetail: "Detil Acara",
      Audience: "Penonton",
      EventDate: "Tanggal Acara",
      ActiveStatus: "Status Aktif",
      CreatedAt: "Dijupuk ing",
      Location: "Panggonan",
      PackageType: "Jinis Paket",
      FlirtsAmount: "Jumlah Gombalan",
      CreatedDate: "Tanggal Dijupuk",
      Price: "Harga",
      Total: "Total",
      PackageName: "Jeneng Paket",
      PackagesDescription: "Pamarekan Paket",
      AccountType: "Jinis Akun",
      OnlineStatus: "Status Online",
      PaymentDate: "Tanggal Pembayaran",
      SelectFake: "Pilih Palsu",
      Moderators: "Moderator",
      Conversions: "Konversi",
      FullName: "Jeneng Lengkap",
      Category: "Kategori",
      LastWeek: "Minggu Iki",
      RealTime: "Waktu Nyata",
      AnswerRate: "Kadhaptar Jawaban",
      ModeratorTabs: "Tab Moderator",
      Messagesrecieved: "Pesan Diterima (Masuk)",
      Lengthoffirstmesssage: "Panjang Pesan Pertama",
      MessagesSent: "Pesan Dikirim (Keluar)",
      ShowOverlay: "Tampilake Tengahan",
      Gallery: "Galeri",
      Statistics: "Statistik",
      Community: "Komunitas",
      Coupons: "Kupen",
      Packages: "Paket",
      Transfer: "Pindah",
      SKIP: "LEWATI",
      Today: "Saiki",
      LasWeek: "Minggu Saben",
      ThisWeek: "Minggu Iki",
      ThisMonth: "Sasi Iki",
      LastMonth: "Sasi Saben",
      MessageReported: "Pesan Dilaporkan",
      report: "Laporkan",
      TimeElapsed: "Waktu Koyok",
      Affilates: "Afiliasi",
      Finance: "Keuangan",
      Events: "Acara",
      Promotion: "Panganggé",
      Status: "Status",
      FakeAccounts: "Akun Palsu",
      AgentOverview: "Ikhtisar Agen",
      LiveMonitoring: "Pantauan Langsung",
      MessageStatistics: "Statistik Pesan",
      ActivityAnalysis: "Analisis Kegiatan",
      AgentActivity: "Kegiatan Agen",
      ChatModeration: "Moderasi Obrolan",
      Reportauser: "Laporkan User",
      startChatting: "Mulai Ngobrol",
      UserProfile: "Profil Pengguna",
      Change: "Ganti",
      Owner: "Pemilik",
      SaveChanges: "Simpen Perubahan",
      UpdateAffilateData: "Perbarui Data Afiliasi",
      AddAffilate: "Tambah Afiliasi",
      addnewaffilite: "Tambahi afiliasi/kreator/pengaruh baru nang kene",
      AffilatesManagement: "Manajemen Afiliasi",
      Cancel: "Batal",
      OK: "OK",
      Youhavebeenblocked: "Sampeyan wis diblokir",
      NoPoolsFoundPleaseRefreshinaWhile: "Ora ana Pohone sing Ketemu. Mangga Segarake Sawetara",
      GenerateVoucher: "Gawe Voucher",
      AddCoupons: "Tambah Kupon",
      createnewcouponshere: "Gawe kupon anyar kene",
      Remove: "Pupus",
      Onetime: "Sekali",
      Welcome: "Sugeng Rawuh",
      DialogExplorer: "Eksplorer Dialog",
      Updateeventdetailsfor: "Perbarui detil acara kanggo",
      AddnewFlirtTooleventhere: "Tambahi acara FlirtTool anyar kene",
      SaveEvent: "Simpen Acara",
      AddanewEvent: "Tambah Acara Anyar",
      Addnewchateventhere: "Tambahi acara obrolan anyar kene",
      eventcover: "Cakupan acara",
      cover: "Cakupan",
      Active: "Aktif",
      EventName: "Jeneng Acara",
      FirstName: "Jeneng Awal",
      LastName: "Jeneng Akeh",
      AddEvents: "Tambahi Acara",
      AddFakeAccount: "Tambahi Akun Palsu",
      Close: "Tutup",
      Profile: "Profil",
      ProfilePic: "Gambar Profil",
      Gender: "Jenis Kelamin",
      UpdateProfilePic: "Perbarui Gambar Profil",
      Noprofilephotos: "Ora Ana Gambar Profil",
      Looks: "Tampilan",
      LifeStyle: "Gaya Hidup",
      EmailUsername: "Email utawa Juru Pungsi",
      Username: "Juru Pungsi",
      Overview: "Ikhtisar",
      Thisweek: "Minggu Iki",
      Earnings: "Pendapatan",
      MontlyStatistics: "Statistik Bulanan",
      Login: "Mlebu",
      Addnewmoderator: "Tambahi Moderator Anyar",
      Enterinformationhere: "Lebokke Infonya Kene",
      AddModerator: "Tambah Moderator",
      Updatepackage: "Perbarui Paket",
      Enterpackageinformationhere: "Lebokke Infonya Paket Kene",
      packagecover: "Cakupan Paket",
      Addnewpackage: "Tambah Paket Anyar",
      Thepackagehasatimerange: "Paket duwe wewatesan wektu",
      PackageManagement: "Manajemen Paket",
      Thiswherewemanageandupdateflirtsorpackages:
        "Neng kene sing kita atur lan perbarui gombalan utawa paket",
      AddNew: "Tambah Anyar",
      RoleSettings: "Pangaturan Peran",
      AddnewModeratedAccount: "Tambah Akun Didhakoni Anyar",
      Enterinformationhere: "Lebokke Infonya Kene",
      Save: "Simpen",
      SendMassPoke: "Kirim Poros Massal",
      YourPOKESwillgoouttotheseusers: "POKES Sampeyan bakal dikiwari kagem wong-wong iki",
      SendMassLikes: "Kirim Sukai Massal",
      YourLIKESwillgoouttotheseusers: "LIKES Sampeyan bakal dikiwari kagem wong-wong iki",
      SendMassSpam: "Kirim Spam Massal",
      Yourmessagewillgoouttotheseusers:
        "Pesan Sampeyan bakal dikiwari kagem wong-wong iki",
      SendSpam: "Kirim Spam",
      SendMassEmail: "Kirim Email Massal",
      Emailmessageswillgoouttotheseusers:
        "Pesen Email bakal dikiwari kagem wong-wong iki",
      SendEmail: "Kirim Email",

      Sendmessageandreachouttousershere:
        "Kirim pesan lan nyambung karo wong-wong neng kene",
      Search: "Goleki",
      Unknown: "Ora Dikenal",
      NOTSET: "ORA DISETEL",
      Youruseraccounts: "Akun Pengguna Sampeyan",
      SellingNudes: "Mènèhi Telanjang",
      SellingGifts: "Mènèhi Hadiah",
      UpdatePhotos: "Perbarui Gambar",
      Agree: "Setuju",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Sampeyan wis diwaswarna déning admin. Tresna padha setuju yen iki ora bakal kejadian manèh",
      Senddirectchatmessage: "Kirim Pesan Obrolan Langsung",
      Dashboard: "Dashboard",
    },
    // Javanisch

    // Italienisch
    Italienisch: {
      SellingImages: "Vendita di Immagini",
      Images: "Immagini",
      AgentID: "ID Agente",
      Actions: "Azioni",
      Rank: "Grado",
      EventPrice: "Prezzo dell'Evento",
      Answers: "Risposte",
      Operator: "Operatore",
      Settings: "Impostazioni",
      HoursSpent: "Ore Trascorse",
      SendMessage: "Invia Messaggio",
      Messages: "Messaggi",
      EventDetail: "Dettaglio dell'Evento",
      Audience: "Pubblico",
      EventDate: "Data dell'Evento",
      ActiveStatus: "Stato Attivo",
      CreatedAt: "Creato il",
      Location: "Posizione",
      PackageType: "Tipo di Pacchetto",
      FlirtsAmount: "Quantità di Flirt",
      CreatedDate: "Data di Creazione",
      Price: "Prezzo",
      Total: "Totale",
      PackageName: "Nome del Pacchetto",
      PackagesDescription: "Descrizione dei Pacchetti",
      AccountType: "Tipo di Account",
      OnlineStatus: "Stato Online",
      PaymentDate: "Data di Pagamento",
      SelectFake: "Seleziona Falso",
      Moderators: "Moderatori",
      Conversions: "Conversioni",
      FullName: "Nome Completo",
      Category: "Categoria",
      LastWeek: "Ultima Settimana",
      RealTime: "Tempo Reale",
      AnswerRate: "Tasso di Risposta",
      ModeratorTabs: "Schede del Moderatore",
      Messagesrecieved: "Messaggi Ricevuti (IN)",
      Lengthoffirstmesssage: "Lunghezza del Primo Messaggio",
      MessagesSent: "Messaggi Inviati (OUT)",
      ShowOverlay: "Mostra Sovrapposizione",
      Gallery: "Galleria",
      Statistics: "Statistiche",
      Community: "Comunità",
      Coupons: "Buoni",
      Packages: "Pacchetti",
      Transfer: "Trasferimento",
      SKIP: "SALTA",
      Today: "Oggi",
      LastWeek: "Scorsa Settimana",
      ThisWeek: "Questa Settimana",
      ThisMonth: "Questo Mese",
      LastMonth: "Mese Scorso",
      MessageReported: "Messaggio Segnalato",
      Report: "Segnala",
      TimeElapsed: "Tempo Trascorso",
      Affilates: "Affiliati",
      Finance: "Finanza",
      Events: "Eventi",
      Promotion: "Utenti",
      Status: "Stato",
      FakeAccounts: "Account Falsi",
      AgentOverview: "Panoramica Agente",
      LiveMonitoring: "Monitoraggio Live",
      MessageStatistics: "Statistiche Messaggi",
      ActivityAnalysis: "Analisi dell'Attività",
      AgentActivity: "Attività Agente",
      ChatModeration: "Moderazione Chat",
      Reportauser: "Segnala un Utente",
      startChatting: "Inizia a Chattare",
      UserProfile: "Profilo Utente",
      Change: "Cambia",
      Owner: "Proprietario",
      SaveChanges: "Salva Modifiche",
      UpdateAffilateData: "Aggiorna Dati Affiliato",
      AddAffilate: "Aggiungi Affiliato",
      Addnewaffilite: "Aggiungi nuovi affiliati/creatori/influencer qui",
      AffilatesManagement: "Gestione Affiliati",
      Cancel: "Annulla",
      OK: "OK",
      Youhavebeenblocked: "Sei stato Bloccato",
      NoPoolsFoundPleaseRefreshinaWhile: "Nessuna Piscina Trovata. Aggiorna tra un Po'",
      GenerateVoucher: "Genera Voucher",
      AddCoupons: "Aggiungi Buoni",
      createnewcouponshere: "Crea nuovi buoni qui",
      Remove: "Rimuovi",
      Onetime: "Una volta",
      Welcome: "Benvenuto",
      DialogExplorer: "Esploratore Dialoghi",
      Updateeventdetailsfor: "Aggiorna dettagli evento per",
      AddnewFlirtTooleventhere: "Aggiungi nuovo evento FlirtTool qui",
      SaveEvent: "Salva Evento",
      AddanewEvent: "Aggiungi un Nuovo Evento",
      Addnewchateventhere: "Aggiungi nuovo evento chat qui",
      eventcover: "copertura evento",
      cover: "copertura",
      Active: "Attivo",
      EventName: "Nome dell'Evento",
      FirstName: "Nome",
      LastName: "Cognome",
      AddEvents: "Aggiungi Eventi",
      AddFakeAccount: "Aggiungi Account Falso",
      Close: "Chiudi",
      Profile: "Profilo",
      ProfilePic: "Foto Profilo",
      Gender: "Genere",
      UpdateProfilePic: "Aggiorna Foto Profilo",
      Noprofilephotos: "Nessuna Foto Profilo",
      Looks: "Aspetto",
      LifeStyle: "Stile di Vita",
      EmailUsername: "Email o Nome Utente",
      Username: "Nome Utente",
      Overview: "Panoramica",
      Thisweek: "Questa Settimana",
      Earnings: "Guadagni",
      MontlyStatistics: "Statistiche Mensili",
      Login: "Accedi",
      Addnewmoderator: "Aggiungi Nuovo Moderatore",
      Enterinformationhere: "Inserisci Informazioni Qui",
      AddModerator: "Aggiungi Moderatore",
      Updatepackage: "Aggiorna Pacchetto",
      Enterpackageinformationhere: "Inserisci Informazioni Pacchetto Qui",
      packagecover: "copertura pacchetto",
      Addnewpackage: "Aggiungi Nuovo Pacchetto",
      Thepackagehasatimerange: "Il pacchetto ha un intervallo di tempo",
      PackageManagement: "Gestione Pacchetto",
      Thiswherewemanageandupdateflirtsorpackages:
        "Qui è dove gestiamo e aggiorniamo flirt o pacchetti",
      AddNew: "Aggiungi Nuovo",
      RoleSettings: "Impostazioni Ruolo",
      AddnewModeratedAccount: "Aggiungi Nuovo Account Moderato",
      Enterinformationhere: "Inserisci Informazioni Qui",
      Save: "Salva",
      SendMassPoke: "Invia Poke di Massa",
      YourPOKESwillgoouttotheseusers: "I TUOI POKES saranno inviati a questi utenti",
      SendMassLikes: "Invia Mi Piace di Massa",
      YourLIKESwillgoouttotheseusers: "I TUOI LIKES saranno inviati a questi utenti",
      SendMassSpam: "Invia Spam di Massa",
      Yourmessagewillgoouttotheseusers:
        "Il tuo messaggio sarà inviato a questi utenti",
      SendSpam: "Invia Spam",
      SendMassEmail: "Invia Email di Massa",
      Emailmessageswillgoouttotheseusers:
        "I messaggi email saranno inviati a questi utenti",
      SendEmail: "Invia Email",
      Promotion: "Utenti",
      Sendmessageandreachouttousershere:
        "Invia messaggio e contatta gli utenti qui",
      Search: "Ricerca",
      Unknown: "Sconosciuto",
      NOTSET: "NON IMPOSTATO",
      Youruseraccounts: "I Tuoi Account Utente",
      SellingNudes: "Vendita di Nudi",
      SellingGifts: "Vendita di Regali",
      UpdatePhotos: "Aggiorna Foto",
      Agree: "Accetta",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Sei stato avvertito dall'amministratore. Accetta che questo non accadrà più",
      Senddirectchatmessage: "Invia Messaggio Chat Diretta",
      Dashboard: "Cruscotto",
    },
    // Italienisch
    // Westpanjabi
    Westpanjabi: {
      SellingImages: "ਚਿੱਤਰ ਵੇਚਣਾ",
      Images: "ਚਿੱਤਰ",
      AgentID: "ਐਜੰਟ ਆਈਡੀ",
      Actions: "ਕਾਰਵਾਈਆਂ",
      Rank: "ਦਰਜਾ",
      EventPrice: "ਈਵੈਂਟ ਦੀ ਕੀਮਤ",
      Answers: "ਜਵਾਬਾਂ",
      Operator: "ਓਪਰੇਟਰ",
      Settings: "ਸੈਟਿੰਗਜ਼",
      HoursSpent: "ਸਮਾਂ ਬਿਤਾਇਆ",
      SendMessage: "ਸੁਨੇਹਾ ਭੇਜੋ",
      Messages: "ਸੁਨੇਹੇ",
      EventDetail: "ਈਵੈਂਟ ਵੇਰਵਾ",
      Audience: "ਸਮਾਗਮ",
      EventDate: "ਈਵੈਂਟ ਦਾ ਮਿਤੀ",
      ActiveStatus: "ਸਰਗਰਮ ਹਾਲਤ",
      CreatedAt: "ਤਿਆਰ ਕੀਤਾ",
      Location: "ਸਥਾਨ",
      PackageType: "ਪੈਕੇਜ ਦੀ ਕਿਸਮ",
      FlirtsAmount: "ਫਲਰਟਸ ਦੀ ਮਾਤਰਾ",
      CreatedDate: "ਬਣਾਈ ਦੀ ਮਿਤੀ",
      Price: "ਭਾਵ",
      Total: "ਕੁੱਲ",
      PackageName: "ਪੈਕੇਜ ਦਾ ਨਾਮ",
      PackagesDescription: "ਪੈਕੇਜ਼ ਦਾ ਵਰਣਨ",
      AccountType: "ਖਾਤਾ ਦੀ ਕਿਸਮ",
      OnlineStatus: "ਆਨਲਾਈਨ ਹਾਲਤ",
      PaymentDate: "ਭੁਗਤਾਨ ਦੀ ਮਿਤੀ",
      SelectFake: "ਝੂਠ ਚੁਣੋ",
      Moderators: "ਮਾਡਰੇਟਰ",
      Conversions: "ਰੂਪਾਂਤਰਣ",
      FullName: "ਪੂਰਾ ਨਾਂ",
      Category: "ਸ਼੍ਰੇਣੀ",
      LastWeek: "ਪਿਛਲੇ ਹਫਤੇ",
      RealTime: "ਅਸਲ ਸਮਾਂ",
      AnswerRate: "ਜਵਾਬ ਦੀ ਦਰ",
      ModeratorTabs: "ਮਾਡਰੇਟਰ ਟੈਬ",
      Messagesrecieved: "ਮੈਸੇਜ਼ ਪ੍ਰਾਪਤ (IN)",
      Lengthoffirstmesssage: "ਪਹਿਲੇ ਸੁਨੇਹੇ ਦੀ ਲੰਬਾਈ",
      MessagesSent: "ਸੁਨੇਹੇ ਭੇਜੇ (OUT)",
      ShowOverlay: "ਓਵਰਲੇ ਦਿਖਾਓ",
      Gallery: "ਗੈਲਰੀ",
      Statistics: "ਅੰਕੜੇ",
      Community: "ਕਮਿਊਨਿਟੀ",
      Coupons: "ਕੁਪਨ",
      Packages: "ਪੈਕੇਜ਼",
      Transfer: "ਸਥਾਨਤਰ",
      SKIP: "ਛੱਡੋ",
      Today: "ਅੱਜ",
      LastWeek: "ਪਿਛਲੇ ਹਫ਼ਤੇ",
      ThisWeek: "ਇਹ ਹਫ਼ਤਾ",
      ThisMonth: "ਇਹ ਮਹੀਨਾ",
      LastMonth: "ਪਿਛਲਾ ਮਹੀਨਾ",
      MessageReported: "ਸੁਨੇਹਾ ਰਿਪੋਰਟ ਕੀਤਾ",
      Report: "ਰਿਪੋਰਟ",
      TimeElapsed: "ਸਮਾਂ ਬਿਤਾਇਆ",
      Affilates: "ਸਹੇਲੇ",
      Finance: "ਵਿੱਤੀਆ",
      Events: "ਈਵੈਂਟ",

      Status: "ਸਥਿਤੀ",
      FakeAccounts: "ਜਾਲੀ ਖਾਤੇ",
      AgentOverview: "ਐਜੰਟ ਦਾ ਖੁਲਾਸਾ",
      LiveMonitoring: "ਲਾਈਵ ਨਿਗਰਾਨੀ",
      MessageStatistics: "ਸੁਨੇਹਾ ਅੰਕੜੇ",
      ActivityAnalysis: "ਸਰਗਰਮੀ ਵਿਸ਼ਲੇਸ਼ਣ",
      AgentActivity: "ਐਜੰਟ ਕਾਰਵਾਈ",
      ChatModeration: "ਚੈੱਟ ਮਾਡਰੇਸ਼ਨ",
      Reportauser: "ਉਪਭੋਗਤਾ ਰਿਪੋਰਟ ਕਰੋ",
      startChatting: "ਚੈਟ ਸ਼ੁਰੂ ਕਰੋ",
      UserProfile: "ਯੂਜ਼ਰ ਪ੍ਰੋਫ਼ਾਈਲ",
      Change: "ਬਦਲੋ",
      Owner: "ਮਾਲਕ",
      SaveChanges: "ਤਬਦੀਲੀਆਂ ਸੰਭਾਲੋ",
      UpdateAffilateData: "ਅਨੁਕੂਲਿਤ ਡੇਟਾ ਅੱਪਡੇਟ ਕਰੋ",
      AddAffilate: "ਸਹੇਲਾ ਸ਼ਾਮਲ ਕਰੋ",
      Addnewaffilite: "ਨਵੇਂ ਸਹੇਲੇ / ਸਰਜਨਾਏ / ਪ੍ਰਭਾਵੀ ਇੱਥੇ ਸ਼ਾਮਲ ਕਰੋ",
      AffilatesManagement: "ਸਹੇਲੇ ਪ੍ਰਬੰਧਨ",
      Cancel: "ਰੱਦ ਕਰੋ",
      OK: "ਠੀਕ ਹੈ",
      Youhavebeenblocked: "ਤੁਸੀਂ ਰੋਕ ਦਿੱਤਾ ਗਿਆ ਹੈ",
      NoPoolsFoundPleaseRefreshinaWhile: "ਕੋਈ ਪੂਲ ਨਹੀਂ ਮਿਲੀ ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਵਿਚ ਰੀਫਰੈਸ਼ ਕਰੋ",
      GenerateVoucher: "ਵਾਊਚਰ ਬਣਾਓ",
      AddCoupons: "ਕੁਪਨ ਸ਼ਾਮਲ ਕਰੋ",
      createnewcouponshere: "ਨਵੇਂ ਕੁਪਨ ਇੱਥੇ ਬਣਾਓ",
      Remove: "ਹਟਾਓ",
      Onetime: "ਇੱਕ ਵਾਰ",
      Welcome: "ਜੀ ਆਇਆਂ ਨੂੰ",
      DialogExplorer: "ਗੱਲਬਾਤ ਖੋਜਣ ਵਾਲਾ",
      Updateeventdetailsfor: "ਇਵੈਂਟ ਦੇ ਵੇਰਵੇ ਅੱਪਡੇਟ ਕਰੋ",
      AddnewFlirtTooleventhere: "ਇਥੇ ਨਵਾਂ ਫਲਰਟਟੂਲ ਈਵੈਂਟ ਸ਼ਾਮਲ ਕਰੋ",
      SaveEvent: "ਈਵੈਂਟ ਸੰਭਾਲੋ",
      AddanewEvent: "ਇਕ ਨਵਾਂ ਈਵੈਂਟ ਸ਼ਾਮਲ ਕਰੋ",
      Addnewchateventhere: "ਇਥੇ ਨਵਾਂ ਚੈੱਟ ਈਵੈਂਟ ਸ਼ਾਮਲ ਕਰੋ",
      eventcover: "ਇਵੈਂਟ ਕਵਰ",
      cover: "ਕਵਰ",
      Active: "ਸਰਗਰਮ",
      EventName: "ਈਵੈਂਟ ਦਾ ਨਾਂ",
      FirstName: "ਪਹਿਲਾ ਨਾਂ",
      LastName: "ਆਖਰੀ ਨਾਂ",
      AddEvents: "ਈਵੈਂਟ ਸ਼ਾਮਲ ਕਰੋ",
      AddFakeAccount: "ਜਾਲੀ ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ",
      Close: "ਬੰਦ ਕਰੋ",
      Profile: "ਪ੍ਰੋਫਾਈਲ",
      ProfilePic: "ਪ੍ਰੋਫਾਈਲ ਤਸਵੀਰ",
      Gender: "ਲਿੰਗ",
      UpdateProfilePic: "ਪ੍ਰੋਫਾਈਲ ਤਸਵੀਰ ਅੱਪਡੇਟ ਕਰੋ",
      Noprofilephotos: "ਕੋਈ ਪ੍ਰੋਫਾਈਲ ਫੋਟੋ ਨਹੀਂ",
      Looks: "ਦਿਖਾਵਾ",
      LifeStyle: "ਜੀਵਨ ਸਟਾਈਲ",
      EmailUsername: "ਈਮੇਲ ਜਾਂ ਯੂਜ਼ਰਨਾਮ",
      Username: "ਯੂਜ਼ਰਨਾਮ",
      Overview: "ਸੰਖੇਪ",
      Thisweek: "ਇਸ ਹਫ਼ਤੇ",
      Earnings: "ਕਮਾਈ",
      MontlyStatistics: "ਮਾਸਿਕ ਅੰਕੜੇ",
      Login: "ਲਾਗਇਨ",
      Addnewmoderator: "ਨਵਾਂ ਮੋਡਰੇਟਰ ਸ਼ਾਮਲ ਕਰੋ",
      Enterinformationhere: "ਇੱਥੇ ਜਾਣਕਾਰੀ ਦਿਓ",
      AddModerator: "ਮੋਡਰੇਟਰ ਸ਼ਾਮਲ ਕਰੋ",
      Updatepackage: "ਪੈਕੇਜ ਅੱਪਡੇਟ ਕਰੋ",
      Enterpackageinformationhere: "ਇੱਥੇ ਪੈਕੇਜ ਜਾਣਕਾਰੀ ਦਿਓ",
      packagecover: "ਪੈਕੇਜ ਕਵਰ",
      Addnewpackage: "ਨਵਾਂ ਪੈਕੇਜ ਸ਼ਾਮਲ ਕਰੋ",
      Thepackagehasatimerange: "ਪੈਕੇਜ ਦੇ ਸਮਾਂ ਦੇ ਮਿਆਰ",
      PackageManagement: "ਪੈਕੇਜ ਮੈਨੇਜਮੈਂਟ",
      Thiswherewemanageandupdateflirtsorpackages:
        "ਇਸ ਵਿੱਚ ਸਾਡੇ ਦੁਆਰਾ ਫਲਰਟ ਜਾਂ ਪੈਕੇਜ ਅਪਡੇਟ ਕੀਤੇ ਜਾਂਦੇ ਹਨ",
      AddNew: "ਨਵਾਂ ਸ਼ਾਮਲ ਕਰੋ",
      RoleSettings: "ਰੋਲ ਸੈਟਿੰਗਜ਼",
      AddnewModeratedAccount: "ਨਵਾਂ ਮੋਡਰੇਟ ਅਕਾਊਂਟ ਸ਼ਾਮਲ ਕਰੋ",
      Enterinformationhere: "ਇੱਥੇ ਜਾਣਕਾਰੀ ਦਿਓ",
      Save: "ਸੰਭਾਲੋ",
      SendMassPoke: "ਮੇਸ ਪੋਕ ਭੇਜੋ",
      YourPOKESwillgoouttotheseusers: "ਤੁਹਾਡੇ POKES ਇਹ ਯੂਜ਼ਰਾਂ ਨੂੰ ਭੇਜੇ ਜਾਣਗੇ",
      SendMassLikes: "ਮੇਸ ਲਾਈਕ ਭੇਜੋ",
      YourLIKESwillgoouttotheseusers: "ਤੁਹਾਡੇ LIKES ਇਹ ਯੂਜ਼ਰਾਂ ਨੂੰ ਭੇਜੇ ਜਾਣਗੇ",
      SendMassSpam: "ਮੇਸ ਸਪੈਮ ਭੇਜੋ",
      Yourmessagewillgoouttotheseusers:
        "ਤੁਹਾਡਾ ਸੁਨੇਹਾ ਇਹ ਯੂਜ਼ਰਾਂ ਨੂੰ ਭੇਜਿਆ ਜਾਵੇਗਾ",
      SendSpam: "ਸਪੈਮ ਭੇਜੋ",
      SendMassEmail: "ਮੇਸ ਈਮੇਲ ਭੇਜੋ",
      Emailmessageswillgoouttotheseusers:
        "ਈਮੇਲ ਸੁਨੇਹੇ ਇਹ ਯੂਜ਼ਰਾਂ ਨੂੰ ਭੇਜੇ ਜਾਣਗੇ",
      SendEmail: "ਈਮੇਲ ਭੇਜੋ",
      Promotion: "ਪ੍ਰੋਮੋਸ਼ਨ",
      Sendmessageandreachouttousershere:
        "ਸੁਨੇਹਾ ਭੇਜੋ ਅਤੇ ਯੂਜ਼ਰਾਂ ਨੂੰ ਇੱਥੇ ਸੰਪਰਕ ਕਰੋ",
      Search: "ਖੋਜ",
      Unknown: "ਅਣਜਾਣ",
      NOTSET: "ਸੈੱਟ ਨਹੀਂ ਕੀਤਾ",
      Youruseraccounts: "ਤੁਹਾਡੇ ਯੂਜ਼ਰ ਖਾਤੇ",
      SellingNudes: "ਨਗੇ ਵੇਚਣਾ",
      SellingGifts: "ਉਪਹਾਰ ਵੇਚਣਾ",
      UpdatePhotos: "ਤਸਵੀਰਾਂ ਅੱਪਡੇਟ ਕਰੋ",
      Agree: "ਸਹਿਮਤ",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "ਤੁਹਾਨੂੰ ਐਡਮਿਨ ਵਲੋਂ ਚੇਤਾਵਨੀ ਦਿੱਤੀ ਗਈ ਹੈ ਕਿ ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਦੀ ਮੁੱਲਾਕਾਤ ਨਹੀਂ ਹੋਵੇਗੀ",
      Senddirectchatmessage: "ਸਿਧੇ ਚੈੱਟ ਸੁਨੇਹਾ ਭੇਜੋ",
      Dashboard: "ਡੈਸ਼ਬੋਰਡ",
    }




    ,
    // Westpanjabi
    // Kannada
    Kannada: {
      SellingImages: "ಚಿತ್ರಗಳನ್ನು ಮಾರುಕಟ್ಟುವುದು",
      Images: "ಚಿತ್ರಗಳು",
      AgentID: "ಎಜೆಂಟ್ ಐಡಿ",
      Actions: "ಕ್ರಿಯೆಗಳು",
      Rank: "ದರ್ಜೆ",
      EventPrice: "ಈವೆಂಟ್ ಬೆಲೆ",
      Answers: "ಉತ್ತರಗಳು",
      Operator: "ಆಪರೇಟರ್",
      Settings: "ಸೆಟ್ಟಿಂಗುಗಳು",
      HoursSpent: "ಕಳೆದ ಗಂಟೆಗಳು",
      SendMessage: "ಸಂದೇಶ ಕಳುಹಿಸಿ",
      Messages: "ಸಂದೇಶಗಳು",
      EventDetail: "ಈವೆಂಟ್ ವಿವರ",
      Audience: "ಪ್ರೇಕ್ಷಕರು",
      EventDate: "ಈವೆಂಟ್ ದಿನಾಂಕ",
      ActiveStatus: "ಸಕ್ರಿಯ ಸ್ಥಿತಿ",
      CreatedAt: "ರಚಿಸಲಾಯಿತು",
      Location: "ಸ್ಥಳ",
      PackageType: "ಪ್ಯಾಕೇಜ್ ಪ್ರಕಾರ",
      FlirtsAmount: "ಫ್ಲರ್ಟ್ ಮೊತ್ತ",
      CreatedDate: "ರಚಿಸಲಾಯಿತು ದಿನಾಂಕ",
      Price: "ಬೆಲೆ",
      Total: "ಒಟ್ಟು",
      PackageName: "ಪ್ಯಾಕೇಜ್ ಹೆಸರು",
      PackagesDescription: "ಪ್ಯಾಕೇಜ್ ವಿವರಣೆ",
      AccountType: "ಖಾತೆಯ ರೀತಿ",
      OnlineStatus: "ಆನ್‌ಲೈನ್ ಸ್ಥಿತಿ",
      PaymentDate: "ಪಾವತಿ ದಿನಾಂಕ",
      SelectFake: "ಅನಿಷ್ಟ ಆಯ್ಕೆಮಾಡಿ",
      Moderators: "ಮಾಡರೇಟರ್‌ಗಳು",
      Conversions: "ರೂಪಾಂತರಣೆಗಳು",
      FullName: "ಪೂರ್ಣ ಹೆಸರು",
      Category: "ವರ್ಗ",
      LastWeek: "ಕೊನೆಯ ವಾರ",
      RealTime: "ವಾಸ್ತವಿಕ ಸಮಯ",
      AnswerRate: "ಉತ್ತರ ಹದಿ",
      ModeratorTabs: "ಮಾಡರೇಟರ್ ಟ್ಯಾಬ್‌ಗಳು",
      Messagesrecieved: "ಸಂದೇಶಗಳು ಸ್ವೀಕೃತ (ಇನ್‌ಗಳು)",
      Lengthoffirstmesssage: "ಮೊದಲ ಸಂದೇಶದ ಉದ್ದ",
      MessagesSent: "ಸಂದೇಶಗಳು ಕಳುಹಿಸಲಾಯಿತು (ಔಟ್‌ಗಳು)",
      ShowOverlay: "ಒಳಪ್ರತಿಸ್ಥಾಪನೆ ತೋರಿಸಿ",
      Gallery: "ಗ್ಯಾಲರಿ",
      Statistics: "ಪಟ್ಟಿಗಳು",
      Community: "ಸಮುದಾಯ",
      Coupons: "ಕೂಪನ್‌ಗಳು",
      Packages: "ಪ್ಯಾಕೇಜ್‌ಗಳು",
      Transfer: "ಹರಿಯಿಸು",
      SKIP: "ಸ್ಕಿಪ್",
      Today: "ಇಂದು",
      LasWeek: "ಕೊನೆಯ ವಾರ",
      ThisWeek: "ಈ ವಾರ",
      ThisMonth: "ಈ ತಿಂಗಳು",
      LastMonth: "ಕಳೆದ ತಿಂಗಳು",
      MessageReported: "ಸಂದೇಶ ವರದಿಗೆ ತಂದಿದೆ",
      report: "ವರದಿ",
      TimeElapsed: "ಕಾಲ ಹೋಗಿದೆ",
      Affilates: "ಅಫೀಲಿಯೇಟ್‌ಗಳು",
      Finance: "ಹಣಕಾಸು",
      Events: "ಘಟನೆಗಳು",
      Promotion: "ಪ್ರಚಾರ",
      Status: "ಸ್ಥಿತಿ",
      FakeAccounts: "ಕಪ್ಪು ಖಾತೆಗಳು",
      AgentOverview: "ಎಜೆಂಟ್ ಸಾರಾಂಶ",
      LiveMonitoring: "ಲೈವ್ ಮಾನಿಟರಿಂಗ್",
      MessageStatistics: "ಸಂದೇಶ ಪಟ್ಟಿಗಳು",
      ActivityAnalysis: "ಕ್ರಿಯಾ ವಿಶ್ಲೇಷಣೆ",
      AgentActivity: "ಎಜೆಂಟ್ ಕ್ರಿಯೆ",
      ChatModeration: "ಚಾಟ್ ಮಾಡರೇಶನ್",
      Reportauser: "ಬಳಕೆದಾರನನ್ನು ವರದಿಮಾಡಿ",
      startChatting: "ಚಾಟ್ ಪ್ರಾರಂಭಿಸಿ",
      UserProfile: "ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್",
      Change: "ಬದಲಾಯಿಸಿ",
      Owner: "ಮಾಲೀಕ",
      SaveChanges: "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಿ",
      UpdateAffilateData: "ಅಫಿಲೇಟ್ ಡೇಟಾ ಅಪ್ಡೇಟ್ ಮಾಡಿ",
      AddAffilate: "ಅಫಿಲೇಟ್ ಸೇರಿಸಿ",
      addnewaffilite: "ಇಲ್ಲಿ ಹೊಸ ಅಫಿಲಿಯೇಟ್/ಸೃಷ್ಟಿಕರ್ತೆ/ಪ್ರಭಾವಕರರನ್ನು ಸೇರಿಸಿ",
      AffilatesManagement: "ಅಫಿಲಿಯೇಟ್ ನಿರ್ವಹಣೆ",
      Cancel: "ರದ್ದುಮಾಡಿ",
      OK: "ಸರಿ",
      Youhavebeenblocked: "ನೀವು ಅಡಿಯಲ್ಲಿದ್ದೀರಿ",
      NoPoolsFoundPleaseRefreshinaWhile:
        "ಯಾವುದೇ ಪೂಲ್‌ಗಳು ಸಿಗಲಿಲ್ಲ. ದಯವಿಟ್ಟು ಕೆಲವು ನಿಮಿಷಗಳಾದರೂ ರಿಫ್ರೆಶ್ ಮಾಡಿ",
      GenerateVoucher: "ವೌಚರ್ ಉತ್ಪಾದಿಸಿ",
      AddCoupons: "ಕೂಪನ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      createnewcouponshere: "ಇಲ್ಲಿ ಹೊಸ ಕೂಪನ್‌ಗಳನ್ನು ರಚಿಸಿ",
      Remove: "ತೆಗೆದುಹಾಕಿ",
      Onetime: "ಒಂದು ಸಲ",
      Welcome: "ಸುಸ್ವಾಗತ",
      DialogExplorer: "ಸಂವಾದ ಅನ್ವೇಷಕ",
      Updateeventdetailsfor: "ಈವೆಂಟ್ ವಿವರಗಳನ್ನು ಅಪ್ಡೇಟ್ ಮಾಡಿ",
      AddnewFlirtTooleventhere: "ಇಲ್ಲಿ ಹೊಸ FlirtTool ಈವೆಂಟ್ ಸೇರಿಸಿ",
      SaveEvent: "ಈವೆಂಟ್ ಉಳಿಸಿ",
      AddanewEvent: "ಹೊಸ ಈವೆಂಟ್ ಸೇರಿಸಿ",
      Addnewchateventhere: "ಇಲ್ಲಿ ಹೊಸ ಚಾಟ್ ಈವೆಂಟ್ ಸೇರಿಸಿ",
      eventcover: "ಈವೆಂಟ್ ಮುಚ್ಚಲು",
      cover: "ಮುಚ್ಚಲು",
      Active: "ಸಕ್ರಿಯ",
      EventName: "ಈವೆಂಟ್ ಹೆಸರು",
      FirstName: "ಮೊದಲ ಹೆಸರು",
      LastName: "ಕೊನೆಯ ಹೆಸರು",
      AddEvents: "ಈವೆಂಟ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      AddFakeAccount: "ಕಪ್ಪು ಖಾತೆ ಸೇರಿಸಿ",
      Close: "ಮುಚ್ಚಿ",
      Profile: "ಪ್ರೊಫೈಲ್",
      ProfilePic: "ಪ್ರೊಫೈಲ್ ಚಿತ್ರ",
      Gender: "ಲಿಂಗ",
      UpdateProfilePic: "ಪ್ರೊಫೈಲ್ ಚಿತ್ರವನ್ನು ಅಪ್ಡೇಟ್ ಮಾಡಿ",
      Noprofilephotos: "ಪ್ರೊಫೈಲ್ ಫೋಟೋಗಳು ಇಲ್ಲ",
      Looks: "ರೂಪ",
      LifeStyle: "ಜೀವನ ಶೈಲಿ",
      EmailUsername: "ಇಮೇಲ್ / ಬಳಕೆದಾರ ಹೆಸರು",
      Username: "ಬಳಕೆದಾರ ಹೆಸರು",
      Overview: "ಅವಲೋಕನ",
      Thisweek: "ಈ ವಾರ",
      Earnings: "ಗಳಿಕೆ",
      MontlyStatistics: "ತಿಂಗಳುವಿನ ಪಟ್ಟಿಗಳು",
      Login: "ಲಾಗಿನ್",
      Addnewmoderator: "ಹೊಸ ಮಾಡರೇಟರ್ ಸೇರಿಸಿ",
      Enterinformationhere: "ಇಲ್ಲಿ ಮಾಹಿತಿ ನಮೂದಿಸಿ",
      AddModerator: "ಮಾಡರೇಟರ್ ಸೇರಿಸಿ",
      Updatepackage: "ಪ್ಯಾಕೇಜ್ ಅಪ್ಡೇಟ್ ಮಾಡಿ",
      Enterpackageinformationhere: "ಇಲ್ಲಿ ಪ್ಯಾಕೇಜ್ ಮಾಹಿತಿ ನಮೂದಿಸಿ",
      packagecover: "ಪ್ಯಾಕೇಜ್ ಮುಚ್ಚಲು",
      Addnewpackage: "ಹೊಸ ಪ್ಯಾಕೇಜ್ ಸೇರಿಸಿ",
      Thepackagehasatimerange: "ಪ್ಯಾಕೇಜ್ ಹೊಸ ಸಮಯ ಶ್ರೇಣಿಯನ್ನು ಹೊಂದಿದೆ",
      PackageManagement: "ಪ್ಯಾಕೇಜ್ ನಿರ್ವಹಣೆ",
      Thiswherewemanageandupdateflirtsorpackages:
        "ಇದು ನಾವು ಫ್ಲರ್ಟ್‌ಗಳನ್ನು ಅಥವಾ ಪ್ಯಾಕೇಜ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತೇವೆ ಮತ್ತು ನವೀಕರಿಸುತ್ತೇವೆ",
      AddNew: "ಹೊಸ ಸೇರಿಸಿ",
      RoleSettings: "ಪಾತ್ರ ಸೆಟ್ಟಿಂಗುಗಳು",
      AddnewModeratedAccount: "ಹೊಸ ಮಾಡರೇಟ್ ಖಾತೆ ಸೇರಿಸಿ",
      Enterinformationhere: "ಇಲ್ಲಿ ಮಾಹಿತಿ ನಮೂದಿಸಿ",
      Save: "ಉಳಿಸಿ",
      SendMassPoke: "ಮಾಸ್ ಪೋಕ್ ಕಳುಹಿಸಿ",
      YourPOKESwillgoouttotheseusers: "ನಿಮ್ಮ POKES ಈ ಬಳಕೆದಾರರಿಗೆ ಹೋಗುತ್ತವೆ",
      SendMassLikes: "ಮಾಸ್ ಇಷ್ಟಗಳನ್ನು ಕಳುಹಿಸಿ",
      YourLIKESwillgoouttotheseusers: "ನಿಮ್ಮ ಇಷ್ಟಗಳು ಈ ಬಳಕೆದಾರರಿಗೆ ಹೋಗುತ್ತವೆ",
      SendMassSpam: "ಮಾಸ್ ಸ್ಪ್ಯಾಮ್ ಕಳುಹಿಸಿ",
      Yourmessagewillgoouttotheseusers: "ನಿಮ್ಮ ಸಂದೇಶ ಈ ಬಳಕೆದಾರರಿಗೆ ಹೋಗುತ್ತದೆ",
      SendSpam: "ಸ್ಪ್ಯಾಮ್ ಕಳುಹಿಸಿ",
      SendMassEmail: "ಮಾಸ್ ಇಮೇಲ್‌ಗಳನ್ನು ಕಳುಹಿಸಿ",
      Emailmessageswillgoouttotheseusers:
        "ಇಮೇಲ್ ಸಂದೇಶಗಳು ಈ ಬಳಕೆದಾರರಿಗೆ ಹೋಗುತ್ತವೆ",
      SendEmail: "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
      Promotion: "ಪ್ರಚಾರ",
      Sendmessageandreachouttousershere:
        "ಇಲ್ಲಿ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಿ ಮತ್ತು ಬಳಕೆದಾರರಿಗೆ ತಲುಪಿ",
      Search: "ಹುಡುಕು",
      Unknown: "ಅಜ್ಞಾತ",
      NOTSET: "ಹೊಂದಿಲ್ಲವು",
      Youruseraccounts: "ನಿಮ್ಮ ಬಳಕೆದಾರ ಖಾತೆಗಳು",
      SellingNudes: "ಬದಲಾಯಿಸಿ ಅನ್ನು",
      SellingGifts: "ಬದಲಾಯಿಸಿ ಕಳುಹಿಸು",
      UpdatePhotos: "ಚಿತ್ರಗಳನ್ನು ನವೀಕರಿಸಿ",
      Agree: "ಒಪ್ಪಿಗೆ",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "ನೀವು ವ್ಯವಸ್ಥಾಪಕರಿಂದ ಎಚ್ಚರಿಕೆ ಪಡಿಸಲಾಗಿದ್ದೀರಿ. ಈಗ ಇದು ಮತ್ತೆ ಆಗುವುದಿಲ್ಲವೆಂದು ಒಪ್ಪಿಗೆಯನ್ನು ಮಾಡಿ",
      Senddirectchatmessage: "ನಿರ್ದೇಶ ಚಾಟ್ ಸಂದೇಶ ಕಳುಹಿಸಿ",
      Dashboard: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
    },
    // Kannada

    // Gujarati
    Gujarati: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    // Gujarati
    // Thai
    Thai: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    // Thai

    Amharisch: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    Bhojpuri: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    Ostpanjabi: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    JinChinesisch: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    Yoruba: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },

    Hakka: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },

    // Burmesisch:,
    // Sindhi

    // 	Sindhi
    Sindhi: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },

    Amharisch: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    // Nepali
    irn: {
      SellingImages: "فروش تصاویر",
      Images: "تصاویر",
      AgentID: "شناسه عامل",
      Actions: "اقدامات",
      Rank: "رتبه",
      EventPrice: "قیمت رویداد",
      Answers: "پاسخ‌ها",
      Operator: "اپراتور",
      Settings: "تنظیمات",
      HoursSpent: "ساعت‌های صرف شده",
      SendMessage: "ارسال پیام",
      Messages: "پیام‌ها",
      EventDetail: "جزئیات رویداد",
      Audience: "مخاطب",
      EventDate: "تاریخ رویداد",
      ActiveStatus: "وضعیت فعال",
      CreatedAt: "ایجاد شده در",
      Location: "مکان",
      PackageType: "نوع بسته",
      FlirtsAmount: "تعداد فلرت‌ها",
      CreatedDate: "تاریخ ایجاد",
      Price: "قیمت",
      Total: "مجموع",
      PackageName: "نام بسته",
      PackagesDescription: "توضیحات بسته‌ها",
      AccountType: "نوع حساب",
      OnlineStatus: "وضعیت آنلاین",
      PaymentDate: "تاریخ پرداخت",
      SelectFake: "انتخاب جعلی",
      Moderators: "مدیران",
      Conversions: "تبدیل‌ها",
      FullName: "نام کامل",
      Category: "دسته‌بندی",
      LastWeek: "هفته گذشته",
      RealTime: "زمان واقعی",
      AnswerRate: "نرخ پاسخ",
      ModeratorTabs: "تب‌های مدیر",
      Messagesrecieved: "پیام‌های دریافتی (ورودی)",
      Lengthoffirstmesssage: "طول پیام اول",
      MessagesSent: "پیام‌های ارسال شده (خروجی)",
      ShowOverlay: "نمایش پوشش",
      Gallery: "گالری",
      Statistics: "آمار",
      Community: "جامعه",
      Coupons: "کوپن‌ها",
      Packages: "بسته‌ها",
      Transfer: "انتقال",
      SKIP: "رد",
      Today: "امروز",
      LasWeek: "هفته گذشته",
      ThisWeek: "این هفته",
      ThisMonth: "این ماه",
      LastMonth: "ماه گذشته",
      MessageReported: "پیام گزارش شده",
      report: "گزارش",
      TimeElapsed: "زمان گذشته",
      Affilates: "بازاریابان",
      Finance: "مالی",
      Events: "رویدادها",
      Promotion: "ترویج",
      Status: "وضعیت",
      FakeAccounts: "حساب‌های جعلی",
      AgentOverview: "بررسی عامل",
      LiveMonitoring: "مانیتورینگ زنده",
      MessageStatistics: "آمار پیام",
      ActivityAnalysis: "تحلیل فعالیت",
      AgentActivity: "فعالیت عامل",
      ChatModeration: "مدیریت گفتگو",
      Reportauser: "گزارش کاربر",
      startChatting: "شروع گپ",
      UserProfile: "پروفایل کاربری",
      Change: "تغییر",
      Owner: "صاحب",
      SaveChanges: "ذخیره تغییرات",
      UpdateAffilateData: "به‌روزرسانی داده‌های بازاریاب",
      AddAffilate: "افزودن بازاریاب",
      addnewaffilite: "افزودن بازاریابان/خالقان/تأثیرگذاران جدید اینجا",
      AffilatesManagement: "مدیریت بازاریابان",
      Cancel: "لغو",
      OK: "تأیید",
      Youhavebeenblocked: "شما مسدود شده‌اید",
      NoPoolsFoundPleaseRefreshinaWhile: "هیچ استخری یافت نشد. لطفاً برای مدتی تازه کنید",
      GenerateVoucher: "تولید کد تخفیف",
      AddCoupons: "افزودن کوپن‌ها",
      createnewcouponshere: "ایجاد کوپن‌های جدید اینجا",
      Remove: "حذف",
      Onetime: "یکبار",
      Welcome: "خوش آمدید",
      DialogExplorer: "کاوشگر گفتگو",
      Updateeventdetailsfor: "به‌روزرسانی جزئیات رویداد برای",
      AddnewFlirtTooleventhere: "افزودن رویداد جدید ابزار فلرت اینجا",
      SaveEvent: "ذخیره رویداد",
      AddanewEvent: "افزودن رویداد جدید",
      Addnewchateventhere: "افزودن رویداد گفتگو جدید اینجا",
      eventcover: "پوشش رویداد",
      cover: "پوشش",
      Active: "فعال",
      EventName: "نام رویداد",
      FirstName: "نام",
      LastName: "نام خانوادگی",
      AddEvents: "افزودن رویدادها",
      AddFakeAccount: "افزودن حساب جعلی",
      Close: "بستن",
      Profile: "پروفایل",
      ProfilePic: "عکس پروفایل",
      Gender: "جنسیت",
      UpdateProfilePic: "به‌روزرسانی عکس پروفایل",
      Noprofilephotos: "بدون عکس پروفایل",
      Looks: "ظاهر",
      LifeStyle: "سبک زندگی",
      EmailUsername: "ایمیل یا نام کاربری",
      Username: "نام کاربری",
      Overview: "بررسی کلی",
      Thisweek: "این هفته",
      Earnings: "درآمد",
      MontlyStatistics: "آمار ماهانه",
      Login: "ورود",
      Addnewmoderator: "افزودن مدیر جدید",
      Enterinformationhere: "اطلاعات را اینجا وارد کنید",
      AddModerator: "افزودن مدیر",
      Updatepackage: "به‌روزرسانی بسته",
      Enterpackageinformationhere: "اطلاعات بسته را اینجا وارد کنید",
      packagecover: "پوشش بسته",
      Addnewpackage: "افزودن بسته جدید",
      Thepackagehasatimerange: "بسته دارای محدوده زمانی است",
      PackageManagement: "مدیریت بسته‌ها",
      Thiswherewemanageandupdateflirtsorpackages: "اینجا جایی است که ما فلرت‌ها یا بسته‌ها را مدیریت و به‌روزرسانی می‌کنیم",
      AddNew: "افزودن جدید",
      RoleSettings: "تنظیمات نقش",
      AddnewModeratedAccount: "افزودن حساب کاربری مدیریت شده جدید",
      Enterinformationhere: "اطلاعات را اینجا وارد کنید",
      Save: "ذخیره",
      SendMassPoke: "ارسال پوک گروهی",
      YourPOKESwillgoouttotheseusers: "پوک‌های شما به این کاربران ارسال خواهد شد",
      SendMassLikes: "ارسال لایک‌های گروهی",
      YourLIKESwillgoouttotheseusers: "لایک‌های شما به این کاربران ارسال خواهد شد",
      SendMassSpam: "ارسال اسپم گروهی",
      Yourmessagewillgoouttotheseusers: "پیام شما به این کاربران ارسال خواهد شد",
      SendSpam: "ارسال اسپم",
      SendMassEmail: "ارسال ایمیل گروهی",
      Emailmessageswillgoouttotheseusers: "پیام‌های ایمیل شما به این کاربران ارسال خواهد شد",
      SendEmail: "ارسال ایمیل",
      Sendmessageandreachouttousershere: "ارسال پیام و برقراری ارتباط با کاربران اینجا",
      Search: "جستجو",
      Unknown: "ناشناخته",
      NOTSET: "تنظیم نشده",
      Youruseraccounts: "حساب‌های کاربری شما",
      SellingNudes: "فروش عکس‌های عاری",
      SellingGifts: "فروش هدایا",
      UpdatePhotos: "به‌روزرسانی عکس‌ها",
      Agree: "موافقم",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "شما توسط مدیر هشدار داده شده‌اید. لطفاً موافقت کنید که این اتفاق دوباره نخواهد افتاد",
      Senddirectchatmessage: "ارسال پیام گفتگوی مستقیم",
      Dashboard: "پیشخوان"
    }

    ,

    // Nepali
    // Khmer
    Khmer: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    // Khmer

    // Turkmenisch
    Turkmenisch: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    // Turkmenisch

    // Assamesisch
    Assamesisch: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },

    // Assamesisch

    // Somali
    // Somali:,
    // Somali
    Magahi: {
      startChatting: "",
      UserProfile: "",
      Change: "",
      Owner: "",
      SaveChanges: "",
      UpdateAffilateData: "",
      AddAffilate: "",
      addnewaffilite: "",
      AffilatesManagement: "",
      Cancel: "",
      OK: "",
      Youhavebeenblocked: "",
      NoPoolsFoundPleaseRefreshinaWhile: "",
      GenerateVoucher: "",
      AddCoupons: "",
      createnewcouponshere: "",
      Remove: "",
      Onetime: "",
      Welcome: "",
      DialogExplorer: "",
      Updateeventdetailsfor: "",
      AddnewFlirtTooleventhere: "",
      SaveEvent: "",
      AddanewEvent: "",
      Addnewchateventhere: "",
      eventcover: "",
      AddEvents: "",
      AddFakeAccount: "",
      Close: "",
      UpdateProfilePic: "",
      Noprofilephotos: "",
      Looks: "",
      LifeStyle: "",
      EmailUsername: "",
      FakeAccounts: "",
      Overview: "",
      Thisweek: "",
      Earnings: "",
      MontlyStatistics: "",
      Login: "",
      Addnewmoderator: "",
      Enterinformationhere: "",
      Moderators: "",
      AddModerator: "",
      Updatepackage: "",
      Enterpackageinformationhere: "",
      packagecover: "",
      Addnewpackage: "",
      Enterpackageinformationhere: "",
      Thepackagehasatimerange: "",
      PackageManagement: "",
      Thiswherewemanageandupdateflirtsorpackages: "",
      AddNew: "",
      RoleSettings: "",
      AddnewModeratedAccount: "",
      Enterinformationhere: "",
      Save: "",
      SendMassPoke: "",
      YourPOKESwillgoouttotheseusers: "",
      SendMassLikes: "",
      YourLIKESwillgoouttotheseusers: "",
      SendMassSpam: "",
      Yourmessagewillgoouttotheseusers: "",
      SendSpam: "",
      SendMassEmail: "",
      Emailmessageswillgoouttotheseusers: "",
      SendEmail: "",
      Promotion: "",
      Sendmessageandreachouttousershere: "",
      Search: "",
      Unknown: "",
      NOTSET: "",
      Youruseraccounts: "",
      SellingNudes: "",
      SellingGifts: "",
      UpdatePhotos: "",
      Community: "",
      Agree: "",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain: "",
    },
    polish: {
      Job: "Praca",
WorkingSchedule: "Harmonogram Pracy",
RelationshipStatus: "Status Związku",
Searchingfor: "Szukam",
pastRelationships: "Przeszłe Związki",
TraitsforIdealPartner: "Cechy Idealnego Partnera",
FuturePlanswithPartner: "Plany na Przyszłość z Partnerem",

      name: "Imię",
      location: "Miejsce",
      birthDay: "Data Urodzenia",
      SellingImages: "Sprzedaż obrazów",
      Images: "Obrazy",
      AgentID: "ID Agenta",
      Actions: "Akcje",
      Rank: "Ranga",
      EventPrice: "Cena wydarzenia",
      Answers: "Odpowiedzi",
      Operator: "Operator",
      Settings: "Ustawienia",
      HoursSpent: "Godziny spędzone",
      SendMessage: "Wyślij wiadomość",
      Messages: "Wiadomości",
      EventDetail: "Szczegóły wydarzenia",
      Audience: "Audytorium",
      EventDate: "Data wydarzenia",
      ActiveStatus: "Status aktywności",
      CreatedAt: "Utworzono o",
      Location: "Lokalizacja",
      PackageType: "Typ pakietu",
      FlirtsAmount: "Ilość flirtów",
      CreatedDate: "Data utworzenia",
      Price: "Cena",
      Total: "Suma",
      PackageName: "Nazwa pakietu",
      PackagesDescription: "Opis pakietów",
      AccountType: "Typ konta",
      OnlineStatus: "Status online",
      PaymentDate: "Data płatności",
      SelectFake: "Wybierz fałszywe",
      Moderators: "Moderatorzy",
      Conversions: "Konwersje",
      FullName: "Pełne imię",
      Category: "Kategoria",
      LastWeek: "Ostatni tydzień",
      RealTime: "Czas rzeczywisty",
      AnswerRate: "Wskaźnik odpowiedzi",
      ModeratorTabs: "Karty moderatora",
      Messagesrecieved: "Otrzymane wiadomości (WEJŚCIA)",
      Lengthoffirstmesssage: "Długość pierwszej wiadomości",
      MessagesSent: "Wiadomości wysłane (WYJŚCIA)",
      ShowOverlay: "Pokaż nakładkę",
      Gallery: "Galeria",
      Statistics: "Statystyki",
      Community: "Społeczność",
      Coupons: "Kupony",
      Packages: "Pakiety",
      Transfer: "Transfer",
      SKIP: "POMIŃ",
      Today: "Dziś",
      LasWeek: "Poprzedni tydzień",
      ThisWeek: "Ten tydzień",
      ThisMonth: "Ten miesiąc",
      LastMonth: "Ostatni miesiąc",
      MessageReported: "Zgłoszona wiadomość",
      report: "Zgłoś",
      TimeElapsed: "Czas upłynął",
      Affilates: "Afiliacje",
      Finance: "Finanse",
      Events: "Wydarzenia",
      Promotion: "Promocja",
      Status: "Status",
      FakeAccounts: "Konta fałszywe",
      AgentOverview: "Przegląd agenta",
      LiveMonitoring: "Monitoring na żywo",
      MessageStatistics: "Statystyki wiadomości",
      ActivityAnalysis: "Analiza aktywności",
      AgentActivity: "Aktywność agenta",
      ChatModeration: "Moderacja czatu",
      Reportauser: "Zgłoś użytkownika",
      startChatting: "Rozpocznij czat",
      UserProfile: "Profil użytkownika",
      Change: "Zmień",
      Owner: "Właściciel",
      SaveChanges: "Zapisz zmiany",
      UpdateAffilateData: "Aktualizuj dane afiliacyjne",
      AddAffilate: "Dodaj afilianta",
      addnewaffilite: "Dodaj nowych afiliantów/kreatorów/influencerów tutaj",
      AffilatesManagement: "Zarządzanie afiliacjami",
      Cancel: "Anuluj",
      OK: "OK",
      Youhavebeenblocked: "Zostałeś zablokowany",
      NoPoolsFoundPleaseRefreshinaWhile: "Nie znaleziono pul. Odśwież za chwilę",
      GenerateVoucher: "Generuj kupon",
      AddCoupons: "Dodaj kupony",
      createnewcouponshere: "Utwórz nowe kupony tutaj",
      Remove: "Usuń",
      Onetime: "Jednorazowy",
      Welcome: "Witaj",
      DialogExplorer: "Eksplorator dialogów",
      Updateeventdetailsfor: "Zaktualizuj szczegóły wydarzenia dla",
      AddnewFlirtTooleventhere: "Dodaj nowe wydarzenie narzędzia flirtu tutaj",
      SaveEvent: "Zapisz wydarzenie",
      AddanewEvent: "Dodaj nowe wydarzenie",
      Addnewchateventhere: "Dodaj nowe wydarzenie czatu tutaj",
      eventcover: "okładka wydarzenia",
      cover: "okładka",
      Active: "Aktywny",
      EventName: "Nazwa wydarzenia",
      FirstName: "Imię",
      LastName: "Nazwisko",
      AddEvents: "Dodaj wydarzenia",
      AddFakeAccount: "Dodaj fałszywe konto",
      Close: "Zamknij",
      Profile: "Profil",
      ProfilePic: "Zdjęcie profilowe",
      Gender: "Płeć",
      UpdateProfilePic: "Zaktualizuj zdjęcie profilowe",
      Noprofilephotos: "Brak zdjęć profilowych",
      Looks: "Wygląd",
      LifeStyle: "Styl życia",
      EmailUsername: "Adres e-mail lub nazwa użytkownika",
      Username: "Nazwa użytkownika",
      Overview: "Przegląd",
      Thisweek: "W tym tygodniu",
      Earnings: "Zarobki",
      MontlyStatistics: "Statystyki miesięczne",
      Login: "Zaloguj się",
      Addnewmoderator: "Dodaj nowego moderatora",
      Enterinformationhere: "Wprowadź informacje tutaj",
      AddModerator: "Dodaj moderatora",
      Updatepackage: "Aktualizuj pakiet",
      Enterpackageinformationhere: "Wprowadź informacje o pakiecie tutaj",
      packagecover: "Okładka pakietu",
      Addnewpackage: "Dodaj nowy pakiet",
      Thepackagehasatimerange: "Pakiet ma zakres czasu",
      PackageManagement: "Zarządzanie pakietami",
      Thiswherewemanageandupdateflirtsorpackages:
        "Tu zarządzamy i aktualizujemy flirtowanie lub pakiety",
      AddNew: "Dodaj nowe",
      RoleSettings: "Ustawienia roli",
      AddnewModeratedAccount: "Dodaj nowe konto moderowane",
      Enterinformationhere: "Wprowadź informacje tutaj",
      Save: "Zapisz",
      SendMassPoke: "Wyślij masowego Poke'a",
      YourPOKESwillgoouttotheseusers: "Twoje POKE przejdą do tych użytkowników",
      SendMassLikes: "Wyślij masowe polubienia",
      YourLIKESwillgoouttotheseusers: "Twoje polubienia przejdą do tych użytkowników",
      SendMassSpam: "Wyślij masowego spamu",
      Yourmessagewillgoouttotheseusers:
        "Twoja wiadomość trafi do tych użytkowników",
      SendSpam: "Wyślij spam",
      SendMassEmail: "Wyślij masową wiadomość e-mail",
      Emailmessageswillgoouttotheseusers:
        "Wiadomości e-mail zostaną wysłane do tych użytkowników",
      SendEmail: "Wyślij e-mail",

      Sendmessageandreachouttousershere:
        "Wyślij wiadomość i dotrzyj do użytkowników tutaj",
      Search: "Szukaj",
      Unknown: "Nieznany",
      NOTSET: "NIEUSTAWIONE",
      Youruseraccounts: "Twoje konta użytkowników",
      SellingNudes: "Sprzedaż nago",
      SellingGifts: "Sprzedaż prezentów",
      UpdatePhotos: "Aktualizuj zdjęcia",
      Agree: "Zgadzam się",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Zostałeś ostrzeżony przez administratora. Proszę zgodzić się, że to się nie powtórzy",
      Senddirectchatmessage: "Wyślij wiadomość bezpośrednią",
      Dashboard: "Tablica",
    },

    Spanish: {
      Job: "Trabajo",
WorkingSchedule: "Horario de Trabajo",
RelationshipStatus: "Estado de la Relación",
Searchingfor: "Buscando",
pastRelationships: "Relaciones Pasadas",
TraitsforIdealPartner: "Características del Socio Ideal",
FuturePlanswithPartner: "Planes Futuros con la Pareja",
      SellingImages: "Venta de Imágenes",
      Images: "Imágenes",
      AgentID: "ID de Agente",
      Actions: "Acciones",
      Rank: "Rango",
      EventPrice: "Precio del Evento",
      Answers: "Respuestas",
      Operator: "Operador",
      Settings: "Ajustes",
      HoursSpent: "Horas Gastadas",
      SendMessage: "Enviar Mensaje",
      Messages: "Mensajes",
      EventDetail: "Detalle del Evento",
      Audience: "Audiencia",
      EventDate: "Fecha del Evento",
      ActiveStatus: "Estado Activo",
      CreatedAt: "Creado En",
      Location: "Ubicación",
      PackageType: "Tipo de Paquete",
      FlirtsAmount: "Cantidad de Coqueteos",
      CreatedDate: "Fecha de Creación",
      Price: "Precio",
      Total: "Total",
      PackageName: "Nombre del Paquete",
      PackagesDescription: "Descripción de los Paquetes",
      AccountType: "Tipo de Cuenta",
      OnlineStatus: "Estado en Línea",
      PaymentDate: "Fecha de Pago",
      SelectFake: "Seleccionar Falso",
      Moderators: "Moderadores",
      Conversions: "Conversiones",
      FullName: "Nombre Completo",
      Category: "Categoría",
      LastWeek: "Semana Pasada",
      RealTime: "Tiempo Real",
      AnswerRate: "Tasa de Respuesta",
      ModeratorTabs: "Pestañas del Moderador",
      Messagesrecieved: "Mensajes Recibidos (ENTRADAS)",
      Lengthoffirstmesssage: "Longitud del Primer Mensaje",
      MessagesSent: "Mensajes Enviados (SALIDAS)",
      ShowOverlay: "Mostrar Superposición",
      Gallery: "Galería",
      Statistics: "Estadísticas",
      Community: "Comunidad",
      Coupons: "Cupones",
      Packages: "Paquetes",
      Transfer: "Transferencia",
      SKIP: "OMITIR",
      Today: "Hoy",
      LasWeek: "Semana Pasada",
      ThisWeek: "Esta Semana",
      ThisMonth: "Este Mes",
      LastMonth: "Mes Pasado",
      MessageReported: "Mensaje Reportado",
      report: "Reportar",
      TimeElapsed: "Tiempo Transcurrido",
      Affilates: "Afiliados",
      Finance: "Finanzas",
      Events: "Eventos",
      Promotion: "usuárias",
      Status: "Estado",
      FakeAccounts: "Cuentas Falsas",
      AgentOverview: "Resumen del Agente",
      LiveMonitoring: "Monitoreo en Vivo",
      MessageStatistics: "Estadísticas de Mensajes",
      ActivityAnalysis: "Análisis de Actividad",
      AgentActivity: "Actividad del Agente",
      ChatModeration: "Moderación del Chat",
      Reportauser: "Reportar un Usuario",
      startChatting: "Empezar a Chatear",
      UserProfile: "Perfil de Usuario",
      Change: "Cambiar",
      Owner: "Propietario",
      SaveChanges: "Guardar Cambios",
      UpdateAffilateData: "Actualizar Datos de Afiliado",
      AddAffilate: "Agregar Afiliado",
      addnewaffilite: "Agregar nuevos afiliados/creadores/influenciadores aquí",
      AffilatesManagement: "Gestión de Afiliados",
      Cancel: "Cancelar",
      OK: "Aceptar",
      Youhavebeenblocked: "Has sido Bloqueado",
      NoPoolsFoundPleaseRefreshinaWhile: "No se Encontraron Piscinas. Por favor, Refresca en un Momento",
      GenerateVoucher: "Generar Vale",
      AddCoupons: "Agregar Cupones",
      createnewcouponshere: "Crear nuevos cupones aquí",
      Remove: "Eliminar",
      Onetime: "Una Vez",
      Welcome: "Bienvenido",
      DialogExplorer: "Explorador de Diálogos",
      Updateeventdetailsfor: "Actualizar detalles del evento para",
      AddnewFlirtTooleventhere: "Agregar nuevo evento de FlirtTool aquí",
      SaveEvent: "Guardar Evento",
      AddanewEvent: "Agregar un Nuevo Evento",
      Addnewchateventhere: "Agregar nuevo evento de chat aquí",
      eventcover: "portada del evento",
      cover: "cubierta",
      Active: "Activo",
      EventName: "Nombre del Evento",
      FirstName: "Nombre",
      LastName: "Apellido",
      AddEvents: "Agregar Eventos",
      AddFakeAccount: "Agregar Cuenta Falsa",
      Close: "Cerrar",
      Profile: "Perfil",
      ProfilePic: "Foto de Perfil",
      Gender: "Género",
      UpdateProfilePic: "Actualizar Foto de Perfil",
      Noprofilephotos: "Sin Fotos de Perfil",
      Looks: "Apariencia",
      LifeStyle: "Estilo de Vida",
      EmailUsername: "Correo Electrónico o Nombre de Usuario",
      Username: "Nombre de Usuario",
      Overview: "Resumen",
      Thisweek: "Esta semana",
      Earnings: "Ganancias",
      MontlyStatistics: "Estadísticas Mensuales",
      Login: "Iniciar Sesión",
      Addnewmoderator: "Agregar Nuevo Moderador",
      Enterinformationhere: "Ingrese la información aquí",
      AddModerator: "Agregar Moderador",
      Updatepackage: "Actualizar Paquete",
      Enterpackageinformationhere: "Ingrese la información del paquete aquí",
      packagecover: "portada del paquete",
      Addnewpackage: "Agregar Nuevo Paquete",
      Thepackagehasatimerange: "El paquete tiene un rango de tiempo",
      PackageManagement: "Gestión de Paquetes",
      Thiswherewemanageandupdateflirtsorpackages:
        "Aquí es donde gestionamos y actualizamos coqueteos o paquetes",
      AddNew: "Agregar Nuevo",
      RoleSettings: "Configuración de Rol",
      AddnewModeratedAccount: "Agregar Nueva Cuenta Moderada",
      Enterinformationhere: "Ingrese la información aquí",
      Save: "Guardar",
      SendMassPoke: "Enviar Poke Masivo",
      YourPOKESwillgoouttotheseusers: "Tus POKES se enviarán a estos usuarios",
      SendMassLikes: "Enviar Me Gusta Masivos",
      YourLIKESwillgoouttotheseusers: "Tus ME GUSTA se enviarán a estos usuarios",
      SendMassSpam: "Enviar Spam Masivo",
      Yourmessagewillgoouttotheseusers:
        "Tu mensaje se enviará a estos usuarios",
      SendSpam: "Enviar Spam",
      SendMassEmail: "Enviar Correo Electrónico Masivo",
      Emailmessageswillgoouttotheseusers:
        "Los mensajes de correo electrónico se enviarán a estos usuarios",
      SendEmail: "Enviar Correo Electrónico",

      Sendmessageandreachouttousershere:
        "Enviar mensaje y comunicarse con los usuarios aquí",
      Search: "Buscar",
      Unknown: "Desconocido",
      NOTSET: "NO ESTABLECIDO",
      Youruseraccounts: "Tus Cuentas de Usuario",
      SellingNudes: "Venta de Desnudos",
      SellingGifts: "Venta de Regalos",
      UpdatePhotos: "Actualizar Fotos",
      Agree: "Aceptar",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Has sido advertido por el administrador. Por favor, acepta que esto no volverá a suceder",
      Senddirectchatmessage: "Enviar Mensaje de Chat Directo",
      Dashboard: "Tablero",
    },
    italic: {
      Job: "Lavoro",
WorkingSchedule: "Orario di Lavoro",
RelationshipStatus: "Stato della Relazione",
Searchingfor: "Cercando",
pastRelationships: "Relazioni Passate",
TraitsforIdealPartner: "Caratteristiche del Partner Ideale",
FuturePlanswithPartner: "Piani Futuri con il Partner",
      AgentID: "ID Agente",
      Rank: "Grado",
      Dashboard: "Dashboard",
      Settings: "Impostazioni",
      ShowOverlay: "Mostra sovrapposizione",
      Community: "Comunità",
      Packages: "Pacchetti",
      Coupons: "Coupon",
      Statistics: "Statistiche",
      PanicRoom: "PanicRoom",
      Noprofilephotos: "Nessuna foto del profilo",
      Transfer: "Trasferimento",
      SKIP: "SALTA",
      MessageReported: "Messaggio Segnalato",
      report: "rapporto",
      TimeElapsed: "Tempo trascorso",
      Affilates: "Affiliati",
      Finance: "Finanza",
      Events: "Eventi",
      Promotion: "utenti",
      FakeAccounts: "Account falsi",
      Moderators: "Moderatori",
      AgentOverview: "Panoramica dell'agente",
      LiveMonitoring: "Monitoraggio in tempo reale",
      MessageStatistics: "Statistiche dei messaggi",
      ActivityAnalysis: "Analisi dell'attività",
      AgentActivity: "Attività dell'agente",
      ChatModeration: "Moderazione della chat",
      UpdateProfile: "Aggiorna il profilo",
      WelcometotheModelgallery: "Benvenuti nella galleria dei modelli",
      SendMessagefromAdmin: "Invia messaggio dall'amministratore",
      SellingImages: "Vendita di immagini",
      TotalEarnings: "Guadagni totali",
      Userbasicprofileinfo: "Informazioni di base sul profilo utente",
      SendWarning: "Invia avviso",
      SendReport: "Invia segnalazione",
      Reportauser: "Segnala un utente",
      Senddirectchatmessage: "Invia un messaggio di chat diretto",
      startChatting: "Inizia chat",
      UserProfile: "Profilo utente",
      Change: "Modifica",
      Owner: "Proprietario",
      SaveChanges: "Salva modifiche",
      UpdateAffilateData: "Aggiorna dati affiliati",
      AddAffilate: "Aggiungi affiliato",
      addnewaffilite: "Aggiungi nuovi affiliati/creatori/influencer qui",
      AffilatesManagement: "Gestione affiliati",
      Cancel: "Annulla",
      OK: "OK",
      Youhavebeenblocked: "Sei stato bloccato",
      NoPoolsFoundPleaseRefreshinaWhile:
        "Nessuna piscina trovata. Si prega di aggiornare tra un po'.",
      GenerateVoucher: "Genera buono",
      AddCoupons: "Aggiungi coupon",
      createnewcouponshere: "Crea nuovi coupon qui",
      Remove: "Rimuovi",
      Onetime: "Una volta",
      Welcome: "Benvenuto",
      DialogExplorer: "Esploratore di dialoghi",
      Updateeventdetailsfor: "Aggiorna dettagli evento per",
      AddnewFlirtTooleventhere: "Aggiungi nuovo evento FlirtTool qui",
      SaveEvent: "Salva evento",
      AddanewEvent: "Aggiungi un nuovo evento",
      Addnewchateventhere: "Aggiungi nuovo evento di chat qui",
      eventcover: "Copertina dell'evento",
      AddEvents: "Aggiungi eventi",
      AddFakeAccount: "Aggiungi account falso",
      Close: "Chiudi",
      UpdateProfilePic: "Aggiorna foto profilo",
      Noprofilephotos: "Nessuna foto profilo",
      Looks: "Aspetto",
      LifeStyle: "Stile di vita",
      EmailUsername: "Email o nome utente",
      FakeAccounts: "Account falsi",
      Overview: "Panoramica",
      Thisweek: "Questa settimana",
      Earnings: "Guadagni",
      MontlyStatistics: "Statistiche mensili",
      Login: "Accedi",
      Addnewmoderator: "Aggiungi nuovo moderatore",
      Enterinformationhere: "Inserisci informazioni qui",
      Moderators: "Moderatori",
      AddModerator: "Aggiungi moderatore",
      Updatepackage: "Aggiorna pacchetto",
      Enterpackageinformationhere: "Inserisci informazioni pacchetto qui",
      packagecover: "Copertina del pacchetto",
      Addnewpackage: "Aggiungi nuovo pacchetto",
      Enterpackageinformationhere: "Inserisci informazioni pacchetto qui",
      Thepackagehasatimerange: "Il pacchetto ha un intervallo di tempo",
      PackageManagement: "Gestione pacchetti",
      Thiswherewemanageandupdateflirtsorpackages:
        "Qui gestiamo e aggiorniamo flirt o pacchetti",
      AddNew: "Aggiungi nuovo",
      RoleSettings: "Impostazioni ruolo",
      AddnewModeratedAccount: "Aggiungi nuovo account moderato",
      Enterinformationhere: "Inserisci informazioni qui",
      Save: "Salva",
      SendMassPoke: "Invia Poke di massa",
      YourPOKESwillgoouttotheseusers:
        "I TUOI POKES verranno inviati a questi utenti",
      SendMassLikes: "Invia Mi piace di massa",
      YourLIKESwillgoouttotheseusers:
        "I TUOI MI PIACE verranno inviati a questi utenti",
      SendMassSpam: "Invia spam di massa",
      Yourmessagewillgoouttotheseusers:
        "Il TUO messaggio verrà inviato a questi utenti",
      SendSpam: "Invia spam",
      SendMassEmail: "Invia email di massa",
      Emailmessageswillgoouttotheseusers:
        "I messaggi email verranno inviati a questi utenti",
      SendEmail: "Invia email",

      Sendmessageandreachouttousershere:
        "Invia messaggio e contatta gli utenti qui",
      Search: "Ricerca",
      Unknown: "Sconosciuto",
      NOTSET: "NON IMPOSTATO",
      Youruseraccounts: "I tuoi account utente",
      SellingNudes: "Vendita di nudi",
      SellingGifts: "Vendita di regali",
      UpdatePhotos: "Aggiorna foto",
      Community: "Comunità",
      Agree: "Accetta",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Sei stato avvertito dall'amministratore. Si prega di accettare che questo non accadrà di nuovo",
    },

    português: {
      SellingImages: "Venda de Imagens",
      Images: "Imagens",
      AgentID: "ID do Agente",
      Actions: "Ações",
      Rank: "Classificação",
      EventPrice: "Preço do Evento",
      Answers: "Respostas",
      Operator: "Operador",
      Settings: "Configurações",
      HoursSpent: "Horas Gastas",
      SendMessage: "Enviar Mensagem",
      Messages: "Mensagens",
      EventDetail: "Detalhe do Evento",
      Audience: "Público",
      EventDate: "Data do Evento",
      ActiveStatus: "Status Ativo",
      CreatedAt: "Criado Em",
      Location: "Localização",
      PackageType: "Tipo de Pacote",
      FlirtsAmount: "Quantidade de Flertes",
      CreatedDate: "Data de Criação",
      Price: "Preço",
      Total: "Total",
      PackageName: "Nome do Pacote",
      PackagesDescription: "Descrição dos Pacotes",
      AccountType: "Tipo de Conta",
      OnlineStatus: "Status Online",
      PaymentDate: "Data de Pagamento",
      SelectFake: "Selecionar Falso",
      Moderators: "Moderadores",
      Conversions: "Conversões",
      FullName: "Nome Completo",
      Category: "Categoria",
      LastWeek: "Semana Passada",
      RealTime: "Tempo Real",
      AnswerRate: "Taxa de Resposta",
      ModeratorTabs: "Guias do Moderador",
      Messagesrecieved: "Mensagens Recebidas (ENTRADAS)",
      Lengthoffirstmesssage: "Comprimento da Primeira Mensagem",
      MessagesSent: "Mensagens Enviadas (SAÍDAS)",
      ShowOverlay: "Mostrar Sobreposição",
      Gallery: "Galeria",
      Statistics: "Estatísticas",
      Community: "Comunidade",
      Coupons: "Cupons",
      Packages: "Pacotes",
      Transfer: "Transferência",
      SKIP: "PULAR",
      Today: "Hoje",
      LasWeek: "Semana Passada",
      ThisWeek: "Esta Semana",
      ThisMonth: "Este Mês",
      LastMonth: "Mês Passado",
      MessageReported: "Mensagem Reportada",
      report: "Reportar",
      TimeElapsed: "Tempo Decorrido",
      Affilates: "Afiliados",
      Finance: "Finanças",
      Events: "Eventos",
      Promotion: "Promoção",
      Status: "Status",
      FakeAccounts: "Contas Falsas",
      AgentOverview: "Visão Geral do Agente",
      LiveMonitoring: "Monitoramento ao Vivo",
      MessageStatistics: "Estatísticas de Mensagens",
      ActivityAnalysis: "Análise de Atividade",
      AgentActivity: "Atividade do Agente",
      ChatModeration: "Moderação de Chat",
      Reportauser: "Reportar um Usuário",
      startChatting: "Começar a Conversar",
      UserProfile: "Perfil de Usuário",
      Change: "Alterar",
      Owner: "Proprietário",
      SaveChanges: "Salvar Alterações",
      UpdateAffilateData: "Atualizar Dados de Afiliado",
      AddAffilate: "Adicionar Afiliado",
      addnewaffilite: "Adicionar novos afiliados/criadores/influenciadores aqui",
      AffilatesManagement: "Gerenciamento de Afiliados",
      Cancel: "Cancelar",
      OK: "OK",
      Youhavebeenblocked: "Você foi Bloqueado",
      NoPoolsFoundPleaseRefreshinaWhile: "Nenhuma Piscina Encontrada. Por favor, Atualize em Breve",
      GenerateVoucher: "Gerar Cupom",
      AddCoupons: "Adicionar Cupons",
      createnewcouponshere: "Crie novos cupons aqui",
      Remove: "Remover",
      Onetime: "Uma vez",
      Welcome: "Bem-vindo",
      DialogExplorer: "Explorador de Diálogos",
      Updateeventdetailsfor: "Atualizar detalhes do evento para",
      AddnewFlirtTooleventhere: "Adicionar novo evento de FlirtTool aqui",
      SaveEvent: "Salvar Evento",
      AddanewEvent: "Adicionar um Novo Evento",
      Addnewchateventhere: "Adicionar novo evento de chat aqui",
      eventcover: "capa do evento",
      cover: "capa",
      Active: "Ativo",
      EventName: "Nome do Evento",
      FirstName: "Primeiro Nome",
      LastName: "Sobrenome",
      AddEvents: "Adicionar Eventos",
      AddFakeAccount: "Adicionar Conta Falsa",
      Close: "Fechar",
      Profile: "Perfil",
      ProfilePic: "Foto de Perfil",
      Gender: "Gênero",
      UpdateProfilePic: "Atualizar Foto de Perfil",
      Noprofilephotos: "Sem Fotos de Perfil",
      Looks: "Aparência",
      LifeStyle: "Estilo de Vida",
      EmailUsername: "E-mail ou Nome de Usuário",
      Username: "Nome de Usuário",
      Overview: "Visão Geral",
      Thisweek: "Esta Semana",
      Earnings: "Ganhos",
      MontlyStatistics: "Estatísticas Mensais",
      Login: "Login",
      Addnewmoderator: "Adicionar Novo Moderador",
      Enterinformationhere: "Digite as informações aqui",
      AddModerator: "Adicionar Moderador",
      Updatepackage: "Atualizar Pacote",
      Enterpackageinformationhere: "Digite as informações do pacote aqui",
      packagecover: "capa do pacote",
      Addnewpackage: "Adicionar Novo Pacote",
      Thepackagehasatimerange: "O pacote tem um intervalo de tempo",
      PackageManagement: "Gerenciamento de Pacotes",
      Thiswherewemanageandupdateflirtsorpackages:
        "Aqui é onde gerenciamos e atualizamos flertes ou pacotes",
      AddNew: "Adicionar Novo",
      RoleSettings: "Configurações de Papel",
      AddnewModeratedAccount: "Adicionar Nova Conta Moderada",
      Enterinformationhere: "Digite as informações aqui",
      Save: "Salvar",
      SendMassPoke: "Enviar Poke em Massa",
      YourPOKESwillgoouttotheseusers: "Seus POKES serão enviados para esses usuários",
      SendMassLikes: "Enviar Curtidas em Massa",
      YourLIKESwillgoouttotheseusers: "Suas CURTIDAS serão enviadas para esses usuários",
      SendMassSpam: "Enviar Spam em Massa",
      Yourmessagewillgoouttotheseusers:
        "Sua mensagem será enviada para esses usuários",
      SendSpam: "Enviar Spam",
      SendMassEmail: "Enviar E-mail em Massa",
      Emailmessageswillgoouttotheseusers:
        "As mensagens de e-mail serão enviadas para esses usuários",
      SendEmail: "Enviar E-mail",
      Promotion: "Promoção",
      Sendmessageandreachouttousershere:
        "Enviar mensagem e alcançar usuários aqui",
      Search: "Pesquisar",
      Unknown: "Desconhecido",
      NOTSET: "NÃO DEFINIDO",
      Youruseraccounts: "Suas Contas de Usuário",
      SellingNudes: "Venda de Nudes",
      SellingGifts: "Venda de Presentes",
      UpdatePhotos: "Atualizar Fotos",
      Agree: "Concordar",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Você foi avisado pelo administrador. Por favor, concorde que isso não acontecerá novamente",
      Senddirectchatmessage: "Enviar Mensagem de Chat Direto",
      Dashboard: "Painel de Controle",
    },

    // Schwedisch
    sv: {
      // Events: "Händelser",
      Dashboard: "Instrumentbräda",
      Packages: "Paket",
      Gallery: "Galleri",
      Statistics: "Statistik",
      PanicRoom: "PanicRoom",
      Finance: "Finans",
      report: "rapport",
      Events: "Händelser",

      Moderators: "Moderatorer",
      MessageStatistics: "Meddelandestatistik",
      SendWarning: "Skicka varning",
      TotalEarnings: "Totala intäkter",
      Senddirectchatmessage: " Skicka direkt chattmeddelande",
      SendWarning: "Skicka varning",
      startChatting: "Börja chatta",
      UserProfile: "Användarprofil",
      Change: "Ändra",
      Owner: "Ägare",
      SaveChanges: "Spara ändringar",
      UpdateAffilateData: "Uppdatera affiliatdata",
      AddAffilate: "Lägg till affiliat",
      addnewaffilite: "Lägg till nya affiliater/skapare/influencers här",
      AffilatesManagement: "Affiliatehantering",
      Cancel: "Avbryt",
      OK: "OK",
      Youhavebeenblocked: "Du har blivit blockerad",
      NoPoolsFoundPleaseRefreshinaWhile:
        "Inga pooler hittades. Var god uppdatera om en stund.",
      GenerateVoucher: "Generera kupong",
      AddCoupons: "Lägg till kuponger",
      createnewcouponshere: "Skapa nya kuponger här",
      Remove: "Ta bort",
      Onetime: "En gång",
      Welcome: "Välkommen",
      DialogExplorer: "Dialogutforskare",
      Updateeventdetailsfor: "Uppdatera händelsedetaljer för",
      AddnewFlirtTooleventhere: "Lägg till nytt FlirtTool-evenemang här",
      SaveEvent: "Spara evenemang",
      AddanewEvent: "Lägg till en ny händelse",
      Addnewchateventhere: "Lägg till nytt chatt-evenemang här",
      eventcover: "Evenemangsförpackning",
      AddEvents: "Lägg till evenemang",
      AddFakeAccount: "Lägg till falskt konto",
      Close: "Stäng",
      UpdateProfilePic: "Uppdatera profilbild",
      Noprofilephotos: "Inga profilbilder",
      Looks: "Utseende",
      LifeStyle: "Livsstil",
      EmailUsername: "E-post eller användarnamn",
      FakeAccounts: "Falska konton",
      Overview: "Översikt",
      Thisweek: "Den här veckan",
      Earnings: "Inkomster",
      MontlyStatistics: "Månadsstatistik",
      Login: "Logga in",
      Addnewmoderator: "Lägg till ny moderator",
      Enterinformationhere: "Ange information här",
      Moderators: "Moderatorer",
      AddModerator: "Lägg till moderator",
      Updatepackage: "Uppdatera paket",
      Enterpackageinformationhere: "Ange paketinformation här",
      packagecover: "Paketomslag",
      Addnewpackage: "Lägg till nytt paket",
      Enterpackageinformationhere: "Ange paketinformation här",
      Thepackagehasatimerange: "Paketet har ett tidsintervall",
      PackageManagement: "Pakethantering",
      Thiswherewemanageandupdateflirtsorpackages:
        "Det är här vi hanterar och uppdaterar flirts eller paket",
      AddNew: "Lägg till nytt",
      RoleSettings: "Rollinställningar",
      AddnewModeratedAccount: "Lägg till ny modererad konto",
      Enterinformationhere: "Ange information här",
      Save: "Spara",
      SendMassPoke: "Skicka masspok",
      YourPOKESwillgoouttotheseusers:
        "Dina POKES kommer att skickas till dessa användare",
      SendMassLikes: "Skicka masslikes",
      YourLIKESwillgoouttotheseusers:
        "Dina LIKES kommer att skickas till dessa användare",
      SendMassSpam: "Skicka masspam",
      Yourmessagewillgoouttotheseusers:
        "Ditt meddelande kommer att skickas till dessa användare",
      SendSpam: "Skicka spam",
      SendMassEmail: "Skicka mass-e-post",
      Emailmessageswillgoouttotheseusers:
        "E-postmeddelanden kommer att skickas till dessa användare",
      SendEmail: "Skicka e-post",
      Promotion: "Framstöt",
      Sendmessageandreachouttousershere:
        "Skicka meddelande och nå användare här",
      Search: "Sök",
      Unknown: "Okänd",
      NOTSET: "INTE INSTÄLLD",
      Youruseraccounts: "Dina användarkonton",
      SellingNudes: "Säljer Nudes",
      SellingGifts: "Säljer Presenter",
      UpdatePhotos: "Uppdatera foton",
      Community: "Gemenskap",
      Agree: "Godkänn",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Du har blivit varnad av administratören. Vänligen godkänn att detta inte kommer att hända igen",
    },

    // Schwedisch
    // Danish
    Danish:
    {
      Job: "Job",
WorkingSchedule: "Arbejdsskema",
RelationshipStatus: "Forholdsstatus",
Searchingfor: "Leder efter",
pastRelationships: "Tidligere Forhold",
TraitsforIdealPartner: "Egenskaber for Ideel Partner",
FuturePlanswithPartner: "Fremtidsplaner med Partneren",
      SellingImages: "Sælger billeder",
      Images: "Billeder",
      AgentID: "Agent ID",
      Actions: "Handlinger",
      Rank: "Rang",
      EventPrice: "Event Pris",
      Answers: "Svar",
      Operator: "Operatør",
      Settings: "Indstillinger",
      HoursSpent: "Timer Brugt",
      SendMessage: "Send Besked",
      Messages: "Beskeder",
      EventDetail: "Event Detalje",
      Audience: "Publikum",
      EventDate: "Event Dato",
      ActiveStatus: "Aktiv Status",
      CreatedAt: "Oprettet den",
      Location: "Placering",
      PackageType: "Pakke Type",
      FlirtsAmount: "Flirt Antal",
      CreatedDate: "Oprettet Dato",
      Price: "Pris",
      Total: "Total",
      PackageName: "Pakke Navn",
      PackagesDescription: "Pakke Beskrivelse",
      AccountType: "Konto Type",
      OnlineStatus: "Online Status",
      PaymentDate: "Betalingsdato",
      SelectFake: "Vælg Falsk",
      Moderators: "Moderatorer",
      Conversions: "Konverteringer",
      FullName: "Fulde Navn",
      Category: "Kategori",
      LastWeek: "Sidste Uge",
      RealTime: "Real Tid",
      AnswerRate: "Svar Rate",
      ModeratorTabs: "Moderator Faneblade",
      Messagesrecieved: "Modtagne Beskeder (IND)",
      Lengthoffirstmesssage: "Længde af Første Besked",
      MessagesSent: "Beskeder Sendt (UD)",
      ShowOverlay: "Vis Overlay",
      Gallery: "Galleri",
      Statistics: "Statistikker",
      Community: "Fællesskab",
      Coupons: "Kuponer",
      Packages: "Pakker",
      Transfer: "Overførsel",
      SKIP: "SPRING OVER",
      Today: "I Dag",
      LasWeek: "Sidste Uge",
      ThisWeek: "Denne Uge",
      ThisMonth: "Denne Måned",
      LastMonth: "Sidste Måned",
      MessageReported: "Besked Rapporteret",
      report: "Rapport",
      TimeElapsed: "Tid Gået",
      Affilates: "Affiliater",
      Finance: "Finans",
      Events: "Events",
      Promotion: "Brugere",
      Status: "Status",
      FakeAccounts: "Falske Konti",
      AgentOverview: "Agent Overblik",
      LiveMonitoring: "Live Overvågning",
      MessageStatistics: "Besked Statistik",
      ActivityAnalysis: "Aktivitetsanalyse",
      AgentActivity: "Agent Aktivitet",
      ChatModeration: "Chat Moderation",
      Reportauser: "Rapporter en Bruger",
      startChatting: "Start Chat",
      UserProfile: "Bruger Profil",
      Change: "Skift",
      Owner: "Ejer",
      SaveChanges: "Gem Ændringer",
      UpdateAffilateData: "Opdater Affilat Data",
      AddAffilate: "Tilføj Affilat",
      addnewaffilite: "Tilføj nye affilierede/kreatører/influencere her",
      AffilatesManagement: "Affilat Styring",
      Cancel: "Annuller",
      OK: "OK",
      Youhavebeenblocked: "Du er blevet Blokeret",
      NoPoolsFoundPleaseRefreshinaWhile: "Ingen Puljer Fundet. Opdater venligst om et Øjeblik",
      GenerateVoucher: "Generer Voucher",
      AddCoupons: "Tilføj Kuponer",
      createnewcouponshere: "Opret nye kuponer her",
      Remove: "Fjern",
      Onetime: "Én gang",
      Welcome: "Velkommen",
      DialogExplorer: "Dialog Explorer",
      Updateeventdetailsfor: "Opdater event detaljer for",
      AddnewFlirtTooleventhere: "Tilføj nyt FlirtTool event her",
      SaveEvent: "Gem Event",
      AddanewEvent: "Tilføj et Nyt Event",
      Addnewchateventhere: "Tilføj nyt chat event her",
      eventcover: "event dække",
      cover: "dække",
      Active: "Aktiv",
      EventName: "Event Navn",
      FirstName: "Fornavn",
      LastName: "Efternavn",
      AddEvents: "Tilføj Events",
      AddFakeAccount: "Tilføj Falsk Konto",
      Close: "Luk",
      Profile: "Profil",
      ProfilePic: "Profil Billede",
      Gender: "Køn",
      UpdateProfilePic: "Opdater Profil Billede",
      Noprofilephotos: "Ingen Profilbilleder",
      Looks: "Udseende",
      LifeStyle: "Livsstil",
      EmailUsername: "Email eller Brugernavn",
      Username: "Brugernavn",
      Overview: "Overblik",
      Thisweek: "Denne Uge",
      Earnings: "Indtjening",
      MontlyStatistics: "Månedlige Statistikker",
      Login: "Log Ind",
      Addnewmoderator: "Tilføj Ny Moderator",
      Enterinformationhere: "Indtast information her",
      AddModerator: "Tilføj Moderator",
      Updatepackage: "Opdater Pakke",
      Enterpackageinformationhere: "Indtast pakkeoplysninger her",
      packagecover: "pakke dække",
      Addnewpackage: "Tilføj Ny Pakke",
      Thepackagehasatimerange: "Pakken har et tidsinterval",
      PackageManagement: "Pakkehåndtering",
      Thiswherewemanageandupdateflirtsorpackages:
        "Her administrerer og opdaterer vi flirts eller pakker",
      AddNew: "Tilføj Ny",
      RoleSettings: "Rolle Indstillinger",
      AddnewModeratedAccount: "Tilføj Ny Modereret Konto",
      Enterinformationhere: "Indtast information her",
      Save: "Gem",
      SendMassPoke: "Send Massiv Poke",
      YourPOKESwillgoouttotheseusers: "Dine POKES vil blive sendt til disse brugere",
      SendMassLikes: "Send Massiv Likes",
      YourLIKESwillgoouttotheseusers: "Dine LIKES vil blive sendt til disse brugere",
      SendMassSpam: "Send Massiv Spam",
      Yourmessagewillgoouttotheseusers:
        "Din besked vil blive sendt til disse brugere",
      SendSpam: "Send Spam",
      SendMassEmail: "Send Massiv Email",
      Emailmessageswillgoouttotheseusers:
        "E-mail beskeder vil blive sendt til disse brugere",
      SendEmail: "Send Email",

      Sendmessageandreachouttousershere:
        "Send besked og nå ud til brugerne her",
      Search: "Søg",
      Unknown: "Ukendt",
      NOTSET: "IKKE INDSTILLET",
      Youruseraccounts: "Dine brugerkonti",
      SellingNudes: "Sælger Nøgenbilleder",
      SellingGifts: "Sælger Gaver",
      UpdatePhotos: "Opdater Billeder",
      Agree: "Enig",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Du er blevet advaret af administratoren. Venligst accepter at dette ikke vil ske igen",
      Senddirectchatmessage: "Send direkte chatbesked",
      Dashboard: "Instrumentbræt",
    },

    // Danish

    // Finnish
    fi:
    {
      Job: "Työ",
WorkingSchedule: "Työaikataulu",
RelationshipStatus: "Suhdetilanne",
Searchingfor: "Etsii",
pastRelationships: "Menneet Suhteet",
TraitsforIdealPartner: "Ihanteellisen Kumppanin Ominaisuudet",
FuturePlanswithPartner: "Tulevaisuudensuunnitelmat Kumppanin Kanssa",
      SellingImages: "Kuvien myynti",
      Images: "Kuvat",
      AgentID: "Edustajan tunniste",
      Actions: "Toimet",
      Rank: "Sijoitus",
      EventPrice: "Tapahtuman hinta",
      Answers: "Vastaukset",
      Operator: "Operaattori",
      Settings: "Asetukset",
      HoursSpent: "Kuluneet tunnit",
      SendMessage: "Lähetä viesti",
      Messages: "Viestit",
      EventDetail: "Tapahtuman tiedot",
      Audience: "Yleisö",
      EventDate: "Tapahtuman päivämäärä",
      ActiveStatus: "Aktiivinen tila",
      CreatedAt: "Luotu",
      Location: "Sijainti",
      PackageType: "Pakettityyppi",
      FlirtsAmount: "Flirttien määrä",
      CreatedDate: "Luontipäivä",
      Price: "Hinta",
      Total: "Yhteensä",
      PackageName: "Paketin nimi",
      PackagesDescription: "Pakettien kuvaus",
      AccountType: "Käyttäjätilin tyyppi",
      OnlineStatus: "Online-tila",
      PaymentDate: "Maksupäivä",
      SelectFake: "Valitse vale",
      Moderators: "Moderaattorit",
      Conversions: "Muunnokset",
      FullName: "Koko nimi",
      Category: "Kategoria",
      LastWeek: "Viime viikko",
      RealTime: "Reaaliaika",
      AnswerRate: "Vastaussuhde",
      ModeratorTabs: "Moderaattorin välilehdet",
      Messagesrecieved: "Vastaanotetut viestit (SIS)",
      Lengthoffirstmesssage: "Ensimmäisen viestin pituus",
      MessagesSent: "Lähetetyt viestit (ULK)",
      ShowOverlay: "Näytä päällekkäisyys",
      Gallery: "Galleria",
      Statistics: "Tilastot",
      Community: "Yhteisö",
      Coupons: "Kupongit",
      Packages: "Paketit",
      Transfer: "Siirto",
      SKIP: "OHITA",
      Today: "Tänään",
      LasWeek: "Viime viikko",
      ThisWeek: "Tällä viikolla",
      ThisMonth: "Tässä kuussa",
      LastMonth: "Viime kuussa",
      MessageReported: "Viesti raportoitu",
      report: "Raportoi",
      TimeElapsed: "Kulunut aika",
      Affilates: "Affiliaatit",
      Finance: "Rahoitus",
      Events: "Tapahtumat",
      Promotion: "Edistäminen",
      Status: "Tila",
      FakeAccounts: "Feikkitilit",
      AgentOverview: "Edustajan yleiskatsaus",
      LiveMonitoring: "Live-seuranta",
      MessageStatistics: "Viestitilastot",
      ActivityAnalysis: "Toiminta-analyysi",
      AgentActivity: "Edustajan toiminta",
      ChatModeration: "Keskustelumoderointi",
      Reportauser: "Ilmoita käyttäjä",
      startChatting: "Aloita keskustelu",
      UserProfile: "Käyttäjäprofiili",
      Change: "Muuta",
      Owner: "Omistaja",
      SaveChanges: "Tallenna muutokset",
      UpdateAffilateData: "Päivitä kumppanin tiedot",
      AddAffilate: "Lisää kumppani",
      addnewaffilite: "Lisää uusia kumppaneita/luojia/vaikuttajia tähän",
      AffilatesManagement: "Kumppanien hallinta",
      Cancel: "Peruuta",
      OK: "OK",
      Youhavebeenblocked: "Olet estetty",
      NoPoolsFoundPleaseRefreshinaWhile: "Ei löytynyt altaita. Päivitä hetken kuluttua",
      GenerateVoucher: "Luo kuponki",
      AddCoupons: "Lisää kuponkeja",
      createnewcouponshere: "Luo uusia kuponkeja täällä",
      Remove: "Poista",
      Onetime: "Kerran",
      Welcome: "Tervetuloa",
      DialogExplorer: "Keskusteluavuste",
      Updateeventdetailsfor: "Päivitä tapahtuman tiedot",
      AddnewFlirtTooleventhere: "Lisää uusi flirtti tapahtuma tähän",
      SaveEvent: "Tallenna tapahtuma",
      AddanewEvent: "Lisää uusi tapahtuma",
      Addnewchateventhere: "Lisää uusi keskustelutapahtuma tähän",
      eventcover: "tapahtuman kansi",
      cover: "kansi",
      Active: "Aktiivinen",
      EventName: "Tapahtuman nimi",
      FirstName: "Etunimi",
      LastName: "Sukunimi",
      AddEvents: "Lisää tapahtumia",
      AddFakeAccount: "Lisää feikkitili",
      Close: "Sulje",
      Profile: "Profiili",
      ProfilePic: "Profiilikuva",
      Gender: "Sukupuoli",
      UpdateProfilePic: "Päivitä profiilikuva",
      Noprofilephotos: "Ei profiilikuvia",
      Looks: "Ulkonäkö",
      LifeStyle: "Elämäntapa",
      EmailUsername: "Sähköposti tai käyttäjänimi",
      Username: "Käyttäjänimi",
      Overview: "Yleiskatsaus",
      Thisweek: "Tämä viikko",
      Earnings: "Ansioita",
      MontlyStatistics: "Kuukausitilastot",
      Login: "Kirjaudu sisään",
      Addnewmoderator: "Lisää uusi moderaattori",
      Enterinformationhere: "Syötä tiedot tähän",
      AddModerator: "Lisää moderaattori",
      Updatepackage: "Päivitä paketti",
      Enterpackageinformationhere: "Syötä paketin tiedot tähän",
      packagecover: "pakettikansi",
      Addnewpackage: "Lisää uusi paketti",
      Thepackagehasatimerange: "Pakettiin sisältyy aikaväli",
      PackageManagement: "Paketinhallinta",
      Thiswherewemanageandupdateflirtsorpackages:
        "Täällä hallinnoimme ja päivitämme flirttejä tai paketteja",
      AddNew: "Lisää uusi",
      RoleSettings: "Rooliasetukset",
      AddnewModeratedAccount: "Lisää uusi valvottu tili",
      Enterinformationhere: "Syötä tiedot tähän",
      Save: "Tallenna",
      SendMassPoke: "Lähetä massapoke",
      YourPOKESwillgoouttotheseusers: "POKESisi lähetetään näille käyttäjille",
      SendMassLikes: "Lähetä massatykkäykset",
      YourLIKESwillgoouttotheseusers: "TYKKÄYKSESI lähetetään näille käyttäjille",
      SendMassSpam: "Lähetä massaspam",
      Yourmessagewillgoouttotheseusers:
        "Viestisi lähetetään näille käyttäjille",
      SendSpam: "Lähetä roskaposti",
      SendMassEmail: "Lähetä massasähköposti",
      Emailmessageswillgoouttotheseusers:
        "Sähköpostiviestit lähetetään näille käyttäjille",
      SendEmail: "Lähetä sähköposti",
      Promotion: "Promootio",
      Sendmessageandreachouttousershere:
        "Lähetä viesti ja tavoita käyttäjät täällä",
      Search: "Haku",
      Unknown: "Tuntematon",
      NOTSET: "EI ASETETTU",
      Youruseraccounts: "Käyttäjätilisi",
      SellingNudes: "Alastonkuvien myynti",
      SellingGifts: "Lahjojen myynti",
      UpdatePhotos: "Päivitä kuvat",
      Agree: "Hyväksy",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Olet saanut varoituksen adminilta. Ole hyvä ja hyväksy, ettei tämä tapahdu uudelleen",
      Senddirectchatmessage: "Lähetä suora chat-viesti",
      Dashboard: "Hallintapaneeli",
    },

    // Finnish

    // Serbian
    sr:
    {
      Job: "Posao",
WorkingSchedule: "Radni Raspored",
RelationshipStatus: "Status Veze",
Searchingfor: "Traži",
pastRelationships: "Prošle Veze",
TraitsforIdealPartner: "Osobine Idealnog Partnera",
FuturePlanswithPartner: "Planovi za Budućnost sa Partnerom",
      name: "Ime",
      location: "Lokacija",
      birthDay: "Datum Rođenja",
      SellingImages: "Prodaja slika",
      Images: "Slike",
      AgentID: "ID agenta",
      Actions: "Radnje",
      Rank: "Rang",
      EventPrice: "Cena događaja",
      Answers: "Odgovori",
      Operator: "Operator",
      Settings: "Podešavanja",
      HoursSpent: "Provedeni sati",
      SendMessage: "Pošalji poruku",
      Messages: "Poruke",
      EventDetail: "Detalji događaja",
      Audience: "Publika",
      EventDate: "Datum događaja",
      ActiveStatus: "Aktivan status",
      CreatedAt: "Kreirano",
      Location: "Lokacija",
      PackageType: "Tip paketa",
      FlirtsAmount: "Količina flertova",
      CreatedDate: "Datum kreiranja",
      Price: "Cena",
      Total: "Ukupno",
      PackageName: "Naziv paketa",
      PackagesDescription: "Opis paketa",
      AccountType: "Tip naloga",
      OnlineStatus: "Online status",
      PaymentDate: "Datum plaćanja",
      SelectFake: "Izaberi lažno",
      Moderators: "Moderatori",
      Conversions: "Konverzije",
      FullName: "Puno ime",
      Category: "Kategorija",
      LastWeek: "Prošle nedelje",
      RealTime: "U realnom vremenu",
      AnswerRate: "Stopa odgovora",
      ModeratorTabs: "Tabovi moderatora",
      Messagesrecieved: "Primljene poruke (UL)",
      Lengthoffirstmesssage: "Dužina prve poruke",
      MessagesSent: "Poslate poruke (IZ)",
      ShowOverlay: "Prikaži preklapanje",
      Gallery: "Galerija",
      Statistics: "Statistika",
      Community: "Zajednica",
      Coupons: "Kuponi",
      Packages: "Paketi",
      Transfer: "Prenos",
      SKIP: "Preskoči",
      Today: "Danas",
      LasWeek: "Prošle nedelje",
      ThisWeek: "Ove nedelje",
      ThisMonth: "Ovog meseca",
      LastMonth: "Prošlog meseca",
      MessageReported: "Prijavljena poruka",
      report: "Prijavi",
      TimeElapsed: "Proteklo vreme",
      Affilates: "Afiliate",
      Finance: "Finansije",
      Events: "Događaji",
      Promotion: "Promocija",
      Status: "Status",
      FakeAccounts: "Lažni nalozi",
      AgentOverview: "Pregled agenta",
      LiveMonitoring: "Uživo praćenje",
      MessageStatistics: "Statistika poruka",
      ActivityAnalysis: "Analiza aktivnosti",
      AgentActivity: "Aktivnost agenta",
      ChatModeration: "Moderacija čata",
      Reportauser: "Prijavi korisnika",
      startChatting: "Započni čet",
      UserProfile: "Korisnički profil",
      Change: "Promeni",
      Owner: "Vlasnik",
      SaveChanges: "Sačuvaj promene",
      UpdateAffilateData: "Ažuriraj podatke afilijata",
      AddAffilate: "Dodaj afilijata",
      addnewaffilite: "Dodaj nove afilijate/kreatore/influensere ovde",
      AffilatesManagement: "Upravljanje afilijatima",
      Cancel: "Otkaži",
      OK: "U redu",
      Youhavebeenblocked: "Blokirani ste",
      NoPoolsFoundPleaseRefreshinaWhile: "Nije pronađen nijedan bazen. Osvežite za trenutak",
      GenerateVoucher: "Generiši vaučer",
      AddCoupons: "Dodaj kupone",
      createnewcouponshere: "Kreiraj nove kupone ovde",
      Remove: "Ukloni",
      Onetime: "Jednokratno",
      Welcome: "Dobrodošli",
      DialogExplorer: "Istraživač dijaloga",
      Updateeventdetailsfor: "Ažuriraj detalje događaja za",
      AddnewFlirtTooleventhere: "Dodaj novi FlirtTool događaj ovde",
      SaveEvent: "Sačuvaj događaj",
      AddanewEvent: "Dodaj novi događaj",
      Addnewchateventhere: "Dodaj novi chat događaj ovde",
      eventcover: "pokrivač događaja",
      cover: "poklopac",
      Active: "Aktivan",
      EventName: "Naziv događaja",
      FirstName: "Ime",
      LastName: "Prezime",
      AddEvents: "Dodaj događaje",
      AddFakeAccount: "Dodaj lažni nalog",
      Close: "Zatvori",
      Profile: "Profil",
      ProfilePic: "Profilna slika",
      Gender: "Pol",
      UpdateProfilePic: "Ažuriraj profilnu sliku",
      Noprofilephotos: "Nema profilnih slika",
      Looks: "Izgled",
      LifeStyle: "Način života",
      EmailUsername: "Email ili korisničko ime",
      Username: "Korisničko ime",
      Overview: "Pregled",
      Thisweek: "Ove nedelje",
      Earnings: "Zarada",
      MontlyStatistics: "Mesečna statistika",
      Login: "Prijava",
      Addnewmoderator: "Dodaj novog moderatora",
      Enterinformationhere: "Unesite informacije ovde",
      AddModerator: "Dodaj moderatora",
      Updatepackage: "Ažuriraj paket",
      Enterpackageinformationhere: "Unesite informacije o paketu ovde",
      packagecover: "pokrivač paketa",
      Addnewpackage: "Dodaj novi paket",
      Thepackagehasatimerange: "Paket ima vremenski opseg",
      PackageManagement: "Upravljanje paketima",
      Thiswherewemanageandupdateflirtsorpackages:
        "Ovde upravljamo i ažuriramo flertove ili pakete",
      AddNew: "Dodaj novi",
      RoleSettings: "Podešavanja uloga",
      AddnewModeratedAccount: "Dodaj novi moderirani nalog",
      Enterinformationhere: "Unesite informacije ovde",
      Save: "Sačuvaj",
      SendMassPoke: "Pošalji masovno povlačenje",
      YourPOKESwillgoouttotheseusers: "Vaši POKESi će biti poslati ovim korisnicima",
      SendMassLikes: "Pošalji masovne lajkove",
      YourLIKESwillgoouttotheseusers: "Vaši LIKESi će biti poslati ovim korisnicima",
      SendMassSpam: "Pošalji masovni spam",
      Yourmessagewillgoouttotheseusers:
        "Vaša poruka će biti poslata ovim korisnicima",
      SendSpam: "Pošalji spam",
      SendMassEmail: "Pošalji masovni email",
      Emailmessageswillgoouttotheseusers:
        "Email poruke će biti poslate ovim korisnicima",
      SendEmail: "Pošalji email",
      Promotion: "Promocija",
      Sendmessageandreachouttousershere:
        "Pošalji poruku i stupi u kontakt sa korisnicima ovde",
      Search: "Pretraga",
      Unknown: "Nepoznato",
      NOTSET: "NIJE POSTAVLJENO",
      Youruseraccounts: "Vaši korisnički nalozi",
      SellingNudes: "Prodaja golih slika",
      SellingGifts: "Prodaja poklona",
      UpdatePhotos: "Ažuriraj fotografije",
      Agree: "Slažem se",
      YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain:
        "Upozoreni ste od strane admina. Molimo vas da se složite da se ovo neće ponoviti",
      Senddirectchatmessage: "Pošalji direktnu chat poruku",
      Dashboard: "Kontrolna tabla",
    },

    // Serbian
  },
};
