<template>
  <div id="app">
    <ModReports />
 </div>
</template>

<script>
/* eslint-disable */
import ModReports from '../components/moderatorReports.vue'
export default {
  components: {
    ModReports
  },
};
</script>
