<template>
  <div>
    <PageLoader v-if="loading" />
    <v-row dense>
      <div class="mt-2">
        <v-row dense class="mt-5 mx-5">
          <h2 class="px-3 pt-2 font-weight-bold">
            <span>Deactivated Accounts </span> <br />
          </h2>
          <v-btn class="ml-2" @click="fetchFakeAccounts()">Refresh</v-btn>
        </v-row>
      </div>
    </v-row>
     <v-data-table
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loadingData"
      :items="items"
      :items-per-page="10"
      :headers="headers"
      fixed-header
      class="elevation-0 mt-3 mx-2"
      :footer-props="{
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
         'items-per-page-options': [10, 20, 30, 40, 50,100]
      }"
    >
      <template v-slot:[`item.profilepic`]="{ item }">
        <v-avatar class="profile m-3" color="grey" size="100" tile>
          <v-img :src="getURL(item.profilePic)">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        @{{ item.username }}
      </template>
      <template v-slot:[`item.lastName`]="{ item }">
        {{ item.base_profile && item.base_profile.lastName }}
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        {{ item.base_profile && item.base_profile.firstName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="initInfoUpdate(item)" outlined>upate details</v-btn>
      </template>
      <template v-slot:[`item.view`]="{ item }">
        <a target="_blank" :href="getUserUrl(item)" outlined>
          <v-icon>mdi-eye</v-icon>
        </a>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <!-- <v-btn @click="changeUserStatus(item,false)" v-if="item.active" outlined>
          Deactivate
        </v-btn> -->
        <v-btn @click="changeUserStatus(item,true)" v-if="!item.active" outlined>
          Activate
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination  :length="Math.ceil(totalCount/options.itemsPerPage)"  rounded="circle" :total-visible="10" v-model="options.page">
    </v-pagination>
  </div>
</template>
<script>
/* eslint-disable */
const PRODUCTION = true
import PageLoader from "@/components/PageLoader.vue";
import {
  FAKE_ACCOUNTS,
  SET_USER_STATUS
} from "../queries/core";
export default {
  components: {
    PageLoader,
  },
  created() {
    // alert("SDFsdf")
    this.fetchFakeAccounts()
  },
  watch: {
    "options.itemsPerPage": {
      async handler() {
        await this.fetchFakeAccounts();
      },
    },
    "options.page": {
      async handler() {
        // console.log("pagedd")
        await this.fetchFakeAccounts();
      },
    },
  },
  methods: {
    async changeUserStatus(item,active) {
      try{ 
        this.loadingSave = true;
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: SET_USER_STATUS,
          variables: {
            id:String(item.id),
            active:active
          },
        });
        this.loadingSave = false;
        this.loading = false;
        this.items = this.items.map((i)=>{
          if(i.id == item.id) {
              i.active = active
          }
          return i
        })
      }catch(e){
        alert(e.message)
        this.loadingSave = false;
        this.loading = false;
      }
    },
    getUserUrl(item) {
      return `https://${this.$store.state.domain}/p/${item.username}`;
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":3000/media/" + url;
      return domain;
    },
    async fetchFakeAccounts() {
      try {
        this.loadingData = true;
        this.loading = true;
        const { data, errors } = await this.$apollo.query({
          query: FAKE_ACCOUNTS,
          fetchPolicy: "no-cache",
          variables: {
            input: {
              order:'DESC',
              page: this.options.page,
              pageSize: this.options.itemsPerPage,
              active:false
            },
          },
        });
        this.loading = false;
        if (!errors) {
          this.items = data.fakeAccounts.data;
          this.totalCount = data.fakeAccounts.total;
          this.loadingData = false;
          this.loading = false;
        }
      }catch(e){ 
         this.loadingData = false;
        this.loading = false;
        console.log(e)
      }
    },
  },
  data() {
    return {
      options: {
      page: 1,
      itemsPerPage: 10,
    },
      loading: false,
      items: [],
      searched: "",
    totalCount: 0,
     headers: [
      {
        text: 'ProfilePic',
        align: "start",
        sortable: false,
        value: "profilepic",
      },
      {
        text: 'Username',
        align: "start",
        sortable: false,
        value: "username",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: 'FirstName',
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: 'LastName',
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: 'Active',
        align: "start",
        sortable: false,
        value: "active",
      },
      {
        text: 'Actions',
        align: "start",
        sortable: false,
        value: "actions",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "view",
      },
    ],
    };
  },
};
</script>
