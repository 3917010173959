import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        netPoolCount:0,
        poolcount:0,
        vipCount:0,
        warningAck:null,
        domain:null,
        modId:null,
        flirtok: null,
        loadedTargetProfileStatus: 'none',
        model: 'profile',
        selectedProfileTarget: null,
        requestedSet: 'vip',
        lang:'en',
        targetData: {},
        profileData: {},
        profileDesc: "",
        profileDescIdris: "",
        targetDesc: "",
        targetDescIdris: "",
        curConv: 0,
        messageSent: 0,
        currentStatus:'offline',
        onlineMods:[],
        noDialogs: false,
        queryFromPools: 'pools',
        startedChatting: false,
        totalAssignedPoolNotifications: 0,
        totalAssignedNotifications: 0,
        rPack: {
            r_name: "",
            r_location: "",
            r_birth: ""
        },
        fPack: {
            r_name: "",
            r_location: "",
            r_birth: ""
        },
        authP: "",
        currentAgent: {},
        modPin: ""
    },
    mutations: {
        setCurrentLang(state, data) {
            state.lang = data
        },
        netPoolCount(state, data) {
            state.netPoolCount = data
        },
        poolcount(state, data) {
            state.poolcount = data
        },
        vipCount(state, data) {
            state.vipCount = data
        },
        setModId(state, data) {
            state.modId = data
        },
        warningAck(state, data) {
            state.warningAck = data
        },
        setCommunityDomain(state, data) {
            state.domain = data
        },
        setChatStart(state, data) {
            state.startedChatting = data
        },
        setQueryTarget(state, data) {
            state.queryFromPools = data
        },
        saveMessageSent(state, data) {
            state.messageSent = data
        },
        setModPin(state, data) {
            state.modPin = data
        },
        setCurrentStatus(state, data) {
            state.currentStatus = data
        },
        setFlirtTok(state, data) {
            state.flirtok = data
        },
        setOnlineMods(state, data) {
            let online_mods = data.reduce((accu,currentVal)=> {
                let mod_data = currentVal.split("@")
                accu.push({
                    id:mod_data[0],
                    status:mod_data[1]
                })
                return accu
            },[])
            state.onlineMods = online_mods
        },
        newLogin(state, payload) {
            state.authP = payload;
        },
        setTotalAssignedPoolNotifications(state, count) {
            state.totalAssignedPoolNotifications = count;
        },
        setTotalAssignedNotifications(state, count) {
            state.totalAssignedNotifications = count;
        },
        setCurrentAgent(state, agent) {
            state.currentAgent = agent
        },
        setNoDialogs(state, stat) {
            state.noDialogs = stat;
        },
        setRpack(state, pack) {
            state.rPack = pack
        },
        setFpack(state, pack) {
            state.fPack = pack
        },
        setProfileDesc(state, desc) {
            state.profileDesc = desc
        },
        setProfileDescIdris(state, desc) {
            state.profileDescIdris = desc
        },
        setTargetDesc(state, desc) {
            state.targetDesc = desc
        },
        setTargetDescIdris(state, desc) {
            state.targetDescIdris = desc
        },
        setCurConv(state, conv) {
            state.curConv = conv
        },
        setDescs(state, set) {
            state.profileDesc = set.profileDesc;
            state.targetDescChangedStatus = set.targetDesc;
        },
        setRequestedSet(state, set) {
            state.requestedSet = set
        },
        setTargetData(state, target) {
            state.targetData = target
        },
        setProfileData(state, profile) {
            state.profileData = profile
        },
        resetState(state) {
            state.loadedTargetProfileStatus = 'none'
            state.model = 'profile'
            state.selectedProfileTarget = null
        },
        changeTarget(state, target) {
            state.selectedProfileTarget = target
        },
        setSideBarTab(state, tab) {
            state.model = tab
        },
        loadTargetProfile(state, status) {
            state.loadedTargetProfileStatus = status
        }
    },
    plugins: [createPersistedState()],
    getters: {
        warningAck: (state) => {
            return state.warningAck
        },
        netPoolCount: (state) => {
            return state.netPoolCount
        },
        poolcount: (state) => {
            return state.poolcount
        },
        vipCount: (state) => {
            return state.vipCount
        },
        getCurrentLang: (state) => {
            return state.lang
        },
        getModId: (state) => {
            return state.modId
        },
        getCommunityDomain: (state) => {
            return state.domain
        },
        getCurrentStatus: (state) => {
            return state.currentStatus
        },
        getstartedChatting: (state) => {
            return state.startedChatting
        },
        getQueryFromPools: (state) => {
            return state.queryFromPools
        },
        rPackChangedStatus: (state) => {
            return state.rPack;
        },
        fPackChangedStatus: (state) => {
            return state.fPack;
        },
        profileDescChangedStatus: (state) => {
            return state.profileDesc;
        },
        profileDescIdris: (state) => {
            return state.profileDescIdris;
        },
        targetDescIdris: (state) => {
            return state.targetDescIdris;
        },
        targetDescChangedStatus: (state) => {
            return state.targetDesc;
        },
        requestedSetStatus: (state) => {
            return state.requestedSet
        },
        profileStatus: (state) => {
            return state.profileData
        },
        saveMessageSent: (state) => {
            return state.messageSent
        },
        targetStatus: (state) => {
            return state.targetData
        },
        getChangeTarget: (state) => {
            return state.model
        },
        getOnlineMods: (state) => {
            return state.onlineMods
        }
    },
    actions: {},
    modules: {}
})