<template>
  <div id="app">
    <v-tabs>
      <v-tab href="#search" v-if="$store.state.authP.accountType == 'admin'">
        {{$t('RoleSettings')}}
      </v-tab>
      <v-tab-item value="search">
        <RoleTabs />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import RoleTabs from '../components/settings/RoleTabs.vue'


export default {
  components: {
    RoleTabs,
    
  },
};
</script>
