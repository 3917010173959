var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"70%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-gift ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Gift")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"700px"}},[[_c('v-row',{attrs:{"justify":"center"}},[_c('v-overlay',{attrs:{"z-index":_vm.zIndex,"value":_vm.overlay,"opacity":.90}},[(_vm.selectedImage)?_c('v-img',{attrs:{"src":("" + _vm.baseUrl + (_vm.selectedImage.asset)),"height":700,"width":700}}):_vm._e(),_c('v-card',{staticClass:"col chat-foot",attrs:{"flat":""}},[_c('v-row',{class:{
                  'mt-0': _vm.$vuetify.breakpoint.smAndUp,
                }},[_c('v-text-field',{attrs:{"filled":"","dense":"","color":"amber darken-2"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-",attrs:{"disabled":!(_vm.$store.state.loadedTargetProfileStatus == 'loaded'),"icon":"","large":"","outlined":"","text":"","color":"amber accent-4","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-emoticon")])],1):_vm._e()]}}])},[_c('v-list',[_c('VEmojiPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmojis),expression:"showEmojis"}],style:({ width: '440px', height: '200' }),attrs:{"labelSearch":"Search","lang":"pt-BR"},on:{"select":_vm.onSelectEmoji}})],1)],1)],1)],1),_c('br'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"teal","width":300},on:{"click":_vm.sendNude}},[_vm._v(" Send Gift ")]),_c('v-btn',{staticClass:"white--text float-right",attrs:{"color":"teal","width":300},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],_c('v-row',[_c('v-col',[_c('v-row',_vm._l((_vm.gifts),function(n){return _c('v-col',{key:n.id,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-img',{style:({padding:'15px',border:'solid 1px black'}),attrs:{"src":("" + _vm.baseUrl + (n.asset)),"height":150,"width":150},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.selectNude(n)}}},[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1)],1):_vm._e()],1)]}}],null,true)})],1)}),1)],1)],1)],2),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }