<template>
	<div id="app">
		<v-container fluid>
			<v-app-bar flat :height="100">
				<!-- from date selector -->
				<v-avatar tile :size="60" class="mx-2">
					<v-img :src="require('@/assets/filter.png')"> </v-img>
				</v-avatar>
				<!-- <v-icon :size="40" class="mx-2">mdi-filter-variant</v-icon> -->
				<v-col cols="12" sm="6" md="4" lg="2" class="mt-4">
					<v-menu
						ref="menuSt"
						v-model="menuStart"
						:close-on-content-click="false"
						:return-value.sync="dateStart"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="dateStart"
								class="mt-7"
								prepend-icon="mdi-calendar"
								readonly
								label="From"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker v-model="dateStart" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="menuStart = false">
								{{$t('Cancel')}}
							</v-btn>
							<v-btn text color="primary" @click="$refs.menuSt.save(dateStart)">
								{{$t('OK')}}
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<!-- end of from selector -->
				<!-- end date selector -->
				<v-col cols="12" sm="6" md="4" lg="2" class="mt-4">
					<v-menu
						ref="menuEn"
						v-model="menuEnd"
						:close-on-content-click="false"
						:return-value.sync="dateEnd"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="dateEnd"
								class="mt-7"
								prepend-icon="mdi-calendar"
								readonly
								label="To"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker v-model="dateEnd" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="menuEnd = false">
								{{$t('Cancel')}}
							</v-btn>
							<v-btn text color="primary" @click="$refs.menuEn.save(dateEnd)">
								{{$t('OK')}}
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<!-- end of end selector -->
			</v-app-bar>
			<!-- start of filter button group -->
			<v-col cols="12" style="padding: 0px" class="my-2">
				<v-btn-toggle
					active-class="activeFilterBtn"
					v-model="text"
					color="blue darken-4"
					mandatory
				>
					<v-btn
						:ripple="false"
						v-for="(fil, idx) in filters"
						:key="idx"
						@click="filterChanged(fil)"
						:value="fil.value"
						large
						v-text="fil.text"
					></v-btn>
				</v-btn-toggle>
			</v-col>
			<v-spacer></v-spacer>
			<!-- stop of filter button group -->
			<template>
				<v-data-table
					:headers="headers"
					:items="analysis_data"
					class="dataCard"
				>
					<template v-slot:[`item.calories`]="{ item }">
						<v-chip :color="getColor(item.calories)" dark>
							{{ item.calories }}
						</v-chip>
					</template>
				</v-data-table>
			</template>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import { AGENT_MOD_MESSAGES } from '../queries/core';
export default {
	apollo: {
		agentModMessages: {
			query: AGENT_MOD_MESSAGES,
			variables() {
				return {
					input: {
						dateFilter: this.dateFilter
					}
				}
			},
			result({ data: { agentModMessages } }) {
				this.analysis_data = [agentModMessages];
			}
		}
	},
	data() {
		return {
			text: this.$t('Today'),
			dateFilter: "today",
			dateStart: new Date().toISOString().substr(0, 10),
			dateEnd: new Date().toISOString().substr(0, 10),
			menuEnd: false,
			menuStart: false,
			headers: [
				{
					text: "Total",
					align: "start",
					sortable: true,
					value: "total",
				},
				// { text: this.$t('Operator'), value: "operator", sortable: false },
				{ text: this.$t('MessagesSent'), value: "outs" },
				// { text: "Answers", value: "answers" },
				// { text: "Answer Rate (%)", value: "answers_r" },
				{ text: this.$t('Messagesrecieved'), value: "ins" },
				{ text: this.$t('Lengthoffirstmesssage'), value: "charLen" },
			],
			analysis_data: [],
			filters: [
				{ value: "today", text: this.$t('Today') },
				{ value: "thisWeek", text: this.$t('Thisweek')},
				{ value: "thisMonth", text: this.$t('ThisMonth') },
				{ value: "lastWeek", text: this.$t('LastWeek') },
				// { value: "lastMonth", text: this.$t('LastMonth') },
			],
			authKey: "",
		};
	},
	created() {
		this.$apollo.queries.agentModMessages.refetch()
	},
	methods: {
		filterChanged(fil){
			this.dateFilter = fil.value
			console.log(fil.value,"fitler changed")
		},
		getColor(calories) {
			if (calories > 400) return "red";
			else if (calories > 200) return "orange";
			else return "green";
		},
		fetchAnalysisData() {
			const url = `https://funnyflirts.net/aj/moderation/get_mod_chat_analysis_data?filter_mode=${this.text}`;
			axios
				.get(url, {
					headers: {
						Authorization: this.authKey,
					},
				})
				.then(({ data: { agentModMessages } }) => {
					this.analysis_data =[agentModMessages];
					// console.warn(data);
				})
				.catch((e) => {
					console.error(e);
				});
		},
	},
};
</script>
<style scoped>
.dataCard {
	box-shadow: 0px 1px 1px -4px rgb(0 0 0 / 0%), 0px 1px 1px 2px rgb(0 0 0 / 0%),
		0px 5px 15px 14px rgb(0 0 0 / 2%) !important;
}
.activeFilterBtn {
	background: #0000;
}
</style>