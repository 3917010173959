<template>
  <v-container fluid>
    <PageLoader
    v-if="loading"
    />
      <v-app-bar flat :height="100">
        <!-- from date selector -->
        <v-avatar tile :size="60" class="mx-2">
          <v-img :src="require('@/assets/filter.png')"> </v-img>
        </v-avatar>
      </v-app-bar>
      <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
          <div class="mx-3">
            <v-card-title style="padding: 0px" class="pt-5 pb-2"
              >SendWarning</v-card-title
            >
            <v-textarea
            v-model="warningText"
             label="Enter your comment here"
              outlined
              filled
            >
            </v-textarea>
          </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mx-2"
            :ripple="false"
            @click="dialog = false"
            large
            text
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            class="mb-2"
            @click="sendWarning"
            large
            :ripple="false"
            color="rgb(255 116 79)"
            dark
            >Warn</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-row>
        <v-col cols="12"
          dense fixed app>
          <template>
           <v-data-table
            :options.sync="options"
            :server-items-length="totalCount"
            :loading="loadingTableData"
            :items="reports"
            :items-per-page="10"
            :headers="headers"
            fixed-header
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
             <template v-slot:[`item.reportActionType`]="{ item }">
              <p style="color:orange" v-if="item.reportActionType == 'warn'">
                Warned
              </p>
              <p style="color:red" v-if="item.reportActionType == null">
                No action taken yet
              </p>
              <p style="color:green" v-if="item.reportActionType == 'block'">
                 {{ item.warnText }}
              </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn v-if="item.adminAction == 'Agent Blocked'" @click="unblockUser(item.id)">unblock</v-btn>
               <v-select
                  v-if="!item.resolved && item.reportedAgainst.warnedByAdmin == 0"
                  v-model="item.seletedOption"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    @change="() => actionSelected(item)"
                    class="mt-4 mx-3"
                    placeholder="Action"
                    :items="actionItems"
                >
              <template v-slot:item="{ on, item }">
                <v-list-item v-on="on" :disabled="item.disabled">{{ item.label }}</v-list-item>
              </template>
             </v-select>
             <v-select
                  v-if="!item.resolved && item.reportedAgainst.warnedByAdmin == 1"
                  v-model="item.seletedOption"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    @change="() => actionSelected(item)"
                    class="mt-4 mx-3"
                    placeholder="Action"
                    :items="actionItems1"
                >
              <template v-slot:item="{ on, item }">
                <v-list-item v-on="on" :disabled="item.disabled">{{ item.label }}</v-list-item>
              </template>
             </v-select>
             <v-select
                  v-if="!item.resolved && item.reportedAgainst.warnedByAdmin == 2"
                  v-model="item.seletedOption"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    @change="() => actionSelected(item)"
                    class="mt-4 mx-3"
                    placeholder="Action"
                    :items="actionItems2"
                >
              <template v-slot:item="{ on, item }">
                <v-list-item v-on="on" :disabled="item.disabled">{{ item.label }}</v-list-item>
              </template>
             </v-select>
            </template>
          </v-data-table>
          </template>
        </v-col>
      </v-row>
      </v-container> 
</template>

<script>
/* eslint-disable */
import {
 GET_ALL_REPORTED_MESSAGES,
 ADMIN_MOD_ACTIONS
} from "../queries/core";
import PageLoader from '@/components/PageLoader.vue'

export default {
  components:{
    PageLoader
  },
  apollo: {
    allReports: {
      query: GET_ALL_REPORTED_MESSAGES,
      result({ data: { getAllReportedMessages } }) {
        getAllReportedMessages = getAllReportedMessages.map((r)=>{
          return { ... r ,seletedOption:"nothing"}
        })
        this.reports = getAllReportedMessages;
        this.loadingTableData = false
      },
    },
  },
  data() {
    return {
      loading:false,
      loadingTableData:true,
      dialog:false,
      activeReport:null,
      warningText:"",
      actionItems:[  
        { label: "Agent Warned (1st Shot)", value: "warn1" },
        { label: "Agent Warned (2nd Shot)", value: "warn2",disabled:true },
        { label: "Agent Blocked / Fired", value: "block" },
        { label: "Report not relevant", value: "notrele" },
        { label: "Select Action", value: "nothing" },
      ],
       actionItems1:[  
        { label: "Agent Warned (1st Shot)", value: "warn1",disabled:true },
        { label: "Agent Warned (2nd Shot)", value: "warn2" },
        { label: "Agent Blocked / Fired", value: "block" },
        { label: "Report not relevant", value: "notrele" },
        { label: "Select Action", value: "nothing" },
      ],
       actionItems2:[  
        { label: "Agent Warned (1st Shot)", value: "warn1",disabled:true },
        { label: "Agent Warned (2nd Shot)", value: "warn2" ,disabled:true},
        { label: "Report not relevant", value: "notrele" },
        { label: "Agent Blocked / Fired", value: "block" },
        { label: "Select Action", value: "nothing" },
      ],
       headers: [
      { text: 'Reported Against', value: 'reportedAgainst.pin' },
      { text: 'Reported By', value: 'reportedBy.pin' },
      { text: 'Message', value: 'message.body' },
      { text: 'Resolved Action', value: 'adminAction' },
      { text: 'Comment', value: 'comment' },
      { text: 'Actions', value: 'actions' },
    ],
    reports:[],
      images:[]
    }
  },
  methods:{
    baseUrl() {
      return ''
    },
    async sendWarning() {
      try {
        this.loading = true
        const { data, errors } = await this.$apollo.mutate({
          mutation: ADMIN_MOD_ACTIONS,
          variables: {
            reportId: String(this.activeReport.id),
            actionType: 'warn',
            comment:this.warningText
          },
        });
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        alert(e)
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
      }
    },
    async blockUser() {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: ADMIN_MOD_ACTIONS,
          variables: {
            reportId: String(this.activeReport.id),
            actionType: 'block',
            comment:this.warningText
          },
        });
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
        alert(e)
      }
    },
    async unblockUser(id) {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: ADMIN_MOD_ACTIONS,
          variables: {
            reportId: String(id),
            actionType: 'unblock',
            comment:this.warningText
          },
        });
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
        alert(e)
      }
    },
    async notRelevant() {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: ADMIN_MOD_ACTIONS,
          variables: {
            reportId: String(this.activeReport.id),
            actionType: 'notrele',
            comment:'Report not releveant'
          },
        });
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
      }catch(e) {
        this.warningText = '',
        this.activeReport = null
        this.dialog = false
        this.loading = false
        this.$apollo.queries.allReports
        .refetch()
        alert(e)
      }
    },
    async actionSelected(item) {
      this.activeReport = item
      let text = "Are you sure you want to proceed with this action";
      if (!confirm(text)) {
         this.$apollo.queries.allReports
        .refetch()
        return
      } 
      console.log(item)
      if(item.seletedOption == 'warn1' || item.seletedOption == 'warn2') {
        this.dialog = true
      }
      if(item.seletedOption == 'block') {
        await this.blockUser(item)
        this.activeReport = null
        this.$apollo.queries.allReports
        .refetch()
      }
      if(item.seletedOption == 'notrele') {
        await this.notRelevant()
        this.activeReport = null
        this.$apollo.queries.allReports
        .refetch()
      }
    }
  }
}
</script>

<style>

</style>