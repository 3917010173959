var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('PageLoader'):_vm._e(),_c('v-app-bar',{attrs:{"flat":"","height":100}},[_c('v-avatar',{staticClass:"mx-2",attrs:{"tile":"","size":60}},[_c('v-img',{attrs:{"src":require('@/assets/filter.png')}})],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"mx-3"},[_c('v-card-title',{staticClass:"pt-5 pb-2",staticStyle:{"padding":"0px"}},[_vm._v("SendWarning")]),_c('v-textarea',{attrs:{"label":"Enter your comment here","outlined":"","filled":""},model:{value:(_vm.warningText),callback:function ($$v) {_vm.warningText=$$v},expression:"warningText"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mx-2",attrs:{"ripple":false,"large":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('v-btn',{staticClass:"mb-2",attrs:{"large":"","ripple":false,"color":"rgb(255 116 79)","dark":""},on:{"click":_vm.sendWarning}},[_vm._v("Warn")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","dense":"","fixed":"","app":""}},[[_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loadingTableData,"items":_vm.reports,"items-per-page":10,"headers":_vm.headers,"fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.reportActionType",fn:function(ref){
var item = ref.item;
return [(item.reportActionType == 'warn')?_c('p',{staticStyle:{"color":"orange"}},[_vm._v(" Warned ")]):_vm._e(),(item.reportActionType == null)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" No action taken yet ")]):_vm._e(),(item.reportActionType == 'block')?_c('p',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.warnText)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.adminAction == 'Agent Blocked')?_c('v-btn',{on:{"click":function($event){return _vm.unblockUser(item.id)}}},[_vm._v("unblock")]):_vm._e(),(!item.resolved && item.reportedAgainst.warnedByAdmin == 0)?_c('v-select',{staticClass:"mt-4 mx-3",attrs:{"outlined":"","dense":"","item-text":"label","item-value":"value","placeholder":"Action","items":_vm.actionItems},on:{"change":function () { return _vm.actionSelected(item); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g({attrs:{"disabled":item.disabled}},on),[_vm._v(_vm._s(item.label))])]}}],null,true),model:{value:(item.seletedOption),callback:function ($$v) {_vm.$set(item, "seletedOption", $$v)},expression:"item.seletedOption"}}):_vm._e(),(!item.resolved && item.reportedAgainst.warnedByAdmin == 1)?_c('v-select',{staticClass:"mt-4 mx-3",attrs:{"outlined":"","dense":"","item-text":"label","item-value":"value","placeholder":"Action","items":_vm.actionItems1},on:{"change":function () { return _vm.actionSelected(item); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g({attrs:{"disabled":item.disabled}},on),[_vm._v(_vm._s(item.label))])]}}],null,true),model:{value:(item.seletedOption),callback:function ($$v) {_vm.$set(item, "seletedOption", $$v)},expression:"item.seletedOption"}}):_vm._e(),(!item.resolved && item.reportedAgainst.warnedByAdmin == 2)?_c('v-select',{staticClass:"mt-4 mx-3",attrs:{"outlined":"","dense":"","item-text":"label","item-value":"value","placeholder":"Action","items":_vm.actionItems2},on:{"change":function () { return _vm.actionSelected(item); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g({attrs:{"disabled":item.disabled}},on),[_vm._v(_vm._s(item.label))])]}}],null,true),model:{value:(item.seletedOption),callback:function ($$v) {_vm.$set(item, "seletedOption", $$v)},expression:"item.seletedOption"}}):_vm._e()]}}],null,true)})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }