<template>
  <v-app>
    <template>
     
        <v-dialog
          v-model="dialog"
          width="800"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Warning By the Admin</span>
            </v-card-title>
            <v-card-text>
             {{$t('YouhavebeenwarnedbytheadminPLeaseagreethatthiswillnothappenagain')}}
              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
                color="green-darken-1"
                variant="text"
                @click="ackWarning()"
              >
                {{$t('Agree')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
     
    </template>
    <router-view />
  </v-app>
</template>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  color: #2c3e50;
}
.v-input .v-label {
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  padding-left: 4px;
  padding-top: 3px;
}
</style>

<script>
/* eslint-disable */
import {ACK_ADMIN_WARNING} from './queries/core'

export default {
  methods: {
    async ackWarning() {
      try {
        await this.$apollo.mutate({
          mutation: ACK_ADMIN_WARNING,
        });
       	this.$store.commit("warningAck", true)
      }catch(e) {
        alert(e)
      }
    }
  },
  data() {
    return {
      dialog:false
    }
  },
  created() {
    // console.log(this.$i18n.locale,"this.$i18n.localethis.$i18n.localethis.$i18n.locale")
    // console.log(this.$store.state.core.lang,"this.$store.state.core.lang")
		this.$i18n.locale = this.$store.state.lang
    if(this.$store.state.warningAck == false) {
      this.dialog = true
    }
  },
  watch: {
  '$store.state.warningAck': function() {
    if(this.$store.state.warningAck == false) {
      this.dialog = true
    }else {
      this.dialog = false
    }
  }
}
};
</script>