var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-overlay',{model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}}),_c('v-app-bar',{attrs:{"flat":"","height":100}},[_c('v-avatar',{staticClass:"mx-2",attrs:{"tile":"","size":60}},[_c('v-img',{attrs:{"src":require('@/assets/filter.png')}})],1),(_vm.$store.state.authP.accountType == 'model')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('WelcometotheModelgallery'))),_c('br'),_vm._v(" Please make a gallery and add your videos/Pictures here ")]):_vm._e()],1),[(_vm.activeCategory)?_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on))]}}],null,false,2701885616),model:{value:(_vm.galleryUpdateDialog),callback:function ($$v) {_vm.galleryUpdateDialog=$$v},expression:"galleryUpdateDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Update Gallery")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-SD","outlined":"","label":"Gallery name"},model:{value:(_vm.activeCategory.name),callback:function ($$v) {_vm.$set(_vm.activeCategory, "name", $$v)},expression:"activeCategory.name"}})],1)],1),_c('img',{staticStyle:{"width":"91%","margin-left":"34px","margin-bottom":"11px"},attrs:{"src":("" + _vm.baseUrl + (_vm.activeCategory.poster))}}),_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Gallery Poster","placeholder":"Select your file","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4244939259),model:{value:(_vm.galleryPoster),callback:function ($$v) {_vm.galleryPoster=$$v},expression:"galleryPoster"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.galleryUpdateDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.updateGallery}},[_vm._v(" Save ")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.activeCategory)?_c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.fileDialog),callback:function ($$v) {_vm.fileDialog=$$v},expression:"fileDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"}),_c('v-card-text',[[_c('br'),_c('v-text-field',{attrs:{"type":"number","label":"Media cost","prepend-icon":"fas fa-list","outlined":""},model:{value:(_vm.cost),callback:function ($$v) {_vm.cost=$$v},expression:"cost"}}),_c('br'),_c('v-switch',{attrs:{"prepend-icon":"fas fa-list","label":"Is media paid"},model:{value:(_vm.is_paid),callback:function ($$v) {_vm.is_paid=$$v},expression:"is_paid"}}),_c('br'),_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Gallery media","placeholder":"Select your file","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4244939259),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})]],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(!_vm.uploadingFiles)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () { return (_vm.fileDialog = false); }}},[_c('p',{staticClass:"mr-8"},[_vm._v("close")])]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.uploadFiles}},[(!_vm.uploadingFiles)?_c('p',[_vm._v("Upload")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1),(_vm.uploadingFiles)?_c('v-progress-linear',{attrs:{"color":"blue-grey","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,false,3002676263),model:{value:(_vm.uploadProgress),callback:function ($$v) {_vm.uploadProgress=$$v},expression:"uploadProgress"}}):_vm._e()],1)],1):_vm._e()],1)],_c('v-spacer'),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"6","dense":"","fixed":"","app":""}},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.galleries,"loading":_vm.loadingTableData,"fixed-header":"","sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[(_vm.$store.state.authP.accountType == 'model')?_c('span',[_vm._v(" Model ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Community'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('Gallery')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New "+_vm._s(_vm.$t('Gallery'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("New "+_vm._s(_vm.$t('Gallery')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-SD","outlined":"","label":"Gallery name"},model:{value:(_vm.newGallery),callback:function ($$v) {_vm.newGallery=$$v},expression:"newGallery"}})],1)],1),_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"Gallery Poster","placeholder":"Select your file","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.galleryPoster),callback:function ($$v) {_vm.galleryPoster=$$v},expression:"galleryPoster"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addNewGallery}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"mr-8",attrs:{"small":"","color":_vm.isItemActive(item)},on:{"click":function($event){return _vm.getGalleryImages(item)}}},[_vm._v("mdi-eye ")]),(_vm.isItemActive(item) === 'green')?_c('v-icon',{staticClass:"mr-8",attrs:{"color":_vm.isItemActive(item),"medium":""},on:{"click":function($event){return _vm.openFileUploadDialogue(item)}}},[_vm._v(" mdi-arrow-up-bold-box-outline ")]):_vm._e(),(_vm.isItemActive(item) === 'green')?_c('v-icon',{attrs:{"color":_vm.isItemActive(item),"medium":""},on:{"click":function($event){return _vm.openGalleryUpdateDialog(item)}}},[_vm._v(" mdi mdi-update ")]):_vm._e()]}}])})]],2),_c('v-col',[[_c('v-row',{staticStyle:{"height":"650px","overflow":"scroll"}},_vm._l((_vm.images),function(n){return _c('v-col',{key:n.id,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('GetMedia',{attrs:{"media":n,"hover":hover},on:{"deleteImage":_vm.deleteImage,"mediaUpdate":_vm.mediaUpdate}})]}}],null,true)})],1)}),1)]],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }