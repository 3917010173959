<template>
  <div style="overflow: scroll">
    <v-text-field v-model="TargetJobTranslations" :label="$t('Job')" style="width: 95%" outlined :placeholder="$t('Job')">
    </v-text-field>
    <v-btn @click="saveNotes('TargetJobTranslations',TargetJobTranslations)" color="green-darken-1" variant="text">
      {{$t('Save')}}
    </v-btn>
    <v-text-field
    v-model="TargetWSTranslations"
      :label="$t('WorkingSchedule')"
      style="width: 95%"
      outlined 
      :placeholder="$t('WorkingSchedule')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetWSTranslations',TargetWSTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="TargetRSTranslations"
       :label="$t('RelationshipStatus')"
      style="width: 95%"
      outlined
     :placeholder="$t('RelationshipStatus')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetRSTranslations',TargetRSTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="TargetSFTranslations"
       :label="$t('Searchingfor')"
      style="width: 95%"
      outlined
      :placeholder="$t('Searchingfor')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetSFTranslations',TargetSFTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="TargetPRTranslations"
     :label="$t('pastRelationships')"
      style="width: 95%"
      outlined
      :placeholder="$t('pastRelationships')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetPRTranslations',TargetPRTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="TargetIPTranslations"
      :label="$t('TraitsforIdealPartner')"
      style="width: 95%"
      outlined
      :placeholder="$t('TraitsforIdealPartner')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetIPTranslations',TargetIPTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
    <v-text-field
    v-model="TargetFPPTranslations"
     :label="$t('FuturePlanswithPartner')"
      style="width: 95%"
      outlined
      :placeholder="$t('FuturePlanswithPartner')"
    >
    </v-text-field>
    <v-btn @click="saveNotes('TargetFPPTranslations',TargetFPPTranslations)" color="green-darken-1" variant="text"> {{$t('Save')}} </v-btn>
  </div>
</template>

<script>
const languageMapper = {
  en:"en",
  Deutsch:"ge",
  arabic:"ur",
  Turkish:"tur",
  france:"fr",
  polish:"po",
  Spanish:"sp",
  Italia:"it",
  portuguese:"pot",
  Schwedisch:"sch",
  Danish:"dan",
  finisch:"fini",
  Serbisch:"seb",
  russisch:"russ",
  japanese:"jap",
  koreanisch:"kor",
  Thailändische:"thai",
  ungarisch:"ung",
  irn:"inr"
}
import { UPDATE_IDRIS_NOTES } from "../queries/core";
export default {
  data() {
    return {
      TargetFPPTranslations: null,
      TargetIPTranslations: null,
      TargetJobTranslations: null,
      TargetPRTranslations: null,
      TargetRSTranslations: null,
      TargetSFTranslations: null,
      TargetWSTranslations: null,
    };
  },
  created() {
     let currentLanguage = this.$store.state.lang
      let toTranslationLang = languageMapper[currentLanguage]
    let notes = this.$store.state.targetDescIdris;
    if(notes.TargetFPPTranslations) {
      this.TargetFPPTranslations = notes.TargetFPPTranslations[toTranslationLang]
    }
    if(notes.TargetIPTranslations) {
      this.TargetIPTranslations = notes.TargetIPTranslations[toTranslationLang]
    }
    if(notes.TargetJobTranslations) {
      this.TargetJobTranslations = notes.TargetJobTranslations[toTranslationLang]
    }
    if(notes.TargetPRTranslations) {
      this.TargetPRTranslations = notes.TargetPRTranslations[toTranslationLang]
    }
    if(notes.TargetRSTranslations) {
      this.TargetRSTranslations = notes.TargetRSTranslations[toTranslationLang]
    }
    if(notes.ModSFTranslations) {
      this.ModSFTranslations = notes.ModSFTranslations[toTranslationLang]
    }
    if(notes.ModWSTranslations) {
      this.ModWSTranslations = notes.ModWSTranslations[toTranslationLang]
    }
  },
  methods: {
    async saveNotes(model,text) {
      console.log(model)
      console.log(text)
      // console.log(UPDATE_IDRIS_NOTES)
      this.$apollo.mutate({
        mutation: UPDATE_IDRIS_NOTES,
        variables: {
          input: {
            text: text,
            type: "real",
            convo: this.$store.state.curConv,
            fieldType: model,
          },
        },
      });
    },
  },
};
</script>
