<template>
  <div id="app">
     <ReportedMessages />
  </div>
</template>

<script>
/* eslint-disable */
import ReportedMessages from '../components/reportedMessages.vue'
export default {
  components: {
    ReportedMessages
  },
};
</script>
