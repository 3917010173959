<template>
  <div id="app" class="mx-3">
    <!-- Provides the application the proper gutter -->
    <!-- <v-container fluid> -->
      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
			<v-row dense>
			<div class="mt-2">
				<v-row dense class="mt-5 mx-5">
					<v-avatar tile :size="60" class="mt-3 ml-2">
						<v-img :src="require('@/assets/chat.png')" />
					</v-avatar>
					<h2 class="px-3 pt-2 font-weight-bold">
						<span
						> {{$t('DialogExplorer')}}
						</span>
					</h2>
				</v-row>
			</div>
		</v-row>
		<br>
      <v-row class="mt-1">
        <profile-side-bar v-if="isLoaded"></profile-side-bar>
        <chat-box-field
					:conversationId = $route.params.conv
					@loaded="loaded"
				></chat-box-field>
        <target-user-side-bar v-if="isLoaded"></target-user-side-bar>
      </v-row>
    <!-- </v-container> -->
  </div>
</template>

<script>
import axios from "axios";
import ChatBoxField from "../components/chatBoxFieldExplorer.vue";
import profileSideBar from "../components/profileSideBar.vue";
import TargetUserSideBar from "../components/targetUserSideBar.vue";

export default {
  components: {
    profileSideBar,
    ChatBoxField,
    TargetUserSideBar,
  },
  data() {
    return {
			isLoaded:false,
      drawer: true,
      group: null,
      startChat: !false,
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
      ],
      notficationCount: 0,
      authKey: "",
      model: null,
    };
  },
  methods: {
		loaded(){
			this.isLoaded = true
		},
    modeChanged() {
      const requestedSet = this.$store.state.requestedSet;
      // this.vipMode = !this.vipMode;
      this.$store.commit("resetState");
      if (requestedSet == "vip") {
        this.$store.commit("setRequestedSet", "nor");
      } else if (requestedSet == "nor") {
        this.$store.commit("setRequestedSet", "vip");
      }
    },
    logout() {
      const confirmExit = confirm("Are you sure you wanna log out ?");
      if (confirmExit) {
        localStorage.clear();
        window.location.assign("../../../../");
        const url = "https://funnyflirts.net/aj/moderation/logoutModerators";
        axios
          .post(
            url,
            {},
            {
              headers: {
                Authorization: this.authKey,
              },
            }
          )
          .then(({ data }) => {
            console.warn(data);
            this.$store.commit("resetState");
            this.$store.commit("setCurrentAgent", {});
            // this.$router.push("/");
            localStorage.clear();
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>