<template>
  <v-container fluid>
    <v-app-bar flat :height="100">
      <!-- from date selector -->
      <v-avatar tile :size="60" class="mx-2">
        <v-img :src="require('@/assets/filter.png')"> </v-img>
      </v-avatar>
    </v-app-bar>
    <br/>
      <v-row>
        <v-col cols="6"
          dense fixed app>
          <span>{{$t('ModeratorTabs')}}</span>
          <template>
            <v-select v-model="moderatorTabs" :items="items" label="Agent Tabs" multiple>
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
           <v-btn color="primary" @click="saveUserTabs('moderator')">
               {{$t('Save')}}
              </v-btn>
          </template>
      </v-col>
      <v-col cols="6"
          dense fixed app>
          <span>{{$t('SubAdminTabs')}}</span>
          <template>
            <v-select v-model="subadminTabs" :items="items" label="SubAdmin Tabs" multiple>
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
             <v-btn color="primary" @click="saveUserTabs('sub_admin')">
               {{$t('Save')}}
              </v-btn>
          </template>
      </v-col>
      <v-col cols="6"
          dense fixed app>
          <span>TeamLeader Tabs</span>
          <template>
            <v-select v-model="teamleaderTabs" :items="items" label="TeamLeader Tabs" multiple>
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
             <v-btn color="primary" @click="saveUserTabs('team_leader')">
               {{$t('Save')}}
              </v-btn>
          </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import {SAVE_PANEL_TAB_SETTINGS,PANEL_TABS_SETTINGS} from '../../queries/core'
export default {
  async created() {
    this.getTabsSettings()
  },
    data: () => ({
      items: ['Chat Moderation', 'Agent Activity', 'Activity Analysis', 'Message Statistics','Statistics','Live Monitoring','Agent Overview','Moderators','Fake Accounts','Promotion','Events','Finance','Affiliates','Reported Messages','Panic Room','Packages','Coupons','Selling Images','Settings'],
      teamleaderTabs: [],
      subadminTabs: [],
      moderatorTabs: [],
    }),
    methods: {
      async saveUserTabs(role) {
        let tabs;
        if(role == 'team_leader') tabs = this.teamleaderTabs
        if(role == 'sub_admin') tabs = this.subadminTabs
        if(role == 'moderator') tabs = this.moderatorTabs
        try {
          await this.$apollo.mutate({
            mutation: SAVE_PANEL_TAB_SETTINGS,
            variables: {
              role:role,
              tabs:JSON.stringify(tabs)
            },
        });
        this.getTabsSettings()
        alert("Data updated succesfully !!!")
        }catch(e) {

        }
      },
      async getTabsSettings() {
        try {
          const { data, errors } = await this.$apollo.query({
            query: PANEL_TABS_SETTINGS,
            fetchPolicy: 'no-cache'
          });
          if(data.getPanelTabSettings){ 
            let tabSettings = data.getPanelTabSettings
            if(tabSettings.moderatorTabs) {
              this.moderatorTabs = JSON.parse(tabSettings.moderatorTabs)
            }
            if(tabSettings.subadminTabs) {
              this.subadminTabs = JSON.parse(tabSettings.subadminTabs)
            }
            if(tabSettings.teamleaderTabs) {
              this.teamleaderTabs = JSON.parse(tabSettings.teamleaderTabs)
            }
          }
        }catch(e) {
          // alert('err')
        }
      }
    }
  }
</script>
<style scoped>
.col {
  padding: 12px !important;
}
</style>
