<template>
  <div id="app" class="mx-3">
    <!-- Provides the application the proper gutter -->
    <!-- <v-container fluid> -->
      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-row class="mt-1" v-if="$store.state.startedChatting">
        <template v-if="shouldStartChat">
          <profile-side-bar></profile-side-bar>
          <chat-box-field></chat-box-field>
          <target-user-side-bar></target-user-side-bar> 
        </template>
        <div v-else>
          <center class="ml-12 mt-12">{{$t('NoPoolsFoundPleaseRefreshinaWhile')}}</center>
        </div>
      </v-row>
    <!-- </v-container> -->
  </div>
</template>

<script>
/* eslint-disable */
// target user sidebar  (uses profileData in template ) --> shows fake  user profile 
// profile sidebar  (uses targetData in template) --> shows real user profile
import axios from "axios";
import ChatBoxField from "../components/chatBoxField.vue";
import profileSideBar from "../components/profileSideBar.vue";
import TargetUserSideBar from "../components/targetUserSideBar.vue";
import {
  GET_POOL_COUNT
} from "../queries/core";
export default {
  components: {
    profileSideBar,
    ChatBoxField,
    TargetUserSideBar,
  },
  created() {
    this.getPoolCountInfo()
  },
  watch:{ 
    '$store.state.queryFromPools' : function() {
       let queryFromPools = this.$store.state.queryFromPools
      if(queryFromPools == 'nt') { 
        this.shouldStartChat = false
      }else { 
        this.shouldStartChat = true
      }
    },
    '$store.state.netPoolCount' : function() {
       let netPoolCount = this.$store.state.netPoolCount
      if(!netPoolCount) {
        this.shouldStartChat = false
      }else {
        this.shouldStartChat = true
      }
    }
    
  },
  data() {
    return {
      shouldStartChat:true,
      drawer: true,
      group: null,
      startChat: !false,
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
      ],
      notficationCount: 0,
      authKey: "",
      model: null,
    };
  },
  methods: {
    async getPoolCountInfo() {
      try {
        let {data} = await this.$apollo
        .query({
          query: GET_POOL_COUNT,
          fetchPolicy: 'no-cache'
        })
        if(data.getPoolInfo){
          let pools = data.getPoolInfo.filter(p => p.pool_type == 'pool')
          let vips = data.getPoolInfo.filter(p => p.pool_type != 'pool')
          this.$store.commit("poolcount", pools.length)
          this.$store.commit("vipCount", vips.length)
          this.$store.commit("netPoolCount", data.getPoolInfo.length)
          if(data.getPoolInfo.length == 0) {
            this.shouldStartChat = false
            this.$store.commit("startedChatting", false)
          }
        }
      }catch(e) {
        console.log(e)
      }
    },
    modeChanged() {
      const requestedSet = this.$store.state.requestedSet;
      // this.vipMode = !this.vipMode;
      this.$store.commit("resetState");
      if (requestedSet == "vip") {
        this.$store.commit("setRequestedSet", "nor");
      } else if (requestedSet == "nor") {
        this.$store.commit("setRequestedSet", "vip");
      }
    },
    logout() {
      const confirmExit = confirm("Are you sure you wanna log out ?");
      if (confirmExit) {
        localStorage.clear();
        window.location.assign("../../../../");
        const url = "https://funnyflirts.net/aj/moderation/logoutModerators";
        axios
          .post(
            url,
            {},
            {
              headers: {
                Authorization: this.authKey,
              },
            }
          )
          .then(({ data }) => {
            console.warn(data);
            this.$store.commit("resetState");
            this.$store.commit("setCurrentAgent", {});
            // this.$router.push("/");
            localStorage.clear();
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>