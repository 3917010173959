<template>
  <div id="app">
    <v-container fluid>
      <v-app-bar flat :height="100">
        <!-- from date selector -->
        <v-avatar tile :size="60" class="mx-2">
          <v-img :src="require('@/assets/filter.png')"> </v-img>
        </v-avatar>
       
        <!-- end of end selector -->
      </v-app-bar>
      <!-- start of filter button group -->
      <v-col cols="12" style="padding: 0px" class="my-2">
        <v-btn-toggle
          active-class="activeFilterBtn"
          v-model="text"
          color="blue darken-4"
          mandatory
        >
          <v-btn
            :ripple="false"
            v-for="(fil, idx) in filters"
            :key="idx"
            :value="fil.value"
            large
            v-text="fil.text"
          ></v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <!-- stop of filter button group -->
      <template>
        <v-data-table
          :headers="headers"
          :items="items"
          class="dataCard"
        >
        </v-data-table>
      </template>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import {
  AGENT_ANALYTICS,
} from "../queries/core";
export default {
  data() {
    return {
      items:[],
      dateFilter:"thisMonth",
      text: this.$t('Today'),
      dateStart: new Date().toISOString().substr(0, 10),
      dateEnd: new Date().toISOString().substr(0, 10),
      menuEnd: false,
      menuStart: false,
      headers: [
      {
        text: "Pin",
        align: "start",
        sortable: false,
        value: "mod.pin",
      },
      {
        text: "Ins",
        align: "start",
        sortable: false,
        value: "ins",
      },
      {
        text: "Outs",
        align: "start",
        sortable: false,
        value: "outs",
      },
      {
        text: 'Total',
        align: "start",
        sortable: false,
        value: "total",
      },
      {
        text: 'Earnings',
        align: "start",
        sortable: false,
        value: "earnings",
      },
    ],
      filters: [
        { value: "today", text: this.$t('Today') },
        { value: "this_week", text: this.$t('ThisWeek') },
        { value: "last_week", text: this.$t('LasWeek') },
        { value: "this_month", text: this.$t('ThisMonth') },
        { value: "last_month", text: this.$t('LastMonth') },
      ],
      authKey: "",
    };
  },
  created() {
    this.loadMods();
  },
  methods: {
     loadMods() {
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: AGENT_ANALYTICS,
          variables: {
            input: {
              dateFilter: this.dateFilter,
              myAnalytics:true
            },
          },
        })
        .then(({ data: { allAgentsAnalytics } }) => {
          allAgentsAnalytics = allAgentsAnalytics.map((r)=>{
          return { ... r ,seletedOption:"nothing",languages:r.mod.languages.split(",")}
        })
          this.items = allAgentsAnalytics;
        });
    },
  },
};
</script>
<style scoped>
.dataCard {
  box-shadow: 0px 1px 1px -4px rgb(0 0 0 / 0%), 0px 1px 1px 2px rgb(0 0 0 / 0%),
    0px 5px 15px 14px rgb(0 0 0 / 2%) !important;
}
.activeFilterBtn {
  background: #0000;
}
</style>