<template>
	<v-navigation-drawer
		width="100%"
		v-model="drawer"
		absolute
		bottom
		stateless
		temporary
	>
		<v-list nav dense>
			<v-row dense class="mt-2">
				<h4
					style="font-size: 35px; color: #257ac5"
					class="px-2 font-weight-bold"
				>
					{{$t('Senddirectchatmessage')}}
					<br />
					<span style="font-size: 20px; color: #5c5a5a"
						>{{$t('Senddirectchatmessage')}}</span
					>
				</h4>
				<v-spacer></v-spacer>
				<v-tooltip bottom v-if="type == 'chat'">
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-switch
								class="mt-12 d-none"
								v-model="adminMode"
								label="Admin Message"
								color="#257ac5"
								value="admin"
								hide-details
							>
								<template v-slot:label>
									<h5 class="pt-3">From Admin</h5>
								</template>
							</v-switch>
						</span>
					</template>
					<span>{{('SendMessagefromAdmin')}}</span>
				</v-tooltip>
				<v-btn
					@click="closeDrawer"
					class="mx-3"
					color="error lighten-2"
					depressed
					><v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-divider></v-divider>

			<v-row dense class="ml-3">
				<v-avatar :size="80">
					<v-img :src="'https://funnyflirts.net/' + user.avater">
						<template v-slot:placeholder>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<v-progress-circular
									indeterminate
									color="grey lighten-5"
								></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-avatar>
				<v-col class="mt-1">
					<h3 class="userName">
						<span style="font-weight: bold">Username:</span>
						<span>{{ user.first_name }}{{ user.last_name }}</span>
					</h3>
					<h3 class="userName">
						<span style="font-weight: bold"> Email: </span>
						<span>{{ user.email }}</span>
					</h3>
				</v-col>

				<v-row dense>
					<v-spacer></v-spacer>
					<v-btn
						class="mt-5 pt-3 mx-5"
						color="primary lighten-1"
						large
						@click="sendMessage"
						:loading="emailSendLoading || loadingSend"
					>
						<h5 v-if="type == 'chat'">{{$t('SendMessage')}}</h5>
						<h5 v-else>{{$t('SendEmail')}}</h5>
					</v-btn>
				</v-row>
			</v-row>
			<v-row> </v-row>
			<b-col sm="12">
				<v-toolbar
					flat
					dark
					color="blue"
					v-if="!(adminMode == 'admin') && type == 'chat'"
				>
					<v-toolbar-title>(($t{'SelectFake'}))</v-toolbar-title>
					<v-autocomplete
						v-model="select"
						:loading="loading"
						:items="items"
						:search-input.sync="search"
						item-text="first_name"
						item-value="id"
						cache-items
						class="mx-4"
						flat
						hide-no-data
						hide-selected
						hide-details
						label="Select fake user to send with."
						solo-inverted
					>
						<template v-slot:selection="{ attr, on, item, selected }">
							<v-chip
								v-bind="attr"
								:input-value="selected"
								color="blue-grey"
								class="white--text"
								v-on="on"
							>
								<v-icon left> mdi-account </v-icon>
							</v-chip>
							<span> {{ item.first_name }} {{ item.last_name }} </span>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item-avatar
								color="indigo"
								class="text-h5 font-weight-light white--text"
							>
								<v-img :src="'https://funnyflirts.net/' + item.avater" />
							</v-list-item-avatar>
							<v-list-item-content>
								{{ item.first_name }} {{ item.last_name }}
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</v-toolbar>
				<b-form-input
					v-if="type == 'email'"
					style="border-radius: 0px"
					class="mb-3 mt-5"
					v-model="subject"
					placeholder="Email Subject"
				></b-form-input>

				<!-- start -->
				<!-- <vue-mce :value="myValue" /> -->
				<center v-if="!(type == 'chat')">
					<Editor
						v-model="content"
						:image-provider="imageProvider"
						width="100%"
					/>
				</center>
				<b-form-textarea
					v-else
					class="mt-3"
					v-model="message"
					style="border-radius: 0px; resize: none"
					id="textarea-large"
					size="lg"
					placeholder="Enter message here"
				></b-form-textarea>

				<!-- <editor-content :editor="editor" /> -->
				<!-- end -->
			</b-col>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import axios from "axios";
import Editor from '@baoshishu/vue-editor'
export default {
	props: {
		drawer: Boolean,
		action: String,
		user: Object,
		type: String,
	},
	components: {
		Editor
		// 'vue-mce': component,
		// EditorContent,
		// quillEditor
	},
	watch: {
		search(val) {
			val && val !== this.select && this.querySelections(val);
		},
	},
	data() {
		return {
			data: '',

			showModuleName: false,
			editor: null,
			items: [],
			subject: "",
			myValue: "your value sir",
			message: "",
			authKey: "",
			search: null,
			select: null,
			adminMode: "",
			loading: false,
			editorOption: {},
			loadingSend: false,
			emailSendLoading: false,
			content: `<p><img src="https://funnyflirts.net/upload/bennerimg.png" alt=""></p><h1>WELCOME TO FLIRTTOOL</h1><p>Love Knows No Borders</p><h3><span style="color: #e75480">TITLE SUBJECT</span></h3><p><span style="font-size: 18px">Here is the main message that needs to be here <br></span></p><p><img src="https://funnyflirts.net/themes/default/massets/images/logo/logo.png"></p><p><br></p><p><img src="https://funnyflirts.net/upload/Footer-.png" alt=""></p>`
		};
	},
	created() {
		const authKey = btoa(localStorage.au123);
		this.authKey = authKey;
	},
	methods: {
		closeDrawer() {
			this.$emit("drawerClosed");
		},
		querySelections(v) {
			this.loading = true;
			axios
				.get("https://funnyflirts.net/aj/moderation/search_fake_users?query=" + v, {
					headers: { Authorization: this.authKey },
				})
				.then(({ data }) => {
					this.items = data.users;
					console.warn(this.items);
					//   console.warn(data.users);
				})
				.catch((e) => {
					this.showToaster("Error sending message", "error");
					console.error(e);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		showToaster(message, type = "info") {
			const data = {
				position: "top-center",
				timeout: 2000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: "button",
				icon: true,
				rtl: false,
			};
			switch (type) {
				case "info":
					this.$toast.info(message, data);
					break;
				case "success":
					this.$toast.success(message, data);
					break;
				case "warning":
					this.$toast.warning(message, data);
					break;
				case "error":
					this.$toast.error(message, data);
					break;

				default:
					this.$toast.info(message, data);
					break;
			}
		},
		sendEmail() {
			this.emailSendLoading = true;
			axios
				.post(
					"https://funnyflirts.net/aj/moderation/send_admin_email",
					{
						message: `${this.content}`,
						subject: this.subject,
						target: this.user.id,
					},
					{
						headers: { Authorization: this.authKey },
					}
				)
				.then(({ data }) => {
					if (data) {
						this.showToaster("Email sent !!!", "success");
						this.subject = "";
						this.message = "";
						setTimeout(() => {
							this.$emit("drawerClosed");
						}, 2000);
					}
				})
				.finally(() => {
					this.emailSendLoading = false;
				});
		},
		sendMessage() {
			if (this.select == null && this.type == "chat") {
				this.showToaster("Please select fake user", "error");
			} else if (this.type == "email" && this.subject == "") {
				this.showToaster("Please email subject", "error");
			} else {
				if (this.type == "chat") {
					this.sendMessageFinal();
				} else {
					this.sendEmail();
				}
			}
		},
		sendMessageFinal() {
			this.loadingSend = true;
			axios
				.post(
					"https://funnyflirts.net/aj/moderation/send_message_spammed",
					{
						message: this.message,
						f_src: this.select,
						target: this.user.id,
					},
					{
						headers: { Authorization: this.authKey },
					}
				)
				.then(({ data }) => {
					if (data) {
						this.showToaster("Message sent successfully", "success");
						this.select = null;
						this.message = "";
						setTimeout(() => {
							this.$emit("drawerClosed");
						}, 2000);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => {
					this.loadingSend = false;
				});
			console.warn("FROM -> " + this.select, "TO->", this.user.id);
		},
	},
};
</script>
<style>
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.userName {
	font-size: 20px;
}
#email-editor-container,
#editor-1 {
	height: 70vh;
}
</style>
