<template>
  <v-app
    :style="{ background: $vuetify.theme.themes.light.background }"
    class="mx-2"
  >
    <!-- start of udpate dialog -->
    <v-dialog v-model="updateDialog" max-width="690" persistent>
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            Update gift
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >Entergiftinformationhere</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <v-text-field
              v-model="selectedGift.name"
              outlined
              label="Gift Name"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="selectedGift.cost"
              type="number"
              outlined
              label="Gift Cost"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="error"
            @click="updateDialog = false"
            outlined
            class="mb-12"
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            :loading="loadingUpdate"
            large
            color="primary"
            class="mb-12"
            @click="updateP()"
            >{{$t('SaveChanges')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- start of create dialog -->
    <v-dialog v-model="newModDialog" max-width="690" persistent>
      <v-card style="overflow-x: hidden">
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            Add new gift
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >Enter gift information here</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <v-text-field
              v-model="name"
              outlined
              label="Gift Name"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="cost"
              type="number"
              outlined
              label="Gift Cost"
              :rules="priceRules"
            >
            </v-text-field>
          </v-col>
        <div class="mx-1 mb-2">
            <label
              style="font-size: 20px; font-weight: bold"
              for=""
              class="px-2"
              >gift:
            </label>
            <input
            ref="fileInput"
              type="file"
              @change="onFileChange"
              accept=".jpg, .jpeg, .png"
              class="px-2"
            />
          </div>
        
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="error"
            outlined
            class="mb-12"
            @click="newModDialog = false"
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            large
            :loading="loadingAdd"
            color="primary"
            class="mb-12"
            @click="addGift"
            >+</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mx-3 mt-10">
      <h2>Gifts Management</h2>
      <p>This where we manage and update gifts</p>
    </div>
    <v-row>
        <v-col cols="8"
          dense fixed app>
          <v-container fluid class="mb-10">
              <v-btn class="primary my-4" dark @click="newModDialog = true"
                >Add New</v-btn
              >
              <v-data-table
                :headers="headers"
                :items="gifts"
                :search="search"
                :loading="loadingContent"
              >
              <template v-slot:[`item.asset`]="{ item }">
                <v-avatar class="profile m-3" color="grey" size="100" tile>
                  <v-img :src="getURL(item.asset)">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-row>
                    <v-btn icon color="blue mx-2 my-2 darken-3" @click="update(item)"
                      ><v-icon>mdi-update</v-icon>
                    </v-btn>
                    <div class="mx-2"></div>
                    <v-btn icon color="red mx-2 my-2 darken-3" @click="deleteP(item)"
                      ><v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-container>
        </v-col>
    </v-row>
    
  </v-app>
</template>
<script>
import {
  ADD_GIFT,
  ALL_GIFTS,
  DELETE_GIFT,
  UPDATE_GIFT,
} from "../queries/core";
const PRODUCTION = true
export default {
  apollo: {
    gifts: {
      query: ALL_GIFTS,
      result() {
        this.loadingContent = false;
      },
    },
  },
  created() {
    this.reloadContent();
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.packageCover = files[0];
    },
    update(item) {
      this.selectedGift = item
      this.updateDialog = true;
    },
    updateP() {
      this.loadingUpdate = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_GIFT,
          variables: {
            gift:this.selectedGift.id,
            name:this.selectedGift.name,
            cost: parseInt(this.selectedGift.cost)
          },
        })
        .then(() => {
          this.loadingUpdate = false;
          this.updateDialog = false;
          this.selectedGift = {}
          this.reloadContent();
        })
        .catch((e) => {
          console.error(e);
          this.selectedGift = {}
          this.loadingUpdate = false;
          this.updateDialog = false;
          this.reloadContent();
        });
    },
    deleteP(item) {
      const runD = confirm("Are you sure you want to remove this package ?");
      if (runD)
        this.$apollo
          .mutate({
            mutation: DELETE_GIFT,
            variables: {
              gift:item.id
            },
          })
          .then(() => {
            this.reloadContent();
          })
          .catch((e) => {
            console.error(e);
            this.reloadContent();
          });
    },
    reloadContent() {
      this.loadingContent = true;
      this.$apollo.queries.gifts
        .refetch()
        .then(() => {
          this.loadingContent = false;
        })
        .catch(() => {
          this.loadingContent = false;
        });
    },
    parseDate(date) {
      return new Date(parseInt(date)).toDateString();
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://funnyflirts.net/media/" + url
        : "http://" + location.hostname + ":8080/media/" + url;
      return domain;
    },
    reset(){
      this.selectedGift = {}
      this.name = ''
      this.cost = ''
      this.$refs.fileInput.value = ''
      this.packageCover = null
    },
    addGift() {
      if (this.$refs.form.validate()) {
        if(!this.packageCover) return alert('Please select a file')
        this.loadingAdd = true;
        this.$apollo
          .mutate({
            mutation: ADD_GIFT,
            variables: {
              file: this.packageCover,
              name: this.name,
              cost:parseInt(this.cost)
            },
          })
          .then(() => {
            this.loadingAdd = false;
            this.newModDialog = false;
           this.reset()
            this.reloadContent();
          })
          .catch((e) => {
            this.reset()
            console.error(e);
            this.loadingAdd = false;
            this.reloadContent();
          });
      }
    },
  },
  data() {
    return {
      name:"",
      cost:"",
      selectedGift:{},


      search: "",
      price: null,
      euroPrice:null,
      poundPrice:null,
      selected: null,
      menuEnd: false,
      isRanged: false,
      packageName: "",
      packageDesc: "",
      all_packages: [],
      menuStart: false,
      packageCover: null,
      creditValue: null,
      newModDialog: false,
      updateDialog: false,
      packageType: "otime",
      packageLvlType: "",
      loadingContent: false,
      loadingAdd: false,
      loadingUpdate: false,
      headers: [
        { text: "Gift", name: "asset", value: "asset" },
        {
          text: "Gift Name",
          align: "start",
          filterable: false,
          value: "name",
        },
        { text: "Cost", name: "cost", value: "cost" },
        { text: "Actions", name: "actions", value: "actions" },
      ],
      nameRules: [
        (v) => !!v || "Package name is required",
        (v) =>
          (v && v.length <= 25) ||
          "Package name must be less than 5 characters",
      ],
      priceRules: [(v) => !!v || "Price is required"],
      fileRules: [(v) => !!v || "Gift file is required"],

    };
  },
};
</script>